import react, { useState, useEffect, useContext } from 'react'
import TrainingsPageChanger from '../../webAppComponents/TrainingsPageChanger/TrainingsPageChanger'
import './TrainingsPage.css'
import axios from 'axios'

import { useMediaQuery } from "react-responsive";
import paginatorArrow from "../../webAppAssets/paginator-arrow.svg"
import Sidebar from '../../webAppComponents/Sidebar/Sidebar'
import ConfirmationPopup from '../../webAppComponents/ConfirmationPopup/Popup'
import AuthContext from '../../context/AuthContext'

import ChangeNamePopup from '../../webAppComponents/ChangeNamePopup/ChangeNamePopup.jsx'

import { useNavigate } from 'react-router-dom';
import MoveToFolderPopup from '../../webAppComponents/MoveToFolderPopup/MoveToFolderPopup.jsx';
import { Folder, Training } from '../../webAppComponents/TrainingExerciseFolders/TrainingExerciseFolders.jsx'
import FolderBreadCrumb from '../../webAppComponents/FoldersBreadCrumb/FoldersBreadCrumb.jsx';
import { useTranslation } from "react-i18next";

import BckLogo from "../../webAppAssets/BackgroundLogo.svg"

const TrainingsPage = () => {
    const { t } = useTranslation(['training-page', 'training-page-changer']);
    const [folders, setFolders] = useState([]);
    const [trainings, setTrainings] = useState([]);
    const [createFolderPopup, setCreateFolderPopup] = useState(false)
    const [newFolderName, setNewFolderName] = useState('')
    const [folderMenuPopup, setFolderMenuPopup] = useState(null)
    const [trainingMenuPopup, setTrainingMenuPopup] = useState(null)
    const [moveToFolderPopup, setMoveToFolderPopup] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteTrainingPopup, setDeleteTrainingPopup] = useState(false)
    const [currentRequestedId, setCurrentRequestedId] = useState(null)
    const [currentRequestedFolderId, setCurrentRequestedFolderId] = useState(null)
    const [deleteFolderPopup, setDeleteFolderPopup] = useState(false)
    const [changeFolderNamePopup, setChangeFolderNamePopup] = useState(false)
    const [changeTrainingNamePopup, setChangeTrainingNamePopup] = useState(false)
    const navigate = useNavigate();
    const [newSessionTest, setNewSessionTest] = useState(false)


    const isMobile = useMediaQuery({ maxWidth: 900 });

    const { authTokens } = useContext(AuthContext);

    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    const toggleCreateFolderPopup = () => {
        setCreateFolderPopup(!createFolderPopup)
    }

    useEffect(() => {
        if (createFolderPopup) {
            const input = document.getElementById('webapp-trainings-page-create-folder-popup');
            if (input) {
                input.focus();
                input.select(); // Select the input content
            }
        }
        else if (changeFolderNamePopup) {
            const input = document.getElementById('webapp-change-folder-name-popup');
            if (input) {
                input.focus();
                input.select(); // Select the input content
            }
        }
        else if (changeTrainingNamePopup) {
            const input = document.getElementById('webapp-change-training-name-popup');
            if (input) {
                input.focus();
                input.select(); // Select the input content
            }
        }
    }, [createFolderPopup, changeFolderNamePopup, changeTrainingNamePopup]);

    const toggleFolderMenuPopup = (folderId) => {
        if (folderMenuPopup === folderId) {
            setFolderMenuPopup(null)
        }
        else {
            setFolderMenuPopup(folderId)
        }
    }

    const toggleTrainingMenuPopup = (trainingId, e) => {

        if (trainingMenuPopup === trainingId) {
            setTrainingMenuPopup(null)
        }
        else {
            setTrainingMenuPopup(trainingId)
        }

    }


    const handleFolderNameChange = (e) => {
        setNewFolderName(e.target.value)
    }

    // Create a new folder
    const createNewFolder = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND + 'training/folder/create', {
                name: newFolderName,
            }, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authTokens.access}` }
            });
            if (response.status === 201) {
                toggleCreateFolderPopup()
                getPageItems()
            } else {
                console.log('Login failed. Handle the error appropriately.');
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Get the folders and trainings
    const getPageItems = () => {
        try {
            axios.get(process.env.REACT_APP_BACKEND + 'training/folder/list?parent_folder__isnull=True', { headers })
                .then((response) => {
                    // Handle the successful response here
                    setFolders(response.data)

                })
                .catch((error) => {
                    // Handle any errors that occurred during the request
                    console.error('Error:', error);
                });
        }
        catch (error) {
            console.log(error);
        }
        try {
            axios.get(process.env.REACT_APP_BACKEND + 'training/exercise/list?folder__isnull=True&archive=False', { headers })
                .then((response) => {
                    // Handle the successful response here
                    setTrainings(response.data)
                })
                .catch((error) => {
                    // Handle any errors that occurred during the request
                    console.error('Error:', error);
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    const changeCurrentRequestedId = (id) => {
        setCurrentRequestedId(id)
    }

    const changeCurrentRequestedFolderId = (id) => {
        setCurrentRequestedFolderId(id)
    }


    //ativar popup de confirmacao
    const handleDeleteTrainingPopup = () => {
        setDeleteTrainingPopup(true);
    }
    const handleDeleteFolderPopup = () => {
        setDeleteFolderPopup(true);
    }

    //ativar popup de mudar nome
    const handleChangeFolderNamePopup = () => {
        setChangeFolderNamePopup(true);
    }

    const handleChangeTrainingNamePopup = () => {
        setChangeTrainingNamePopup(true);
    }

    const handleMoveToFolderPopup = () => {
        setMoveToFolderPopup(true);
    }


    const handleEditTrainingPopup = (id) => {
        for (let i = 0; i < trainings.length; i++) {
            if (trainings[i].id === id) {
                navigate('create', { state: { training: trainings[i], editMode: true } }); // Pass the found training object to the 'create' screen
                return;
            }
        }
    }


    const handleCreateTraining = () => {
        navigate('create')
    }


    const handleCanvasOpen = (id, imageLink) => {
        localStorage.setItem('training_image', imageLink);
        navigate(`/webapp/canvas/${id}`);
    };



    //delete a training
    const deleteTrainingRequest = async () => {
        try {
            // Make a DELETE request to your Django API
            axios.delete(process.env.REACT_APP_BACKEND + `training/exercise/delete/${currentRequestedId}`, { headers })
                .then((response) => {

                    getPageItems()
                })
                .catch((error) => {
                    // Handle any errors that occurred during the request
                    console.error('Error:', error);
                })
        }
        catch (error) {
            console.log(error);
        }


    };

    const changeTrainingName = async (name) => {
        setChangeTrainingNamePopup(false)
        try {
            axios.patch(process.env.REACT_APP_BACKEND + `training/exercise/update/${currentRequestedId}`, {
                name: name,
            }, { headers })
                .then((response) => {
                    // Handle the successful response here
                    getPageItems()
                })
                .catch((error) => {
                    // Handle any errors that occurred during the request
                    console.error('Error:', error);
                });
        }
        catch (error) {
            console.log(error);
        }
    }

    //delete a folder
    const deleteTrainingFolderRequest = async () => {
        try {
            // Make a DELETE request to your Django API
            axios.delete(process.env.REACT_APP_BACKEND + `training/folder/delete/${currentRequestedFolderId}`, { headers })
                .then((response) => {

                    getPageItems()
                })
                .catch((error) => {
                    // Handle any errors that occurred during the request
                    console.error('Error:', error);
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    //alterar nome da pasta
    const changeFolderName = async (name) => {
        setChangeFolderNamePopup(false)
        try {
            axios.patch(process.env.REACT_APP_BACKEND + `training/folder/update/${currentRequestedFolderId}`, {
                name: name,
            }, { headers })
                .then((response) => {
                    // Handle the successful response here
                    getPageItems()
                })
                .catch((error) => {
                    // Handle any errors that occurred during the request
                    console.error('Error:', error);
                });
        }
        catch (error) {
            console.log(error);
        }
    }

    const addToFolder = async (folder) => {
        try {
            axios.patch(process.env.REACT_APP_BACKEND + `training/exercise/update/${currentRequestedId}`, { folder: folder }, { headers })
                .then((response) => {
                    getPageItems();
                }
                )
                .catch((error) => {
                    console.error('Error:', error);
                }
                );
            setMoveToFolderPopup(false)
        }
        catch (error) {
            console.log(error);
        }

    }

    const postsPerPage = isMobile ? 4 : 10;
    const totalPosts = trainings.length;

    const totalPages = Math.ceil(totalPosts / postsPerPage);


    const startIndex = (currentPage - 1) * postsPerPage;
    const endIndex = startIndex + postsPerPage;

    const currentTrainings =
        trainings.slice(startIndex, endIndex)



    let displayEllipsis = false;


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        getPageItems()

    }, [])

    const createNewSessionTest = () => {
        setNewSessionTest(!newSessionTest)
    }

    const placeBackgroundImages = () => {
        const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
        const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
        const bckLogo1Height = bckLogo1.offsetHeight;
        bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
        bckLogo2.style.top = `0%`;
        const bckLogo1Width = bckLogo1.offsetWidth;
        bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
        bckLogo2.style.left = `75%`;
    }

    useEffect(() => {
        placeBackgroundImages();
        window.addEventListener('resize', placeBackgroundImages);
        return () => {
            window.removeEventListener('resize', placeBackgroundImages);
        }
    }, [])

    return (
        <div className="webapp-trainings-page-scroll">
            <Sidebar selectedOption="trainings" />
            <div className="webapp-trainings-page">
                <div className="webapp-create-team-background-images">
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
                </div>
                {createFolderPopup ? (
                    <div className="webapp-trainings-page-create-folder-popup">
                        <form className="webapp-create-folder-popup-container" onSubmit={createNewFolder}>
                            <h1>{t('New Folder')}</h1>
                            <input id="webapp-trainings-page-create-folder-popup" placeholder={t('Folder Name')} onChange={handleFolderNameChange}></input>
                            <div>
                                <span onClick={toggleCreateFolderPopup} className="webapp-cancel-folder-button">{t('Cancel')}</span>
                                <button type="submit" className="webapp-create-folder-button">{t('Create')}</button>
                            </div>
                        </form>
                    </div>
                ) : null}
                {deleteTrainingPopup ? (<ConfirmationPopup data={{ image: "close", message: [t('delete-exercise-popup.message'), ""], close: t('Cancel'), advance: t('delete-exercise-popup.delete-exercise')}} setState={setDeleteTrainingPopup} action={deleteTrainingRequest} />) : null}
                {deleteFolderPopup ? (<ConfirmationPopup data={{ image: "close", message: [t('delete-folder-popup.message'), ""], close: t('Cancel'), advance: t('delete-folder-popup.delete-folder')}} setState={setDeleteFolderPopup} action={deleteTrainingFolderRequest} />) : null}
                {changeFolderNamePopup ? (<ChangeNamePopup id={"webapp-change-folder-name-popup"} data={{ message: t('change-folder-name-popup.message'), close: t('Cancel'), advance: t('change-folder-name-popup.change'), placeholder: t('change-folder-name-popup.folder-name') }} setState={setChangeFolderNamePopup} changeName={changeFolderName} />) : null}
                {changeTrainingNamePopup ? (<ChangeNamePopup id={"webapp-change-exercise-name-popup"} data={{ message: t('change-exercise-name-popup.message'), close: t('Cancel'), advance: t('change-exercise-name-popup.change'), placeholder: t('change-exercise-name-popup.exercise-name') }} setState={setChangeTrainingNamePopup} changeName={changeTrainingName} />) : null}
                {moveToFolderPopup ? (<MoveToFolderPopup setState={setMoveToFolderPopup} id={currentRequestedId} type="exercise" addToFolder={addToFolder}/>) : null}
                <div className="webapp-trainings-page-subsections"  >
                    <TrainingsPageChanger
                        selectedPage={0}
                        pages={[
                            { label: t('Your Exercises'), path: '/webapp/trainings' },
                            { label: t('Your Training Sessions'), path: '/webapp/sessions' },
                        ]}
                    />
                </div>
                <div className="webapp-trainings-page-folders-container">
                    <div className="trainings-folder-container-header">
                        <FolderBreadCrumb folder={null}/>

                        <button onClick={toggleCreateFolderPopup}>+ {t('New Folder')}</button>
                    </div>
                    <div className="webapp-trainings-folder-grid">
                        {folders.map((folder) => (
                            <Folder key={folder.id} folder={folder} folderMenuPopup={folderMenuPopup} toggleFolderMenuPopup={toggleFolderMenuPopup} changeCurrentRequestedId={changeCurrentRequestedFolderId} handleDeleteFolderPopup={handleDeleteFolderPopup} handleChangeFolderNamePopup={handleChangeFolderNamePopup} editable={true} />
                        ))}
                    </div>
                </div>
                <div className="webapp-trainings-page-trainings-container">
                    <div className="trainings-folder-container-header">
                        <h1>{t('Exercises')}</h1>
                        <button onClick={handleCreateTraining}>+ {t('New Exercise')}</button>
                    </div>
                    <div className="webapp-trainings-trainings-grid">
                        {currentTrainings.map((training, index) => (
                            <Training key={training.id} index={index} numberOfExercises={currentTrainings.length} training={training} toggleTrainingMenuPopup={toggleTrainingMenuPopup} trainingMenuPopup={trainingMenuPopup} isMobile={isMobile} changeCurrentRequestedId={changeCurrentRequestedId} handleDeleteTrainingPopup={handleDeleteTrainingPopup} handleChangeTrainingNamePopup={handleChangeTrainingNamePopup} handleEditTrainingPopup={handleEditTrainingPopup} setCurrentRequestedId={setCurrentRequestedId} handleMoveToFolderPopup={handleMoveToFolderPopup} editable={true} />
                        ))}
                    </div>
                    <div className="webapp-trainings-pagination">
                        <div></div>
                        <div className="webapp-trainings-pagination-page-changer">
                            <img src={paginatorArrow}
                                onClick={() =>
                                    handlePageChange(currentPage - 1)
                                }
                                className={
                                    currentPage === 1 ? "disabled trainings-left-paginator-arrow" : "trainings-left-paginator-arrow"
                                }
                            />
                            <div className="webapp-trainings-pagination-numbers">
                                {Array.from({ length: totalPages }).map(
                                    (_, index) => {
                                        index = index + 1;

                                        // Case for displaying buttons 1 to 5 when totalPages is 5 or less
                                        if (totalPages <= 3) {
                                            return (
                                                <button
                                                    key={index}
                                                    onClick={() =>
                                                        handlePageChange(
                                                            index
                                                        )
                                                    }
                                                    className={
                                                        currentPage ===
                                                            index
                                                            ? "trainings-paginator-active"
                                                            : ""
                                                    }>
                                                    {index}
                                                </button>
                                            );
                                        }

                                        // Case for displaying buttons with ellipsis when totalPages is greater than 5
                                        else {
                                            // Flag to track if ellipsis is displayed

                                            // Displaying buttons 1, 2, ..., 5 and the last page
                                            if (
                                                index === currentPage + 1 ||
                                                index === currentPage - 1 ||
                                                index === currentPage ||
                                                index === 1 ||
                                                index === totalPages
                                            ) {
                                                // If the current button is one of the above, show the page number
                                                displayEllipsis = false;
                                                return (
                                                    <button
                                                        key={index}
                                                        onClick={() =>
                                                            handlePageChange(
                                                                index
                                                            )
                                                        }
                                                        className={
                                                            currentPage ===
                                                                index
                                                                ? "active"
                                                                : ""
                                                        }>
                                                        {index}
                                                    </button>
                                                );
                                            } else if (
                                                index >= currentPage + 2 ||
                                                index <= currentPage - 2
                                            ) {
                                                // If the current button is two positions away from the selected page, show an ellipsis

                                                if (
                                                    displayEllipsis === true
                                                ) {
                                                    // If the ellipsis flag is true, skip the affected indexes by returning null
                                                    return null;
                                                } else {
                                                    displayEllipsis = true;
                                                    // Otherwise, render the index as a button
                                                    return <span>...</span>;
                                                }
                                            }
                                        }
                                    }
                                )}
                            </div>
                            <img src={paginatorArrow}
                                onClick={() =>
                                    handlePageChange(currentPage + 1)
                                }
                                className={
                                    currentPage === totalPages ? "disabled trainings-right-paginator-arrow" : "trainings-right-paginator-arrow"
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )


}

export default TrainingsPage
