import { useEffect, useRef, useState } from 'react';
import './CreateInjuryElements.css';
import InjuryDetail from '../../webAppPages/InjuryDetail/InjuryDetail';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import { Body, Xray, BandageAdhesive, Bandaged, ExerciseRunning, ExerciseWeights, Sling, Symptom, ChartLine, Calendar, ChartCuredIncreasing, HealthDataSecurity, Observation, BrokenBone, ClinicalF } from '../../webAppAssets/InjuryDetails/ExportIcons';
import axios from 'axios';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../context/AuthContext';

const CreateInjuryElements = (props) => {



    if (props.step === 1) {
        return (
            <InjuryInfoForm handleChangeInjuryData={props.handleChangeInjuryData} injuryData={props.injuryData} options={props.options} t={props.t} />

        )
    } else if (props.step === 2) {
        return (
            <InjuryTimeForm handleChangeInjuryData={props.handleChangeInjuryData} injuryData={props.injuryData} t={props.t} />
        )

    } else if (props.step === 3) {
        return (
            <InjuryMicrocycle handleChangeInjuryData={props.handleChangeInjuryData} injuryData={props.injuryData} t={props.t} />
        )
    }

}


const InjuryInfoForm = (props) => {
    const [InjuryFormElements, setInjuryFormElements] = useState({});

    useEffect(() => {
        setInjuryFormElements(props.options)
    }, [props.options])

    return (
        <div className="webapp-create-injury-info-grid">
            {Object.entries(InjuryFormElements).map(([fieldToChange, choices]) => (
                <InjuryDropdown
                    key={fieldToChange}
                    fieldToChange={fieldToChange}
                    choices={choices.map((choice) => choice)} // Assuming choices is an object
                    functionToChange={props.handleChangeInjuryData}
                    label={fieldToChange}
                    formData={props.injuryData}
                    t={props.t}
                />
            ))}
        </div>
    );
}

// This will not work in safari, user will to click on the input to open the date picker
const handlePicker = (id) => {
    const dateInput = document.getElementById(id);
    if (dateInput.type !== 'date') return;
    dateInput.showPicker();
}

const InjuryTimeForm = (props) => {
    const { t } = useTranslation(['injuries-page']);
    const { authTokens } = useContext(AuthContext);
    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };


    const getInjuryMonth = () => {
        if (props.injuryData.injury_date === null) return;

        const date = new Date(props.injuryData.injury_date);
        return date.toLocaleString('default', { month: 'long' });
    }

    const daysDiff = (start_date, end_date) => {
        if (start_date === null || end_date === null) return;
        const start = new Date(start_date);
        const end = new Date(end_date);
        const diffTime = Math.abs(end - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }


    return (
        <div className="webapp-create-injury-time-form">
            <div>
                <div>
                    <label>{props.t("injury_date")}<span className='webapp-asterisco'>*</span></label>
                    <div id="injury_date" className='date-input-holder'>
                        <img className='webapp-injuries-date-input-icon' src={ClinicalF} onClick={() => handlePicker('injury-date')} /> <div className='date-wrapper'><input id="injury-date" value={props.injuryData.injury_date} onChange={(e) => props.handleChangeInjuryData('injury_date', e.target.value)} className='injury-info-date' type='date' /></div>
                    </div>
                </div>
                <div>
                    <label>{props.t("Discharged date")}</label>
                    <div id="discharged_date" className='date-input-holder'>
                        <img className='webapp-injuries-date-input-icon' src={HealthDataSecurity} onClick={() => handlePicker('discharged-date')} /> <div className='date-wrapper'><input id="discharged-date" className='injury-info-date' value={props.injuryData.discharged_date} onChange={(e) => props.handleChangeInjuryData('discharged_date', e.target.value)} type='date' min={props.injuryData.injury_date} /></div>
                    </div>
                </div>
            </div>
            <div>
                <div className='webapp-injuries-no-input-img'>
                    <label>{props.t("Injury month")}</label>
                    <div className='webapp-injuries-flex-row'>
                        <img src={BrokenBone} /><span> {t(getInjuryMonth())} </span>
                    </div>
                </div>

                <div>
                    <label>{props.t("Return date")}</label>
                    <div id="return_date" className='date-input-holder'>
                        <img className='webapp-injuries-date-input-icon' src={ChartCuredIncreasing} onClick={() => handlePicker('return-date')} /> <div className='date-wrapper'><input id="return-date" value={props.injuryData.return_date} onChange={(e) => props.handleChangeInjuryData('return_date', e.target.value)} className='injury-info-date' type='date' min={props.injuryData.injury_date} /></div>
                    </div>
                </div>
            </div>
            <div>
                <div>

                </div>
                <div className='webapp-injuries-no-input-img'>
                    <label>{props.t("Days Without Training")}</label>
                    <div className='webapp-injuries-flex-row'>
                        <img src={Calendar} /><p> {daysDiff(props.injuryData.injury_date, props.injuryData.discharged_date)} {t('days')} </p>
                    </div>
                </div>
            </div>
            <div>
                <div>

                </div>
                <div className='webapp-injuries-no-input-img'>
                    <label>{props.t("Days Out")}</label>
                    <div className='webapp-injuries-flex-row'>
                        <img src={Observation} /><p>{daysDiff(props.injuryData.injury_date, props.injuryData.return_date)} {t('days')} </p>
                    </div>
                </div>
            </div>
        </div>
    )
}


const InjuryMicrocycle = (props) => {

    const { t } = useTranslation(['injuries-page']);
    return (
        <div className="webapp-create-injury-microcycle">
            <div className='webapp-injurry-microcycle-flex-column'>
                <label>{t('LU Last microcycle')}</label>
                <p> {props.injuryData.uc_before_injury === null ? 0 : props.injuryData.uc_before_injury} </p>
            </div>
            <div className='webapp-injurry-microcycle-flex-column'>
                <label>{t('UC MC before return')}</label>
                <p> {props.injuryData.first_uc_after_injury === null ? 0 : props.injuryData.first_uc_after_injury} </p>
            </div>
            <div className='webapp-injurry-microcycle-flex-column'>
                <label>{t('Difference MC')}</label>
                <p> {props.injuryData.first_uc_after_injury === null ? 0 : props.injuryData.uc_before_injury - props.injuryData.first_uc_after_injury} </p>
            </div>
        </div>
    )

}


const InjuryDropdown = (props) => {

    const [icon, setIcon] = useState("")
    const [openDropdown, setOpenDropdown] = useState(false)
    const dropdownRef = useRef(null);

    const handleOpenDropdown = (fieldToChange) => {
        if (openDropdown === fieldToChange) {
            setOpenDropdown(false)
        }
        else {
            setOpenDropdown(fieldToChange)
        }
    }


    const option = {
        'mechanism': { icon: Body, label: props.t('Mechanism') },
        'type': { icon: Sling, label: props.t('Type') },
        'anatomical_location': { icon: Xray, label: props.t('Anatomical location') },
        'specific_zone': { icon: Symptom, label: props.t('Zone') },
        'laterality': { icon: Bandaged, label: props.t('Laterality') },
        'context': { icon: ExerciseWeights, label: props.t('Context') },
        'specific_context': { icon: ExerciseRunning, label: props.t('Specific context') },
        'detailed_context': { icon: BandageAdhesive, label: props.t('Detailed context') },
        'exercise_specificity': { icon: ChartLine, label: props.t('SI of ther provocative exercise') },
    }


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenDropdown(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    return (
        <div className="webapp-create-injury-dropdown">
            <label> {option[props.fieldToChange]?.label} <span className='webapp-asterisco'>*</span></label>

            <div id={props.fieldToChange} className="injury-dropdown-input" onClick={() => handleOpenDropdown(props.fieldToChange)} ref={dropdownRef}>
                <div className='injury-dropdown-input-placeholder'>
                    <img src={option[props.fieldToChange]?.icon} />
                    <p className='injury-dropdown-text-holder'> {props.formData[props.fieldToChange]?.name === undefined ? props.t(option[props.fieldToChange]?.label) : props.t(props.formData[props.fieldToChange].name)} </p>
                </div>
                {!(props.fieldToChange === openDropdown) ? <div onClick={(e) => {
                    e.stopPropagation(); handleOpenDropdown(props.fieldToChange);
                }}>
                    <BiSolidDownArrow />
                </div>
                    : <BiSolidUpArrow />}
                {props.fieldToChange === openDropdown && (
                    <div className="injury-form-multiple-choice-dropdown">
                        {props.choices.map((choice) => {
                            return (
                                <div key={choice.id} className={props.formData[props.fieldToChange]?.id === choice?.id ? "selected-option-dropdown" : ""}  onClick={() => props.functionToChange(props.fieldToChange, choice.id, choice.name)}>{props.t(choice.name)}</div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>

    )


}


export default CreateInjuryElements;