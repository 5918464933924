import React, { useState } from "react";
import "./RegisterPage.css";
import backArrow from "../../webAppAssets/register-arrow-right.svg" 
import blueLogo from "../../webAppAssets/navbar-logo.svg"
import { Link, useNavigate } from "react-router-dom";
import showHidePasswordButton from "../../webAppAssets/show-hide-password.svg"
import backgroundThreeFirst from "../../webAppAssets/blue-background-three.svg";
import Axios from "axios";
import { useTranslation } from "react-i18next";


const RegisterPage = () => {
    const { t } = useTranslation(['register-page']);

    const navigate = useNavigate();

    //useStates para mostrar as senhas
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    //useStates para guardar as variaveis
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    
    //alterar a visibilidade da pass

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    //Alterar valores das variaveis
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };
    
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };
    
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };    
    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };




    const handleSubmit = async (e) => {
        
        e.preventDefault();
    
        // Check if the password matches the confirm password
        if (password !== confirmPassword) {
            alert("Password and confirm password do not match.");
            return;
        }
    
        // Check if the email is a valid email address using a regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          alert("Invalid email address.");
          return;
        }

        // Check if the username is a valid username address (if there is no other accoutn with the same username)
        try {
            const response = (await Axios.get(process.env.REACT_APP_BACKEND + `users/username-exists/${username}`))

            if (response.status === 200) {

                const exists = response.data.exists;
                
                if (exists === true) {
                    alert("Username already exists.");
                } else {
                    navigate('/webapp/register-packages' , {state: {username: username, email: email, password: password}})
                }

            } else {
                console.log(response)
            }

        } catch (error) {
            console.log(error);
        }       
      };



    return (
        <div className="webapp-register-page">
            <img className="webapp-register-left-background-three" src={backgroundThreeFirst}></img>
            <img className="webapp-register-right-background-three" src={backgroundThreeFirst}></img>
            <div className="webapp-register-container">
            <div className="webapp-register-icons">
                <Link to="/webapp"><img src={backArrow}></img></Link>
                <img src={blueLogo}></img>
            </div> 
            <div className="webapp-register-form-container">
                <h1>{t('Create an Account')}</h1>
                <form className="webapp-register-form" onSubmit={handleSubmit}>
                    <div className=" webapp-register-form-input-divs">
                        <label className="dark-blue-15px-700">{t('Username')} <span className="webapp-asterisco">*</span></label>
                        <input placeholder={t('Username')} value={username} onChange={handleUsernameChange}></input>
                    </div>
                    <div className="webapp-register-form-input-divs">
                        <label className="dark-blue-15px-700">{t('Email')} <span className="webapp-asterisco">*</span></label>
                        <input placeholder={t('Email')} value={email} onChange={handleEmailChange}></input>
                    </div>
                    <div className="webapp-register-form-input-divs">
                        <label className="dark-blue-15px-700">{t('Password')} <span className="webapp-asterisco">*</span></label>
                        <input type={showPassword ? 'text': 'password'} placeholder={t('Password')} value={password} onChange={handlePasswordChange}></input>
                        <img  src = {showHidePasswordButton} onClick={togglePasswordVisibility} className= {`password-toggle ${showPassword ? 'visible' : '' }`}>
                        </img>
                    </div>
                    <div className="webapp-register-form-input-divs">
                        <label className="dark-blue-15px-700">{t('Confirm Password')}<span className="webapp-asterisco">*</span></label>
                        <input type={showConfirmPassword ? 'text': 'password'} placeholder={t('Confirm Password')} value= {confirmPassword} onChange={handleConfirmPasswordChange}></input>
                        <img  src = {showHidePasswordButton} onClick={toggleConfirmPasswordVisibility} className= {`password-toggle ${showConfirmPassword ? 'visible' : '' }`}>
                        </img>
                    </div>

                    <div className="webapp-register-submit-button">
                        <button  type="submit">{t('Create Account')}</button>
                        <p className="black-16px-400">{t('Already have an account')} <Link to="/webapp/login"><span className="webapp-register-login">{t('Log in')}</span></Link></p>
                    </div>
                </form>
            </div>
        </div>
        </div>
    );
};

export default RegisterPage;