import "./GraphsUC.css";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import DashboardFilters from "../DashboardFilters/DashboardFilters";

import { Chart as ChartJS, CategoryScale, BarElement, LinearScale, PointElement, LineElement, Tooltip, LineController, BarController} from "chart.js";
import { Chart } from "react-chartjs-2";
import { use } from "i18next";


ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Tooltip, LineController, BarController);

const GraphsUC = ({data, filters, handle, type }) => {
    const { t } = useTranslation(["charts-uc"]);

    const [graph1, setGraph1] = useState(null);
    const [graph2, setGraph2] = useState(null);
    const [graph3, setGraph3] = useState(null);

    useEffect(() => {
        setGraph1({
            labels: data.labels,
            datasets: [
                {
                    type: 'line',
                    label: t("ACWR Ratio"),
                    borderColor: 'rgb(252,165,85, 0)',
                    backgroundColor: 'rgb(4, 14, 100)',
                    borderWidth: 5,
                    pointRadius: 6,
                    data: data.graph1.line,
                    yAxisID: 'y1',
                },
                {
                    type: 'bar',
                    label: t("Total MC Load"),
                    backgroundColor: 'rgb(105, 120, 249)',
                    data: data.graph1.bar1,
                    borderWidth: false,
                    borderRadius: 10,
                    yAxisID: 'y',
                },
                {
                    type: 'bar',
                    label: t("Chronic Load"),
                    backgroundColor: 'rgb(252, 165, 85)',
                    data: data.graph1.bar2,
                    borderWidth: false,
                    borderRadius: 10,
                    yAxisID: 'y',
                },
            ],
        });

        setGraph2({
            labels: data.labels,
            datasets: [
                {
                    type: 'line',
                    label: t("Difference (%) ACWR"),
                    borderColor: 'rgb(252,165,85, 0)',
                    backgroundColor: 'rgb(4, 14, 100)',
                    borderWidth: 5,
                    pointRadius: 6,
                    data: data.graph2.line,
                    yAxisID: 'y1',
                },
                {
                    type: 'bar',
                    label: t("Total MC Load"),
                    backgroundColor: 'rgb(105, 120, 249)',
                    data: data.graph2.bar1,
                    borderWidth: false,
                    borderRadius: 10,
                    yAxisID: 'y',
                },
                {
                    type: 'bar',
                    label: t("Chronic Load"),
                    backgroundColor: 'rgb(252, 165, 85)',
                    data: data.graph2.bar2,
                    borderWidth: false,
                    borderRadius: 10,
                    yAxisID: 'y',
                },
            ],
        });

        setGraph3({
            labels: data.labels,
            datasets: [
                {
                    type: 'line',
                    label: t("Sum of Total MC Load"),
                    borderColor: 'rgb(252,165,85, 0)',
                    backgroundColor: 'rgb(4, 14, 100)',
                    borderWidth: 5,
                    pointRadius: 6,
                    data: data.graph3.line,
                    yAxisID: 'y',
                },
                {
                    type: 'bar',
                    label: t("Total Average E.I."),
                    backgroundColor: 'rgb(252,165,85)',
                    data: data.graph3.bar1,
                    borderWidth: false,
                    borderRadius: 10,
                    yAxisID: 'y1',
                }
            ],
        });
        
    }, [data]);

    const options = {
    responsive: true,
    plugins: {
        legend: {
        display: false,
        },
        title: {
        display: false,
        },
    },
    scales: {
        y: {
            type: 'linear',
            display: true,
            position: 'left',
        },
        y1: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
                drawOnChartArea: false,
            },
        },
        },
    };

    return (
        <div className="webapp-graphuc">
            <div className="webapp-graphuc-filters">
                {filters?.map((filter) => {
                return(
                    <DashboardFilters name={filter.name} filters={filter.content} handle={handle} type={type} isUC={true}/>
                )
                })}
            </div>
            {graph1 && <div className="webapp-graphuc-content">
                <div className="webapp-graphuc-header">
                    <h2 className="webapp-graphuc-header-title">{t("ACWR and Ratio")}</h2>
                </div>
                <div className="webapp-graphuc-chart">
                    <Chart data={graph1} options={options}/>
                </div>
            </div>}
            {graph2 &&<div className="webapp-graphuc-content">
                <div className="webapp-graphuc-header">
                    <h2 className="webapp-graphuc-header-title">{t("UC Difference and Percentual Variation")}</h2>
                </div>
                <div className="webapp-graphuc-chart">
                    <Chart data={graph2} options={options}/>
                </div>
            </div>}
            {graph3 && <div className="webapp-graphuc-content">
                <div className="webapp-graphuc-header">
                    <h2 className="webapp-graphuc-header-title">{t("Load Unit and E.I.")}</h2>
                </div>
                <div className="webapp-graphuc-chart">
                    <Chart data={graph3} options={options}/>
                </div>
            </div>}
        </div>
    );
}

export default GraphsUC;