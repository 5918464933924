import React, { useState } from "react";
import "./ResetPasswordPage.css";
import backArrow from "../../webAppAssets/register-arrow-right.svg" 
import blueLogo from "../../webAppAssets/navbar-logo.svg"
import { Link, useNavigate } from "react-router-dom";
import showHidePasswordButton from "../../webAppAssets/show-hide-password.svg"
import backgroundThreeFirst from "../../webAppAssets/blue-background-three.svg";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from 'react-toastify';


const ResetPasswordPage = () => {
    const { t } = useTranslation(['login-page']);

    const navigate = useNavigate();
    const [password1, setPassword1] = useState("");
    const handlePassowrd1Change = (e) => {
        setPassword1(e.target.value);
    }
    const [password2, setPassword2] = useState("");
    const handlePassowrd2Change = (e) => {
        setPassword2(e.target.value);
    }

    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password1 === "" || password2 === ""){
            toast.warning("Please fill in all the fields")
            return;
        }

        if (password1 !== password2){
            toast.warning("Passwords don't match")
            return;
        }

        axios.patch(process.env.REACT_APP_BACKEND + 'users/recover-password', {
            password1: password1,
            password2: password2,
            url : window.location.pathname
        })
        .then((response) => {
            navigate("/webapp/login", {state: {reset_toast: true}});
        })
        .catch((error) => {
            console.log(error);
        });
    }

    return (
        <div className="webapp-login-page">
            <img className="webapp-register-left-background-three" src={backgroundThreeFirst}></img>
            <img className="webapp-register-right-background-three" src={backgroundThreeFirst}></img>
            <div className="webapp-login-container">
            <div className="webapp-login-icons">
                <Link to="/webapp/login"><img src={backArrow}></img></Link>
                <img src={blueLogo}></img>
            </div> 
            <div className="webapp-register-form-container">
                <h1>{t('recover-password')}</h1>
                <span className="webapp-recover-password-subtitle">{t('reset-password-subtitle')}</span>
                <form className="webapp-register-form" onSubmit = {(e) => handleSubmit(e)}>
                    <div className="webapp-register-form-input-divs">
                        <label className="dark-blue-15px-700">{t('new-password')} <span className="webapp-asterisco">*</span></label>
                        <input name="password" type={showPassword ? 'text': 'password'} placeholder={t('new-password')} value={password1} onChange={handlePassowrd1Change}></input>
                        <img  src = {showHidePasswordButton} onClick={togglePasswordVisibility} className= {`password-toggle ${showPassword ? 'visible' : '' }`}>
                        </img>
                    </div>
                    <div className="webapp-register-form-input-divs">
                        <label className="dark-blue-15px-700">{t('confirm-password')} <span className="webapp-asterisco">*</span></label>
                        <input name="password" type={showPassword ? 'text': 'password'} placeholder={t('confirm-password')} value={password2} onChange={handlePassowrd2Change}></input>
                        <img  src = {showHidePasswordButton} onClick={togglePasswordVisibility} className= {`password-toggle ${showPassword ? 'visible' : '' }`}>
                        </img>
                    </div>
                    <div className="webapp-register-submit-button">
                        <button type="submit">{t('submit')}</button>
                    </div>
                </form>
            </div>
        </div>
        </div>
    );
};

export default ResetPasswordPage;