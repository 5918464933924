import { useState } from "react";
import "./FormsGame.css"

const GamesForm = ({lab, setLab, labels, setValue, t }) => {

    const handleClick = (label) => {
        setValue(label)
        setLab(label)
    }

    return (
        <div className="games-form-container">
            {labels.map((label) => (
                <p key={label} className={`games-forms-label ${lab == label && 'games-forms-label-selected'}`} onClick={() => handleClick(label)}>
                    {t(label)}
                </p>
            ))}

        </div>
    )
}

export default GamesForm;