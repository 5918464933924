import { useEffect, useState } from 'react'

import './Forms.css'

const Forms = ({ labels, onChange, formType, initialValue }) => {
    const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        // Atualiza o valor ao mudar o initialValue
        setValue(initialValue);
    }, [initialValue]);
    
    const handleClick = (e) => {
        const selectedValue = e.target.textContent;
        setValue(selectedValue);
        onChange(formType, selectedValue);
    }

    return (
        <div className='webapp-forms'>
            <h1 className='webapp-forms-title'>{labels.title} <span className="webapp-asterisco">*</span> </h1>
            <div className='webapp-forms-form'>
                {numbers.map((number) => (
                    <p className={`webapp-forms-form-input ${value == number && 'webapp-forms-form-selected'}`} onClick={handleClick}>
                        {number}
                    </p>
                ))}
            </div>
            <div className='webapp-forms-labels'>
                <p className='webapp-forms-label webapp-forms-label-left'> {labels.bad} </p>
                <p className='webapp-forms-label webapp-forms-label-center'> {labels.mid}</p>
                <p className='webapp-forms-label webapp-forms-label-right'> {labels.good} </p>
            </div>
        </div>
    )
}

export default Forms