
import React, {useState} from "react";
import "./Popup.css"
import successIcon from "../../assets/success-icon.svg"

const Popup = ({data, onClose, onSubmit, selectedLanguage}) => {
    
    const [success, setSuccess] = useState(false)
    
    const toggleSuccess = () => {
        setSuccess(!success)
    }

    const handleSubmission = () => {
        toggleSuccess();
        setTimeout(() => {
            onSubmit();
        }, 5000); // 5000 milliseconds = 5 seconds
    };


    const popupLanguageDict = {
        PT: {
            popupSuccess: 'SUCESSO!',
            popupText1: 'Agradecemos encarecidamente o seu contacto',
            popupText2: 'Em breve receberá, no E-mail que forneceu',
            popupText3: 'Até breve,',
            popupText4: 'A Equipa 3D Training Control',
            popupVoltarAtras : "Voltar à página inicial",
            popupHeader: 'Tem a certeza que pretende submeter a seguinte informação?',
            popupRelatorio: 'RELATÓRIO DE FORMULÁRIO DE CONTACTO',
            popupDepartamento: 'Departamento a contactar',
            popupNome: 'Nome Completo',
            popupEmail: 'Email',
            popupMensagem: 'Mensagem',
            popupSubmeter: 'Submeter',
            popupEditar: 'Editar Dados',
        },
        ES: {
            popupSuccess: 'EXITO!',
            popupText1: "Muchas gracias por ponerse en contacto con nosotros",
            popupText2: "Pronto recibirá, en el correo electrónico que proporcionó",
            popupText3: "Hasta pronto,",
            popupText4: "El equipo 3D Training Control",
            popupVoltarAtras: "Volver a la página de inicio",
            popupHeader: '¿Estás seguro de que quieres enviar la siguiente información?',
            popupRelatorio: 'INFORME DEL FORMULARIO DE CONTACTO',
            popupDepartamento: 'Departamento a contactar',
            popupNome: 'Nombre completo',
            popupEmail: 'Email',
            popupMensagem: 'Mensaje',
            popupSubmeter: 'Enviar',
            popupEditar: 'Editar Datos',

        },
        EN: {
            popupSuccess: 'SUCCESS!',
            popupText1: 'Thank you very much for contacting us',
            popupText2: 'Soon you will receive, in the email you provided',
            popupText3: 'See you soon,',
            popupText4: 'The 3D Training Control Team',
            popupVoltarAtras: "Back to homepage",
            popupHeader: 'Are you sure you want to submit the following information?',
            popupRelatorio: 'CONTACT FORM REPORT',
            popupDepartamento: 'Department to contact',
            popupNome: 'Full Name',
            popupEmail: 'Email',
            popupMensagem: 'Message',
            popupSubmeter: 'Submit',
            popupEditar: 'Edit Data',
            

        }
    };
   
    return (
        <div className="popup">
            
            {success ? (
            <div className="popup-success-inner">
                <div className="success-icon"><img src={successIcon}></img> <h4>{popupLanguageDict[selectedLanguage].popupSuccess}</h4> </div>
                <div className="success-text">
                    <p className="text-22px">{popupLanguageDict[selectedLanguage].popupText1} <span className="strong-text">{data.user_name}</span>.</p>
                    <p className="text-22px">{popupLanguageDict[selectedLanguage].popupText2} <span className="strong-text">( {data.user_email})</span></p>
                    <p className="text-22px">{popupLanguageDict[selectedLanguage].popupText3}</p>
                    <p className="text-22px">{popupLanguageDict[selectedLanguage].popupText4}</p>

                </div>
                <div className="sucess-button"><button className="submit-button" onClick={onClose}>{popupLanguageDict[selectedLanguage].popupVoltarAtras}</button></div>
            </div>
            ) : <div className="popup-inner">
            <div className="popup-header">
                <h2>{popupLanguageDict[selectedLanguage].popupHeader}</h2>
            </div>
            <div className="popup-body">
                <h3>{popupLanguageDict[selectedLanguage].popupRelatorio}</h3>
                <div className="popup-body-data">
                    <div><p className="strong-text">{popupLanguageDict[selectedLanguage].popupDepartamento}</p>
                    <p>{data.department}</p></div>
                    
                    <div className="popup-name-email">
                        <div><p className="strong-text">{popupLanguageDict[selectedLanguage].popupNome}</p>
                        <p>{data.user_name}</p></div>

                        <div><p className="strong-text">{popupLanguageDict[selectedLanguage].popupEmail}</p>
                        <p>{data.user_email}</p></div>
                    </div>
                    <div><p className="strong-text">{popupLanguageDict[selectedLanguage].popupMensagem}</p>
                    <p>{data.message}</p></div>
                </div>
                <div className="popup-buttons">
                    <span className="edit-button" onClick={onClose}>{popupLanguageDict[selectedLanguage].popupEditar}</span>
                    <button className="submit-button" onClick={handleSubmission}>{popupLanguageDict[selectedLanguage].popupSubmeter}</button>
                </div>
        </div>
        </div>}
        </div>
    )
    }

    export default Popup;

