import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { jwtDecode } from 'jwt-decode';

const AnonymousRoute = () => {
    const { authTokens, logoutUser } = useContext(AuthContext);
    // If the user is logged, redirect into the app according to the role
    if (authTokens) {
        const role = jwtDecode(authTokens.access).role;
        if (role === 'COACH' || role === 'TRIAL') {
            return <Navigate to="/webapp/trainings" />;
        }
        else if (role === 'PLAYER') {
            return <Navigate to="/webapp/player/forms" />;
        }
        // If the user is logged but the role is not defined, redirect to the login page
        else {
            // Remove user from local storage, to avoid errors
            logoutUser(); // navigate("/webapp")
        }
    } else {
        return <Outlet />;
    }


};

export default AnonymousRoute;