import React, { useState } from "react";
import "./RecoverPasswordPage.css";
import backArrow from "../../webAppAssets/register-arrow-right.svg" 
import blueLogo from "../../webAppAssets/navbar-logo.svg"
import { Link, useNavigate } from "react-router-dom";
import backgroundThreeFirst from "../../webAppAssets/blue-background-three.svg";
import { useTranslation } from "react-i18next";
import axios from "axios";


const RecoverPasswordPage = () => {
    const { t } = useTranslation(['login-page']);

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
        const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        axios.get(process.env.REACT_APP_BACKEND + 'users/recover-password', {
            params: {
                email: email
            }
        })
        .then((response) => {
            navigate("/webapp/login", {state: {recovery_toast: true}});
        })
        .catch((error) => {
            console.error(error);
            navigate("/webapp/login", {state: {recovery_toast: false}});
        });
    }

    return (
        <div className="webapp-login-page">
            <img className="webapp-register-left-background-three" src={backgroundThreeFirst}></img>
            <img className="webapp-register-right-background-three" src={backgroundThreeFirst}></img>
            <div className="webapp-login-container">
            <div className="webapp-login-icons">
                <Link to="/webapp/login"><img src={backArrow}></img></Link>
                <img src={blueLogo}></img>
            </div> 
            <div className="webapp-register-form-container">
                <h1>{t('recover-password')}</h1>
                <span className="webapp-recover-password-subtitle">{t('recover-password-subtitle')}</span>
                <form className="webapp-register-form" onSubmit = {(e) => handleSubmit(e)}>
                    <div className=" webapp-register-form-input-divs">
                        <div className="webapp-register-form-top-header-with-error">
                            <label className="dark-blue-15px-700">{t('Username')} <span className="webapp-asterisco">*</span></label>
                        </div>
                        <input name="username" value={email} placeholder={t('Username')} onChange= {handleEmailChange}></input>
                    </div>
                    <div className="webapp-register-submit-button">
                        <button type="submit">{t('submit')}</button>
                    </div>
                </form>
            </div>
        </div>
        </div>
    );
};

export default RecoverPasswordPage;