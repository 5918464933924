import React, {useState} from "react";
import "./Contactos.css";
import facebookLogo from "../../assets/facebook.svg";
import instagramLogo from "../../assets/instagram.svg";
import linkedinLogo from "../../assets/linkedin.svg";
import whatsappLogo from "../../assets/whatsapp.svg";
import youtubeLogo from "../../assets/YT.svg";
import contactosBackgroundLogo from "../../assets/contactos-blue-three.svg";
import emailjs from 'emailjs-com';
import { useMediaQuery } from 'react-responsive';
import SubmissionPopup from "../../components/ContactPopup/Popup";
import {BsArrowRight} from "react-icons/bs";

const Contactos = (props) => {
    const isMobile = useMediaQuery({ maxWidth: 900 });

    const [agreementChecked, setAgreementChecked] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState({
        department: '',
        user_name: '',
        user_email: '',
        message: '',
    });

    const openContactPopup = (e) => {
        e.preventDefault();
        const updatedFormData = {
            department: e.target.department.value,
            user_name: e.target.user_name.value,
            user_email: e.target.user_email.value,
            message: e.target.message.value,
        };
        setFormData(updatedFormData);
        formValidation(updatedFormData);
        if (!agreementChecked || !isFormComplete(updatedFormData)) {
            return;
        }
        
        setShowPopup(true);
        
    };

    const formValidation = (formData) => {
        if (!formData.department) {
            document.getElementsByName('department')[0].classList.add('form-error');
            document.getElementsByClassName('error-message department')[0].classList.add('visible');
        }

        if (!formData.user_name) {
            document.getElementsByName('user_name')[0].classList.add('form-error');
            document.getElementsByClassName('error-message user_name')[0].classList.add('visible');
        }

        if (!formData.user_email) {
            document.getElementsByName('user_email')[0].classList.add('form-error');
            document.getElementsByClassName('error-message user_email')[0].classList.add('visible');
        }

        if (!formData.message) {
            document.getElementsByName('message')[0].classList.add('form-error');
            document.getElementsByClassName('error-message message')[0].classList.add('visible');
        }

        if (!agreementChecked) {
            document.getElementsByClassName('formulario-custom-checkbox')[0].classList.add('form-error');
            document.getElementsByClassName('error-message checkbox')[0].classList.add('visible');
        }

    }

    const handleInputChange = (e) => {
        document.getElementsByName(e.target.name)[0].classList.remove('form-error');
        document.getElementsByClassName(`error-message ${e.target.name}`)[0].classList.remove('visible');
    }

    const closePopup = () => {
        setShowPopup(false);
    };
    
    const submitForm = () => {
        const emailData = {
            department: formData.department,
            user_name: formData.user_name,
            user_email: formData.user_email,
            message: formData.message,
          };

          
          if (formData.department === 'Informações') {
            emailjs
            .send(
              'service_5elnab5',
              'template_7kwnu6t',
              emailData,
              '9_CWTMFE4g8ToiAIe'
            )

                  
          }
          if (formData.department === 'CEO') {
            emailjs
            .send(
              'service_e36eqgl',
              'template_85e4cx1',
              emailData,
              'wsWeGTDlqbXLEcfT0'
            )
        
          }

          if (formData.department === 'Vendas') {
            emailjs
            .send(
              'service_uzylcob',
              'template_k7lyore',
              emailData,
              'PS93xbxSTnyz8neUN'
            )
        };

        setShowPopup(false);
    }

    const handleCheckboxChange = (e) => {
        document.getElementsByClassName('formulario-custom-checkbox')[0].classList.remove('form-error');
        document.getElementsByClassName('error-message checkbox')[0].classList.remove('visible');
        setAgreementChecked(e.target.checked);
    };

    const isFormComplete = (data) => {
        const { department, user_name, user_email, message } = data;
        return department && user_name && user_email && message;
    };


    const contactosLanguageDict = {
        PT: {
            contactosTitle: 'CONTACTOS',
            contactosTele: 'Telemóvel',
            contactosText1: 'Ou contacte-nos através do seguinte formulário',
            contactosText2: 'FORMULÁRIO DE CONTACTO',
            contactosLabel1: 'Departamento que pretende contactar',
            contactosLabel2: 'O Seu Nome Completo',
            contactosLabel3: 'O Seu Email',
            contactosLabel4: 'A Sua Mensagem para Nós',
            contactosDropdown: 'Informações/CEO/Vendas',
            contactosOption1: 'Informações',
            contactosOption2:'CEO',
            contactosOption3:'Vendas',
            contactosPlaceholder1:'Nome Completo',
            contactosPlaceholder2:'Exemplo: nome@domínio.pt',
            contactosPlaceholder3:'Mensagem',
            contactosDeclaracao:'Declaração',
            contactosText3:'Declaro que concordo com a recolha dos meus dados pessoais para contacto com a entidade 3D Training Control',
            contactosText5:'Item de preenchimento obrigatório',
            contactosText6:'Próximo',
        },
        ES: {
            contactosTitle: 'CONTACTOS',
            contactosTele: 'Teléfono',
            contactosText1: 'O póngase en contacto con nosotros a través del siguiente formulario',
            contactosText2: 'FORMULARIO DE CONTACTO',
            contactosLabel1: 'Departamento al que desea contactar',
            contactosLabel2: 'Su nombre completo',
            contactosLabel3: 'Su correo electrónico',
            contactosLabel4: 'Su mensaje para nosotros',
            contactosDropdown: 'Información/CEO/Ventas',
            contactosOption1: 'Información',
            contactosOption2: 'CEO',
            contactosOption3: 'Ventas',
            contactosPlaceholder1: 'Nombre completo',
            contactosPlaceholder2: 'Ejemplo:nome@domínio.es',
            contactosPlaceholder3: 'Mensaje',
            contactosDeclaracao: 'Declaración',
            contactosText3: 'Declaro que estoy de acuerdo con la recopilación de mis datos personales para contactar con la entidad 3D Training Control',
            contactosText5: 'Elemento de relleno obligatorio',
            contactosText6: 'Próximo',
            
        },
        EN: {
            contactosTitle: 'CONTACT US',
            contactosTele: 'Phone',
            contactosText1: 'Or contact us through the following form',
            contactosText2: 'CONTACT FORM',
            contactosLabel1: 'Department you wish to contact',
            contactosLabel2: 'Your Full Name',
            contactosLabel3: 'Your Email',
            contactosLabel4: 'Your Message to Us',
            contactosDropdown: 'Information/CEO/Sales',
            contactosOption1: 'Information',
            contactosOption2: 'CEO',
            contactosOption3: 'Sales',
            contactosPlaceholder1: 'Full Name',
            contactosPlaceholder2: 'Example:name@domain.en',
            contactosPlaceholder3: 'Message',
            contactosDeclaracao: 'Declaration',
            contactosText3: 'I declare that I agree with the collection of my data personal to contact the entity 3D Training Control',
            contactosText5: 'Mandatory fill item',
            contactosText6: 'Next',
            

            }
    }
    
    
    return (
        <div className="contactos-page">
            
            <img className="contactos-background-logo" src={contactosBackgroundLogo}></img>
            <div className="contactos-contactos">
                <h2 className="text-36px azul-8">{contactosLanguageDict[props.selectedLanguage].contactosTitle}</h2>
                <div className="contactos-container div-underline">
                    <div className="contactos-page-telemovel">
                        <span className="strong-text">{contactosLanguageDict[props.selectedLanguage].contactosTele}</span>
                        <p className="text-20px">+351 912 273 244</p>
                    </div>
                    <div className="contactos-page-logos">
                        <a href="https://www.facebook.com/profile.php?id=100094902237862&mibextid=ZbWKwL" target="_blank"><img src= {facebookLogo}/></a> 
                        <a href="https://www.linkedin.com/in/3d-trainingcontrol-435775286/" target="_blank"><img src= {linkedinLogo}/></a>
                        <a href="https://wa.me/912273244" target="_blank">    <img src= {whatsappLogo}/></a>
                        <a  href={props.selectedLanguage === 'ES' ? "https://www.instagram.com/3d_trainingcontrol.es/" : "https://www.instagram.com/3d_trainingcontrol.br/"} target="_blank"><img src= {instagramLogo}/></a>
                        <a  href="https://youtube.com/@3DTrainingControl?si=NtnEs-0yuZwp9rl5" target="_blank"><img src= {youtubeLogo}/></a>

                    </div>
                </div>
            </div>

            <form onSubmit={openContactPopup} className="contactos-formulario">
                <p className="text-22px ">{contactosLanguageDict[props.selectedLanguage].contactosText1}</p>
                <h4 className="text-26px">{contactosLanguageDict[props.selectedLanguage].contactosText2}</h4>
                <label >{contactosLanguageDict[props.selectedLanguage].contactosLabel1} <span className="asterisco">*</span></label>
                <select name="department" className="formulario-dropdown" onChange={handleInputChange}>
                    <option disabled selected value="">{contactosLanguageDict[props.selectedLanguage].contactosDropdown}</option>
                    <option>{contactosLanguageDict[props.selectedLanguage].contactosOption1}</option>
                    <option>{contactosLanguageDict[props.selectedLanguage].contactosOption2}</option>
                    <option>{contactosLanguageDict[props.selectedLanguage].contactosOption3}</option>
                </select>
                <p className="error-message department"> ⓘ Este campo é de preenchimento obrigatório</p>
                <div className="contactos-formulario-container">
                    <div className="formulario-inputs">
                        <div>
                            <label >{contactosLanguageDict[props.selectedLanguage].contactosLabel2} <span className="asterisco">*</span></label>
                            <input name="user_name" placeholder={contactosLanguageDict[props.selectedLanguage].contactosPlaceholder1}onChange={handleInputChange} ></input>
                            <p className="error-message user_name"> ⓘ Este campo é de preenchimento obrigatório</p>

                        </div>
                        <div>
                            <label >{contactosLanguageDict[props.selectedLanguage].contactosLabel3}<span className="asterisco">*</span></label>
                            <input name="user_email" placeholder={contactosLanguageDict[props.selectedLanguage].contactosPlaceholder2} onChange={handleInputChange}></input>
                            <p className="error-message user_email"> ⓘ Este campo é de preenchimento obrigatório</p>

                        </div>
                    </div >
                    <div className="formulario-textarea">
                        <label >{contactosLanguageDict[props.selectedLanguage].contactosLabel4} <span className="asterisco">*</span></label>
                        <textarea name="message" rows="20" placeholder={contactosLanguageDict[props.selectedLanguage].contactosPlaceholder3} onChange={handleInputChange}></textarea>
                        <p className="error-message message"> ⓘ Este campo é de preenchimento obrigatório</p>
                    </div>
                    
                    <div className="declaracao-submissao">
                        <div className="formulario-declaracao">
                            <h5>{contactosLanguageDict[props.selectedLanguage].contactosDeclaracao} <span className="asterisco">*</span></h5>
                            <div className="formulario-checkbox-container">
                            <div className="formulario-custom-checkbox">
                                <input className="formulario-checkbox" type="checkbox" 
                                checked={agreementChecked}
                                onChange={handleCheckboxChange}/> 
                            </div>
                                                    
                            <p className="declaracao-checkbox-label">{contactosLanguageDict[props.selectedLanguage].contactosText3} </p>
                            </div>

                        </div>
                        {isMobile ? (
                        <div>
                            <p className="error-message checkbox"> ⓘ Este campo é de preenchimento obrigatório</p>
                            <p><span className="asterisco">*</span>{contactosLanguageDict[props.selectedLanguage].contactosText5}</p>
                        </div>
                        ): null }
                        <button type="submit">{contactosLanguageDict[props.selectedLanguage].contactosText6} <BsArrowRight className="submit-arrow-right"/></button>
                    </div>

                    {!isMobile ? (
                        <div>
                            <p className="error-message checkbox"> ⓘ Este campo é de preenchimento obrigatório</p>
                        <p><span className="asterisco">*</span>{contactosLanguageDict[props.selectedLanguage].contactosText5}</p>
                        </div>
                        
                        ): null }
                </div>
            </form>
            {showPopup && (
                <SubmissionPopup
                data={formData}
                onClose={closePopup}
                onSubmit={submitForm}
                selectedLanguage = {props.selectedLanguage}
                />
            )}
        </div>
    )

}
export default Contactos
