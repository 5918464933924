import react, {useState} from 'react'
import './MultipleChoiceDropdown.css'

const MultipleChoiceDropdown = (props) => {

    const [isChecked, setIsChecked] = useState(false);
 
    const handleExerciseFormsDataChange = (event,option) => {
        //Se for uma lista de FK ( e preciso a opcao inteira) apenasé necessario adicionar pois no 
        //handleExerciseDataChange já está lá a verificar se o valor existe na lista e se existir remove
        if (props.typeOfKey === "foreignKeyList") {
            props.handleExerciseDataChange(props.valueToChange,option)
        }
        //Se for uma FK simples, apenas e preciso vericicar se ja existe, se existir então volta a alterar para 0
        //se nao exsitir entao adiciona
        else if (props.typeOfKey === "foreignKey") {
            if (option === props.currentExercise[props.valueToChange]) {
                props.handleExerciseDataChange(props.valueToChange, null)
            } else {
                props.handleExerciseDataChange(props.valueToChange, option)
                
            }
        }
        //aqui a mesma coisa so que em vez de adicionar a opcao adiciona o valor
        else {

            if(parseInt(event.target.value) === props.currentExercise[props.valueToChange]) {
                props.handleExerciseDataChange(props.valueToChange, null)
            }
            else {
                props.handleExerciseDataChange(props.valueToChange, event.target.value)
            }

        }
    } 


    return (
        <div className={`multiple-choice-dropdown-component ${props.css && "multiple-choice-dropdown-component-2"}`}>
            {props.dropdownOptions.map((option, index) => {
                const value = option.id || option.value || index;
                const label = option.name || option[1] || option[0];
                return (
                    <div key={value}>
                        <input
                            type="checkbox"
                            id={`checkbox-${value}`}
                            value={value}
                            checked={
                                props.valueToChange === "principles_of_play" || props.valueToChange === "players"
                                    ? props.currentExercise[props.valueToChange].some(
                                        (item) => item.id === value
                                    )
                                    : props.valueToChange === "exerciseTeamOrganization" ? Number(props.currentExercise[props.valueToChange]) === value 
                                      : Number(props.currentExercise[props.valueToChange]) === value ||
                                        (props.currentExercise[props.valueToChange]?.id === value &&
                                          (props.valueToChange !== "principles_of_play" || props.valueToChange !== "players"))
                            }
                            onChange={(event) => handleExerciseFormsDataChange(event, option)}
                        />
                        <label htmlFor={`checkbox-${value}`}>{props.t(label)}</label>
                    </div>
                );
            })}
        </div>
    );
};




export default MultipleChoiceDropdown
