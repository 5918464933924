import { useState, useRef } from 'react'
import { useTranslation } from "react-i18next";

const Password = ({setPassword, changePassword, upDatePass}) => {
    const [error, setError] = useState("");
    const { t } = useTranslation(['coach-profile']);

    const password = useRef(null);
    const newPassword = useRef(null);

    const getNewPassword = () => {
        const inputs = document.querySelectorAll(".webapp-coachprofile-password-body-input")
        if(inputs[0]?.value === inputs[1]?.value){
            //set the new password, password is set in the parent component using useRef
            upDatePass(inputs[1].value)
        } else {
            //set the new password, password is set in the parent component using useRef
            upDatePass(null)
        }
    }

    const closePopup = () => {
        setPassword(false)
        //activate the scroll in the background
        document.body.style.overflow = 'auto';
        upDatePass(null)
    }

    const confirmPasswords = () => { 
        setError("")
        if (password.current.trim() === "" || newPassword.current.trim() === "") {
            setError(t("Password not accepted"));
            return;
        }
        if(password.current !== newPassword.current){
            setError(t("The passwords don't match"));
        } else {
            setError("")
            upDatePass(newPassword.current)
            changePassword()
        }
    }

    return (
        <div className="webapp-coachprofile-password-background">
            <div className='webapp-coachprofile-password'>
                <div className="webapp-coachprofile-password-header">
                    <h2>{t('CHANGE PASSWORD')}</h2>
                </div>
                <div className="webapp-coachprofile-password-body">
                    <label className="webapp-coachprofile-password-body-label">{t('New Password')}</label>
                    <input className="webapp-coachprofile-password-body-input" ref={password} type="password" name="new_password1" onChange={(e)=>(password.current=e.target.value)}/>
                    <label className="webapp-coachprofile-password-body-label">{t('Confirm')} {t('New Password')}</label>
                    <input className="webapp-coachprofile-password-body-input" ref={newPassword} type="password" name="new_password2" onChange={(e)=>(newPassword.current=e.target.value)}/>
                </div>
                <div className="webapp-coachprofile-password-error">
                    <p>{error}</p>
                </div>
                <div className="webapp-coachprofile-password-footer">
                    <button className="webapp-coachprofile-password-button webapp-coachprofile-password-cancel" onClick={closePopup}>{t('Cancel')}</button>
                    <button className="webapp-coachprofile-password-button webapp-coachprofile-password-accept" onClick={confirmPasswords}>{t('Confirm')}</button>
                </div>
            </div>
        </div>
    )
}

export default Password