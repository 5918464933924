
import React, {useState, useEffect} from "react";
import "./AboutUs.css";
import statsImage from "../../assets/stats-image.png"
import statsDashboard from "../../assets/stats-dashboard.png"
import firstLogoBackground from "../../assets/about-us-first-three-blue.svg"
import secondLogoBackground from "../../assets/about-us-second-three-blue.svg"
import { useMediaQuery } from 'react-responsive';
import { Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {BsArrowRight} from "react-icons/bs";
import { useInView } from 'react-intersection-observer';
import navbarLogo from "../../assets/navbar_logo.svg";

const AboutUs = (props) => {
    const isMobile = useMediaQuery({ maxWidth: 900 });
    
    const defaultThreshold = 0.1;
    const mobileThreshold = 0.0001; 
   
    const aboutUsThreshold = isMobile ? mobileThreshold : defaultThreshold;

    const [aboutUsMissaoRef, aboutUsMissaoInView] = useInView({ threshold: aboutUsThreshold });
    const [aboutUsAboutRef, aboutUsAboutInView] = useInView({ threshold: aboutUsThreshold });
    
    const aboutUsLanguageDict = {
        PT: {
            aboutTitle: '3D Training Control',
            aboutSubtitle: 'Evoluir',
            aboutSubtitle1: 'Visão, Missão, Valores',
            aboutCardHeader1: 'Visão',
            aboutCardHeader2: 'Missão',
            aboutCardHeader3:  'Valores da empresa',  
            aboutCardText1: 'Promover as ideias pelas quais somos apaixonados, para construir relações duradouras baseadas no trabalho, na investigação, na confiança e na lealdade no mundo do desporto.',
            aboutCardText2: 'Dedicamo-nos a criar a melhor ferramenta tecnológica, para ajudar treinadores de todo o mundo, nos processos de planeamento, monitorização e controlo de cargas de treino, com base na mais recente, de elevada qualidade de investigação e inovação científica.',
            aboutCardText3: 'Inovação, originalidade, dedicação, coragem e profissionalismo.',
            aboutSubtitle2: 'Quem Somos',
            aboutText1: 'A ligação experiente entre formação teórica e a prática como Treinador de Futebol, permitiu-me detetar uma lacuna no planeamento, monitorização e controlo de cargas de treino. Os softwares que atualmente são comercializado para treinadores não permitem quantificar o nível de especificidade, pelo que, dentro de uma sessão de treino todos os exercícios são considerados iguais, o que não permite o seu ajuste perante a progressão dos treinos.',
            aboutText2: 'Neste contexto, propus-me criar uma ferramenta, com a colaboração científica de vários especialistas de grande importância em Portugal e no mundo, com o objetivo de revolucionar a forma de planear, monitorizar e controlar as cargas de treino no futebol e noutros desportos coletivos.',
            aboutText3: 'Nasce, assim, o',
            aboutText4: ' 3D Training Control (3DTC)',
            aboutText5: ' a primeira e única ferramenta tecnológica que permite quantificar a especificidade do treino, o que por sua vez permite quantificar a carga de treino em três dimensões (volume – intensidade – especificidade).',
            aboutText6: 'Assim, o',
            aboutText7: ' 3DTC',
            aboutText8: ' essencialmente permite uma maior precisão no controlo do treino, elemento fundamental para a otimização do desempenho e para a prevenção de lesões.',
        },
        ES: {
            aboutTitle:  '3D Training Control',
            aboutSubtitle: 'Evoluciona',
            aboutSubtitle1: 'Visión, Misión, Valores',
            aboutCardHeader1: 'Visión',
            aboutCardHeader2: 'Misión',
            aboutCardHeader3: 'Valores de la empresa',
            aboutCardText1: 'Impulsar las ideas que nos apasionan, para construir relaciones duraderas con base en el trabajo, la investigación, la confianza y fidelidad en el mundo del deporte. ',
            aboutCardText2: 'Estamos dedicados a crear la mejor herramienta tecnológica, para ayudar a los entrenadores de todo el mundo, en los procesos de planificación, monitorización y control de las cargas de entrenamiento, basados en la investigación e innovación científica más reciente y de alta calidad.',
            aboutCardText3: 'Innovación, originalidad, dedicación, coraje (valiente) y profesionalismo.',
            aboutSubtitle2: 'Quienes somos',
            aboutText1: 'La conexión experimentada entre la formación teórica como Ph.D y la practica como Entrenador de Fútbol, me permitió detectar un vacío teórico y práctico en la planificación, monitorización y control de las cargas de entrenamiento. Los softwares que actualmente son comercializados para entrenadores no permiten cuantificar el nivel de especificidad, por lo cual, dentro de una sesión de entrenamiento todos los ejercicios son considerados como iguales, lo que no permite una diferenciación y análisis de la progresión de los entrenamientos.  ',
            aboutText2: 'En este contexto, me propuse crear una herramienta, con la colaboración científica de varios especialistas de gran importancia en Portugal y el mundo, con el objetivo de revolucionar la forma de planificar, monitorizar y controlar las cargas de entrenamiento en el fútbol y otros deportes colectivos.',
            aboutText3: 'Así, nace',
            aboutText4: ' 3D Training Control (3DTC)',
            aboutText5: ' la primera y única herramienta tecnológica que permite cuantificar la especificidad del entrenamiento, lo que a su vez permite cuantificar la carga de entrenamiento en tres dimensiones (volumen - intensidad - especificidad).',
            aboutText6: 'Adicionalmente,',
            aboutText7: ' 3DTC',
            aboutText8: ' otorga una mayor precisión en el control del entrenamiento, elemento fundamental para la optimización del desempeño y para la prevención de lesiones.'
        },
        EN: {
            aboutTitle:  '3D Training Control',
            aboutSubtitle: 'Evolve',
            aboutSubtitle1: 'Vision, Mission, Values',
            aboutCardHeader1: 'Vision', 
            aboutCardHeader2: 'Mission',
            aboutCardHeader3: 'Values of the company',
            aboutCardText1: 'Promote the ideas we are passionate about, to build lasting relationships based on work, research, trust and loyalty in the world of sport.',
            aboutCardText2: 'We are dedicated to creating the best technological tool to help trainers around the world in the processes of planning, monitoring and controlling training loads, based on the latest, high-quality scientific research and innovation.',
            aboutCardText3: 'Innovation, originality, dedication, courage and professionalism',
            aboutSubtitle2: 'Who we are',
            aboutText1: `My experience linking theoretical training and practice as a soccer coach has allowed me to detect a gap in the planning, monitoring and control of training loads. The software currently being marketed to coaches doesnt allow the level of specificity to be quantified, so within a training session all the exercises are considered the same, which doesn't allow them to be adjusted as training progresses`,
            aboutText2: 'In this context, I set out to create a tool, with the scientific collaboration of several experts in Portugal and around the world, with the aim of revolutionizing the way we plan, monitor and control training loads in soccer and other team sports',
            aboutText3: 'Thus was born',
            aboutText4: ' 3D Training Control (3DTC)',
            aboutText5: ', the first and only technological tool that allows a user to quantify the specificity of training, which in turn allows them to quantify the training load in three dimensions (volume - intensity - specificity).',
            aboutText6: 'In addition,',
            aboutText7: ' 3DTC',
            aboutText8: ' allows a greater precision in the control of the training, which is a fundamental element for the optimization of the performance and prevention of injuries.'

        }
    };

    const [aboutUsMissaoScrolled, setAboutUsMissaoScrolled] = useState(false);
    const [aboutUsAboutScrolled, setAboutUsAboutScrolled] = useState(false);

    const handleScroll = () => {
        if ( aboutUsMissaoInView && !aboutUsMissaoScrolled) {
            setAboutUsMissaoScrolled(true);
        }
        if ( aboutUsAboutInView && !aboutUsAboutScrolled) {
            setAboutUsAboutScrolled(true);
        }
    }
    
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, [aboutUsMissaoInView, aboutUsAboutInView]);

    return (
        <div className="about-us-page" ref={aboutUsMissaoRef}>
           <section id="aboutus-missao" className={`section-missao ${aboutUsMissaoInView || aboutUsMissaoScrolled ? 'visible' : ''}`}>
           <img className="about-us-first-logo-background" src= {firstLogoBackground} alt="Background" />
           <img className="about-us-second-logo-background" src= {secondLogoBackground}></img>
            <div className="about-header-div div-underline">
                <h1>{aboutUsLanguageDict[props.selectedLanguage].aboutTitle}</h1>
                <h2 className="about-page-subheader">{aboutUsLanguageDict[props.selectedLanguage].aboutSubtitle}</h2>
            </div>
            <div className="about-values-div div-underline">
                <h2 className="subtitle-color">{aboutUsLanguageDict[props.selectedLanguage].aboutSubtitle1}</h2>
                <div className="values-cards">
                {!isMobile ? (
                    <>
                    <div className="value-card">
                        <h3 className="value-card-header">{aboutUsLanguageDict[props.selectedLanguage].aboutCardHeader1}</h3>
                        <p className="value-card-text">{aboutUsLanguageDict[props.selectedLanguage].aboutCardText1}</p>
                        </div>
                    <div className="value-card">
                        <h3 className="value-card-header">{aboutUsLanguageDict[props.selectedLanguage].aboutCardHeader2}</h3>
                        <p className="value-card-text"> {aboutUsLanguageDict[props.selectedLanguage].aboutCardText2}</p>
                    </div>
                    <div className="value-card">
                        <h3 className="value-card-header">{aboutUsLanguageDict[props.selectedLanguage].aboutCardHeader3}</h3>
                        <p className="value-card-text">{aboutUsLanguageDict[props.selectedLanguage].aboutCardText3}</p>
                        </div>
                    </>
                ) : 
                    <div className="value-card-carousel">
                        <Carousel
                        infiniteLoop={true}
                        autoPlay={true}
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={true}
                        stopOnHover={true}
                        transitionTime={500}
                        interval={20000}
                        thumbWidth={90}
                        showArrows={false}
                        swipeable={true}
                        
                       
                        className="values-custom-carousel"
                        >
                            <div className="value-card">
                                <h3 className="value-card-header">{aboutUsLanguageDict[props.selectedLanguage].aboutCardHeader1}</h3>
                                <p className="value-card-text">{aboutUsLanguageDict[props.selectedLanguage].aboutCardText1}</p>
                            </div>
                            <div className="value-card">
                                <h3 className="value-card-header">{aboutUsLanguageDict[props.selectedLanguage].aboutCardHeader2}</h3>
                                <p className="value-card-text"> {aboutUsLanguageDict[props.selectedLanguage].aboutCardText2}</p>
                            </div>
                            <div className="value-card">
                                <h3 className="value-card-header">{aboutUsLanguageDict[props.selectedLanguage].aboutCardHeader3}</h3>
                                <p className="value-card-text">{aboutUsLanguageDict[props.selectedLanguage].aboutCardText3}</p>
                            </div>
                        </Carousel>
                
                </div>
                
                
                }
                </div>
            {isMobile ? (
                <div className="carousel-swipe">
                    <span>Scroll <BsArrowRight/></span>
                </div>
            ) : null}
            </div>
            </section>
            <section id="aboutus-about" ref={aboutUsMissaoRef} className={`aboutus-about-section ${aboutUsMissaoInView || aboutUsMissaoScrolled ? 'visible' : ''}`}>
            <div className="about-about-div">
                <h2 className="subtitle-color">{aboutUsLanguageDict[props.selectedLanguage].aboutSubtitle2}</h2>
                <div className="about-about-first-container">
                    <div className="about-text-container ">
                    <span className="about-text">{aboutUsLanguageDict[props.selectedLanguage].aboutText1}</span>
                        <span className="about-text">{aboutUsLanguageDict[props.selectedLanguage].aboutText2}</span>
                        </div>
                    <img className="laptop-image" src= {statsDashboard}/>
                    </div>

                <div className="about-about-second-container">
                    <img className="stats-image" src= {statsImage}/>
                    <div className="about-text-container ">
                    <span className="about-text">
                        {aboutUsLanguageDict[props.selectedLanguage].aboutText3}<span className="strong-text">{aboutUsLanguageDict[props.selectedLanguage].aboutText4}</span>{aboutUsLanguageDict[props.selectedLanguage].aboutText5}</span>
                        <span className="about-text">
                        {aboutUsLanguageDict[props.selectedLanguage].aboutText6}<span className="strong-text">{aboutUsLanguageDict[props.selectedLanguage].aboutText7}</span> {aboutUsLanguageDict[props.selectedLanguage].aboutText8}
                        </span>
                        </div>
                </div>
            </div>
            </section>
        
            </div>
            
    )

}

export default AboutUs;