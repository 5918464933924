import "../../webAppPages/AddGame/AddGame.css";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { UNSAFE_DataRouterContext, useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext } from "react";
import AuthContext from '../../context/AuthContext'
import { Adversary, Liga, Data, Lado } from "../../webAppComponents/AddGameItems/AddGameItems";
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';

import BckLogo from "../../webAppAssets/BackgroundLogo.svg"

const CreateGame = () => {
    const { authTokens } = useContext(AuthContext);
    const { t } = useTranslation(["add-game"]);

    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [gameData, setGameData] = useState({});
    const [leagues, setLeagues] = useState([]);
    const [adversary, setAdversary] = useState('');

    const handleReturn = () => {
        navigate('/webapp/collective-load')
    }

    const previousStep = () => {
        setStep(step - 1)
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND}games/leagues/list`, { headers })
            .then((response) => {
                setLeagues(response.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });

        axios.get(`${process.env.REACT_APP_BACKEND}games/adversaries`, { headers })
            .then((response) => {
                setAdversary(response.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [])

    useEffect(() => {
    }, [gameData])

    function formatTime(time) {
        // Split the time string into hours and minutes
        const [hoursStr, minutesStr] = time.split('h');

        // Convert hours and minutes to numbers
        let hours = parseInt(hoursStr);
        let minutes = 0;

        if (minutesStr) {
            minutes = parseInt(minutesStr);
        }

        // Format hours and minutes with leading zeros
        hours = hours.toString().padStart(2, '0');
        minutes = minutes.toString().padStart(2, '0');

        // Return the formatted time string
        return `${hours}:${minutes}`;
    }


    const goToStep = (step) => {
        if (step > 1) {
            if (gameData.against === undefined) {
                const element = document.getElementById('create-game-adversary');
                if (element !== null) {
                    element.classList.add("injury-error");
                    // Add a click event listener to remove the class when clicked
                    element.addEventListener("click", () => {
                        element.classList.remove("injury-error");
                    });
                }
                return
            }
        }

        if (step > 2) {

            if (gameData.league === undefined) {
                const element = document.getElementById('create-game-league');
                if (element !== null) {
                    element.classList.add("injury-error");
                    // Add a click event listener to remove the class when clicked
                    element.addEventListener("click", () => {
                        element.classList.remove("injury-error");
                    });
                }
                return
            }
        }

        if (step > 3) {
            if (gameData.date === undefined || gameData.start_time === "") {
                if (gameData.date === undefined) {
                    const element = document.getElementById('create-game-date');
                    if (element !== null) {
                        element.classList.add("injury-error");
                        // Add a click event listener to remove the class when clicked
                        element.addEventListener("click", () => {
                            element.classList.remove("injury-error");
                        });
                    }
                    return
                } else{
                    const element = document.getElementById('create-game-start-time');
                    if (element !== null) {
                        element.classList.add("injury-error");
                        // Add a click event listener to remove the class when clicked
                        element.addEventListener("click", () => {
                            element.classList.remove("injury-error");
                        });
                    }
                    return
                }
            }
        }
            

        // if passes all the checks, then set the step
        setStep(step)
    }

    const nextStep = () => {
        if (step != 4) {
            if (step === 1 && gameData.against === undefined) {
                const element = document.getElementById('create-game-adversary');
                if (element !== null) {
                    element.classList.add("injury-error");
                    // Add a click event listener to remove the class when clicked
                    element.addEventListener("click", () => {
                        element.classList.remove("injury-error");
                    });
                }
                return
            }
            if (step === 2 && gameData.league === undefined) {
                const element = document.getElementById('create-game-league');
                if (element !== null) {
                    element.classList.add("injury-error");
                    // Add a click event listener to remove the class when clicked
                    element.addEventListener("click", () => {
                        element.classList.remove("injury-error");
                    });
                }
                return
            }
            if (step === 3 && (gameData.date === undefined || gameData.start_time === "")) {
                if (gameData.date === undefined) {
                    const element = document.getElementById('create-game-date');
                    if (element !== null) {
                        element.classList.add("injury-error");
                        // Add a click event listener to remove the class when clicked
                        element.addEventListener("click", () => {
                            element.classList.remove("injury-error");
                        });
                    }
                    return
                } else {
                    const element = document.getElementById('create-game-start-time');
                    if (element !== null) {
                        element.classList.add("injury-error");
                        // Add a click event listener to remove the class when clicked
                        element.addEventListener("click", () => {
                            element.classList.remove("injury-error");
                        });
                    }
                    return
                }
            }
            setStep(step + 1)
        } else {


            let payload = gameData
            // search for the team id in the adversary array
            const team = adversary.find(team => team.name === gameData.against)
            if (team) {
                payload.against = team.id
            }

            // search for the league id in the leagues array
            const league = leagues.find(league => league.name === gameData.league)
            if (league) {
                payload.league = league.id
            }
            // Format start time in correct format ( start_time is coing as XXhXX)
            payload.start_time = formatTime(payload.start_time)

            //  Lógica para guardar toda as informacoes das respostas 
            axios.post(`${process.env.REACT_APP_BACKEND}games/create-game`, payload, { headers })
                .then((response) => {
                    navigate('/webapp/collective-load')
                })
                .catch((error) => {
                    toast.error(t('Error creating game'));
                    console.error('Error:', error);
                });
        }
    }

    //function to place the background images in the correct position
    const placeBackgroundImages = () => {
        const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
        const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
        const bckLogo1Height = bckLogo1.offsetHeight;
        bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
        bckLogo2.style.top = `0%`;
        const bckLogo1Width = bckLogo1.offsetWidth;
        bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
        bckLogo2.style.left = `75%`;
    }

    useEffect(() => {
        placeBackgroundImages();
        window.addEventListener('resize', placeBackgroundImages);
        return () => {
            window.removeEventListener('resize', placeBackgroundImages);
        }
    }, [])

    return (
        <div className="webapp-create-game-page">
            <div className="webapp-menu-return">
                <button onClick={handleReturn}><HiOutlineArrowLeft /></button>
                <h1>{t('New game')}</h1>
            </div>
            <div className="container">
                <div className="webapp-create-team-background-images">
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
                </div>
                <div className="webapp-game-number">
                    <h1>{t('Game')}</h1>
                </div>
                <div className="webapp-game-info">
                    <div className="create-game-info-form-step-header">
                        <span onClick={() => goToStep(1)} className={`${step === 1 && 'create-game-info-form-selected-step'}`}>1</span>
                        <h1>{t('Opposing team')}</h1>
                    </div>
                    <div className="create-game-info-form-step-items">
                        {step === 1 ? <Adversary gameData={gameData} setGameData={setGameData} t={t} adversaryOptions={adversary} /> : null}
                    </div>

                    <div className="create-game-info-form-step-header">
                        <span onClick={() => goToStep(2)} className={`${step === 2 && 'create-game-info-form-selected-step'}`}>2</span>
                        <h1>{t('League')}</h1>
                    </div>
                    <div className="create-game-info-form-step-items">
                        {step === 2 ? <Liga gameData={gameData} setGameData={setGameData} leaguesOptions={leagues} t={t} /> : null}
                    </div>

                    <div className="create-game-info-form-step-header">
                        <span onClick={() => goToStep(3)} className={`${step === 3 && 'create-game-info-form-selected-step'}`}>3</span>
                        <h1>{t('Date and hour')}</h1>
                    </div>
                    <div className="create-game-info-form-step-items">
                        {step === 3 ? <Data gameData={gameData} setGameData={setGameData} t={t} /> : null}
                    </div>

                    <div className="create-game-info-form-step-header">
                        <span onClick={() => goToStep(4)} className={`${step === 4 && 'create-game-info-form-selected-step'}`}>4</span>
                        <h1>{t('Side')}</h1>
                    </div>
                    <div className="create-game-info-form-step-items2">
                        {step === 4 ? <Lado gameData={gameData} setGameData={setGameData} t={t} /> : null}
                    </div>


                    <div className="create-game-info-form-step-buttons">
                        <div className="games-buttons">
                            {step != 1 &&
                                (<div className="anterior-button" onClick={previousStep}>{t('Previous')}</div>)
                            }
                            <div className={step != 4 ? "proximo-button" : "save-game-button"} onClick={nextStep}>{step == 4 ? t('Save Game') : t('Next')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateGame;