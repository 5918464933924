import { useState, useEffect, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import Sidebar from "../../webAppComponents/Sidebar/Sidebar";
import TrainingsPageChanger from '../../webAppComponents/TrainingsPageChanger/TrainingsPageChanger'
import GamesSubpageChanger from "../../webAppComponents/GamesSubpageChanger/GamesSubpageChanger";
import FilterBy from "../../webAppComponents/FilterBy/FilterBy";
import ConfirmationPopup from "../../webAppComponents/ConfirmationPopup/Popup";
import MapTable from "../../webAppComponents/MapTable/MapTable";
import "./GamesCollectiveLoadPage.css";
import Dots from "../../webAppAssets/3-dot.svg";
import RedTrash from "../../webAppAssets/red-trash.svg";
import FillEdit from "../../webAppAssets/fill-edit.svg";
import tab1 from "../../webAppAssets/games-tab1.svg";
import tab2 from "../../webAppAssets/games-tab2.svg";
import scrollLeft from "../../assets/scroll-left.svg";
import scrollRight from "../../assets/scroll-right.svg";
import { useNavigate } from "react-router-dom";
import GameInfo from "../../webAppComponents/GameInfo/GameInfo";
import axios from "axios";

import BckLogo from "../../webAppAssets/BackgroundLogo.svg";

import { useTranslation } from "react-i18next";

const GamesCollectiveLoadPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["games-collective-load"]);

  const [selectedFilter, setSelectedFilter] = useState("");
  const [leagueFilter, setLeagueFilter] = useState("");
  const [gamesData, setGamesData] = useState([]);
  const [leaguesGamesStats, setLeaguesGamesStats] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [gameDeletePopup, setGameDeletePopup] = useState(false);
  const [selectedSubpage, setSelectedSubpage] = useState(0);

  const [view, setView] = useState(false);

  const data = new Date();
  var [anoAtual, setAnoAtual] = useState(data.getFullYear());
  var [mesAtual, setMesAtual] = useState(data.getMonth());
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const columnNames = [
    "Adversary",
    "League",
    "Date",
    "Home",
    "Away",
    "Points",
    "Time",
  ];
  const columnNamesGeneral = [
    `%${t("Home")}`,
    t("Max psb home"),
    `%${t("Away")}`,
    t("Max psb away"),
    t("Points Total"),
    `%${t("Total")}`,
  ];
  const mappedColumns = {
    ItemName: "league",
    [columnNamesGeneral[0]]: "percentual_home_points",
    [columnNamesGeneral[1]]: "total_home_points",
    [columnNamesGeneral[2]]: "percentual_away_points",
    [columnNamesGeneral[3]]: "total_away_points",
    [columnNamesGeneral[4]]: "total_points",
    [columnNamesGeneral[5]]: "percentual_total_points",
  };

  const filterOptions1 = [
    { value: "", label: t("All") },
    { value: "home_game=True", label: t("Home") },
    { value: "home_game=False", label: t("Away") },
  ];
  const [filterOptions2, setFilterOptions2] = useState([
    { value: "", label: t("All leagues") },
  ]);
  const { authTokens } = useContext(AuthContext);

  const headers = {
    Authorization: `Bearer ${authTokens.access}`,
  };

  const requestGames = async () => {
    // Initialize parameters array
    const params = [];

    // Check if selectedFilter is present and add it to the parameters array
    if (selectedFilter !== "") {
      params.push(`${selectedFilter}`);
    }
    // Check if leagueFilter is present and add it to the parameters array
    if (leagueFilter !== "") {
      params.push(`${leagueFilter}`);
    }
    // Check if view is true and add month and year to the parameters array
    if (view === true) {
      const currentMonth = mesAtual + 1; // Adjust month index
      const currentYear = anoAtual;
      params.push(`month=${currentMonth}`);
      params.push(`year=${currentYear}`);
    }
    // Construct the query string
    const queryString = params.length > 0 ? "?" + params.join("&") : "";
    // Send the request with the constructed URL
    axios
      .get(`${process.env.REACT_APP_BACKEND}games/list${queryString}`, {
        headers,
      })
      .then((response) => {
        setGamesData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const leaguesStats = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND}games/league_games_stats_list`, {
        headers,
      })
      .then((response) => {
        setLeaguesGamesStats(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    requestGames();
    leaguesStats();
  }, [selectedFilter, leagueFilter, anoAtual, mesAtual, view]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND}games/leagues/list`, { headers })
      .then((response) => {
        let options = [{ value: "", label: t("All leagues") }];
        response.data.map((league) => {
          options.push({ value: `league=${league.id}`, label: league.name });
        });
        setFilterOptions2(options);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [leagueFilter]);

  const handleClickOutside = () => {
    const gamesRows = document.querySelectorAll(".webapp-games-row-popup, hidden-popup");
    for (let i = 0; i < gamesRows.length; i++) {
      gamesRows[i].classList.add("hidden-popup");
      document.removeEventListener('click', handleClickOutside);
    }
};

  const handleRowPopup = (e, index) => {
    // Get div wiht key index
    const rowPopup = document.getElementsByClassName("webapp-games-row-popup")[
      index
    ];

    if (rowPopup.classList.contains("hidden-popup")) {
      handleClickOutside();
      rowPopup.classList.remove("hidden-popup");
      e.stopPropagation();
      document.addEventListener('click', handleClickOutside);
    } else {
      rowPopup.classList.add("hidden-popup");
    }
  };

  const handleDeleteGame = (id) => {
    setSelectedGame(id);
    setGameDeletePopup(true);
  };

  const deleteGameRequest = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND}games/delete-game/${selectedGame}`,
        { headers },
      )
      .then((response) => {
        requestGames();
        leaguesStats();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSubpageChange = (subpage) => {
    setView(false);
    setSelectedSubpage(subpage);
  };

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
  };
  const handleLeagueFilterChange = (value) => {
    setLeagueFilter(value);
  };

  const handleAddGame = () => {
    navigate(`/webapp/addgame`);
  };

  const handleView1 = () => {
    setView(false);
  };
  const handleView2 = () => {
    setView(true);
  };

  const handleLeftButton = () => {
    if (mesAtual > 0) {
      setMesAtual((mesAtual += -1));
    } else {
      setAnoAtual((anoAtual += -1));
      setMesAtual((mesAtual = 11));
    }
  };

  const handleRightButton = () => {
    if (mesAtual < 11) {
      setMesAtual((mesAtual += 1));
    } else {
      setMesAtual((mesAtual = 0));
      setAnoAtual((anoAtual += 1));
    }
  };

  return (
    <div className="webapp-games-page-scroll">
      <Sidebar selectedOption="games" />
      <div className="webapp-games-page">
        <div className="webapp-create-team-background-images">
          <img
            src={BckLogo}
            alt="Logo"
            className="webapp-create-team-bcklogo"
            id="webapp-create-team-bcklogo1"
          />
          <img
            src={BckLogo}
            alt="Logo"
            className="webapp-create-team-bcklogo"
            id="webapp-create-team-bcklogo2"
          />
        </div>
        {gameDeletePopup ? (
          <ConfirmationPopup
            data={{
              image: "close",
              message: [t("Delete game message"), ""],
              close: "Cancelar",
              advance: "Apagar treino",
            }}
            setState={setGameDeletePopup}
            action={deleteGameRequest}
          />
        ) : null}
        <div className="webapp-games-page-subsections">
          {/* <GamesPageChanger
            selectedPage={2}
            page2={"Carga Individual"}
            page3={"Carga Coletiva"}
            t={t}
          /> */}
          <TrainingsPageChanger
            selectedPage={0}
            pages={[
                { label: t("Collective Load"), path: '/webapp/collective-load' },
                { label: t("Individual Load"), path: '/webapp/individual-load' },
                // { label: t('Training Statistics'), path: '/webapp/stats' },
            ]}
          />
        </div>
        <div className="webapp-games-page-games-container">
          <div className="games-folder-container-header">
            <h1>{t("Games - Collective Load")}</h1>
            <button onClick={() => handleAddGame()}>+ {t("New Game")}</button>
          </div>
          <GamesSubpageChanger
            selectedSubpage={selectedSubpage}
            onSubpageChange={handleSubpageChange}
            page1={t("Games")}
            page2={t("General performance of competition")}
          />
          {selectedSubpage === 0 && (
            <div className="webapp-games-collective-page-table-mods">
              <div className="webapp-games-collective-page-table-mods2">
                <FilterBy
                  options={filterOptions1}
                  onChange={handleFilterChange}
                />
                <FilterBy
                  options={filterOptions2}
                  onChange={handleLeagueFilterChange}
                />
              </div>
              <div className="views-container">
                <div className="view-page-games" onClick={handleView1}>
                  <img src={tab1} />
                  {view === false ? <div>{t("Performance table")}</div> : null}
                </div>
                <div className="view-page-games" onClick={handleView2}>
                  <img src={tab2} />
                  {view === true ? (
                    <div>{t("General view of games")}</div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
        {view === false ? (
          <>
            {selectedSubpage === 0 ? (
              <div className="webapp-games-table">
                <div className="webapp-games-table-row webapp-games-table-header">
                  {columnNames.map((columnName, index) => {
                    return (
                      <div key={index} className="webapp-games-table-column">
                        {t(columnName)}
                      </div>
                    );
                  })}
                  <div className="webapp-games-table-column-select align-asterisc">
                    :
                  </div>
                </div>

                {gamesData.map((game, index) => {
                  return (
                    <div key={index} className="webapp-games-table-row">
                      <div className="webapp-games-table-column">
                        {game.against.name}
                      </div>
                      <div className="webapp-games-table-column">
                        {game.league.name}
                      </div>
                      <div className="webapp-games-table-column">
                        {game.date}
                      </div>
                      {game.home_game ? (
                        <div className="webapp-games-table-column">
                          {" "}
                          <span className="game-asterisc">* </span>
                          {game.goals_for}
                        </div>
                      ) : (
                        <div className="webapp-games-table-column">
                          <span className="align-asterisc">* </span>
                          {game.goals_against}
                        </div>
                      )}
                      {game.home_game ? (
                        <div className="webapp-games-table-column">
                          <span className="align-asterisc">* </span>
                          {game.goals_against}
                        </div>
                      ) : (
                        <div className="webapp-games-table-column">
                          {" "}
                          <span className="game-asterisc">* </span>
                          {game.goals_for}
                        </div>
                      )}
                      <div className="webapp-games-table-column">
                        {game.points}
                      </div>
                      <div className="webapp-games-table-column">
                        {game.duration}
                      </div>
                      <div
                        className="webapp-games-table-column-select"
                        onClick={(e) => {
                          handleRowPopup(e, index);
                        }}
                      >
                        <img src={Dots} />
                      </div>
                      <div className="webapp-games-row-popup hidden-popup">
                        <div className="games-row-popup-option" onClick={() => navigate(`/webapp/gamestats`, {state: { id: game.id }, } ) }>
                          <img src={FillEdit} /> <span>{t("Fill")}</span>
                        </div>
                        <div className="webapp-games-popup-delete" onClick={() => handleDeleteGame(game.id)}>
                          <img src={RedTrash} /> <span>{t("Delete game")}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="webapp-games-table">
                <MapTable
                  ItemName={t("League")}
                  Data={leaguesGamesStats}
                  mappedColumns={mappedColumns}
                  columnNames={columnNamesGeneral}
                  visibleColumns = {6}
                  t = {t}
                />
              </div>
            )}
          </>
        ) : (
          <div>
            <div className="webapp-games-page-top-bar-scroll">
              <img
                src={scrollLeft}
                alt="Scroll Left"
                onClick={handleLeftButton}
              />
              <div className="webapp-games-page-top-bar-text">
                {t(months[mesAtual]) + " " + anoAtual}
              </div>
              <img
                src={scrollRight}
                alt="Scroll Left"
                onClick={handleRightButton}
              />
            </div>
            <div className="game-info-container-display">
              {gamesData.map((game, index) => {
                return (
                  <div>
                    {/* COLOCAR NAS PROPS DE CADA CHAMADA DE GAME INFO OS VALORES DO GAME DATA */}
                    <GameInfo game={game} t={t} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GamesCollectiveLoadPage;
