import { useState, useContext } from "react";
import axios from "axios";
import AuthContext from '../../context/AuthContext';
import { useTranslation } from "react-i18next";

const ConfirmPasswordPopup = ({setState, action, actionType}) => {
    const { t } = useTranslation(['coach-profile']);

    const [password, setPassword] = useState("")

    const { authTokens, logoutUser } = useContext(AuthContext);

    const [error, setError] = useState("");

    // Define the headers with the Bearer token
    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    const onCancel = () => {
        setState(false)
    }

    /*
    cancelar subscricao da red screen em error
    */ 

    const checkPassword = (password) => {
        // Chamada ao backend para verificação da senha
        axios.post(process.env.REACT_APP_BACKEND + 'users/password-verification', { password }, { headers })
            .then((response) => {
                // Limpa erro e chama a ação passada pelos props
                setError("");
                action(); // Chama a ação ( cancelSubscription ou changePassword)
            })
            .catch((error) => {
                setPassword(""); // Limpa o campo de senha
                setError(t('Password verification failed.')); // Exibe erro se a verificação falhar
            });
    };

    return (
        <div className="webapp-popup-background">
            <div className="webapp-popup">
                <div className="webapp-popup-warning">
                    <h3 className="webapp-popup-title-normal">{t('Confirm your')} {t('password')}</h3>
                </div>
                <div className="webapp-popup-message">
                    {/*<p>{t('To continue cancelling your subscription, please insert you password')}</p>*/} 
                    {actionType === "cancelSubscription" ? (
                        <p>{t('To continue cancelling your subscription, please insert your password')}</p>
                    ) : (
                        <p>{t('To confirm your password change, please insert your password')}</p>
                    )}
                </div>
                <input type="password" className="webapp-coachprofile-password-body-input" value={password} onChange={(e) => setPassword(e.target.value)}/>
                <hr className="webapp-popup-line"/>
                <div className="webapp-popup-buttons">
                    <button onClick={onCancel} className="webapp-createteam-button webapp-createteam-white-button">{t('Cancel')}</button>
                    <button onClick={()=>checkPassword(password)} className="webapp-createteam-button webapp-createteam-blue-button">{t('Confirm')}</button>
                </div>
                <div className="webapp-coachprofile-password-error">
                    <p>{error}</p>
                </div>
            </div>
        </div>
    )
}

export default ConfirmPasswordPopup;