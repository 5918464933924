import './PlaceHolders.css';
const TextPlaceHolder = ({fontSize, width}) => {
    const style = {
        height: `${fontSize * 1.2}rem`, // Adjusting height based on props
        borderRadius: `${fontSize * 0.5}rem`, // Adjusting border-radius based on fontSize
        width: `${width}`, // Adjusting width based on props
    };

    return (
        <div className="text-placeholder" style={style}>
        </div>
    )
}
export default TextPlaceHolder;