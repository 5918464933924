import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import './dashboard2.css';

function ChartComponent2() {
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);

  const createChart = (chartRef, data) => {
    if (chartRef.current) {
      if (chartRef.current.chart) {
        chartRef.current.chart.destroy(); // Destroy the previous chart instance
      }

      const ctx = chartRef.current.getContext('2d');
      chartRef.current.chart = new Chart(ctx, data);
    }
  };
  useEffect(() => {
    const data1 = {
      labels: ['Jogador', 'Posição', 'Geral'],
      datasets: [
        {
          label: 'Carga Crónica  ',
          backgroundColor: '#0B24FB',
          borderColor: '#0B24FB',
          data: [5500, 3900,2000],
          type: 'bar',
          borderWidth:3,
          order:1,
        },
        {
          label: 'Relação CA/CC', // Additional column
          backgroundColor: '#FCA555',
          data: [4500, 700, 5800], // Data for the new column
          type: 'line',
          borderColor: '#FCA555',
          order:0,
        },
      ],
    };
  
    createChart(chartRef1, {
      type: 'bar',
      data: data1,
      options: {
        elements: {
          line: {
            borderWidth: 3, // Increase the width of the line
          },
        },
        barThickness: 8,
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              usePointStyle: false,
              pointStyle: 'square',
              boxWidth: 10,
              boxHeight: 10,
              padding: 10,
            },
          },
          title: {
            display: true,
            text: ['Unidade de Carga'],
            align: 'start',
            font: {
              size: 10,
            },
            
            color: 'grey',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        },
        scales: {
          x: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 8,
              },
            },
          },
          y: {
            border: {
              display:false,
            },
            suggestedMin: 0,
            suggestedMax: 6000,
            ticks: {
              stepSize: 2000,
              font:{
                size:9,
              }
            },

          },
          
        },
        aspectRatio: 1,
      },
    });
  }, []);
  
  useEffect(() => {
    const data2 = {
      labels: ['Jogador', 'Posição', 'Geral'],
      datasets: [
        {
          label: 'Carga Crónica',
          backgroundColor: '#0B24FB',
          borderColor:'#0B24FB',
          borderWidth:2,
          data: [4005, 1000, 5008],
          type: 'bar',
          order:1,
        },
        {
          label: '% CA ≠ CC     ',
          data: [1000, 4000, 3000],
          borderColor: '#FCA555',
          backgroundColor: '#FCA555',
          type: 'line',
          font: {
            size: 2,
          },
          order: 0,
        },
      ],
    };
  
    createChart(chartRef2, {
      type: 'bar',
      data: data2,
      options: {
        responsive: true,
        barThickness:8,
        plugins: {
          legend: {
            
            display: true,
            position: 'bottom',
            labels: {
              usePointStyle: false,
              pointStyle: 'square',
              boxWidth: 10,
              boxHeight: 10,
              padding: 10,
            },
          },
          title: {
            display: true,
            text: ['Unidade de Carga'],
            align: 'start',
            font: {
              size: 10,
            },
            lineHeight: 1.2,
            color: 'grey',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        },
        scales: {
          x: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 8,
              },
            },
          },
          y: {
            border: {
              display:false,
            },
            suggestedMin: 0,
            suggestedMax: 6000,
            ticks: {
              stepSize: 2000,
              font:{
                size:9,
              }
            },
            
          },
        },
        aspectRatio: 1,
      },
    });
  }, []);
  
  
  useEffect(() => {
    const data3 = {
      labels: ['Jogador', 'Posição', 'Geral'],
      datasets: [
        {
          label: 'Soma de Carga Total MC',
          backgroundColor: '#FCA555',
          borderColor:'#FCA555',
          data: [3000, 3000, 5800],
          type:'line',
          pointStyle: 'rect', 
          radius: 0, 
        },
        {
          label: 'Soma de Carga Total MC',
          fill: false,
          backgroundColor: '#0B24FB',
          borderColor:'#0B24FB',
          data: [4900, 4200, 3600],
          type:'line',
        },
      ],
    };

    createChart(chartRef3, {
        type: 'line',
        data: data3,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                usePointStyle: false, // Use square point style
                pointStyle: 'square',
                boxWidth: 10, // Define the width of the square
                boxHeight: 10, // Define the height of the square
                padding: 10, // Adjust the padding for the legend labels
              },
            },
            title: {
              display: true,
              text: ['Índice de Especifidade'],
              align: 'start', // You can adjust this to 'start', 'center', or 'end' as needed
              font: {
                size: 10,
              },
              lineHeight: 1.2, // Adjust the line height to separate the titles
              color: 'grey',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          },
          scales: {
            x: {
              beginAtZero: true,
              grid: {
                display: false,
              },
              ticks: {
                font: {
                  size: 8,
                },
              },
            },
            y: {
              border: {
                display:false,
              },
              suggestedMin: 0,
              suggestedMax: 6000,
              ticks: {
                stepSize: 2000,
                font: {
                  size: 9, // Adjust the font size of the ticks
                },
              },
              
            },
          },
          aspectRatio: 1, // Adjust the aspect ratio to reduce the size (0.5 for example)
        },
      });
    }, []);


  return (
    <div className="chart-card2">
      <div className="chart-title">UNIDADE DE CARGA</div>
      <div className="chart-column1">
        <h4>CA/CC e Racio</h4>
        <canvas ref={chartRef1}></canvas>
      </div>
      <div className="chart-column2">
        <h4>Carga CA/CC e variação percentual</h4>
        <canvas ref={chartRef2}></canvas>
      </div>
      <div className="chart-column3">
        <h4>Unidade de Carga e Índice de Especificidade</h4>
        <canvas ref={chartRef3}></canvas>
      </div>
    </div>
  );
}


export default ChartComponent2;