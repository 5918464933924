import React, { useState, useEffect } from "react";
import "./SearchBar.css";
import searchGlass from '../../assets/search-glass.svg';

const SearchBar = ({ playerData, setFilteredPlayerData,t }) => {
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        // Set filtered data initially to match the player data
        setFilteredPlayerData(playerData);
    }, [playerData, setFilteredPlayerData]);

    const handleSearchChange = event => {
        const query = event.target.value;
        setSearchQuery(query);
        filterPlayerData(query);
    };

    function flattenObject(obj, parentKey = '', separator = '_') {
        let flattened = {};

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                let newKey = parentKey ? parentKey + separator + key : key;

                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    Object.assign(flattened, flattenObject(obj[key], newKey, separator));
                } else {
                    flattened[newKey] = obj[key];
                }
            }
        }

        return flattened;
    }

    const filterPlayerData = query => {
        const lowerCaseQuery = query.toLowerCase();
    
        if (query === "") {
            setFilteredPlayerData(playerData); // Reset to original data when search query is empty
            return;
        }
    
        const filteredData = playerData.filter(player => {
            const flatPlayer = flattenObject(player);
            const playerValues = Object.values(flatPlayer);
    
            return playerValues.some(value => {
                if (typeof value === 'string') {
                    return value.toLowerCase().includes(lowerCaseQuery);
                } else if (typeof value === 'number') {
                    // Convert numbers to strings for comparison, adjust as needed
                    return value.toString().toLowerCase().includes(lowerCaseQuery);
                }
                return false;
            });
        });
    
        setFilteredPlayerData(filteredData);
    };

    return (
        <div className="search-bar-box">
            <img src={searchGlass} alt="Search icon" />
            <input
                className="search-bar-input dark-blue-15px-700"
                id="search-bar-input"
                type="text"
                name="search"
                placeholder={t('Name, Player, State...')}
                value={searchQuery}
                onChange={handleSearchChange}
            />
        </div>
    );
};

export default SearchBar;
