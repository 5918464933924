import "./InjuriesStatistics.css";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/AuthContext";

import InjuriesChart from "../../webAppComponents/InjuriesChart/InjuriesChart";
import Sidebar from "../../webAppComponents/Sidebar/Sidebar";
import TrainingsPageChanger from "../../webAppComponents/TrainingsPageChanger/TrainingsPageChanger";
import FilterInjuriesStats from "../../webAppComponents/FilterInjuriesStats/FilterInjuriesStats";

import BckLogo from "../../webAppAssets/BackgroundLogo.svg"

//import everyhtinf from the injury details folder from webAppAssets
import { Body, Xray, BandageAdhesive, Bandaged, ExerciseRunning, ExerciseWeights, Sling, Symptom, ChartLine, Calendar, ChartCuredIncreasing, HealthDataSecurity, Observation, BrokenBone, ClinicalF } from '../../webAppAssets/InjuryDetails/ExportIcons';

const InjuriesStatistics = () => {
    const { t } = useTranslation(["injuries-page"]);
    const { authTokens } = useContext(AuthContext);
    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    //put all the images in an array
    const images = [Sling, Xray, Bandaged, Symptom, BrokenBone, Body, ExerciseWeights, ExerciseRunning, ChartLine, BandageAdhesive, Calendar, ChartCuredIncreasing, HealthDataSecurity, Observation, ClinicalF];

    const [injuries, setInjuries] = useState([]);
    const [selected, setSelected] = useState([]);
    const [none, setNone] = useState(true);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_BACKEND + "injuries/stats", { headers })
            .then((response) => {
                setInjuries(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        const charts = document.querySelectorAll(".webapp-injurieschart");

        let sum_1half = 0;
        let sum_2half = 0;

        for(let i = 0; i < charts.length; i++) {
            //if the chart is in the first half of the array
            if (i < charts.length / 2) {
                sum_1half += charts[i].offsetHeight+32;
            } else {
                sum_2half += charts[i].offsetHeight+32;
            }
        };

        let sum = sum_1half > sum_2half ? sum_1half : sum_2half;
        document.querySelector(".webapp-is-content-layout").style.height = `${sum}px`;
    }, [injuries]);

    const updateSelected = (option) => {
        let check;
        if (selected.includes(option)) {
            setSelected(selected.filter((item) => item !== option));
            if(selected.length === 1) {
                check=false;
            }
        } else if (option !== undefined){
            setSelected([...selected, option]);
            check=true;
        }
        if(check === true) {
            setNone(false);
        }   else if(check === false){
            setNone(true);
        }
    }

    const placeBackgroundImages = () => {
        const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
        const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
        const bckLogo1Height = bckLogo1.offsetHeight;
        bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
        bckLogo2.style.top = `0%`;
        const bckLogo1Width = bckLogo1.offsetWidth;
        bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
        bckLogo2.style.left = `75%`;
    }

    useEffect(() => {
        placeBackgroundImages();
        window.addEventListener('resize', placeBackgroundImages);
        return () => {
            window.removeEventListener('resize', placeBackgroundImages);
        }
    }, [])


    return (
        <div className="webapp-is">
            <Sidebar selectedOption="injury"/>
            <div className="webapp-is-content">
                <div className="webapp-create-team-background-images">
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
                </div>
                <TrainingsPageChanger
                    selectedPage={1}
                    pages={[
                        { label: t('Team Injuries'), path: '/webapp/injuries' },
                        { label: t('Overall Statistics'), path: '/webapp/injuries/statistics' },
                        // { label: t('Training Statistics'), path: '/webapp/stats' },
                    ]}
                />
                <div className="webapp-is-header">
                    <h1>{t("Injuries")}</h1>
                    <FilterInjuriesStats options={Object.keys(injuries)} updateSelected={updateSelected} none={none}/>
                    <div className="webapp-is-selected">
                        {none ? (
                            <p className="webapp-is-selcted-option">
                                {t("all")}
                            </p>

                        ) : (
                        selected.map((option) => {
                            return (
                                <p className="webapp-is-selcted-option" key={option}>
                                    {t(option)}
                                </p>
                            );
                        }
                        ))}
                    </div>
                </div>
                <div className="webapp-is-content-layout">
                    {Object.keys(injuries).map((key, index) => {
                        if((none === true || selected.includes(key)) && key !== "Total"){
                            return (
                                <InjuriesChart title={key} data={injuries[key]} image={images[index]}/>
                            )
                        }
                        
                    })}
                </div>
            </div>
        </div>
    );
}

export default InjuriesStatistics;