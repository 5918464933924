import React, {useState,useEffect} from "react";
import "./Packages.css";
import packagesThree from "../../assets/packages-first-three.svg";
import { useMediaQuery } from 'react-responsive';
import { Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {BsArrowRight} from "react-icons/bs";
import { useInView } from 'react-intersection-observer';
import { useNavigate } from "react-router-dom";

const Packages = (props) => {

    const navigate = useNavigate();
    const packagesLanguageDict = {
        PT: {
            packagesTitle: 'PREÇOS DE LANÇAMENTO',
            packageCardName1: 'Mensal',
            packageCardName2: 'Semestral',
            packageCardName3: 'Anual',
            //pacote 1
            packageCard1Description1: 'O pacote',
            packageCard1Description2: ' mensal',
            packageCard1Description3: ' oferece',
            packageCard1Description4: ' 1 mês',
            packageCard1Description5: ' de plataforma, com um',
            packageCard1Description6: ' período experimental',
            packageCard1Description7: ' de',
            packageCard1Description8: ' 15 dias.',
            //pacote 2
            packageCard2Description1: 'O pacote',
            packageCard2Description2: ' semestral',
            packageCard2Description3: ' oferece',
            packageCard2Description4: ' 1 semestre',
            packageCard2Description5: ' (6 meses) de plataforma, com um',
            packageCard2Description6: ' período experimental',
            packageCard2Description7: ' de',
            packageCard2Description8: ' 15 dias.',
            //pacote 3
            packageCard3Description1: 'O pacote',
            packageCard3Description2: ' anual',
            packageCard3Description3: ' oferece',
            packageCard3Description4: ' 1 ano',
            packageCard3Description5: ' (12 meses) de plataforma, com um',
            packageCard3Description6: ' período experimental',
            packageCard3Description7:  ' de',
            packageCard3Description8: ' 15 dias.',
            

            //info pacote1
            packageCard1Info1: 'Período',
            packageCard1Info2: 'Experimental',
            packageCard1Info3: '15 dias',
            packageCard1Info4: 'Valor Pacote',
            packageCard1Info5: '13,74€ + IVA (23%)',
            packageCard1Info6: '16,90€',
            packageCard1Info7: '/mês',

            //info pacote2
            packageCard2Info1: 'Período',
            packageCard2Info2: 'Experimental',
            packageCard2Info3: '15 dias',
            packageCard2Info4: 'Valor Pacote',
            packageCard2Info5: '69,02€ + IVA (23%)',
            packageCard2Info6: '84,90€',
            packageCard2Info7: '/semestre',

            //info pacote3
            packageCard3Info1: 'Período',
            packageCard3Info2: 'Experimental',
            packageCard3Info3: '15 dias',
            packageCard3Info4: 'Valor Pacote',
            packageCard3Info5: '130,00€ + IVA (23%)',
            packageCard3Info6: '159,90€',
            packageCard3Info7: '/ano',


            packageButton: 'Comprar',

            packageReccomended: 'Recomendado',


        },
        ES: {
            packagesTitle: 'PRECIOS DE LANZAMIENTO',
            packageCardName1: 'Mensual',
            packageCardName2: 'Semestral',
            packageCardName3: 'Anual',
            //pacote 1
            packageCard1Description1: 'El paquete',
            packageCard1Description2: ' mensual',
            packageCard1Description3: ' ofrece',
            packageCard1Description4: ' 1 mes',
            packageCard1Description5: ' de plataforma, con un',
            packageCard1Description6: ' período experimental',
            packageCard1Description7: ' de',
            packageCard1Description8: ' 15 dias.',
            //pacote 2
            packageCard2Description1: 'El paquete',
            packageCard2Description2: ' semestral',
            packageCard2Description3: ' ofrece',
            packageCard2Description4: ' 1 semestre',
            packageCard2Description5: ' (6 meses) de plataforma, con un',
            packageCard2Description6: ' período experimental',
            packageCard2Description7: ' de',
            packageCard2Description8: ' 15 dias.',
            //pacote 3
            packageCard3Description1: 'El paquete',
            packageCard3Description2: ' anual',
            packageCard3Description3: ' ofrece',
            packageCard3Description4: ' 1 ano',
            packageCard3Description5: ' (12 meses) de plataforma, con un',
            packageCard3Description6: ' período experimental',
            packageCard3Description7:  ' de',
            packageCard3Description8: ' 15 dias.',
            

            //info pacote1
            packageCard1Info1: 'Período',
            packageCard1Info2: 'Experimental',
            packageCard1Info3: '15 dias',
            packageCard1Info4: 'Precio del paquete',
            packageCard1Info5: '13,74€ + IVA (23%)',
            packageCard1Info6: '16,90€',
            packageCard1Info7: '/mes',

            //info pacote2
            packageCard2Info1: 'Período',
            packageCard2Info2: 'Experimental',
            packageCard2Info3: '15 dias',
            packageCard2Info4: 'Precio del paquete',
            packageCard2Info5: '69,02€ + IVA (23%)',
            packageCard2Info6: '84,90€',
            packageCard2Info7: '/semestre',

            //info pacote3
            packageCard3Info1: 'Período',
            packageCard3Info2: 'Experimental',
            packageCard3Info3: '15 dias',
            packageCard3Info4: 'Precio del paquete',
            packageCard3Info5: '130,00€ + IVA (23%)',
            packageCard3Info6: '159,90€',
            packageCard3Info7: '/ano',


            packageButton: 'Comprar',

            packageReccomended: 'Recomendado',

        },
        EN: {
            packagesTitle: 'LAUNCH PRICES',
            packageCardName1: 'Monthly',
            packageCardName2: 'Semesterly',
            packageCardName3: 'Yearly',
            //pacote 1
            packageCard1Description1: 'The ',
            packageCard1Description2: ' monthly',
            packageCard1Description3: ' package offers',
            packageCard1Description4: ' 1 month',
            packageCard1Description5: ' of the platform, with a',
            packageCard1Description6: ' 15 day',
            packageCard1Description7: '',
            packageCard1Description8: ' trial period',
            //pacote 2
            packageCard2Description1: 'The ',
            packageCard2Description2: ' semestral',
            packageCard2Description3: ' package offers',
            packageCard2Description4: ' 1 semester',
            packageCard2Description5: ' (6 months) of the platform, with a',
            packageCard2Description6: ' 15 day',
            packageCard2Description7: '',
            packageCard2Description8: ' trial period',
            //pacote 3
            packageCard3Description1: 'The ',
            packageCard3Description2: ' yearly',
            packageCard3Description3: ' package offers',
            packageCard3Description4: ' 1 year',
            packageCard3Description5: ' (12 months) of the platform, with a',
            packageCard3Description6: ' 15 day',
            packageCard3Description7:  '',
            packageCard3Description8: 'trial period',
            

            //info pacote1
            packageCard1Info1: 'Trial',
            packageCard1Info2: 'Period',
            packageCard1Info3: '15 day',
            packageCard1Info4: 'Package price',
            packageCard1Info5: '13,74€ + IVA (23%)',
            packageCard1Info6: '16,90€',
            packageCard1Info7: '/month',

            //info pacote2
            packageCard2Info1: 'Trial',
            packageCard2Info2: 'Period',
            packageCard2Info3: '15 days',
            packageCard2Info4: 'Package price',
            packageCard2Info5: '69,02€ + IVA (23%)',
            packageCard2Info6: '84,90€',
            packageCard2Info7: '/semester',

            //info pacote3
            packageCard3Info1: 'Trial',
            packageCard3Info2: 'Period',
            packageCard3Info3: '15 days',
            packageCard3Info4: 'Package price',
            packageCard3Info5: '130,00€ + IVA (23%)',
            packageCard3Info6: '159,90€',
            packageCard3Info7: '/year',


            packageButton: 'Buy',

            packageReccomended: 'Recommended',


        }};


    const isMobile = useMediaQuery({ maxWidth: 1100 });

    const defaultThreshold = 0.1;
    const mobileThreshold = 0.0001; 


    const packagesThreshold = isMobile ? mobileThreshold : defaultThreshold;

    const [packagesRef, packagesInView] = useInView({ threshold: packagesThreshold });


    const [packagesScrolled, setPackagesScrolled] = useState(false);


    const handleScroll = () => {
        if ( packagesInView && !packagesScrolled) {
            setPackagesScrolled(true);
        }
        
    }
    
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [packagesInView]);


    const handleRedirect = () => {
        navigate('/webapp/register');
    }





    return (
        <div className="packages-background">
           
        <section id="packages-section-items"  ref={packagesRef} className={`packages-section-items ${packagesInView || packagesScrolled ? 'visible' : ''}`}>
        <div className="packages-page">

            <img className="packages-background-three" src={packagesThree}></img> 
            <h1>{packagesLanguageDict[props.selectedLanguage].packagesTitle}</h1>
            <div className="packages-grid">
            
            {isMobile ? (
                <div className="package-page-carousel">
                <Carousel
                infiniteLoop={true}
                autoPlay={true}
                showThumbs={false}
                showStatus={false}
                showIndicators={true}
                stopOnHover={true}
                transitionTime={500}
                interval={20000}
                thumbWidth={90}
                showArrows={false}
                swipeable={true}
                className="custom-package-carousel"
                >

                <div className="package-card">
                    <div className="package-name">
                        <h2 className="text-36px azul-8">{packagesLanguageDict[props.selectedLanguage].packageCardName1}</h2>
                        
                        <p className="package-description">{packagesLanguageDict[props.selectedLanguage].packageCard1Description1} 
                        <span className="strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard1Description2}</span> 
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description3}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description4}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description5} <span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description6}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description7}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description8}</span></p>
                        
                        
                    </div>
                    <div className="package-info">
                        <div className="package-info-periodo">
                            <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard1Info1} <p>{packagesLanguageDict[props.selectedLanguage].packageCard1Info2}</p></span>
                            <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard1Info3}</p>
                        </div>
                        <div className="package-info-valor">
                            <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard1Info4}</span>
                            <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard1Info5}</p>
                        </div>
                        <div className="package-info-compra">
                            <span className="preco-final">{packagesLanguageDict[props.selectedLanguage].packageCard1Info6} <p>{packagesLanguageDict[props.selectedLanguage].packageCard1Info7}</p></span>
                            <button className="botao-comprar" onClick={() => handleRedirect}>{packagesLanguageDict[props.selectedLanguage].packageButton}</button>
                        </div>
                    </div>
                </div>
                <div className="package-card">
                    <div className="package-name">
                        <h2 className="text-36px azul-8">{packagesLanguageDict[props.selectedLanguage].packageCardName2}</h2>
                    
                        <p className="package-description">{packagesLanguageDict[props.selectedLanguage].packageCard2Description1}
                        <span className="strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard2Description2}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description3}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description4}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description5}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description6}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description7}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description8}</span></p>
                        
                    </div>    
                    <div className="package-info">
                        <div className="package-info-periodo">
                            <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard2Info1} <p>{packagesLanguageDict[props.selectedLanguage].packageCard2Info2}</p></span>
                            <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard2Info3}</p>
                        </div>
                        <div className="package-info-valor">
                            <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard2Info4}</span>
                            <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard2Info5}</p>
                        </div>
                        <div className="package-info-compra">
                            <span className="preco-final">{packagesLanguageDict[props.selectedLanguage].packageCard2Info6} <p>{packagesLanguageDict[props.selectedLanguage].packageCard2Info7}</p></span>
                            <button className="botao-comprar" onClick={handleRedirect}>{packagesLanguageDict[props.selectedLanguage].packageButton}</button>
                        </div>
                    </div>
                </div>
                    <div className="recommended package-card">
                        <div className="package-name">
                        <div className="recommended-package-name">
                            <h2 className="text-36px azul-8">{packagesLanguageDict[props.selectedLanguage].packageCardName3}</h2>
                            <span className="recommended-text">{packagesLanguageDict[props.selectedLanguage].packageReccomended}</span>
                        </div>  
                        <p className="package-description">{packagesLanguageDict[props.selectedLanguage].packageCard3Description1}
                        <span className="strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard3Description2}</span> 
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description3} <span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description4}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description5} <span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description6}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description7} <span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description8}</span></p>
                        
                        
                    </div>    
                        <div className="package-info">
                            <div className="package-info-periodo">
                                <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard3Info1} <p>{packagesLanguageDict[props.selectedLanguage].packageCard3Info2}</p></span>
                                <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard3Info3}</p>
                            </div>
                            <div className="package-info-valor">
                                <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard3Info4}</span>
                                <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard3Info5}</p>
                            </div>
                            <div className="package-info-compra">
                                <span className="preco-final">{packagesLanguageDict[props.selectedLanguage].packageCard3Info6} <p>{packagesLanguageDict[props.selectedLanguage].packageCard3Info7}</p></span>
                                <button className="botao-comprar" onClick={handleRedirect}>{packagesLanguageDict[props.selectedLanguage].packageButton}</button>
                            </div>
                        </div>
                    </div>
            
                </Carousel>
                </div>
            ) : (
                <>
                <div className="package-card">
                    <div className="package-name">
                        <h2 className="text-36px azul-8">{packagesLanguageDict[props.selectedLanguage].packageCardName1}</h2>
                        
                        <p className="text-20px cor-preta package-description">{packagesLanguageDict[props.selectedLanguage].packageCard1Description1} 
                        <span className="strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard1Description2}</span> 
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description3}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description4}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description5} <span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description6}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description7}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description8}</span></p>
                        
                        
                    </div>
                    <div className="package-info">
                        <div className="package-info-periodo">
                            <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard1Info1} <p>{packagesLanguageDict[props.selectedLanguage].packageCard1Info2}</p></span>
                            <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard1Info3}</p>
                        </div>
                        <div className="package-info-valor">
                            <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard1Info4}</span>
                            <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard1Info5}</p>
                        </div>
                        <div className="package-info-compra">
                            <span className="preco-final">{packagesLanguageDict[props.selectedLanguage].packageCard1Info6} <p>{packagesLanguageDict[props.selectedLanguage].packageCard1Info7}</p></span>
                            <button className="botao-comprar" onClick={handleRedirect}>{packagesLanguageDict[props.selectedLanguage].packageButton}</button>
                        </div>
                    </div>
                </div>
                <div className="package-card">
                    <div className="package-name">
                        <h2 className="text-36px azul-8">{packagesLanguageDict[props.selectedLanguage].packageCardName2}</h2>
                    
                        <p className="text-20px cor-preta package-description">{packagesLanguageDict[props.selectedLanguage].packageCard2Description1}
                        <span className="strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard2Description2}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description3}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description4}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description5}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description6}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description7}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description8}</span></p>
                        
                    
                    </div>    
                    <div className="package-info">
                        <div className="package-info-periodo">
                            <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard2Info1} <p>{packagesLanguageDict[props.selectedLanguage].packageCard2Info2}</p></span>
                            <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard2Info3}</p>
                        </div>
                        <div className="package-info-valor">
                            <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard2Info4}</span>
                            <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard2Info5}</p>
                        </div>
                        <div className="package-info-compra">
                            <span className="preco-final">{packagesLanguageDict[props.selectedLanguage].packageCard2Info6} <p>{packagesLanguageDict[props.selectedLanguage].packageCard2Info7}</p></span>
                            <button className="botao-comprar darker" onClick={handleRedirect}>{packagesLanguageDict[props.selectedLanguage].packageButton}</button>
                        </div>
                    </div>
                </div>  
                
                    <div className="recommended package-card">
                        <div className="package-name">  
                        <div className="recommended-package-name">
                            <h2 className="text-36px azul-8">{packagesLanguageDict[props.selectedLanguage].packageCardName3}</h2>
                            <span className="recommended-text">{packagesLanguageDict[props.selectedLanguage].packageReccomended}</span> 
                        </div>
                        
                        <p className="text-20px cor-preta package-description">{packagesLanguageDict[props.selectedLanguage].packageCard3Description1}
                        <span className="strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard3Description2}</span> 
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description3} <span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description4}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description5} <span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description6}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description7} <span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description8}</span></p>
                        
                        
                    </div>    
                        <div className=" package-info">
                            <div className="package-info-periodo">
                                <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard3Info1} <p>{packagesLanguageDict[props.selectedLanguage].packageCard3Info2}</p></span>
                                <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard3Info3}</p>
                            </div>
                            <div className="package-info-valor">
                                <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard3Info4}</span>
                                <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard3Info5}</p>
                            </div>
                            <div className="package-info-compra">
                                <span className="preco-final">{packagesLanguageDict[props.selectedLanguage].packageCard3Info6} <p>{packagesLanguageDict[props.selectedLanguage].packageCard3Info7}</p></span>
                                <button className="botao-comprar" onClick={handleRedirect}>{packagesLanguageDict[props.selectedLanguage].packageButton}</button>
                            </div>
                        </div>
                    </div>
                </>
            ) }
             
            
            </div>    
        {isMobile ? (
        <div className="package-carousel-swipe">
            <span>Scroll <BsArrowRight className="swipe-arrow-right" /></span>
        </div>
        ) : null }    
        </div>
        </section>
        </div>
    )

}

export default Packages;