import "./NavbarJogadorQuestionario.css";
import Logo from "../../webAppAssets/3D-logo.svg";
import React from 'react';
import PlayerOptions from "../../webAppComponents/PlayerOptions/PlayerOptions"
import { useTranslation } from "react-i18next";
import { useState, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import i18next from "i18next";
import axios from "axios";

const NavbarJogador = ({ setPassword }) => {
    const { t, i18n } = useTranslation(['player-forms']);
    const [selectedLanguage, setLanguage] = useState(i18n.language); // Default language
    const { authTokens } = useContext(AuthContext);
    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    const handleLanguage = (language) => {
        if (language !== i18n.language) {
            i18n.changeLanguage(language)
            setLanguage(language)
            updateLanguage(language)
        }
    }

    const updateLanguage = (language) => {
        axios.patch(process.env.REACT_APP_BACKEND + 'users/edit-user', { language }, { headers })
            .then((response) => {
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <div className="navbar-container">
            <div className="navbar-logo">
                <img src={Logo} />
            </div>
            <div className="jogador-info">
                <div className="website-languages">
                    <span onClick={() => handleLanguage('pt')} className={selectedLanguage === 'pt' ? 'selected-language' : ''}>PT</span>
                    <span onClick={() => handleLanguage('es')} className={selectedLanguage === 'es' ? 'selected-language' : ''}>ES</span>
                    <span onClick={() => handleLanguage('en')} className={selectedLanguage === 'en' ? 'selected-language' : ''}>EN</span>
                </div>
                <div className='textDisplay'>
                    <span className="jogador-info-title">{t('Player')}</span>
                    <PlayerOptions setPassword={setPassword} />
                </div>
            </div>
        </div>
    )
}
export default NavbarJogador;