import "./DashboardFilters.css";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";

const DashboardFilters = ({ name, filters, handle, type, isUC=false}) => {
    const { t } = useTranslation(["injuries-page"]);

    const [checkedItems, setCheckedItems] = useState({});
    const handleFilterChange = (e) => {
        const filter = e.target.value;
        handle(name, filter)
        if (isUC){
            setCheckedItems((prev) => ({
                ...prev,
                [filter]: !prev[filter],
            }));
        }
    }

    return (
        <div className="webapp-db-filter">
            <p className="webapp-db-filter-name">
                {t(name)}
            </p>
            <div className="webapp-db-filters-clickables">
                {/* <label className="webapp-db-select-all" onClick={handleFilterChange}>
                    <input type="checkbox" className="webapp-db-select-all-input" name="select-all" value="all" />
                    {t("Select All")}
                </label> */}
                {filters.map((filter) => (
                    <label key={filter.value} className="webapp-db-filter-clickable">
                        <div className={`webapp-db-filter-${type}`}>
                            { 
                            isUC ? <input type={type} id={filter.value} value={filter.value} onChange={handleFilterChange} className={type} name={t(name)} checked={!!checkedItems[filter.value]} />
                             : <input type={type} id={filter.value} value={filter.value} onChange={handleFilterChange} className={type} name={t(name)}/>
                            }
                        </div>
                        {t(filter.label)}
                    </label>
                ))}
            </div>
        </div>
    );
}

export default DashboardFilters;
