import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { jwtDecode } from 'jwt-decode';

const PlayerRoute = () => {
    const { authTokens, logoutUser } = useContext(AuthContext);
    // If the user is logged, verify it's role
    if (authTokens) {
        const role = jwtDecode(authTokens.access).role;
        // If the user is logged, verify if the user is a coach
        if (role === 'PLAYER') {
            return <Outlet />;
        }
        else if (role === 'COACH') {
            return <Navigate to="/webapp/trainings" />;
        }
        // If the user is logged but the role is not defined, redirect to the login page
        else {
            // Remove user from local storage, to avoid errors
            logoutUser(); // navigate("/webapp")
        }
    }
    else {
        return <Navigate to="/webapp/login" />;
    }
};

export default PlayerRoute;