import React, { useEffect, useRef, useState, useContext } from 'react';
import Chart from 'chart.js/auto';
import './treinospage2.css';
import axios from 'axios';
import { IoPerson } from "react-icons/io5";
import { useParams } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import { useTranslation } from "react-i18next";


function TreinoChartComponent2({sessionID}) {
  const { t } = useTranslation(['training-chars']);
  const [fetchedData, setFetchedData2] = useState([]);
  const [posSessionanswered, setPosSessionAnswered] = useState('0/0');
  const [posSessionanswered2, setPosSessionAnswered2] = useState('0/0');
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);

  const { authTokens } = useContext(AuthContext);

  const headers = {
    Authorization: `Bearer ${authTokens.access}`,
  };

  useEffect(() => {
    const fetchData2 = async () => {
      try {
        // Fetch uctotal data
        const uctotalResponse = await axios.get(`${process.env.REACT_APP_BACKEND}training/uctotal/session/${sessionID}`, { headers });
        setFetchedData2(uctotalResponse.data)

        const uctotalPseData = uctotalResponse.data.map(item => item.pse);
        const uctotalPseDataWithoutNull = uctotalPseData.filter(val => val !== null);
        const uctotalCountPse = uctotalPseDataWithoutNull.reduce((acc, val) => {
          acc[val] = (acc[val] || 0) + 1;
          return acc;
        }, {});


        const uctotalAllPossibleValuesPse = Array.from({ length: 11 }, (_, i) => i);
        uctotalAllPossibleValuesPse.forEach(val => {
          if (!(val in uctotalCountPse)) {
            uctotalCountPse[val] = 0;
          }
        });

        const uctotalFilteredDataPse = uctotalResponse.data.filter(item => item.pse !== null);
        const uctotalPlayerNamesByPse = Array.from({ length: 11 }, () => []);

        uctotalFilteredDataPse.forEach(item => {
          uctotalPlayerNamesByPse[item.pse].push(item.player);
        });


        // Fetch ucgym data
        const ucgymResponse = await axios.get(`${process.env.REACT_APP_BACKEND}training/ucgym/session/${sessionID}`, { headers });
        setFetchedData2(ucgymResponse.data);

        // Rename variables for ucgym
        const ucgymPseData = ucgymResponse.data.map(item => item.pse);
        const ucgymPseDataWithoutNull = ucgymPseData.filter(val => val !== null);
        const ucgymCountPse = ucgymPseDataWithoutNull.reduce((acc, val) => {
          acc[val] = (acc[val] || 0) + 1;
          return acc;
        }, {});


        const ucgymAllPossibleValuesPse = Array.from({ length: 11 }, (_, i) => i);
        ucgymAllPossibleValuesPse.forEach(val => {
          if (!(val in ucgymCountPse)) {
            ucgymCountPse[val] = 0;
          }
        });


        const ucgymFilteredDataPse = ucgymResponse.data.filter(item => item.pse !== null);
        const ucgymPlayerNamesByPse = Array.from({ length: 11 }, () => []);

        ucgymFilteredDataPse.forEach(item => {
          ucgymPlayerNamesByPse[item.pse].push(item.player);
        });


        try {
          const additionalResponse = await axios.get(`${process.env.REACT_APP_BACKEND}training/session/${sessionID}`, { headers });
          // Extract the number of people that answered the preform
          const { ucGymAnswered } = additionalResponse.data;
          setPosSessionAnswered(ucGymAnswered);

          
          const ucTotalAdditionalResponse = await axios.get(`${process.env.REACT_APP_BACKEND}training/session/${sessionID}`, { headers });
          const { ucTotalAnswered } = ucTotalAdditionalResponse.data;
          setPosSessionAnswered2(ucTotalAnswered);
        } catch (error) {
          console.error('Error fetching additional data:', error);
        }






        const data1 = {
          labels: [`${t('Relaxing')} 0`, '1', `${t('Very easy')} 2`, '3', `${t('Easy')} 4`, '5', `${t('Hard')} 6`, '7', `${t('Very hard')} 8`, '9', `${t('Extremely hard')} 10`],
          datasets: [{
            label: 'Gym intensity',
            data: Object.values(ucgymCountPse),
            backgroundColor: [
              'rgba(252, 165, 85, 1)', // Red color for values from 0 to 5
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)', // Red color for value 5
              'rgba(105, 120, 249, 1)', // Blue color for values from 6 to 10
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
            ],
            barThickness: 8,
          }],
        };


        const data2 = {
            labels: [`${t('Relaxing')} 0`, '1', `${t('Very easy')} 2`, '3', `${t('Easy')} 4`, '5', `${t('Hard')} 6`, '7', `${t('Very hard')} 8`, '9', `${t('Extremely hard')} 10`],          datasets: [{
            label: 'Field Intesity',
            data: Object.values(uctotalCountPse),
            backgroundColor: [
              'rgba(252, 165, 85, 1)', // Red color for values from 0 to 5
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)',
              'rgba(252, 165, 85, 1)', // Red color for value 5
              'rgba(105, 120, 249, 1)', // Blue color for values from 6 to 10
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
              'rgba(105, 120, 249, 1)',
            ],
            barThickness: 8,
          }],
        };

        const createChart = (chartRef, data) => {
          if (chartRef.current) {
            if (chartRef.current.chart) {
              chartRef.current.chart.destroy(); // Destroy the previous chart instance
            }

            const ctx = chartRef.current.getContext('2d');
            chartRef.current.chart = new Chart(ctx, data);
          }
        };

        // Chart 1
        createChart(chartRef1, {
          type: 'bar',
          data: data1,
          options: {
            indexAxis: 'y',
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                min: 0,
                suggestedmax: 30,
                stepSize: 5,
                position: 'top',
                border: {
                  display: false,
                },
                ticks: {
                  font: {
                    size: 8,
                  },
                },
              },
              y: {
                min: 0,
                max: 10,
                stepSize: 1,
                grid: {
                  display: false,
                },
                ticks: {
                  font: {
                    size: 8,
                  },
                },
              },
            },
            aspectRatio: 1,
            plugins: {

              tooltip: {

                callbacks: {
                  title: function (tooltipItems, data) {
                    if (tooltipItems.length > 0) {
                      const yValue = tooltipItems[0].label;
                      const totalDataPoints = Object.values(ucgymCountPse).reduce((acc, val) => acc + val, 0);
                      const yValueSplit = yValue.split(" ");
                      const yValueNum = yValueSplit[yValueSplit.length - 1];
                      const num_people = ucgymCountPse[yValueNum];
                      const percentage = parseInt((num_people / totalDataPoints) * 100);
                      return `${num_people} (${percentage}%)`;
                    }
                    return '';
                  },

                  label: function (context) {

                    const gymQuality = parseInt(context.parsed.y);
                    const playerNames = ucgymPlayerNamesByPse[gymQuality] || [];

                    // Display player names for the bar's key
                    return playerNames;
                  }
                }
              },
              title: {
                display: true,
                text: [t('nº Players'), ''],
                align: 'end',
                font: {
                  size: 8,
                },
                lineHeight: 0.5,
                color: 'grey',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
              legend: {
                display: false,
              }
            }
          },
        });

        // Chart 2
        createChart(chartRef2, {
          type: 'bar',
          data: data2,
          options: {
            indexAxis: 'y',
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                min: 0,
                suggestedmax: 30,
                stepSize: 5,
                position: 'top',
                border: {
                  display: false,
                },
                ticks: {
                  font: {
                    size: 8,
                  },
                },
              },
              y: {
                min: 0,
                max: 20,
                stepSize: 1,
                beginAtZero: true,
                grid: {
                  display: false,
                },
                ticks: {
                  font: {
                    size: 8,
                  },
                },
              },
            },
            aspectRatio: 1,
            plugins: {

              tooltip: {

                callbacks: {
                  title: function (tooltipItems, data) {
                    if (tooltipItems.length > 0) {
                      const yValue = tooltipItems[0].label;
                      const totalDataPoints = Object.values(uctotalCountPse).reduce((acc, val) => acc + val, 0);
                      const yValueSplit = yValue.split(" ");
                      const yValueNum = yValueSplit[yValueSplit.length - 1];
                      const num_people = uctotalCountPse[yValueNum];
                      const percentage = parseInt((num_people / totalDataPoints) * 100);
                      return `${num_people} (${percentage}%)`;
                    }
                    return '';
                  },

                  label: function (context) {

                    const fieldQuality = parseInt(context.parsed.y);
                    const playerNames = uctotalPlayerNamesByPse[fieldQuality] || [];

                    // Display player names for the bar's key
                    return playerNames;
                  }
                }
              },
              title: {
                display: true,
                text: [t('nº Players'), ''],
                align: 'end',
                font: {
                  size: 8,
                },
                lineHeight: 0.5,
                color: 'grey',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
              legend: {
                display: false,
              }
            }
          },
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData2();


  }, [sessionID]);

  return (
    <div className="chart-cardcombined02">
      <h2 className="chart-titlepos2">{t('After training')}</h2>
      <div className="chart-column">
        <div className='chart-wrapper-header'>
          <h4 className='chart-wrapper-title'>{t('Gym')}</h4>
          <span className="icon-person-text2">
            {posSessionanswered} <IoPerson color="#626472" size={22}/>
          </span>
        </div>
        <canvas ref={chartRef1} />
      </div>
      <div className="chart-column">
        <div className='chart-wrapper-header'>
          <h4 className='chart-wrapper-title'>{t('Field')}</h4>
          <span className="icon-person-text2">
            {posSessionanswered2} <IoPerson color="#626472" size={22}/>
          </span>
        </div>
        <canvas ref={chartRef2} />
      </div>
    </div>
  );
}

export default TreinoChartComponent2;