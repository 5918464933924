import React, {useState, useEffect} from "react";
import "./Footer.css";
import navbarLogo from "../../assets/navbar_logo.svg"

const Footer = ({selectedLanguage}) => {

    const footerLanguageDict = {
        PT: {
            contacto: "Contacto",
        },
        ES: {
            contacto: "Contacto",
        },
        EN: {
            contacto: "Contact",
            
        },
    }

    return (
        <div className="footer">
            <div className="footer-container">
                <div className="image-container"><img className="footer-logo" src={navbarLogo}/></div>
                <div className="footer-contacto"><p className="text-18px strong-text">{footerLanguageDict[selectedLanguage].contacto}</p> <p className="text-18px">+351 912 273 244</p></div>
            </div>

        </div>

    )

}
    export default Footer