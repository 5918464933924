import "./PastPurchases.css";

const PastPurchases = (data) => {
    const cenas=data.data;
    return (
        <div className="webapp-past-purchases">
            <p className="webapp-past-purchase-card">**** **** **** {cenas?.payment_method_last4}</p>
            <p className="webapp-past-purchase-date">{cenas?.date}</p>
            <p className="webapp-past-purchase-value">{cenas?.amount} {cenas?.currency}</p>
        </div>
    );
};

export default PastPurchases;