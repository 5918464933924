import "./SessionPlayers.css";
import { useState, useEffect } from "react";
import axios from "axios";
import RedExlcudeButton from "../../webAppAssets/red-x-in-circle.svg";
import EditPencil from "../../webAppAssets/edit-pencil.svg";
import timerClock from "../../webAppAssets/timer-clock.svg"

const SessionPlayers = ({ headers, session_id, setaddPlayer, setSelectedPlayers, selectedPlayers, setMissingPlayers, readyToAdd, setReadyToAdd, t }) => {
    const [PlayersExerciseData, setPlayersExerciseData] = useState([]);
    const [edit, setEdit] = useState(false);
    const [exercises, setExercises] = useState([]);
    const [selectedExercise, setSelectedExercise] = useState(0);
    const [session, setSessionId] = useState(session_id);
    const [playersToEdit, setPlayersToEdit] = useState([]);


    useEffect(() => {
        const exercises = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_BACKEND + `training/session-exercises/${session}`, { headers })
                setExercises(res.data);
                handleClick(res.data[0].id);
            }
            catch (err) {
                console.log(err);
            }
        }
        exercises();
    }, []);

    const handleEdit = () => {
        setEdit(!edit);
        setPlayersToEdit([]);
    }

    const handleAdd = () => {
        setaddPlayer(true);
        // Make a POST request with the specified headers
        const a = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_BACKEND + `training/session-exercise-missing-players`, { headers: headers, params: { session: session, exercise: selectedExercise } })
                setMissingPlayers(res.data);
            }
            catch (err) {
                console.log(err);
            }
        }
        a();
    }

    const handleClick = (exercise_id, refresh) => {

        if (selectedExercise === exercise_id && refresh === false) {
            return;
        }
        setSelectedExercise(exercise_id);
        // Make a POST request with the specified headers
        const a = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_BACKEND + `training/session-exercise-players`, { headers: headers, params: { session: session, exercise: exercise_id } })
                setPlayersExerciseData(res.data);
            }
            catch (err) {
                console.log(err);
            }
        }
        a();
        if (!refresh) {
            setEdit(false);
        }
    }

    useEffect(() => {
        const handleAddSubmit = async () => {
            // Make a POST request with the specified headers
            try {
                const res = await axios.post(process.env.REACT_APP_BACKEND + `training/session-exercise-player-add`, { selectedPlayers }, { headers: headers, params: { session: session, exercise: selectedExercise } })
                setSelectedPlayers({
                    players: []
                })
                handleClick(selectedExercise, true); // Refresh the players list
            }
            catch (err) {
                console.log(err);
            }
        }

        if (readyToAdd) {
            handleAddSubmit()
            setReadyToAdd(false);
        }
    }, [readyToAdd]);

    const handlePlayerEdit = (data) => {
        if (edit) {
            if (playersToEdit.includes(data.id)) {
                setPlayersToEdit(playersToEdit.filter((player) => player !== data.id));
            }
            else {
                setPlayersToEdit([...playersToEdit, data.id]);
            }
        }
    }

    const handleDeletePlayer = async (data) => {
        // Make a POST request with the specified headers
        if (!edit) {
            return;
        }
        try {
            const res = await axios.post(process.env.REACT_APP_BACKEND + `training/session-exercise-player-delete`, { "player": data.id }, { headers: headers, params: { session: session, exercise: selectedExercise } })

            if (res.status === 200) {
                setPlayersToEdit(playersToEdit.filter((player) => player !== data.id));
                handleClick(selectedExercise, true); // Refresh the players list
            }

        }
        catch (err) {
            console.log(err);
        }
    }

    const handleRemovePlayersToEdit = async () => {
        try {
            const res = await axios.post(process.env.REACT_APP_BACKEND + `training/session-exercise-players-delete`, { "players": playersToEdit }, { headers: headers, params: { session: session, exercise: selectedExercise } })

            if (res.status === 200) {
                setPlayersToEdit([]);
                handleClick(selectedExercise, true); // Refresh the players list
            }

        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="webapp-session-players">
            <div className="webapp-session-players-title">
                <h2 className="webapp-session-players-title"> {t('Exercises')} </h2>
                <div className={`webapp-session-players-player-selected-to-edit-popup ${playersToEdit.length > 0 ? "" : "nondisplay"} `}>
                    <p>{playersToEdit.length} {('Selecteds')}</p>
                    <button onClick={handleRemovePlayersToEdit}>{t('Remove selected players')}</button>
                </div>
            </div>
            <div className="webapp-session-players-header">
                <div className="webapp-session-players-exercises">
                    {exercises.map((exercise) => {
                        return (
                            <div className={`webapp-session-players-exercise ${selectedExercise === exercise.id ? 'exercise-selected' : ''}`} key={exercise.id} onClick={() => handleClick(exercise.id)}><span>E{exercise.id}</span> <p className="webapp-trainings-training-timer"><img src={timerClock} /> {exercise.time} min </p> </div>
                        )
                    })}
                </div>
                {!edit ? (
                    <button className="webapp-createteam-button webapp-createteam-white-button webapp-createteam-edit webapp-session-players-edit" onClick={handleEdit}>
                        <img src={EditPencil} alt="Edit Button" />{t('Edit')}
                    </button>
                ) : (
                    <button className="webapp-createteam-button webapp-createteam-white-button webapp-createteam-edit webapp-session-players-edit webapp-session-players-edit-save" onClick={handleEdit}>
                        {t('Save Changes')}
                    </button>
                )}

            </div>
            <div className="webapp-session-players-list">
                {PlayersExerciseData.players && PlayersExerciseData.players.map((data) => {
                    let player_name = data.name;
                    return (
                        <p onClick={() => handlePlayerEdit(data)} className={`webapp-session-players-player ${playersToEdit.includes(data.id) === true ? 'webapp-session-players-player-selected-to-edit' : ''}`} key={data.id}>{player_name}
                            <button onClick={() => handleDeletePlayer(data)} className={`webapp-session-players-player-delete ${playersToEdit.includes(data.id) === false ? 'nondisplay' : ''}`}> <img src={RedExlcudeButton} alt="Button to delete player" /> </button></p>
                    )
                })}
                {edit && <button className="webapp-session-players-add" onClick={handleAdd}>+ {t('Add Players')}</button>}
            </div>
        </div>
    );
};

export default SessionPlayers;