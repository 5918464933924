import { useMediaQuery } from 'react-responsive';
import { Carousel } from 'react-responsive-carousel';
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import backArrow from "../../webAppAssets/register-arrow-white.svg"
import whiteLogo from "../../webAppAssets/3D_Branco.svg"
import "./WebAppPackages.css";
import Axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PopUp from "../../webAppComponents/ConfirmationPopup/Popup";
import { useTranslation } from "react-i18next";


const WebAppPackages = (props) => {
    const { t, i18n } = useTranslation(['register-packages']);
    const location = useLocation();
    const { username, email, password } = location.state || {};
    const navigate = useNavigate();
    const [price_ID, setPrice_ID] = useState("");
    const [subscription, setSubscription] = useState();
    const [popup, setPopup] = useState(false);

    const handleMonthlyPackage = async () => {
        setPrice_ID(process.env.REACT_APP_STRIPE_PRICE_MONTHLY)

    }
    const handleSemesterlyPackage = async () => {
        setPrice_ID(process.env.REACT_APP_STRIPE_PRICE_SEMESTERLY)
    }
    const handleYearlyPackage = async () => {
        setPrice_ID(process.env.REACT_APP_STRIPE_PRICE_YEARLY)
    }

    const isMobile = useMediaQuery({ maxWidth: 1100 });

    const sendToStripe = async () => {

        try {
            const response = await Axios.post(process.env.REACT_APP_BACKEND + 'users/register-coach', {
                priceId: price_ID, // Replace with the actual price ID
                email: email,             // Replace with the user's email
                username: username,     // Replace with the user's username
                password: password,
                lan : i18n.language
            });

            if (response.status === 201) {
                navigate("/webapp/login")
            }
        } catch (error) {

            console.error(error);
        }
    };

    useEffect(() => {
        if (price_ID) {
            if (subscription) {
                setPopup(true)
            } else {
                sendToStripe()
            }
        }
    }, [price_ID])


    return (
        <div className="webapp-packages-page">

            <div className="webapp-register-icons">
                <Link to={subscription ? "/webapp/register" : "/webapp/coachprofile"}><img className="white-back-arrow" src={backArrow}></img></Link>
                <img src={whiteLogo}></img>
            </div>
            <div className="webapp-packages-container">

                <div className="webapp-packages-items">
                    {!subscription && <h1>{t('Choose an Plan')}</h1>}
                    <div className="webapp-packages-grid">

                        {isMobile ? (
                            <div className="packages-carousel">
                                <Carousel
                                    infiniteLoop={true}
                                    autoPlay={true}
                                    showThumbs={false}
                                    showStatus={false}
                                    showIndicators={true}
                                    stopOnHover={true}
                                    transitionTime={500}
                                    interval={20000}
                                    thumbWidth={90}
                                    showArrows={false}
                                    swipeable={true}
                                    className="webapp-custom-package-carousel"

                                >
                                    <div className="package-card">
                                        <div className="package-name">
                                            <h2 className="text-36px azul-8">{t('Monthly.title')}</h2>

                                            <p className="package-description">{t('Monthly.description.0')}
                                                <span className="strong-text">{t('Monthly.description.1')}</span>
                                                {t('Monthly.description.2')}<span className="strong-text">
                                                    {t('Monthly.description.3')}</span>
                                                {t('Monthly.description.4')}<span className="strong-text">
                                                    {t('Monthly.description.5')}</span>
                                                {t('Monthly.description.6')}<span className="strong-text">
                                                    {t('Monthly.description.7')}</span>{t('Monthly.description.8')}
                                            </p>

                                        </div>
                                        <div className="package-info">
                                            <div className="package-info-periodo">
                                                <span className="package-info-key strong-text">{t('Monthly.free-trial-title')}</span>
                                                <p className="free-trial-price">{t('Monthly.free-trial-time')}</p>
                                            </div>
                                            <div className="package-info-valor">
                                                <span className="package-info-key strong-text">{t('Monthly.plan-price')}</span>
                                                <p className="free-trial-price">{t('Monthly.plan-price-value')}</p>
                                            </div>
                                            <div className="package-info-compra">
                                                <span className="preco-final">{t('Monthly.plan-price-value-after-tax')}<p>{t('Monthly.per-month')}</p></span>
                                                <button onClick={handleMonthlyPackage} className="webapp-botao-comprar-package ">{t('Buy')}</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="package-card">
                                        <div className="package-name">
                                            <h2 className="text-36px azul-8">{t('Semestral.title')}</h2>
                                            <p className="package-description">{t('Semestral.description.0')}
                                                <span className="strong-text">{t('Semestral.description.1')}</span>
                                                {t('Semestral.description.2')}<span className="strong-text">
                                                    {t('Semestral.description.3')}</span>
                                                {t('Semestral.description.4')}<span className="strong-text">
                                                    {t('Semestral.description.5')}</span>
                                                {t('Semestral.description.6')}<span className="strong-text">
                                                    {t('Semestral.description.7')}</span>{t('Semestral.description.8')}
                                            </p>
                                        </div>
                                        <div className="package-info">
                                            <div className="package-info-periodo">
                                                <span className="package-info-key strong-text">{t('Semestral.free-trial-title')}</span>
                                                <p className="free-trial-price">{t('Semestral.free-trial-time')}</p>
                                            </div>
                                            <div className="package-info-valor">
                                                <span className="package-info-key strong-text">{t('Semestral.plan-price')}</span>
                                                <p className="free-trial-price">{t('Semestral.plan-price-value')}</p>
                                            </div>
                                            <div className="package-info-compra">
                                                <span className="preco-final">{t('Semestral.plan-price-value-after-tax')} <p>{t('Semestral.per-semester')}</p></span>
                                                <button onClick={handleSemesterlyPackage} className="webapp-botao-comprar-package ">{t('Buy')}</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="recommended package-card">
                                        <div className="package-name">
                                            <div className="recommended-package-name">
                                                <h2 className="text-36px azul-8">{t('Anual.title')}</h2>
                                                <span className="recommended-text">{t('Anual.recommended')}</span>
                                            </div>
                                            <p className="package-description">{t('Anual.description.0')}
                                                <span className="strong-text">{t('Anual.description.1')}</span>
                                                {t('Anual.description.2')}<span className="strong-text">
                                                    {t('Anual.description.3')}</span>
                                                {t('Anual.description.4')}<span className="strong-text">
                                                    {t('Anual.description.5')}</span>
                                                {t('Anual.description.6')} <span className="strong-text">
                                                    {t('Anual.description.7')}</span>{t('Anual.description.8')}</p>

                                        </div>
                                        <div className="package-info">
                                            <div className="package-info-periodo">
                                                <span className="package-info-key strong-text">{t('Anual.free-trial-title')}</span>
                                                <p className="free-trial-price">{t('Anual.free-trial-time')}</p>
                                            </div>
                                            <div className="package-info-valor">
                                                <span className="package-info-key strong-text">{t('Anual.plan-price')}</span>
                                                <p className="free-trial-price">{t('Anual.plan-price-value')}</p>
                                            </div>
                                            <div className="package-info-compra">
                                                <span className="preco-final">{t('Anual.plan-price-value-after-tax')} <p>{t('Anual.per-year')}</p></span>
                                                <button onClick={handleYearlyPackage} className="webapp-botao-comprar-package">{t('Buy')}</button>
                                            </div>
                                        </div>
                                    </div>

                                </Carousel>
                            </div>
                        ) : (
                            <>
                                <div className="package-card">
                                    <div className="package-name">
                                        <h2 className="text-36px azul-8">{t('Monthly.title')}</h2>
                                        <p className="text-20px cor-preta package-description">
                                            {t('Monthly.description.0')} <span className='strong-text'>{t('Monthly.description.1')}</span> {t('Monthly.description.2')}<span className='strong-text'>{t('Monthly.description.3')}</span> {t('Monthly.description.4')}
                                            <span className='strong-text'> {t('Monthly.description.5')}</span> {t('Monthly.description.6')}
                                            <span className='strong-text'> {t('Monthly.description.7')}</span>{t('Monthly.description.8')}
                                        </p>
                                    </div>

                                    <div className="package-info">
                                        <div className="package-info-periodo">
                                            <span className="package-info-key strong-text">{t('Monthly.free-trial-title')}</span>
                                            <p className="free-trial-price">{t('Monthly.free-trial-time')}</p>
                                        </div>
                                        <div className="package-info-valor">
                                            <span className="package-info-key strong-text">{t('Monthly.plan-price')} </span>
                                            <p className="free-trial-price">{t('Monthly.plan-price-value')}</p>
                                        </div>
                                        <div className="package-info-compra">
                                            <span className="preco-final">{t('Monthly.plan-price-value-after-tax')} <p>{t('Monthly.per-month')}</p></span>
                                            <button onClick={handleMonthlyPackage} className="webapp-botao-comprar-package ">{t('Buy')}</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="package-card">
                                    <div className="package-name">
                                        <h2 className="text-36px azul-8">{t('Semestral.title')}</h2>
                                        <p className="text-20px cor-preta package-description">
                                            {t('Semestral.description.0')} <span className='strong-text'>{t('Semestral.description.1')}</span> {t('Semestral.description.2')}<span className='strong-text'>{t('Semestral.description.3')}</span> {t('Semestral.description.4')}
                                            <span className='strong-text'> {t('Semestral.description.5')}</span> {t('Semestral.description.6')}
                                            <span className='strong-text'> {t('Semestral.description.7')}</span>{t('Semestral.description.8')}
                                        </p>
                                    </div>
                                    <div className="package-info">
                                        <div className="package-info-periodo">
                                            <span className="package-info-key strong-text">{t('Semestral.free-trial-title')}</span>
                                            <p className="free-trial-price">{t('Semestral.free-trial-time')}</p>
                                        </div>
                                        <div className="package-info-valor">
                                            <span className="package-info-key strong-text">{t('Semestral.plan-price')}</span>
                                            <p className="free-trial-price">{t('Semestral.plan-price-value')}</p>
                                        </div>
                                        <div className="package-info-compra">
                                            <span className="preco-final">{t('Semestral.plan-price-value-after-tax')} <p>{t('Semestral.per-semester')}</p></span>
                                            <button onClick={handleSemesterlyPackage} className="webapp-botao-comprar-package ">{t('Buy')}</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="recommended package-card">
                                    <div className="package-name">
                                        <div className="recommended-package-name">
                                            <h2 className="text-36px azul-8">{t('Anual.title')}</h2>
                                            <span className="recommended-text">{t('Anual.recommended')}</span>
                                        </div>
                                        <p className="text-20px cor-preta package-description">
                                            {t('Anual.description.0')} <span className='strong-text'>{t('Anual.description.1')}</span> {t('Anual.description.2')}<span className='strong-text'>{t('Anual.description.3')}</span> {t('Anual.description.4')}
                                            <span className='strong-text'> {t('Anual.description.5')}</span> {t('Anual.description.6')}
                                            <span className='strong-text'> {t('Anual.description.7')}</span>{t('Anual.description.8')}
                                        </p>
                                    </div>
                                    <div className=" package-info">
                                        <div className="package-info-periodo">
                                            <span className="package-info-key strong-text">{t('Anual.free-trial-title')}</span>
                                            <p className="free-trial-price">{t('Anual.free-trial-time')}</p>
                                        </div>
                                        <div className="package-info-valor">
                                            <span className="package-info-key strong-text">{t('Anual.plan-price')}</span>
                                            <p className="free-trial-price">{t('Anual.plan-price-value')}</p>
                                        </div>
                                        <div className="package-info-compra">
                                            <span className="preco-final">{t('Anual.plan-price-value-after-tax')}<p>{t('Anual.per-year')} </p></span>
                                            <button onClick={handleYearlyPackage} className="webapp-botao-comprar-package">{t('Buy')} </button>
                                        </div>
                                    </div>
                                </div>

                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WebAppPackages;