import "./SessionUC.css";
import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { LiaCheckDoubleSolid } from 'react-icons/lia';
import { LuClock } from 'react-icons/lu';
import EditPencil from "../../webAppAssets/edit-pencil.svg"
import TrainingsPageChanger from "../../webAppComponents/TrainingsPageChanger/TrainingsPageChanger";
import UCStats from "../../webAppComponents/ucStats/ucStats";
import Sidebar from "../../webAppComponents/Sidebar/Sidebar";
import axios from "axios";
import AuthContext from "../../context/AuthContext";
import { useTranslation } from "react-i18next";


const SessionPageHeader = (sessionData) => {
    const { t } = useTranslation(['session-page-header']);

    const navigate = useNavigate();
    const handleReturn = () => {
        navigate('/webapp/sessions')
    }

    return (
        <div className="webapp-session-detail-menu-return">
            <button onClick={() => handleReturn()}><HiOutlineArrowLeft /></button>
            <h1>{t('Session')} {sessionData?.sessionData?.session_number}, {t('Microcycle')} {sessionData?.sessionData?.microcycle?.cycle_number} </h1>
            <p>{sessionData?.sessionData.training?.name}</p>
            <p>{sessionData?.sessionData?.date}</p>
            {sessionData?.sessionData?.completed === true ? (
                <span className='completed-box-ucstats'>
                    <LiaCheckDoubleSolid /> {t('Finish')}
                </span>
            ) : (
                <span className='ongoing-box-ucstats' >
                    <LuClock /> <span>{t('On going')}</span>
                </span>
            )}
        </div>
    )

}

const SessionUC = () => {
    const { t } = useTranslation(['session-page']);  
    const { state } = useLocation(); // Receives the state from the previous page
    const [edit, setEdit] = useState(false);
    const [sessionData, setSessionData] = useState({
        session_number: state.dataLoaded ? state.dataLoaded.session_number : "",
        date: state.dataLoaded ? state.dataLoaded.date : "",
        microcycle: {
            cycle_number: state.dataLoaded ? state.dataLoaded.microcycle.cycle_number : "",
        },
        completed: state.dataLoaded ? state.dataLoaded.completed : "",
    });
    const [UCStatsData, setUCStatsData] = useState({
        UCStatsData: "",
    });

    const { authTokens } = useContext(AuthContext);
    const [playersChanged, setPlayersChanged] = useState([]);

    // Define the headers with the Bearer token
    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    const handleEdit = () => {
        if (edit)
            EditPseSubmit();
        setEdit(!edit);

    }

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_BACKEND + `training/uc-stats-session/${state.session}`, { headers })
            .then((response) => {
                response.data.session.date = new Date(response.data.session.date).toLocaleDateString();

                setSessionData(response.data.session);
                setUCStatsData(response.data.UCStatsData);

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [edit]);


    const EditPseSubmit = () => {
        axios
            .patch(process.env.REACT_APP_BACKEND + `training/uc-stats-coach-pse-change/`, { "PlayersUcStats": playersChanged, "session": state.session }, { headers })
            .then((response) => {
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        setPlayersChanged([])
    }

    return (
        <div className="webapp-sessioninfo">
            <Sidebar selectedOption="trainings" />
            <div className="webapp-sessioninfo-content">

                <SessionPageHeader sessionData={sessionData} />

                <div className="webapp-ucstats-nav-header">
                    <TrainingsPageChanger
                        selectedPage={1}
                        pages={[
                            { label: t('Training details'), path: '/webapp/session/trainings', state: { "session": state.session, "training": sessionData?.training?.id, "dataLoaded": sessionData } },
                            { label: t('Load Unit'), path: '/webapp/SessionUC', state: { "session": state.session, "dataLoaded": sessionData } },
                            { label: t('Forms'), path: '/webapp/session/forms', state: { "session": state.session, "dataLoaded": sessionData } },
                            { label: t('Players'), path: '/webapp/SessionEditPlayers', state: { "session": state.session, "dataLoaded": sessionData } },
                        ]}
                    />
                    {!edit ? (
                        <button className="webapp-createteam-button webapp-createteam-white-button webapp-createteam-edit webapp-session-players-edit" onClick={handleEdit}>
                            <img src={EditPencil} alt="Edit Button" />{t('Edit')}
                        </button>
                    ) : (
                        <button className="webapp-createteam-button webapp-createteam-white-button webapp-createteam-edit webapp-session-players-edit webapp-session-players-edit-save" onClick={handleEdit}>
                            {t('Save Changes')}
                        </button>
                    )}
                </div>
                <UCStats headers={headers} UCStatsData={UCStatsData} edit={edit} playersChanged={playersChanged} setPlayersChanged={setPlayersChanged} t={t}/>
            </div>
        </div>
    );
};





export { SessionUC, SessionPageHeader };
