
import React, {useState} from "react";
import WhiteLogo from "../../assets/3DTC.gif";
import "./HomePage.css";
import {BsArrowUp} from "react-icons/bs";
import homeBackgroundLogoThree from "../../assets/home-three.svg";
import academyLogoBackground from "../../assets/academy-three.svg"
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from "react-router-dom";

const HomePage = (props) => {
    const isMobile = useMediaQuery({ maxWidth: 900 });

    const navigate = useNavigate();

    const goToWebApp = () => {
        navigate('/webapp');
    }


    
    return (
        <div className="home-page">
            <img className="home-background-three" src={homeBackgroundLogoThree}></img> 
            <img className="home-background-three-inverse" src={academyLogoBackground}></img> 
            <div className="logo-container">
                <img src= {WhiteLogo} alt="3d Logo" className="app-logo" />
                <button onClick={goToWebApp}>Go to Web App</button>
            </div>
            <div className="swipe">
                <BsArrowUp  className="swipe-up-arrow"/>
                <p className="swipe-text">Swipe up</p>
            </div>
        </div>
    )
    }

export default HomePage;
