import React, { useState, useEffect, useRef } from 'react';
import './FilterBy.css';
import setinha from '../../assets/setinha_baixo.svg';
import { useTranslation } from 'react-i18next';

const FilterBy = ({ options, onChange}) => {
  const { t } = useTranslation(['filter-by']);

  const [selectedOption, setSelectedOption] = useState("");
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownContentRef = useRef(null);
  const optionRef = useRef(null);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = options.filter((option) => option.value === selectedValue)[0];
    setSelectedOption(options.filter((option) => option.value === selectedValue)[0]);
    onChange(selectedOption.value);
  };

  const toggleOptions = () => {
    setIsOptionsVisible(!isOptionsVisible);
  };

  useEffect(() => {
    setSelectedOption(options[0]);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOptionsVisible(false);
        }
    };
    // Add event listener to handle click outside of dropdown
    document.addEventListener('click', handleClickOutside);
    return () => {
        document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Set padding to dropdown content based on scrollbar presence
  useEffect(() => {
    if (!isOptionsVisible) return;
    // Determine if dropdown has a vertical scrollbar
    if (dropdownContentRef.current.offsetHeight >= dropdownContentRef.current.scrollHeight) {
      dropdownContentRef.current.style.padding = '0.5rem';
    }

    // Determine if option has a horizontal overflow
    if (optionRef.current.offsetWidth < optionRef.current.scrollWidth) {
      const innerText = optionRef.current.innerText;
      const contentWidht = dropdownContentRef.current.offsetWidth
      // Cut the text to fit the container
      const cuttedText = innerText.substring(0, Math.floor(contentWidht / 10) - 3);
      optionRef.current.innerText = `${cuttedText}...`;
      // Add hover effect to show the full text as popup
      optionRef.current.title = innerText;
    }
  }, [isOptionsVisible]);

  return (
    <div className='filter-by' ref={dropdownRef}>
      <label htmlFor='filterSelect' onClick={toggleOptions}>
        {selectedOption?.label ? selectedOption?.label : t('Filter-by')}
        <img src={setinha} alt='Setinha' className={`setinha ${isOptionsVisible ? 'active' : ''}`}/>
      </label>
      {isOptionsVisible && (
        <div className="option-list" ref={dropdownContentRef}>
          {options.map((option, index) => (
            <div
              key={index}
              className={`option ${selectedOption?.value === option.value ? 'selected-option-filter' : ''}`}
              onClick={() => {
                handleOptionChange({ target: { value: option.value } });
                toggleOptions();
              }}
              ref={optionRef}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterBy;


