import React, { useState, useEffect, useContext } from "react";
import "./Sidebar.css";
import dicon from "../../webAppAssets/3D-icon.svg"
import dlogo from "../../webAppAssets/3D-logo.svg"
import icon from "../../webAppAssets/icons_default.svg"
import toggleArrow from "../../webAppAssets/side-bar-arrow.svg"
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import AuthContext from '../../context/AuthContext'
import Avaliacoes from "../../webAppAssets/Sidebar/Avaliacoes.svg"
import Jogadores from "../../webAppAssets/Sidebar/Jogadores.svg"
import Jogos from "../../webAppAssets/Sidebar/Jogos.svg"
import Lesoes from "../../webAppAssets/Sidebar/Lesoes.svg"
import Treinos from "../../webAppAssets/Sidebar/Treinos.svg"
import UnidadeCarga from "../../webAppAssets/Sidebar/UC.svg"
import Dashboard from "../../webAppAssets/Sidebar/Dashboard.svg"
import UserDefault from "../../webAppAssets/user-default.svg"
import TrainingIcon from "../../webAppAssets/Sidebar/sidebar-training-games.svg"
import PlayerIcon from "../../webAppAssets/Sidebar/sidebar-players.svg"
import ExcelDownload from "../../webAppAssets/Sidebar/excel-export.svg"
import { useTranslation } from "react-i18next";

import Modal from 'react-modal';
import Avatar from "react-avatar-edit";

const Sidebar = (props) => {
    const { t } = useTranslation(['sidebar']);
    const initialIsClosed = localStorage.getItem('isClosed') === 'true';
    const [isClosed, setIsClosed] = useState(initialIsClosed);
    const initialCoachData = JSON.parse(localStorage.getItem('coachData'));
    const [coachImage, setCoachImage] = useState(
        JSON.parse(localStorage.getItem('coachData')) ? JSON.parse(localStorage.getItem('coachData')).coachImage : null
    );

    if (!initialCoachData) {
        localStorage.setItem('coachData', JSON.stringify({ coachName: '', coachTeam: '' }));
    }
    const [coachData, setCoachData] = useState(initialCoachData);

    useEffect(() => {
        localStorage.setItem('isClosed', isClosed);
    }, [isClosed]);

    const handleToggleClick = () => {
        setIsClosed((prevIsClosed) => !prevIsClosed);
    };

    const [selectedOption, setSelectedOption] = useState(props.selectedOption);

    const [isClicked, setIsClicked] = useState(false);

    const { authTokens } = useContext(AuthContext);

    const navigate = useNavigate();

    const changePage = (route) => {
        navigate(`/webapp/${route}`);
    };
    const rotate = isClosed ? "translateX(50%) translateY(-50%) rotate(180deg)" : " translateX(50%) translateY(-50%) rotate(0)"

    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    const [teamImage, setTeamImage] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND + 'users/coach-info', { headers })
            .then((response) => {
                let fullteamImageUrl = null;
                if (response.data.image_url !== null) {
                    fullteamImageUrl = process.env.REACT_APP_BACKEND + response.data.image_url;
                }

                const updatedCoachData = {
                    coachName: response.data.user.first_name + " " + response.data.user.last_name,
                    coachTeam: response.data.team.name,
                    coachImage: fullteamImageUrl
                };

                setCoachData(updatedCoachData);
                setCoachImage(fullteamImageUrl);
                localStorage.setItem('coachData', JSON.stringify(updatedCoachData));
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [props.reload]);

    const handleExcelDownload = () => {
        axios.get(process.env.REACT_APP_BACKEND + 'users/excel', { headers, responseType: 'blob' })
            .then((response) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    '3D-Training-Control-Excel-Data.xlsx'
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

   
    useEffect(() => {
        const fetchURL = `${process.env.REACT_APP_BACKEND}users/team`;
    
        axios.get(fetchURL, { headers })
            .then((response) => {
                if (response.data && response.data.image_url) { 
                    const fullteamImageUrl = `${process.env.REACT_APP_BACKEND}${response.data.image_url}`; 
    
                    localStorage.setItem('teamImage', fullteamImageUrl);
                    setTeamImage(fullteamImageUrl);
                } else {
                    setTeamImage(null);
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.log("Fetch Error data:", error.response.data);
                    console.log("Fetch Error status:", error.response.status);
                } else if (error.request) {
                    console.log("Fetch Error request:", error.request);
                } else {
                    console.log('Fetch Error', error.message);
                }
            });
    }, [props.reload]);
    
    async function handleImageUpload(file) {
        const uploadURL = process.env.REACT_APP_BACKEND + 'users/change-team-image';
    
        try {
            const formData = new FormData();
            formData.append("team_image", file);
    
            if (file.size > 5242880) {
                return;
            }
    
            const response = await axios.patch(uploadURL, formData, { headers });
            if (response.data) console.log("Upload response message:", response.data.message);
    
            if (response.status === 200) {
                setTeamImage(URL.createObjectURL(file));
            }
        } catch (error) {
            if (error.response) {
                console.log("Error data:", error.response.data);
                console.log("Error status:", error.response.status);
            } else if (error.request) {
                console.log("Error request:", error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    }


    async function onCrop(croppedTeamImage, file) {
        try {
            setTeamImage(croppedTeamImage);
            // Extract base64 data from data URI
            var base64Data = croppedTeamImage.split(',')[1];
            // Convert base64 to binary
            var binaryData = atob(base64Data);
            // Create ArrayBuffer and Uint8Array
            var arrayBuffer = new ArrayBuffer(binaryData.length);
            var uint8Array = new Uint8Array(arrayBuffer);
            for (var i = 0; i < binaryData.length; i++) {
                uint8Array[i] = binaryData.charCodeAt(i);
            }
            // Create Blob
            var blob = new Blob([uint8Array], { type: 'image/png' });
            // Call the async function to handle image upload
            await handleImageUpload(blob);
        } catch (error) {
            // Handle the error here
            console.error("Error cropping image:", error);
        }
    }
    
    const handleImageChange = () => {
        setShowModal(true);
    };
    const customStyles2 = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: "0",
        },
    };
    function closeModal() {
        setShowModal(false);
    }
    const onClose = () => {
        closeModal();
    }




    return (
        <div>

            <nav className={`webapp-sidebar ${isClosed && "webapp-sidebar-close"}`}>
                <header>
                    <div className="webapp-sidebar-logo-text">
                        <img src={dicon} id="dicon" />
                        <img src={dlogo} id="dlogo" />
                        {/* <span >3D TRAINING <br /> CONTROL</span> */}
                    </div>
                    <img src={toggleArrow} id="chevron" className={`webapp-sidebar-toggle ${isClosed && "webapp-sidebar-hide"}`} onClick={handleToggleClick} style={{ transform: rotate }}></img>
                    {/* className={`toggle ${isClosed && "hide"}` */}
                </header>

                <div className="webapp-sidebar-menu-bar">
                    <div className="webapp-sidebar-menu">
                        <ul className="webapp-sidebar-menu-links">
                            <li className="webapp-sidebar-league-box">
                                <div className="webapp-sidebar-team-photo-holder">
                                {teamImage === null ?
                                        <img className='webapp-sidebar-team-photo-default' src={UserDefault} onClick={handleImageChange} />:
                                        <img className='webapp-sidebar-team-photo' src={teamImage} onClick={handleImageChange} />
                                }
                                {showModal && (
                                    <Modal
                                        isOpen={showModal}
                                        onRequestClose={() => setShowModal(false)}
                                        style={customStyles2}
                                        contentLabel="Example Modal"
                                    >
                                        <Avatar
                                            width={390}
                                            height={295}
                                            onCrop={onCrop}
                                            onClose={onClose}
                                            label={t('Choose a file')}
                                        />
                                    </Modal>
                                )}
                                    <div className="webapp-sidebar-team-photo-overlay" onClick={handleImageChange}>
                                        <span></span> {/* You can add an icon here if you prefer */}
                                    </div>
                                </div>
                                <div className="webapp-sidebar-team-info">
                                    <span className="webapp-sidebar-text webapp-sidebar-team-text">{coachData ? coachData.coachTeam : ""}</span>
                                </div>
                            </li>
                            <li className={`webapp-sidebar-nav-link ${(selectedOption === "dashboard" ? 'webapp-sidebar-active-link' : '')}`} onClick={() => changePage("home")}>
                                <img src={Dashboard} className='webapp-sidebar-bx webapp-sidebar-bx-home-alt webapp-sidebar-icon' ></img>
                                <span className="webapp-sidebar-text webapp-sidebar-nav-text">{t('Dashboard')}</span>
                            </li>
                            <li className={`webapp-sidebar-nav-link ${(selectedOption === "trainings" ? 'webapp-sidebar-active-link' : '')}`} onClick={() => changePage("trainings")}>
                                <img src={TrainingIcon} className='webapp-sidebar-bx webapp-sidebar-bx-bar-chart-alt-2 webapp-sidebar-icon' ></img>
                                <span className="webapp-sidebar-text webapp-sidebar-nav-text">{t('Exercises')}</span>
                            </li>

                            <li className={`webapp-sidebar-nav-link ${(selectedOption === "load-unit" ? 'webapp-sidebar-active-link' : '')}`} onClick={() => changePage("uc")}>
                                <img src={UnidadeCarga} className='webapp-sidebar-bx webapp-sidebar-bx-bell webapp-sidebar-icon'></img>
                                <span className="webapp-sidebar-text webapp-sidebar-nav-text">{t('Load Unit')}</span>
                            </li>

                            <li className={`webapp-sidebar-nav-link ${(selectedOption === "players" ? 'webapp-sidebar-active-link' : '')}`} onClick={() => changePage("players")}>
                                <img src={PlayerIcon} className='webapp-sidebar-bx webapp-sidebar-bx-pie-chart-alt webapp-sidebar-icon' ></img>
                                <span className="webapp-sidebar-text webapp-sidebar-nav-text">{t('Players')}</span>
                            </li>

                            <li className={`webapp-sidebar-nav-link ${(selectedOption === "games" ? 'webapp-sidebar-active-link' : '')}`} onClick={() => changePage("collective-load")}>
                                <img src={Jogos} className='webapp-sidebar-bx webapp-sidebar-bx-heart webapp-sidebar-icon' ></img>
                                <span className="webapp-sidebar-text webapp-sidebar-nav-text">{t('Games')}</span>
                            </li>

                            <li className={`webapp-sidebar-nav-link ${(selectedOption === "injury" ? 'webapp-sidebar-active-link' : '')}`} onClick={() => changePage("injuries")}>
                                <img src={Lesoes} className='webapp-sidebar-bx webapp-sidebar-bx-wallet webapp-sidebar-icon' ></img>
                                <span className="webapp-sidebar-text webapp-sidebar-nav-text">{t('Injuries')}</span>
                            </li>
                            <li className={`webapp-sidebar-nav-link ${(selectedOption === "evaluations" ? 'webapp-sidebar-active-link' : '')}`} onClick={() => changePage("evaluations")}>
                                <img src={Avaliacoes} className='webapp-sidebar-bx webapp-sidebar-bx-wallet webapp-sidebar-icon' ></img>
                                <span className="webapp-sidebar-text webapp-sidebar-nav-text">{t('Avaliations')}</span>
                            </li>

                        </ul>
                    </div>

                    <div className={`webapp-sidebar-bottom-content`}>
                      <div className={`webapp-sidebar-bottom-excel-content`} onClick={handleExcelDownload}>
                          <div className="webapp-sidebar-excel-holder">
                             <img src={ExcelDownload} className='webapp-sidebar-excel-icon' />
                          </div>
                          <div className="webapp-sidebar-coach-info">
                              <span className="webapp-sidebar-text webapp-sidebar-nav-text"> {t('Download data')} <span className="webapp-sidebar-excel-extension">(.xlxs)</span> </span>
                          </div>
                      </div>
                      <div className={`webapp-sidebar-coach-holder ${selectedOption === "profile" && "webapp-sidebar-bottom-cenas"}`} onClick={() => changePage("coachprofile")}>
                        <div className="webapp-sidebar-coach-photo-holder">
                          { coachImage === null ? <img src={UserDefault} className='webapp-sidebar-coach-photo-default' /> : <img src={coachImage} className='webapp-sidebar-coach-photo' />}
                          </div>
                        <div className="webapp-sidebar-coach-info">
                            <span className="webapp-sidebar-text webapp-sidebar-nav-text">{t('Coach')}</span>
                            <span className="webapp-sidebar-text webapp-sidebar-nav-text">{coachData ? coachData.coachName : ""} </span>
                        </div>
                      </div>
                    </div>
                </div>

            </nav>
        </div>
    )

    // {/* <script>

    // }); */}

    // const useMediaQuery = (query) => {
    //     const [matches, setMatches] = useState(false);

    //     useEffect(() => {
    //         const media = window.matchMedia(query);
    //         if (media.matches !== matches) {
    //             setMatches(media.matches);
    //         }
    //         const listener = () => setMatches(media.matches);
    //         window.addEventListener("resize", listener);
    //         return () => window.removeEventListener("resize", listener);
    //     }, [matches, query]);

    //     return matches;
    // };

    // const [hovered, setHovered] = useState(null);
    // const [active, setActive] = useState(1);
    // const [animate, setAnimate] = useState(false);
    // const [expanded, setExpanded] = useState(false);
    // const changeSmall = useMediaQuery("(max-height: 550px)");
    // let delay = 1;




    // let menuItems = [
    //     {
    //         name: "",
    //         iconName: "menu",
    //     },
    //     {
    //         name: "Home",
    //         iconName: "home",
    //         type: "solid",
    //     },
    //     {
    //         name: "Explore",
    //         iconName: "compass",
    //         type: "solid",
    //     },
    //     {
    //         name: "Messages",
    //         iconName: "envelope",
    //         type: "solid",
    //     },
    //     {
    //         name: "Resources",
    //         iconName: "spreadsheet",
    //         type: "solid",
    //     },
    //     {
    //         name: "Starred",
    //         iconName: "star",
    //         type: "solid",
    //     },
    //     {
    //         name: "Settings",
    //         iconName: "cog",
    //         type: "solid",
    //     },
    //     {
    //         name: "Log Out",
    //         iconName: "log-out",
    //         color: "red",
    //         rotate: "180",
    //     },
    // ];


    // useEffect(() => {
    //     setAnimate(true);
    //     let timer = setTimeout(() => setAnimate(false), delay * 1000);

    //     return () => {
    //         clearTimeout(timer);
    //     };
    // }, [active, delay]);

    // return (
    //     <div classNameName={`sidebar ${expanded && "expanded"}`}>
    //         {menuItems.map((item, index) => {
    //             let middle = false;
    //             if (!(index === 0 || index === menuItems.length - 1)) {
    //                 middle = true;
    //             }
    //             return (
    //                 <div
    //                     classNameName={`boxicon-container ${expanded && "expanded-boxicon-container"
    //                         }`}
    //                     onMouseEnter={() => {
    //                         if (middle) {
    //                             setHovered(index);
    //                         }
    //                     }}
    //                     onMouseLeave={() => {
    //                         if (middle) {
    //                             setHovered(null);
    //                         }
    //                     }}
    //                     onClick={() => {
    //                         if (middle) {
    //                             setActive(index);
    //                         }
    //                         if (index === 0) {
    //                             setExpanded(!expanded);
    //                         }
    //                     }}
    //                     key={index}
    //                 >
    //                     <box-icon
    //                         className={`${middle && "boxicon"}
    //                   ${!middle && "first-and-last-trash-fix"}
    //                   ${active === index && "active"}
    //                   `}
    //                         size={changeSmall ? "sm" : "md"}
    //                         name={item.iconName}
    //                         type={item.type}
    //                         color={
    //                             hovered === index || active === index ? "white" : item.color
    //                         }
    //                         animation={active === index && animate ? "tada" : ""}
    //                         rotate={item.rotate}
    //                     ></box-icon>
    //                     <p
    //                         classNameName={`description
    //         ${expanded && "show-description"}
    //         ${active === index && "active-description"}`}
    //                     >
    //                         {item.name}
    //                     </p>
    //                 </div>
    //             );
    //         })}
    //     </div>
    // );
};

export default Sidebar;
