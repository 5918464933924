import react from 'react'
import "./CreateEvaluationElements.css"

const CreateEvaluationElements = (props) => {

    const evaluationFormData = props.evaluationFormData

    return (
        <div className={`create-evaluation-form-item-container ${props.step === 3 ? 'create-evaluation-form-final-step' : ''}`}>
            {evaluationFormData[props.step].fields.map(evaluationField => (
                <CreateEvaluationInput key={evaluationField.label} label={evaluationField.label} type={evaluationField.type} placeholder={evaluationField.placeholder} value={evaluationField.value} handleChangeEvaluationData={props.handleChangeEvaluationData} 
                missingFields={props.missingFields} evaluationData={props.evaluationData} t={props.t}/>
            
            ))}
            
        
        </div>
    )
}


const CreateEvaluationInput = (props) => {
    return (
        <div className="create-evaluation-form-input-container">
            
            <label>{props.t(props.label)}</label>
            <div>
                <input 
                    className={props.missingFields.includes(props.value) ? 'create-evaluation-form-input-missing' : 'create-evaluation-form-input'}
                    placeholder={props.t(props.placeholder)}
                    value={props.evaluationData[props.value]}
                    onChange={(e) => {props.handleChangeEvaluationData(props.value, e)}}
                    type={props.type}
                    />
            </div>
        </div>
    )
}


export default CreateEvaluationElements