import { useMediaQuery } from 'react-responsive';
import { HiOutlineArrowLeft } from "react-icons/hi";
import { Carousel} from 'react-responsive-carousel';
import React, {useState, useEffect, useContext} from "react";
import {Link, useLocation, useNavigate} from 'react-router-dom';
import "./ChangePackages.css";
import Axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PopUp from "../../webAppComponents/ConfirmationPopup/Popup";
import ConfirmPasswordPopup from '../../webAppComponents/ConfirmPasswordPopup/ConfirmPasswordPopup';
import AuthContext from '../../context/AuthContext'


const ChangePackages = () => {
    const location = useLocation();
    const { username, email, password } = location.state || {};
    const [price_ID, setPrice_ID] = useState("unset");
    const [newPlan, setNewPlan] = useState("unset");
    const [subscription, setSubscription] = useState(
        {"interval": ""}
    );
    const [popup, setPopup] = useState(false);
    const [passwordPopup, setPasswordPopup] = useState(false);
    const props= {selectedLanguage: "PT"}
    const navigate = useNavigate();
    const { authTokens } = useContext(AuthContext);

    // Define the headers with the Bearer token
    const headers = {
      Authorization: `Bearer ${authTokens.access}`,
    };


    const packagesLanguageDict = {
        PT: {
            packagesTitle: 'PREÇOS DE LANÇAMENTO',
            packageCardName1: 'Mensal',
            packageCardName2: 'Semestral',
            packageCardName3: 'Anual',
            //pacote 1
            packageCard1Description1: 'O pacote',
            packageCard1Description2: ' mensal',
            packageCard1Description3: ' oferece',
            packageCard1Description4: ' 1 mês',
            packageCard1Description5: ' de plataforma, com um',
            packageCard1Description6: ' período experimental',
            packageCard1Description7: ' de',
            packageCard1Description8: ' 15 dias.',
            //pacote 2
            packageCard2Description1: 'O pacote',
            packageCard2Description2: ' semestral',
            packageCard2Description3: ' oferece',
            packageCard2Description4: ' 1 semestre',
            packageCard2Description5: ' (6 meses) de plataforma, com um',
            packageCard2Description6: ' período experimental',
            packageCard2Description7: ' de',
            packageCard2Description8: ' 15 dias.',
            //pacote 3
            packageCard3Description1: 'O pacote',
            packageCard3Description2: ' anual',
            packageCard3Description3: ' oferece',
            packageCard3Description4: ' 1 ano',
            packageCard3Description5: ' (12 meses) de plataforma, com um',
            packageCard3Description6: ' período experimental',
            packageCard3Description7:  ' de',
            packageCard3Description8: ' 15 dias.',
            

            //info pacote1
            packageCard1Info1: 'Período',
            packageCard1Info2: 'Experimental',
            packageCard1Info3: '15 dias',
            packageCard1Info4: 'Valor Pacote',
            packageCard1Info5: '13,74€ + IVA (23%)',
            packageCard1Info6: '16,90€',
            packageCard1Info7: '/mês',

            //info pacote2
            packageCard2Info1: 'Período',
            packageCard2Info2: 'Experimental',
            packageCard2Info3: '15 dias',
            packageCard2Info4: 'Valor Pacote',
            packageCard2Info5: '69,02€ + IVA (23%)',
            packageCard2Info6: '84,90€',
            packageCard2Info7: '/semestre',

            //info pacote3
            packageCard3Info1: 'Período',
            packageCard3Info2: 'Experimental',
            packageCard3Info3: '15 dias',
            packageCard3Info4: 'Valor Pacote',
            packageCard3Info5: '130,00€ + IVA (23%)',
            packageCard3Info6: '159,90€',
            packageCard3Info7: '/ano',


            packageButton: 'Comprar',
            packageChange: 'Mudar',

            packageReccomended: 'Recomendado',
            packageCurrent: 'Plano Atual',

        },
        ES: {
            packagesTitle: 'PRECIOS DE LANZAMIENTO',
            packageCardName1: 'Mensual',
            packageCardName2: 'Semestral',
            packageCardName3: 'Anual',
            //pacote 1
            packageCard1Description1: 'El paquete',
            packageCard1Description2: ' mensual',
            packageCard1Description3: ' ofrece',
            packageCard1Description4: ' 1 mes',
            packageCard1Description5: ' de plataforma, con un',
            packageCard1Description6: ' período experimental',
            packageCard1Description7: ' de',
            packageCard1Description8: ' 15 dias.',
            //pacote 2
            packageCard2Description1: 'El paquete',
            packageCard2Description2: ' semestral',
            packageCard2Description3: ' ofrece',
            packageCard2Description4: ' 1 semestre',
            packageCard2Description5: ' (6 meses) de plataforma, con un',
            packageCard2Description6: ' período experimental',
            packageCard2Description7: ' de',
            packageCard2Description8: ' 15 dias.',
            //pacote 3
            packageCard3Description1: 'El paquete',
            packageCard3Description2: ' anual',
            packageCard3Description3: ' ofrece',
            packageCard3Description4: ' 1 ano',
            packageCard3Description5: ' (12 meses) de plataforma, con un',
            packageCard3Description6: ' período experimental',
            packageCard3Description7:  ' de',
            packageCard3Description8: ' 15 dias.',
            

            //info pacote1
            packageCard1Info1: 'Período',
            packageCard1Info2: 'Experimental',
            packageCard1Info3: '15 dias',
            packageCard1Info4: 'Precio del paquete',
            packageCard1Info5: '13,74€ + IVA (23%)',
            packageCard1Info6: '16,90€',
            packageCard1Info7: '/mes',

            //info pacote2
            packageCard2Info1: 'Período',
            packageCard2Info2: 'Experimental',
            packageCard2Info3: '15 dias',
            packageCard2Info4: 'Precio del paquete',
            packageCard2Info5: '69,02€ + IVA (23%)',
            packageCard2Info6: '84,90€',
            packageCard2Info7: '/semestre',

            //info pacote3
            packageCard3Info1: 'Período',
            packageCard3Info2: 'Experimental',
            packageCard3Info3: '15 dias',
            packageCard3Info4: 'Precio del paquete',
            packageCard3Info5: '130,00€ + IVA (23%)',
            packageCard3Info6: '159,90€',
            packageCard3Info7: '/ano',


            packageButton: 'Comprar',
            packageChange: 'Cambiar',

            packageReccomended: 'Recomendado',
            packageCurrent: 'Plan Actual',

        },
        EN: {
            packagesTitle: 'LAUNCH PRICES',
            packageCardName1: 'Monthly',
            packageCardName2: 'Semesterly',
            packageCardName3: 'Yearly',
            //pacote 1
            packageCard1Description1: 'The ',
            packageCard1Description2: ' monthly',
            packageCard1Description3: ' package offers',
            packageCard1Description4: ' 1 month',
            packageCard1Description5: ' of the platform, with a',
            packageCard1Description6: ' 15 day',
            packageCard1Description7: '',
            packageCard1Description8: ' trial period',
            //pacote 2
            packageCard2Description1: 'The ',
            packageCard2Description2: ' semestral',
            packageCard2Description3: ' package offers',
            packageCard2Description4: ' 1 semester',
            packageCard2Description5: ' (6 months) of the platform, with a',
            packageCard2Description6: ' 15 day',
            packageCard2Description7: '',
            packageCard2Description8: ' trial period',
            //pacote 3
            packageCard3Description1: 'The ',
            packageCard3Description2: ' yearly',
            packageCard3Description3: ' package offers',
            packageCard3Description4: ' 1 year',
            packageCard3Description5: ' (12 months) of the platform, with a',
            packageCard3Description6: ' 15 day',
            packageCard3Description7:  '',
            packageCard3Description8: 'trial period',
            

            //info pacote1
            packageCard1Info1: 'Trial',
            packageCard1Info2: 'Period',
            packageCard1Info3: '15 day',
            packageCard1Info4: 'Package price',
            packageCard1Info5: '13,74€ + IVA (23%)',
            packageCard1Info6: '16,90€',
            packageCard1Info7: '/month',

            //info pacote2
            packageCard2Info1: 'Trial',
            packageCard2Info2: 'Period',
            packageCard2Info3: '15 days',
            packageCard2Info4: 'Package price',
            packageCard2Info5: '69,02€ + IVA (23%)',
            packageCard2Info6: '84,90€',
            packageCard2Info7: '/semester',

            //info pacote3
            packageCard3Info1: 'Trial',
            packageCard3Info2: 'Period',
            packageCard3Info3: '15 days',
            packageCard3Info4: 'Package price',
            packageCard3Info5: '130,00€ + IVA (23%)',
            packageCard3Info6: '159,90€',
            packageCard3Info7: '/year',


            packageButton: 'Buy',
            packageChange: 'Change',

            packageReccomended: 'Recommended',
            packageCurrent: 'Current Plan',


        }};


    const handleMonthlyPackage = async () => {
        if (subscription.interval == "month") return

        setPrice_ID(process.env.REACT_APP_STRIPE_PRICE_MONTHLY)
        setNewPlan("Monthly")
        setPopup(true)
    }

    const handleSemesterlyPackage = async () => {
        if (subscription.interval == "semestre") return;
        
        setPrice_ID(process.env.REACT_APP_STRIPE_PRICE_SEMESTERLY)
        setNewPlan("Semesterly")
        setPopup(true)
    }
    const handleYearlyPackage = async () => {
        if (subscription.interval == "year") return;

        setPrice_ID(process.env.REACT_APP_STRIPE_PRICE_YEARLY)
        setNewPlan("Yearly")
        setPopup(true)
    }
    
    const isMobile = useMediaQuery({ maxWidth: 1100 });

    useEffect(() => {
        // Make a GET request with the specified headers
        Axios.get(process.env.REACT_APP_BACKEND + 'users/subscription', { headers })
            .then((response) => {
                // Handle the successful response here
                setSubscription(response.data.subscription)
            })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error('Error:', error);
            });
    }, [])

    const handleReturn = () => {
        navigate('/webapp/coachprofile')
    }
        
    const changeSubscription = async () => {
        try {
            const response = await Axios.patch(process.env.REACT_APP_BACKEND + 'users/change-subscription', {
            newPriceId: price_ID, // Replace with the actual price ID
            newPlan: newPlan
        },{ headers });

        navigate('/webapp/coachprofile')
        } catch (error) {
            console.error(error);
        }
    }

    const openPassword = () => {
        setPopup(false)
        setPasswordPopup(true)
    }
        
    return (
    <div className="webapp-changepackages-page">

            <div className="webapp-paint-return">
                <button onClick={handleReturn}><HiOutlineArrowLeft/></button>
                <h1>Escolha o novo plano</h1>
            </div>
            <div className="webapp-changepackages-container">

                <div className="webapp-packages-items">
                <div className="webapp-packages-grid">
                
                {isMobile ? (
                <div className="packages-carousel">
                <Carousel
                infiniteLoop={true}
                autoPlay={true}
                showThumbs={false}
                showStatus={false}
                showIndicators={true}
                stopOnHover={true}
                transitionTime={500}
                interval={20000}
                thumbWidth={90}
                showArrows={false}
                swipeable={true}
                className="webapp-custom-changepackage-carousel"
                >
                <div className={subscription.interval == "month" ? "current package-card" : "package-card"}>
                    <div className="package-name">
                        <h2 className="text-36px azul-8">{packagesLanguageDict[props.selectedLanguage].packageCardName1}</h2>
                        
                        <p className="package-description">{packagesLanguageDict[props.selectedLanguage].packageCard1Description1} 
                        <span className="strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard1Description2}</span> 
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description3}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description4}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description5} <span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description6}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description7}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description8}</span></p>
                        
                        
                    </div>
                    <div className="package-info">
                        <div className="package-info-periodo">
                            <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard1Info1} <p>{packagesLanguageDict[props.selectedLanguage].packageCard1Info2}</p></span>
                            <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard1Info3}</p>
                        </div>
                        <div className="package-info-valor">
                            <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard1Info4}</span>
                            <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard1Info5}</p>
                        </div>
                        <div className="package-info-compra">
                            <span className="preco-final">{packagesLanguageDict[props.selectedLanguage].packageCard1Info6} <p>{packagesLanguageDict[props.selectedLanguage].packageCard1Info7}</p></span>
                            <button onClick={handleMonthlyPackage}  className={subscription.interval == "month" ? "webapp-botao-comprar-changepackage " : "enable webapp-botao-comprar-changepackage "}>{subscription.interval == "month" ? packagesLanguageDict[props.selectedLanguage].packageCurrent : packagesLanguageDict[props.selectedLanguage].packageChange}</button>
                        </div>
                    </div>
                </div>
                <div className={subscription.interval == "semestre" ? "current changepackage-card" : "changepackage-card"}>
                    <div className="package-name">
                        <h2 className="text-36px azul-8">{packagesLanguageDict[props.selectedLanguage].packageCardName2}</h2>
                       
                        <p className="package-description">{packagesLanguageDict[props.selectedLanguage].packageCard2Description1}
                        <span className="strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard2Description2}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description3}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description4}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description5}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description6}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description7}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description8}</span></p>
                        
                       
                    </div>    
                    <div className="package-info">
                        <div className="package-info-periodo">
                            <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard2Info1} <p>{packagesLanguageDict[props.selectedLanguage].packageCard2Info2}</p></span>
                            <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard2Info3}</p>
                        </div>
                        <div className="package-info-valor">
                            <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard2Info4}</span>
                            <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard2Info5}</p>
                        </div>
                        <div className="package-info-compra">
                            <span className="preco-final">{packagesLanguageDict[props.selectedLanguage].packageCard2Info6} <p>{packagesLanguageDict[props.selectedLanguage].packageCard2Info7}</p></span>
                            <button onClick={handleSemesterlyPackage} className={subscription.interval == "semestre" ? "webapp-botao-comprar-changepackage " : "enable webapp-botao-comprar-changepackage "}>{subscription.interval == "semestre" ? packagesLanguageDict[props.selectedLanguage].packageCurrent : packagesLanguageDict[props.selectedLanguage].packageChange}</button>
                        </div>
                    </div>
                </div>
                  
                    <div className={subscription.interval == "year" ? "current changepackage-card" : "changepackage-card"}>
                        <div className="package-name">
                        <div className="recommended-package-name">
                            <h2 className="text-36px azul-8">{packagesLanguageDict[props.selectedLanguage].packageCardName3}</h2>
                            <span className="recommended-text">{packagesLanguageDict[props.selectedLanguage].packageReccomended}</span>
                        </div>  
                        <p className="package-description">{packagesLanguageDict[props.selectedLanguage].packageCard3Description1}
                        <span className="strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard3Description2}</span> 
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description3} <span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description4}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description5} <span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description6}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description7} <span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description8}</span></p>
                        
                        
                    </div>    
                        <div className="package-info">
                            <div className="package-info-periodo">
                                <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard3Info1} <p>{packagesLanguageDict[props.selectedLanguage].packageCard3Info2}</p></span>
                                <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard3Info3}</p>
                            </div>
                            <div className="package-info-valor">
                                <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard3Info4}</span>
                                <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard3Info5}</p>
                            </div>
                            <div className="package-info-compra">
                                <span className="preco-final">{packagesLanguageDict[props.selectedLanguage].packageCard3Info6} <p>{packagesLanguageDict[props.selectedLanguage].packageCard3Info7}</p></span>
                                <button onClick={handleYearlyPackage} className={subscription.interval == "year" ? "webapp-botao-comprar-changepackage " : "enable webapp-botao-comprar-changepackage "}>{subscription.interval == "year" ? packagesLanguageDict[props.selectedLanguage].packageCurrent : packagesLanguageDict[props.selectedLanguage].packageChange}</button>
                            </div>
                        </div>
                    </div>
              
                </Carousel>
                </div>
                ) : (
                    <>
                <div className={subscription.interval == "month" ? "current changepackage-card" : "changepackage-card"}>
                    <div className="package-name">
                        <h2 className="text-36px azul-8">{packagesLanguageDict[props.selectedLanguage].packageCardName1}</h2>
                        
                        <p className="text-20px cor-preta package-description">{packagesLanguageDict[props.selectedLanguage].packageCard1Description1} 
                        <span className="strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard1Description2}</span> 
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description3}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description4}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description5} <span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description6}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description7}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard1Description8}</span></p>
                        
                        
                    </div>
                    <div className="package-info">
                        <div className="package-info-periodo">
                            <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard1Info1} <p>{packagesLanguageDict[props.selectedLanguage].packageCard1Info2}</p></span>
                            <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard1Info3}</p>
                        </div>
                        <div className="package-info-valor">
                            <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard1Info4}</span>
                            <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard1Info5}</p>
                        </div>
                        <div className="package-info-compra">
                            <span className="preco-final">{packagesLanguageDict[props.selectedLanguage].packageCard1Info6} <p>{packagesLanguageDict[props.selectedLanguage].packageCard1Info7}</p></span>
                            <button onClick={handleMonthlyPackage} className={subscription.interval == "month" ? "webapp-botao-comprar-changepackage " : "enable webapp-botao-comprar-changepackage "}>{subscription.interval == "month" ? packagesLanguageDict[props.selectedLanguage].packageCurrent : packagesLanguageDict[props.selectedLanguage].packageChange}</button>
                        </div>
                    </div>
                </div>
                <div className={subscription.interval == "semestre" ? "current changepackage-card" : "changepackage-card"}>
                    <div className="package-name">
                        <h2 className="text-36px azul-8">{packagesLanguageDict[props.selectedLanguage].packageCardName2}</h2>
                       
                        <p className="text-20px cor-preta package-description">{packagesLanguageDict[props.selectedLanguage].packageCard2Description1}
                        <span className="strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard2Description2}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description3}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description4}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description5}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description6}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description7}<span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard2Description8}</span></p>
                        
                       
                    </div>    
                    <div className="package-info">
                        <div className="package-info-periodo">
                            <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard2Info1} <p>{packagesLanguageDict[props.selectedLanguage].packageCard2Info2}</p></span>
                            <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard2Info3}</p>
                        </div>
                        <div className="package-info-valor">
                            <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard2Info4}</span>
                            <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard2Info5}</p>
                        </div>
                        <div className="package-info-compra">
                            <span className="preco-final">{packagesLanguageDict[props.selectedLanguage].packageCard2Info6} <p>{packagesLanguageDict[props.selectedLanguage].packageCard2Info7}</p></span>
                            <button onClick={handleSemesterlyPackage}  className={subscription.interval == "semestre" ? "webapp-botao-comprar-changepackage " : "enable webapp-botao-comprar-changepackage "}>{subscription.interval == "semestre" ? packagesLanguageDict[props.selectedLanguage].packageCurrent : packagesLanguageDict[props.selectedLanguage].packageChange}</button>
                        </div>
                    </div>
                </div>  
                
                    <div className={subscription.interval == "year" ? "current changepackage-card" : "changepackage-card"}>
                        <div className="package-name">  
                            <h2 className="text-36px azul-8">{packagesLanguageDict[props.selectedLanguage].packageCardName3}</h2>
                        
                        <p className="text-20px cor-preta package-description">{packagesLanguageDict[props.selectedLanguage].packageCard3Description1}
                        <span className="strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard3Description2}</span> 
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description3} <span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description4}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description5} <span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description6}</span>
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description7} <span className="strong-text">
                        {packagesLanguageDict[props.selectedLanguage].packageCard3Description8}</span></p>
                        
                        
                    </div>    
                        <div className=" package-info">
                            <div className="package-info-periodo">
                                <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard3Info1} <p>{packagesLanguageDict[props.selectedLanguage].packageCard3Info2}</p></span>
                                <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard3Info3}</p>
                            </div>
                            <div className="package-info-valor">
                                <span className="package-info-key strong-text">{packagesLanguageDict[props.selectedLanguage].packageCard3Info4}</span>
                                <p className="free-trial-price">{packagesLanguageDict[props.selectedLanguage].packageCard3Info5}</p>
                            </div>
                            <div className="package-info-compra">
                                <span className="preco-final">{packagesLanguageDict[props.selectedLanguage].packageCard3Info6} <p>{packagesLanguageDict[props.selectedLanguage].packageCard3Info7}</p></span>
                                <button onClick={handleYearlyPackage} className={subscription.interval == "year" ? "webapp-botao-comprar-changepackage " : "enable webapp-botao-comprar-changepackage "}>{subscription.interval == "year" ? packagesLanguageDict[props.selectedLanguage].packageCurrent : packagesLanguageDict[props.selectedLanguage].packageChange}</button>
                            </div>
                        </div>
                    </div>
                    
                </>
                )}
                </div>
                {subscription && 
                    <div className='webapp-packages-current-subscription'>
                        <h2 className={`webapp-packages-current-subscription-title ${subscription.interval}`}>{packagesLanguageDict[props.selectedLanguage].packageCurrent }</h2>
                    </div>
                }
            </div>
        </div>
        {popup && <PopUp data={{
            image: "advance",
            title: "MUDAR PLANO!",
            message: [`Ao mudar de plano, a sua subscrição atual será cancelada no próximo dia ${subscription.current_period_end} e será iniciada uma nova subscrição com o novo plano.`],
            close: "Cancelar",
            advance: "Continuar"
        }} setState={setPopup} action={openPassword} selectedLanguage={props.selectedLanguage}/>}
        {passwordPopup && <ConfirmPasswordPopup
            setState={setPasswordPopup}
            action={changeSubscription} />
        }
    </div>
    )
}

export default ChangePackages;