import { useState, useEffect, useContext, useRef } from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";
import './CreateInjuryPage.css'
import AuthContext from '../../context/AuthContext';
import axios from 'axios';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import RedTrash from "../../webAppAssets/red-trash.svg"
import ConfirmationPopup from '../../webAppComponents/ConfirmationPopup/Popup'
import CreateInjuryElements from "../../webAppComponents/CreateInjuryElements/CreateInjuryElements";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import BckLogo from "../../webAppAssets/BackgroundLogo.svg"

const CreateInjuryPage = () => {

    const { t } = useTranslation(['injuries-page']);
    const playerDropdownRef = useRef(null);
    const { authTokens } = useContext(AuthContext);
    const location = useLocation();
    const [editMode, setEditMode] = useState(location.state ? location.state.editMode : false)
    const [injuryDeletePopup, setInjuryDeletePopup] = useState(false)
    const [players, setPlayers] = useState([])
    const [selectedPlayer, setSelectedPlayer] = useState(() => editMode ? location.state.injury.player.user.id : null)
    const [openSelectPlayer, setOpenSelectPlayer] = useState(false)
    const [step, setStep] = useState(1)

    const formatDate = (date) => {
        if (date === null) return null;

        // From DD/MM/YYYY to YYYY-MM-DD
        const dateArray = date.split("/");
        return dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];
    }


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (playerDropdownRef.current && !playerDropdownRef.current.contains(event.target)) {
                setOpenSelectPlayer(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const [injuryData, setInjuryData] = useState(
        () => editMode ?
            {
                id: location.state.injury.id,
                player: location.state.injury.player.user.id,
                position: location.state.injury.player.position,
                type: { id: null, name: location.state.injury.type },
                mechanism: { id: null, name: location.state.injury.mechanism },
                anatomical_location: { id: null, name: location.state.injury.anatomical_location },
                specific_zone: { id: null, name: location.state.injury.specific_zone },
                laterality: { id: null, name: location.state.injury.laterality },
                specific_context: { id: null, name: location.state.injury.specific_context },
                detailed_context: { id: null, name: location.state.injury.detailed_context },
                exercise_specificity: { id: null, name: location.state.injury.exercise_specificity },
                context: { id: null, name: location.state.injury.context },
                injury_date: formatDate(location.state.injury.injury_date),
                discharged_date: formatDate(location.state.injury.discharged_date),
                return_date: formatDate(location.state.injury.return_date),
                uc_before_injury: location.state.injury.uc_before_injury,
                first_uc_after_injury: location.state.injury.uc_after_injury,
            }
            :
            {
                player: null,
                position: null,
                type: null,
                mechanism: null,
                anatomical_location: null,
                specific_zone: null,
                laterality: null,
                specific_context: null,
                detailed_context: null,
                exercise_specificity: null,
                context: null,
                injury_date: null,
                discharged_date: null,
                return_date: null,
                uc_before_injury: null,
                first_uc_after_injury: null,
            })


    const [options, setOptions] = useState({})

    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    const handleChangeInjuryData = (field, value, name) => {
        // if field contains date
        if (field === "injury_date" || field === "discharged_date" || field === "return_date") {
            setInjuryData({
                ...injuryData,
                [field]: value
            })
            return;
        }

        setInjuryData({
            ...injuryData,
            [field]: { id: value, name: name }
        })
    }

    const handleOpenSelectPlayer = () => {
        setOpenSelectPlayer(!openSelectPlayer)
    }

    const changeSelectedPlayer = (player) => {
        setSelectedPlayer(player)
        setInjuryData({
            ...injuryData,
            player: player
        })
    }


    const handleSelectStep = (step) => {
        if (checkStep()) {
            setStep(step)
        }
    }

    const nextStep = () => {

        if (checkStep()) {
            setStep(step + 1)
        }

        return
    }

    const checkStep = () => {
        if (step === 1 &&
            (injuryData.type === null ||
                injuryData.mechanism === null ||
                injuryData.anatomical_location === null ||
                injuryData.specific_zone === null ||
                injuryData.laterality === null ||
                injuryData.specific_context === null ||
                injuryData.detailed_context === null ||
                injuryData.exercise_specificity === null ||
                injuryData.context === null ||
                injuryData.player === null)) {
            const first_step_field = ["type", "mechanism", "anatomical_location", "specific_zone", "laterality", "specific_context", "detailed_context", "exercise_specificity", "context", "player"];
            handleMandatoryFields(first_step_field);
            return false;
        }
        else if (step === 2 &&
            (injuryData.injury_date === null)) {
            handleMandatoryFields("injury_date");
            return false;
        }


        return true;
    }


    const previousStep = () => {
        setStep(step - 1)
    }

    const handleMandatoryFields = (mandatoryFields) => {
        const null_fields = Object.keys(injuryData).filter((key) => injuryData[key] === null && mandatoryFields.includes(key));
        null_fields.forEach((field) => {
            const element = document.getElementById(field);
            if (element !== null) {
                element.classList.add("injury-error");
                // Add a click event listener to remove the class when clicked
                element.addEventListener("click", () => {
                    element.classList.remove("injury-error");
                });
            }
        });
    }

    const saveInjury = async () => {

        if (editMode) {
            const newObj = {};

            for (const key in injuryData) {
                // console.log(key);
                if (injuryData[key]?.id !== null && injuryData[key]?.id !== undefined) {
                    newObj[key] = injuryData[key].id;
                } else if ((key === "id" || key === "injury_date" || key === "discharged_date" || key === "return_date")) {
                    newObj[key] = injuryData[key];
                }
            }

            try {
                const response = await axios.patch(process.env.REACT_APP_BACKEND + "injury/update/" + injuryData.id, newObj, { headers });
                if (response.status === 200) {
                    window.location.href = "/webapp/injuries";
                }
            }
            catch (error) {
                console.error('Error:', error);
            }


        } else {
            //post injuryData
            try {
                const response = await axios.post(process.env.REACT_APP_BACKEND + "injury/create", {
                    player: injuryData.player,
                    type: injuryData.type.id,
                    mechanism: injuryData.mechanism.id,
                    anatomical_location: injuryData.anatomical_location.id,
                    specific_zone: injuryData.specific_zone.id,
                    laterality: injuryData.laterality.id,
                    specific_context: injuryData.specific_context.id,
                    detailed_context: injuryData.detailed_context.id,
                    exercise_specificity: injuryData.exercise_specificity.id,
                    context: injuryData.context.id,
                    injury_date: injuryData.injury_date,
                    discharged_date: injuryData.discharged_date,
                    return_date: injuryData.return_date,
                }, { headers });
                if (response.status === 201) {
                    window.location.href = "/webapp/injuries";
                }
            }
            catch (error) {
                console.error('Error:', error);
            }
        }
    }


    const handleReturn = () => {
        window.location.href = "/webapp/injuries";
    }


    const getPlayers = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BACKEND + "users/list-players", { headers });
            setPlayers(response.data);
        }
        catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        if (injuryData.injury_date !== null && injuryData.player !== null) {
            axios
                .get(process.env.REACT_APP_BACKEND + 'injury_uc', {
                    headers,
                    params: {
                        injury_date: injuryData.injury_date,
                        player: injuryData.player
                    }
                })
                .then((response) => {
                    setInjuryData({
                        ...injuryData,
                        uc_before_injury: response.data.uc
                    })
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }

        if (injuryData.return_date !== null && injuryData.player !== null) {
            axios
                .get(process.env.REACT_APP_BACKEND + 'injury_uc', {
                    headers,
                    params: {
                        return_date: injuryData.return_date,
                        player: injuryData.player
                    }
                })
                .then((response) => {
                    setInjuryData({
                        ...injuryData,
                        first_uc_after_injury: response.data.uc
                    })
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }


    }, [injuryData.injury_date, injuryData.player, injuryData.return_date])

    const getOptions = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BACKEND + "injuries/options", { headers });

            if (response.status === 200) {
                setOptions(response.data)
            }
        }
        catch (error) {
            console.error('Error:', error);
        }
    }




    useEffect(() => {
        //view para ir buscar jogadores
        getPlayers();


        //view para ir buscar opcoes 
        getOptions();
    }, []);

    const setDeletePopup = () => {
        setInjuryDeletePopup(true);
    }


    const handleDelete = async () => {
        try {
            const response = await axios.delete(process.env.REACT_APP_BACKEND + "injury/delete/" + injuryData.id, { headers });
            if (response.status === 204) {
                window.location.href = "/webapp/injuries";
            }
        }
        catch (error) {
            console.error('Error:', error);
        }
    }

    const placeBackgroundImages = () => {
        const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
        const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
        const bckLogo1Height = bckLogo1.offsetHeight;
        bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
        bckLogo2.style.top = `0%`;
        const bckLogo1Width = bckLogo1.offsetWidth;
        bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
        bckLogo2.style.left = `75%`;
    }

    useEffect(() => {
        placeBackgroundImages();
        window.addEventListener('resize', placeBackgroundImages);
        return () => {
            window.removeEventListener('resize', placeBackgroundImages);
        }
    }, [])

    return (
        <div className="webapp-create-injury-page">
            <div className="webapp-create-team-background-images">
                <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
                <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
            </div>
            {injuryDeletePopup ? (<ConfirmationPopup data={{ image: "close", message: [t('Delete Injury message'), ""], close: t('Cancel'), advance: t('Delete') }} setState={setInjuryDeletePopup} action={handleDelete} />) : null}
            <div className="webapp-coach-paint-menu-return">
                <button onClick={handleReturn}><HiOutlineArrowLeft /></button>
                <h1> {editMode ? t('Edit Injury') : t('New Injury')}</h1>
            </div>
            <div className="webapp-create-training-header">
                <div className="webapp-create-training-header-left-inputs">
                    <div>
                        <label>{t('Player Name')}<span className="webapp-asterisco">*</span></label>
                        <div id="player" className={` select-player-dropdown-container ${openSelectPlayer ? 'open-select-player-dropdown-container' : ''}`} onClick={handleOpenSelectPlayer} ref={playerDropdownRef}>
                            <span>
                                {selectedPlayer ? players.find((player) => player.user.id === selectedPlayer)?.user?.first_name + " " + players.find((player) => player.user.id === selectedPlayer)?.user?.last_name : t('Player Name')}
                            </span>
                            {!openSelectPlayer ? <div onClick={(e) => {e.stopPropagation(); handleOpenSelectPlayer();  }}>
                                                <BiSolidDownArrow /> 
                                                </div> 
                                                : 
                                                <div onClick={(e) => {e.stopPropagation(); handleOpenSelectPlayer();  }}>
                                                <BiSolidUpArrow />
                                                </div> 
                                                }
                            {openSelectPlayer ? (
                                <div className="select-player-dropdown">
                                    {players.map((player) => {
                                        return (
                                            <div key={player.user.id} className={`webapp-players-injury-options ${selectedPlayer === player.user.id ? "selected-option-player-dropdown" : ""}`} onClick={() => changeSelectedPlayer(player.user.id)}>{player.user.first_name + " " + player.user.last_name}</div>
                                        );
                                    })}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div>
                        <label>{t('Position')}<span>*</span></label>
                        <span>
                            {selectedPlayer ?
                                t(players.find((player) => player.user.id === selectedPlayer)?.position)
                                : " "
                            }
                        </span>
                    </div>
                    {editMode &&
                        <div className='webapp-injury-delete-button' onClick={() => setDeletePopup(injuryData.id)}>
                            <img src={RedTrash} /> <span>{t('Delete Injury')}</span>
                        </div>
                    }
                </div>
            </div>

            <div className="webapp-create-injury-container">
                <div className="webapp-create-training-exercise-info">
                    <div className="webapp-create-training-exercise-info-form">
                        <div className="create-training-info-form-step-header">
                            <span className={`${step === 1 && 'create-training-info-form-selected-step'}`} onClick={() => {handleSelectStep(1)}}>1</span>
                            <h3>{t('Injury Information')}</h3>
                        </div>
                        <div className="create-training-info-form-step-items">
                            {step === 1 ? <CreateInjuryElements step={1} handleChangeInjuryData={handleChangeInjuryData} injuryData={injuryData} options={options} t={t} /> : null}
                        </div>
                        <div className="create-training-info-form-step-header">
                            <span className={`${step === 2 && 'create-training-info-form-selected-step'}`}  onClick={() => {handleSelectStep(2)}}>2</span>
                            <h3>{t('Injury date')}</h3>
                        </div>
                        <div className="create-training-info-form-step-items">
                            {step === 2 ? <CreateInjuryElements step={2} handleChangeInjuryData={handleChangeInjuryData} injuryData={injuryData} t={t} /> : null}
                        </div>
                        <div className="create-training-info-form-step-header">
                            <span className={`${step === 3 && 'create-training-info-form-selected-step'}`}  onClick={() => {handleSelectStep(3)}}>3</span>
                            <h3>{t('Load units before and after injury')}</h3>
                        </div>
                        {step === 3 ? <CreateInjuryElements step={3} handleChangeInjuryData={handleChangeInjuryData} injuryData={injuryData} t={t} /> : null}

                    </div>
                    <div className="webapp-create-training-change-step-buttons">
                        <button className="create-training-previous-step-button" onClick={previousStep}>{t('Previous')}</button>
                        {step === 3 ? <button className="create-training-save-button" onClick={saveInjury}>{t('Save injury')}</button> :
                            <button className="create-training-next-step-button" onClick={nextStep}>{t('Next')}</button>}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default CreateInjuryPage;