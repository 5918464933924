import "./FullHistory.css";

import axios from "axios";
import { useEffect, useState, useContext } from "react";

import Sidebar from "../../webAppComponents/Sidebar/Sidebar";
import PastPurchases from "../../webAppComponents/PastPurchases/PastPurchases";
import TextPlaceHolder from "../../webAppComponents/PlaceHolders/PlaceHolders";

import { HiOutlineArrowLeft } from "react-icons/hi";

import AuthContext from "../../context/AuthContext";

const FullHistory = () => {
    const [history, setHistory] = useState(null);

    const { authTokens, logoutUser } = useContext(AuthContext);

    // Define the headers with the Bearer token
    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND + 'users/payment-history', { headers })
            .then((response) => {
                // Handle the successful response here
                setHistory(response.data)
            })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error('Error:', error);
            });
    }, []);

    const handleReturn = () => {
        window.location.href = "/webapp/cardhistory";
    }

    return (
        <div className="webapp-fullhistory">
            <Sidebar />
            <div className="webapp-fullhistory-section">
                <div className="webapp-coach-paint-menu-return">
                    <button onClick={handleReturn}><HiOutlineArrowLeft/></button>
                    <h1>Histórico Completo</h1>
                </div>
                <div className="webapp-fullhistory-section-pastpurchases">
                    {history ?
                        history.map((purchase) => (
                            <PastPurchases data={purchase} />
                        ))
                        :
                        <>
                            <TextPlaceHolder fontSize={2} width={'40vw'}/>
                            <TextPlaceHolder fontSize={2} width={'40vw'}/>
                            <TextPlaceHolder fontSize={2} width={'40vw'}/>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default FullHistory;