import React, { useState, useEffect } from "react";
import "./EditButton.css";
import editPencil from '../../assets/edit-pencil.svg';
import { useTranslation } from 'react-i18next';



const EditButton = (props) => {
    const { t } = useTranslation(['EditButton']);

    return (
        <div className="edit-button">
            <button onClick={props.handleFunction} className="edit-button-text dark-blue-15px-700">
                <img src={editPencil} />
                {t('Edit')}
            </button>

        </div>
    )
};

export default EditButton;