import React, { useState, useEffect, useContext, Children } from 'react';
import ChartComponent from '../../webAppComponents/ChartsDashboard/index';
import ChartComponent2 from '../../webAppComponents/ChartsDashboard/index2';
import ChartComponent3 from '../../webAppComponents/ChartsDashboard/index3';
import Sidebar from '../../webAppComponents/Sidebar/Sidebar';
import FilterBy from '../../webAppComponents/ChartsDashboard/filter';
import './DashboardPage.css';
import axios from 'axios';
import AuthContext from '../../context/AuthContext';

import IE_icon from '../../webAppAssets/IE_Icon.png';
import Horizontal from '../../webAppComponents/DashboardHorizontal/DashboardHorizontal';
import Vertical from '../../webAppComponents/DashboardVertical/DashboardVertical';
import IE from '../../webAppComponents/DashboardIE/DashboardIE';
import Donut from '../../webAppComponents/DashboardDonut/DashboardDonut';
import { useTranslation } from "react-i18next";

function DashboardPage() {
  const { t } = useTranslation(['dashboard']);
  const { authTokens } = useContext(AuthContext);
  const headers = {
    Authorization: `Bearer ${authTokens.access}`,
  };
  const [numberPlayers, setNumberPlayers] = useState([]);
  const [organizationTeams, setOrganizationTeams] = useState([]);
  const [decisionMaking, setDecisionMaking] = useState([]);
  const [oppositionPlayers, setOppositionPlayers] = useState([]);
  const [ballMobile, setBallMobile] = useState([]);
  const [tacticalOrientation, setTacticalOrientation] = useState([]);
  const [actionSpace, setActionSpace] = useState([]);
  const [practGoalkeepers, setPractGoalkeepers] = useState([]);
  const [m2PlayerField, setM2PlayerField] = useState([]);

  const [ IEFilters, setIEFilters] = useState(null);
  const [ selectedIEFilters, setselectedIEFilters] = useState({
    "MC" : [],
    "SESSION" : []
  })
  const [ IEData, setIEData] = useState(null);

  const [ verticalFilters, setVerticalFilters] = useState([
    {"name": "Element", "content": [{"value": "general_bases_of_orientation", "label": t("General Bases of Orientation")}, {"value": "game_actions", "label": t("Game Actions")}, {"value": "principles_of_play", "label": t("Principles of Play")}]},
    {"name": "Moment", "content": [{"value": "Offensive", "label": t("Offensive")}, {"value": "Defensive", "label": t("Defensive")}, {"value": "Mix", "label": t("Mix")}, {"value": "Physical", "label": t("Physical")}]}
  ]);
  const [ selectedVerticalFilters, setselectedVerticalFilters] = useState({
    "Element" : [],
    "Moment" : []
  })

  const [vertData, setVertData] = useState(null);
  const [verticalData, setVerticalData] = useState(
    []
  );
  const [donutData, setDonutData] = useState([]);

  useEffect(() => {
    fetchIEFiltersData();
    fetchVerticalFiltersData();
  }, []);

  useEffect(() => {
    if(selectedIEFilters.MC.length == 0 || selectedIEFilters.SESSION.length == 0) return;

    const queryString = `?MC=${selectedIEFilters.MC.join(',')}&S=${selectedIEFilters.SESSION.join(',')}`

    axios.get(process.env.REACT_APP_BACKEND + "training/dashboard-filtered-data" + queryString, {headers})
      .then((response) => {
        //console.log(response)
        const futureIEData = response.data.reduce((array, data) => {
          const matchedElement = IEFilters[0].content.find(
            element => element.value === parseInt(data.microcycle)
          );
          
          if (matchedElement) {
            array.push({
              value: matchedElement.label,
              data: data.sessions_data
            });
          }

          return array;
        }, []);// empty array as initial value
        //console.log(futureIEData)
        setIEData(futureIEData);
      })
      .catch((error) => {console.error(error)})

  }, [selectedIEFilters])

  useEffect(() => {
    if(selectedVerticalFilters.Element.length == 0 || selectedVerticalFilters.Moment.length == 0) return;

    let futureVerticalData = [];
    //console.log(vertData);
    //compare the selected filters with the data
    Object.keys(vertData).forEach((key) => {
      if(selectedVerticalFilters.Element.includes(key)){
        const data = vertData[key];
        //console.log(data);
        data.forEach((element) => {
          //console.log(element);
          if(selectedVerticalFilters.Moment.includes(element.category__name)){
            //console.log(element);
            futureVerticalData.push(element);
          }})
      }
    })

    //console.log(futureVerticalData);

    setVerticalData(futureVerticalData);

    //setVerticalData(futureVerticalData);
  }, [selectedVerticalFilters])


  // data for the horizontal graphics
  useEffect(() =>{
    axios
      .get(process.env.REACT_APP_BACKEND + `training/exercise/stats`, { headers })
      .then((response) => {
        const data = response.data;
        
        setDecisionMaking(cleanGraphData(data.decision_making));
        setOppositionPlayers(cleanGraphData(data.players_in_opposition));
        setOrganizationTeams(cleanGraphData(data.organization_of_teams_in_the_exercise));
        setNumberPlayers(cleanGraphData(data.number_of_players_per_team));
        setBallMobile(cleanGraphData(data.ball_or_mobile));
        setTacticalOrientation(cleanGraphData(data.tactical_orientation));
        setActionSpace(cleanGraphData(data.action_space));
        setPractGoalkeepers(cleanGraphData(data.practicing_goalkeepers));
        setM2PlayerField(cleanGraphData(data.m2_for_player_in_the_field));
        
      });
    
  }, [])

  // data for the donut graphics
  useEffect(() =>{
    axios
      .get(process.env.REACT_APP_BACKEND + `training/dashboard-donut`, { headers })
      .then((response) => {
        const data = response.data;
        // muito alto - ... - muito baixo
        setDonutData(data);
      });
    
  }, [])

  const cleanGraphData = (data) => {
    let aux = [];
    Object.keys(data).map((key, index) => {
      aux.push({ id: index, name: key , percentage: data[key]}); 
    });
    return aux;
  }

  const fetchVerticalFiltersData = () => {
    axios.get(process.env.REACT_APP_BACKEND + "training/exercise-options-usage", {headers})
      .then((response) => {
        setVertData(response.data);
      })
      .catch((error) => {console.error(error)})
  }

  const fetchIEFiltersData = () => {
    axios.get(process.env.REACT_APP_BACKEND + "training/dashboard-filter", { headers })
      .then((response) => {
        //console.log(response)
        let microcylesFilter = [];
        let mostSessions = 0;
        response.data.forEach((element) => {
          if(element.microcycle_sessions > mostSessions)
            mostSessions = element.microcycle_sessions

          microcylesFilter.push({"value": element.microcyle_data.id,
            "label": 'MC' + element.microcyle_data.number
          })

        })

        if (mostSessions != 7) mostSessions = 7 // each MC must have 7 Sessions

        const sessionsFilter = Array.from({ length: mostSessions }, (_, i) => ({
             value: i + 1,
             label: `S${i + 1}`
         }));
         //console.log(microcylesFilter,sessionsFilter)
        setIEFilters([{"name": "MC", "content":microcylesFilter}, {"name": "SESSION", "content":sessionsFilter}])
      })
      .catch((error) => {console.error(error)})
  }

  const handleIEFilter = (filterName, value) => {
    let index = selectedIEFilters[filterName].findIndex((element) => element === value);

    if(index === -1){      
      setselectedIEFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: [...prevFilters[filterName], value] ,
      }));
    }else{
      setselectedIEFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: selectedIEFilters[filterName].filter((element) => element!==value ),
      }));
    }

    if(filterName == "SESSION"){
      //sort the array
      setselectedIEFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: prevFilters[filterName].sort((a, b) => a - b),
      }));
    
    }
  };

  const handleVerticalFilter = (filterName, value) => {
    //there can only be one filter selected at a time
    setselectedVerticalFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: [value] ,
    }));
  
  }

  return (
    <div className="db-page">
      <Sidebar selectedOption="dashboard" />
      <div className="db-content">
        <h4 className="db-title">{t("Dashboard")}</h4>
        <div className='db-subtitle'>
          <hr className='db-subtitle-line'/>
          <div className='db-subtitle-content'>
            <img className="db-subtitle-image" src={IE_icon}/>
            <h5>{t("SPECIFICITY INDEX")}</h5>
          </div>
          <hr className='db-subtitle-line db-subtitle-line2'/>
        </div>
        <div className='db-section'>
          <Vertical filters={verticalFilters} handle={handleVerticalFilter} data={verticalData} type={"radio"} pageDashboard={true} />
          <IE filters={IEFilters} handle={handleIEFilter} data={IEData} type={"checkbox"} selected={selectedIEFilters}/>
          <Donut data={donutData}/>
          <Horizontal data={ballMobile} title={t("Ball or mobile")}/>
          <Horizontal data={actionSpace} title={t("Action space")}/>
          <Horizontal data={organizationTeams} title={t("Organization of teams in the exercise")}/>        
          <Horizontal data={numberPlayers} title={t("Number of players")}/>
          <Horizontal data={oppositionPlayers} title={t("Opposing players")}/>
          <Horizontal data={m2PlayerField} title={t("m2 per player inside the field")}/>
          <Horizontal data={tacticalOrientation} title={t("Tactical orientation")}/>
          <Horizontal data={practGoalkeepers} title={t("Practicing goalkeepers")}/>
          <Horizontal data={decisionMaking} title={t("Decision making")}/>
          

        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
