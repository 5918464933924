import React, {useState} from "react";
import "./Academy.css"

import { useMediaQuery } from 'react-responsive';

const Academy = (props) => {
    const isMobile = useMediaQuery({ maxWidth: 900 });

    const academyLanguageDict = {
        PT: {
            academyTitle: 'ACADEMIA',
            academyTextMobile: 'Área da empresa focada em criar conteúdo científico e formações que ajudem a potenciar o desenvolvimento profissional dos treinadores que sejam membros desta comunidade.',
            academyText2: 'Área da empresa focada em criar conteúdo científico e',
            academyText3: 'formações que ajudem a potenciar o desenvolvimento',
            academyText4: 'profissional dos treinadores que sejam membros desta',
            academyText5: 'comunidade.',
        },
        ES: {
            academyTitle: 'ACADEMIA',
            academyTextMobile: 'Área de la empresa enfocada en crear contenido científico y formaciones que ayuden a potenciar el desarrollo profesional de los entrenadores que sean miembros de esta comunidad.',
            academyText2: 'Área de la empresa enfocada en crear contenido científico y',
            academyText3:  'formaciones que ayuden a potenciar el desarrollo',
            academyText4:  'profesional de los entrenadores que sean miembros de esta',
            academyText5: 'comunidad.',
        },
        EN: {
            academyTitle: 'ACADEMY',
            academyTextMobile: 'Area of the company focused on creating scientific content and training courses that help boost the professional development of coaches who are members of this community.',
            academyText2: 'Area of the company focused on creating scientific content and',
            academyText3: 'training courses that help boost the',
            academyText4: 'professional development of coaches who are members of this',
            academyText5: 'community.'

        }
    };


    return (
        <div className="academy-page">
            
           <h2 className="text-36px azul-8">{academyLanguageDict[props.selectedLanguage].academyTitle}</h2>
            {isMobile ? (
                <p>{academyLanguageDict[props.selectedLanguage].academyTextMobile}</p>

            ) :   (  <p>{academyLanguageDict[props.selectedLanguage].academyText1}
                    <p>{academyLanguageDict[props.selectedLanguage].academyText2}</p>
                    <p> {academyLanguageDict[props.selectedLanguage].academyText3}</p>
                    <p>{academyLanguageDict[props.selectedLanguage].academyText4}</p> </p>) }
           
           
        </div>
    )

}

export default Academy;