import "./SessionEditPlayers.css"
import { useState, useEffect, useContext } from "react"
import AddPlayerSessionExercisePopup from "../../webAppComponents/AddPlayerSessionExercisePopup/AddPlayerSessionExercisePopup"
import TrainingsPageChanger from '../../webAppComponents/TrainingsPageChanger/TrainingsPageChanger'
import SessionPlayers from "../../webAppComponents/SessionPlayers/SessionPlayers"
import Sidebar from "../../webAppComponents/Sidebar/Sidebar"
import axios from "axios"
import { useLocation, useNavigate } from "react-router-dom";
import { LiaCheckDoubleSolid } from 'react-icons/lia';
import { LuClock } from 'react-icons/lu';
import { HiOutlineArrowLeft } from "react-icons/hi";
import { SessionPageHeader } from "../SessionUC/SessionUC"
import { useTranslation } from "react-i18next";


import paginatorArrow from "../../webAppAssets/paginator-arrow.svg"

import AuthContext from "../../context/AuthContext"

const SessionInfo = () => {
    const { t } = useTranslation(['session-page']);
    const { state } = useLocation()
    const navigate = useNavigate();
    const { authTokens } = useContext(AuthContext);
    const [addPlayer, setaddPlayer] = useState(false);
    const [sessionData, setSessionData] = useState({
        session_number: state.dataLoaded ? state.dataLoaded.session_number : "",
        date: state.dataLoaded ? state.dataLoaded.date : "",
        microcycle: {
            cycle_number: state.dataLoaded ? state.dataLoaded.microcycle.cycle_number : "",
        },
        completed: state.dataLoaded ? state.dataLoaded.completed : "",
    });
    const [missingPlayers, setMissingPlayers] = useState({
        players: []
    });
    const [selectedPlayers, setSelectedPlayers] = useState({
        players: []
    });
    const [readyToAdd, setReadyToAdd] = useState(false);


    // Define the headers with the Bearer token
    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };


    const handleReturn = () => {
        navigate('/webapp/sessions')
    }

    return (
        <div className="webapp-sessioninfo">
            <Sidebar selectedOption="trainings" />
            <div className="webapp-sessioninfo-content">
                <SessionPageHeader sessionData={sessionData} />
                <TrainingsPageChanger
                    selectedPage={3}
                    pages={[
                        { label: t('Training details'), path: '/webapp/session/trainings', state: { "session": state.session, "training": sessionData?.training?.id, "dataLoaded": state?.dataLoaded } },
                        { label: t('Load Unit'), path: '/webapp/SessionUC', state: { "session": state.session, "dataLoaded": sessionData } },
                        { label: t('Forms'), path: '/webapp/SessionUC', path: '/webapp/session/forms', state: { "session": state.session, "dataLoaded": sessionData } },
                        { label: t('Players'), path: '/webapp/SessionEditPlayers', state: { "session": state.session, "dataLoaded": sessionData } },
                    ]}
                />                <SessionPlayers headers={headers} session_id={state.session} setaddPlayer={setaddPlayer} setMissingPlayers={setMissingPlayers} setSelectedPlayers={setSelectedPlayers} selectedPlayers={selectedPlayers} readyToAdd={readyToAdd} setReadyToAdd={setReadyToAdd} t={t} />
            </div>
            {addPlayer ? (<div className="addplayer-session-exercise-popup"><AddPlayerSessionExercisePopup setReadyToAdd={setReadyToAdd} setaddPlayer={setaddPlayer} missingPlayers={missingPlayers} selectedPlayers={selectedPlayers} setSelectedPlayers={setSelectedPlayers} t={t} /></div>) : null}
        </div>
    )
}

export default SessionInfo