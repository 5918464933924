import "./DashboardDonut.css";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Chart as ChartJS, CategoryScale, BarElement, LinearScale, PointElement, LineElement, Tooltip, LineController, BarController} from "chart.js";
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Tooltip, LineController, BarController);


const Donut = ({ data }) => {
    const { t } = useTranslation(["dashboard"]);

    // Isto fica aqui pq passar um data com os valores a 0 cria um erro na biblioteca dos gráficos
    let label;
    try {
        label = [t("Very High E. I.") + " : " + data.ie_p[0], t("High E. I.") + " : "  + data.ie_p[1], t("Medium E. I.") + " : "  + data.ie_p[2], t("Low E. I.") + " : "  + data.ie_p[3], t("Very Low E. I.") + " : "  + data.ie_p[4]];
    }
    catch  { label = [t("Very High E. I."), t("High E. I."), t("Medium E. I."), t("Low E. I."), t("Very Low E. I.")];}

    const [graph, setGraph] = useState({
        labels: label,
        datasets: [
            {
                label: t("Number of Exercises"),
                data: data,
                backgroundColor: [
                    "#CED3EE",
                    "#9DA7ED",
                    "#6978E9",
                    "#3C50EC",
                    "#0B24EB",
                ],
                borderWidth: 0,
                borderRadius: 10,
                hoverBackgroundColor: [
                    "#CED3FE", // Hover color for General Bases of Orientation
                    "#9DA7FD", // Hover color for Game Actions
                    "#6978F9", // Hover color for Principles of Play
                    "#3C50FC", // Hover color for Offensive
                    "#0B24FB", // Hover color for Defensive
                ],
                
            },
        ],
    });

    useEffect(() => {
        setGraph({
            labels: label,
        datasets: [
            {
                label: t("Number of Exercises"),
                data: data.ie,
                backgroundColor: [
                    "#CED3EE",
                    "#9DA7ED",
                    "#6978E9",
                    "#3C50EC",
                    "#0B24EB",
                ],                
            },
        ],
        }) 
    }, [data]);

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1.5, 
        plugins: {
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    boxWidth: 10,
                    boxHeight: 10,
                }
            },
            title: {
                display: false,
            },
        },
    };

    return (
        <div className="webapp-donut">
            <h2 className="webapp-donut-header-title">{t("Specificity Index")}</h2>
            <div className="webapp-donut-content">
                <Doughnut options={options} data={graph}/>
            </div>
        </div>
    );
}

export default Donut;