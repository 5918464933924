import './InjuriesChart.css';

import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

const InjuriesChart = ({ data, title, image }) => {
    const { t } = useTranslation(['injuries-page']);

    /* Dados para testar inicialmente
    const Data = [
        {
            id: 0,
            name: 'Injury 1',
            amount: 5,
            days_injured: 10,
            percentage: 50,
        },
        {
            id: 1,
            name: 'Injury 2',
            amount: 7,
            days_injured: 14,
            percentage: 100,
        },
        {
            id: 2,
            name: 'Injury 3',
            amount: 3,
            days_injured: 6,
            percentage: 30,
        }
    ];*/

    /* chartJS cenas que eu não usei porque não dava para integrar bem no card
    const [chartData, setChartData] = useState({
        labels: Data.map((item) => ""),
        datasets: [{
            label: 'Injuries',
            data: Data.map((item) => item.percentage),
            backgroundColor: [
                '#6978F9'
            ],
            indexAxis: 'y',
        }]
        }
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        }
    };*/

    useEffect(() => {
        //select all the bars
        const bars = document.querySelectorAll(`.webapp-injurieschart-bar-${title}`);
        //give each bar a width based on the percentage
        //give the width in percentage
        bars.forEach((bar, index) => {
            bar.style.width = `${data[index].percentage}%`;
        });
    }, []);
    
    return (
        <div className='webapp-injurieschart'>
            <div className='webapp-injurieschart-header'>
                <h2 className='webapp-injurieschart-header-title'>{t(title)}</h2>
                <img src={image} alt='Injury Type'/>
            </div>
            <table className='webapp-injurieschart-table'>
               <thead className='webapp-injurieschart-table-header'>
                     <tr>
                          <th></th>
                          <th className='webapp-injurieschart-table-header-element'>{t('Number')}</th>
                          <th className='webapp-injurieschart-table-header-element'>{t('Days Out')}</th>
                          <th className='webapp-injurieschart-table-header-element'>{t('Percentage')}</th>
                     </tr> 
                </thead>
                <tbody className='webapp-injurieschart-table-body'>
                    {Object.keys(data).map((item, index) => {
                        const itemData = data[item];
                        return(
                            <tr key={item.name} className='webapp-injurieschart-table-body-row'>
                                <th className='webapp-injurieschart-table-body-row-header'>{t(itemData.name)}</th>
                                <td className='webapp-injurieschart-table-body-row-item'>{t(itemData.amount)}</td>
                                <td className='webapp-injurieschart-table-body-row-item'>{itemData.days_lost === null ? "0" : itemData.days_lost }</td>
                                <td className='webapp-injurieschart-table-body-row-item'>
                                    {index === 0 ?
                                        <div className='webapp-injurieschart-scale'>
                                            <p>0</p>
                                            <p>25</p>
                                            <p>50</p>
                                            <p>75</p>
                                            <p>100</p>
                                        </div> : null
                                    }
                                    <div className='webapp-injurieschart-bar-lines'>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <div className={`webapp-injurieschart-bar webapp-injurieschart-bar-${title}`}>
                                        {itemData.percentage}
                                        <span className='webapp-injurieschart-bar-tooltip'>{itemData.percentage}</span>
                                    </div>
                                </td>
                                {/*item.id === 0 ?
                                    <td rowSpan={Data.length} className='webapp-injurieschart-chart'>
                                        <Bar data={chartData} options={options}/>
                                    </td> : null
                                */}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            
        </div>
    );
}

export default InjuriesChart;