import "./DashboardVertical.css";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import DashboardFilters from "../DashboardFilters/DashboardFilters";

const Vertical = ({ data, filters, handle, type, pageDashboard = false }) => {
    
    let pageTranslation;

    if (pageDashboard) pageTranslation = "create-training-page";
    else pageTranslation = "injury-page";

    const { t } = useTranslation([pageTranslation]);

    const [sum, setSum] = useState(0);

    useEffect(() => {
        setSum(data.reduce((acc, item) => acc + item.usage_count, 0));
    }, [data]);

    useEffect(() => {
        const bars = document.querySelectorAll(`.webapp-injurieschart-bar-number`);

        if (sum === 0) {
            // If sum is zero, set all bars to 0% width
            bars.forEach((bar) => {
                bar.style.width = `0%`;
            });
        } else {
            // Otherwise, calculate and set each bar's width based on its percentage
            bars.forEach((bar, index) => {
                const percentage = (data[index].usage_count / sum) * 100;
                bar.style.width = `${percentage}%`;
            });
        }
    }, [sum, data]);

    return (
        <div className="webapp-vertical">
            <div className="webapp-vertical-filters">
                {filters?.map((filter) => {
                    return (
                        <DashboardFilters name={filter.name} filters={filter.content} handle={handle} type={type} />
                    );
                })}
            </div>
            <div className="webapp-vertical-content">
                <div className="webapp-vertical-header">
                    <h2 className="webapp-vertical-header-title">{t("Number of Exercises")}</h2>
                </div>
                <table className="webapp-vertical-table">
                    <tbody className="webapp-vertical-table-body">
                        {Object.keys(data).map((item, index) => {
                            const itemData = data[item];
                            return (
                                <tr key={itemData.name} className='webapp-injurieschart-table-body-row'>
                                    <th className='webapp-horizontal-table-body-row-header'>{t(itemData.name)}</th>
                                    <td className='webapp-injurieschart-table-body-row-item'>
                                        {index === 0 ? (
                                            <div className='webapp-injurieschart-scale'>
                                                <p>0%</p>
                                                <p>25%</p>
                                                <p>50%</p>
                                                <p>75%</p>
                                                <p>100%</p>
                                            </div>
                                        ) : null}
                                        <div className='webapp-injurieschart-bar-lines'>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                        <div className={`webapp-injurieschart-bar-number`}>
                                            {itemData.usage_count}
                                            <span className='webapp-injurieschart-bar-tooltip'>
                                                {sum > 0 ? ((itemData.usage_count / sum) * 100).toFixed(2) : "0"}%
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Vertical;
