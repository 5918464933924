import { useState } from "react";

import cardarrow from "../../webAppAssets/card-arrow.svg"

import { useTranslation } from "react-i18next";

const CardInfo = ({ info, selected, mode, action, setPopup, deleteM, setCardToDelete}) => {
    const { t } = useTranslation(['card-history']);

    return (
        <div className={`webapp-cardhistory-section-cardinfo ${selected && "webapp-cardhistory-section-card-selected"} ${mode && "webapp-cardhistory-section-card-change"}`} onClick={
            () => {
                if (mode) {
                    action(info)
                }
        }}>
            <p className={`webapp-cardhistory-section-card`}>
                {info === undefined || info === null ? t('Card Info.Not Defined') : `**** **** **** ${info?.card?.last4}`}
                {info && (
                    <div className="webapp-cardhistory-section-cardinfo-moreinfo">
                        <p className="webapp-cardhistory-section-cardinfo-moreinfo-title">
                            {t('Card Info.Expiring Date')}
                        </p>
                        <p className="webapp-cardhistory-section-card-date">
                            {info?.card?.exp_month}/{info?.card?.exp_year}
                        </p>
                    </div>
                )}
                {deleteM && <h4 className="webapp-cardhistory-section-card-delete" onClick={() => {setPopup(info?.card?.last4); setCardToDelete(info)}}>X</h4>}
            </p>
        </div>
    )
}

export default CardInfo;