import "../GameInfo/GameInfo.css"
import calendar from "../../webAppAssets/little-calendar.svg"
import clock from "../../webAppAssets/little-clock.svg"
import { useState } from "react"

const GameInfo = ({ game, t }) => {
    {/* As props recebem o numero do jogo, a data e o resto dos valores */ }
    const [showMore, setShowMore] = useState(true);

    const handleShowMore = () => {
        setShowMore(!showMore);
    }

    return (
        <div className="game-info-container">
            <div className="game-info-first">
                <div className="game-info-first-text">
                    {game?.against?.name}
                </div>
                <div className="middle-bar"></div>
                <div className="game-info-first-right">
                    <div>
                        <img src={clock} />
                        {game?.duration}min
                    </div>
                    <div>
                        <img src={calendar} />
                        {game?.date}
                    </div>
                </div>
            </div>
            <div className="linha"></div>
            <div className="underline-text">{t('Total Values')}</div>
            <div className="game-info-bottom">
                <div className="yellow-cards">
                    <div className="cards-left-number-yellow">
                        {/* LOGICA PRA APARECER o numero de amarelos */}
                        {game?.yellow_cards}
                    </div>
                    <div className="cards-text">
                        {t('Yellow Cards')}
                    </div>
                </div>
                <div className="red-cards">
                    <div className="cards-left-number-red">
                        {/* LOGICA PRA APARECER o numero de vermelhos */}
                        {game?.red_cards}
                    </div>
                    <div className="cards-text">
                        {t('Red Cards')}
                    </div>
                </div>
                <div className="goals">
                    <div className="cards-left-number-goals">
                        {/* LOGICA PRA APARECER o numero de golos */}
                        {game?.goals_for}
                    </div>
                    <div className="cards-text">
                        {t('Goals for')}
                    </div>
                </div>
                {showMore == false ? <div className="goals-against">
                    <div className="cards-left-number-goals-against">
                        {/* LOGICA PRA APARECER o numero de golos */}
                        {game?.goals_against}
                    </div>
                    <div className="cards-text">
                        {t('Goals against')}
                    </div>
                </div> : null}
                {showMore == false ? <div className="goal-assist">
                    <div className="cards-left-number-goal-assist">
                        {/* LOGICA PRA APARECER o numero de golos */}
                        {game?.assists}
                    </div>
                    <div className="cards-text">
                        {t('Assists')}
                    </div>
                </div> : null}
                <div className="underline-text" onClick={handleShowMore}>
                    {showMore == true ? t('See more'): null}
                </div>
            </div>
            <div>
                <div onClick={handleShowMore}>
                    {showMore == true ?
                        null
                        :
                        <div className="show-more-container">
                            <div className="linha"></div>
                            <div className="teams-info">
                                <div className="teams-info-container">
                                    <div className="teams-info-container-group">
                                        <div className="teams-info-top-text">
                                            {t('League')}
                                        </div>
                                        <div className="teams-info-top-text2">
                                            {game.league.name}
                                        </div>
                                    </div>
                                    {/* <div className="teams-info-container-group">
                                        <div className="teams-info-top-text">
                                            Equipa adversária
                                        </div>
                                        <div className="teams-info-top-text2">
                                            Equipa x
                                        </div>
                                    </div> */}
                                    <div className="teams-info-container-group">
                                        <div className="teams-info-top-text">
                                            {t('Equipa da casa')}
                                        </div>
                                        <div className="teams-info-top-text2">
                                            {game.home_game ? game.user_team : game.against.name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="underline-text2">
                                {t('See less')}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}



export default GameInfo;