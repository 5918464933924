import React, { useState, useEffect, useContext } from 'react';
import FilterBy from '../../webAppComponents/FilterBy/FilterBy';
import './PlayerDetailPage.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import PlayerRow from '../../webAppComponents/PlayerRow/PlayerRow';
import EditButton from '../../webAppComponents/EditButton/EditButton'
import Sidebar from '../../webAppComponents/Sidebar/Sidebar';
import AuthContext from '../../context/AuthContext';
import { useTranslation } from "react-i18next";

import BckLogo from "../../webAppAssets/BackgroundLogo.svg"

function PlayerDetailPage() {

  const { t } = useTranslation(['evaluations-page']);
  const { id } = useParams();
  const [playerData, setPlayerData] = useState({});

  const { authTokens } = useContext(AuthContext);

  const headers = {
    Authorization: `Bearer ${authTokens.access}`,
  };


  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACKEND + `evaluations/${id}`, { headers })
      .then((response) => {
        setPlayerData(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  const navigate = useNavigate();


  const handleGoBack = () => {
    navigate(`/webapp/evaluations`);
  };

  const createEvaluationRoute = () => {
    navigate('/webapp/evaluations/create')
  }

  const handleEdit = () => {
    navigate(`/webapp/evaluations/create`, { state: {"evaluation": playerData, "editMode": true } })
  }

  const placeBackgroundImages = () => {
    const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
    const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
    const bckLogo1Height = bckLogo1.offsetHeight;
    bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
    bckLogo2.style.top = `0%`;
    const bckLogo1Width = bckLogo1.offsetWidth;
    bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
    bckLogo2.style.left = `75%`;
}

useEffect(() => {
    placeBackgroundImages();
    window.addEventListener('resize', placeBackgroundImages);
    return () => {
        window.removeEventListener('resize', placeBackgroundImages);
    }
}, [])

  return (
    <div className="webapp-trainings-page-scroll">
      <Sidebar selectedOption="evaluations" />
      <div className="webapp-player-detail-page">
        <div className="webapp-create-team-background-images">
            <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
            <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
        </div>
        <div className="webapp-player-detail-page-header">
          <h1>{t('Evaluation')}</h1>
          <button onClick={createEvaluationRoute}>+ {t("Add Evaluation")}</button>
        </div>
        <div className="webapp-player-detail-page-content">
          {/* Top bar */}
          <div className="webapp-player-detail-page-top-bar">
            <span className="webapp-player-detail-page-player-name black-16px-600">{playerData.player?.user?.first_name} {playerData.player?.user?.last_name}</span>
            <button className='webapp-player-detail-top-bar-button ' onClick={handleGoBack}>{t('Go back')}</button>
            <EditButton handleFunction={handleEdit} />
          </div>

          {/* Player data */}
          <div className="webapp-player-detail-page-data">
            <div className="webapp-player-detail-row">
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">{t('Position')}</span>
                <span className="webapp-player-detail-row-data-info">{t(playerData.player?.position)}</span>
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">{t('Date')}</span>
                <span className="webapp-player-detail-row-data-info">{playerData.evaluation_form}</span>
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">{t('Birthdate')}</span>
                <span className="webapp-player-detail-row-data-info">{playerData.player?.dateofbirth}</span>
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">{t('Quartile')}</span>
                <span className="webapp-player-detail-row-data-info">Q{playerData.caurt_nac}</span>
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">{t('Age')}</span>
                <span className="webapp-player-detail-row-data-info">{playerData.age}</span>
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">{t('Height P')}</span>
                {playerData.heightP !== undefined && playerData.heightP !== null ? (
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.heightP.toFixed(2)}</span>
                )
                  : (
                    <span className="webapp-player-detail-row-data-info">-</span>
                  )}
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">{t('Height S')}</span>
                {playerData.heightS !== undefined &&  playerData.heightS !== null ? (
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.heightS.toFixed(2)}</span>
                )
                  : (
                    <span className="webapp-player-detail-row-data-info">-</span>
                  )}
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">{t('Length P')}</span>
                {playerData.largeP !== undefined && playerData.largeP !== null ? (
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.largeP.toFixed(2)}</span>
                )
                  : ( 
                    <span className="webapp-player-detail-row-data-info">-</span>
                  )}
              </div>
            </div>

            <div className="webapp-player-detail-row">
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">{t('Weight')}</span>
                {playerData.weight !== undefined && playerData.weight !== null ? (
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.weight.toFixed(2)}</span>
                )
                  : (
                    <span className="webapp-player-detail-row-data-info">-</span>
                  )}
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">{t('BMI')}</span>
                {playerData.imc !== undefined && playerData.imc !== null ?(
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.imc.toFixed(2)}</span>
                )
                  : (
                    <span className="webapp-player-detail-row-data-info">-</span>
                  )}
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">PHV</span>
                {playerData.phv !== undefined && playerData.phv !== null ? (
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.phv.toFixed(2)}</span>
                )
                  : (
                    <span className="webapp-player-detail-row-data-info">-</span>
                  )}
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">CMJ</span>
                {playerData.cmj !== undefined && playerData.cmj !== null ? (
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.cmj.toFixed(2)}</span>
                )
                  : (
                    <span className="webapp-player-detail-row-data-info">-</span>
                  )}
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">CMJ Z</span>
                {playerData.cmjZ !== undefined && (
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.cmjZ.toFixed(2)}</span>
                )}
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">SJ</span>
                {playerData.sj !== undefined && playerData.sj !== null ? (
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.sj.toFixed(2)}</span>
                )
                  : (
                    <span className="webapp-player-detail-row-data-info">-</span>
                  )}
              </div>
            </div>

            <div className="webapp-player-detail-row">
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">10M </span>
                {playerData.ten_meters !== undefined && playerData.ten_meters !== null ? (
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.ten_meters.toFixed(2)}</span>
                )
                  : (
                    <span className="webapp-player-detail-row-data-info">-</span>
                  )}
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">10M Z</span>
                {playerData.ten_metersZ !== undefined && (
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.ten_metersZ.toFixed(2)}</span>
                )}
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">20M</span>
                {playerData.twenty_metersL !== undefined && playerData.twenty_metersL !== null ? (
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.twenty_metersL.toFixed(2)}</span>
                )
                  : (
                    <span className="webapp-player-detail-row-data-info">-</span>
                  )}
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">20M Z</span>
                {playerData.twenty_metersLZ !== undefined && (
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.twenty_metersLZ.toFixed(2)}</span>
                )}
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">30M</span>
                {playerData.thirty_meters !== undefined && playerData.thirty_meters !== null ? (
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.thirty_meters.toFixed(2)}</span>
                )
                  : (
                    <span className="webapp-player-detail-row-data-info">-</span>
                  )}
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">30M Z</span>
                {playerData.thirty_metersZ !== undefined && (
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.thirty_metersZ.toFixed(2)}</span>
                )}
              </div>
            </div>

            <div className="webapp-player-detail-row">
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">ZIG ZAG</span>
                {playerData.zig_zag !== undefined && playerData.zig_zag !== null ? (
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.zig_zag.toFixed(2)}</span>
                )
                  : (
                    <span className="webapp-player-detail-row-data-info">-</span>
                  )}
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">ZIG Z</span>
                {playerData.zig_zagZ !== undefined && (
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.zig_zagZ.toFixed(2)}</span>
                )}
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">YO-YO P</span>
                {playerData.yo_yoP !== undefined && playerData.yo_yoP !== null ?  (
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.yo_yoP.toFixed(2)}</span>
                )
                  : (
                    <span className="webapp-player-detail-row-data-info">-</span>
                  )}
              </div>
              <div className="webapp-player-detail-row-data">
                <span className="webapp-player-detail-row-data-label">YO-YO Z</span>
                {playerData.yo_yoZ !== undefined && (
                  <span className="webapp-player-detail-row-data-info">
                    {playerData.yo_yoZ.toFixed(2)}</span>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerDetailPage;
