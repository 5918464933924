import react from 'react';
import './ChangeNamePopup.css';
import { useState } from 'react';

const ChangeNamePopup = (props) => {
    const [name, setName] = useState("");

    const handleChangeName = (e) => {
        setName(e.target.value)
    }

    return (
        <div className="webapp-trainings-page-change-folder-popup">
            <form className="webapp-change-folder-popup-container" >
                <h1>{props.data.message}</h1>
                <input className="webapp-change-name-folder-popup-input" id={props?.id} placeholder={props.data.placeholder}  onChange={handleChangeName}></input>
                <div>
                    <span onClick={() =>  {
                        props.setState(false)}} className="webapp-cancel-folder-button">{props.data.close}</span>
                    <button  onClick={(e) => {e.preventDefault(); props.changeName(name)}} className="webapp-create-folder-button">{props.data.advance}</button>
                </div>
            </form>
        </div>
    )

}

export default ChangeNamePopup;