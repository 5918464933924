import { useState, useEffect } from "react";
import TextPlaceHolder from "../../webAppComponents/PlaceHolders/PlaceHolders";
import { useTranslation } from "react-i18next";

const PlanInfo = ({ plan, popup }) => {
    const { t } = useTranslation(['plan-info']);
    const [name, setName] = useState(null)

    useEffect(() => {
        if (plan.interval === "month") {
            setName("Monthly")
        } else if (plan.interval === "year") {
            setName("Annual")
        } else if (plan.interval === "semester") {
            setName("Semestral")
        }
    }, [plan.interval])

    const openCancelPopup = () => {
        popup(true)
    }

    return (
        <div className="webapp-coachprofile-planinfo">
            <div className="webapp-coachprofile-planinfo-header">
                {name !== null && plan !== undefined ?
                    <div className="webapp-coachprofile-planinfo-header-text">
                        <h4 className="webapp-coachprofile-planinfo-name">{t(`${name}.title`)}</h4>
                        <p className="webapp-coachprofile-planinfo-description">{t(`${name}.description`)}</p>
                    </div>
                    :
                    <div className="webapp-coachprofile-planinfo-header-text">
                        <TextPlaceHolder fontSize={2} />
                        <TextPlaceHolder />
                    </div>
                }
                <hr className="webapp-coachprofile-planinfo-hr" />
            </div>
            <div className="webapp-coachprofile-planinfo-data">
                {name !== null && plan !== undefined ?
                    <div className="webapp-coachprofile-planinfo-data-section">
                        { plan.status && plan.status === "trialing" ?
                            <h4 className="webapp-coachprofile-planinfo-subtitle">{t(`${name}.free-trial-title`)}</h4>
                            :
                            <h4 className="webapp-coachprofile-planinfo-subtitle">{t(`Next invoice`)}</h4>
                        }
                        <p className="webapp-coachprofile-planinfo-subvalue">{plan.current_period_end}</p>
                    </div>
                    :
                    <div className="webapp-coachprofile-planinfo-data-section">
                        <TextPlaceHolder fontSize={1} width={'80%'} />
                        <TextPlaceHolder fontSize={1} width={'80%'} />
                    </div>
                }
                {plan.price_beforeIVA === undefined ?
                    <div className="webapp-coachprofile-planinfo-data-section">
                        <TextPlaceHolder fontSize={1} width={"80%"} />
                        <TextPlaceHolder fontSize={1} width={"80%"} />
                    </div>
                    :
                    <div className="webapp-coachprofile-planinfo-data-section">
                        <h4 className="webapp-coachprofile-planinfo-subtitle">{t(`${name}.plan-price`)}</h4>
                        <p className="webapp-coachprofile-planinfo-subvalue">{t(`${name}.plan-price-value`)}</p>
                    </div>

                }
                <div className="webapp-coachprofile-planinfo-data-section webapp-coachprofile-planinfo-data-lastsection">
                    {plan.price_final === undefined ?
                        <div className="webapp-coachprofile-planinfo-price">
                            <TextPlaceHolder fontSize={2} width={'80%'} />
                        </div>
                        :
                        <div className="webapp-coachprofile-planinfo-price">
                            <h5 className="webapp-coachprofile-planinfo-value">{t(`${name}.plan-price-value-after-tax`)}</h5>
                            <h6 className="webapp-coachprofile-planinfo-rate">{t(`${name}.per-period`)}</h6>
                        </div>
                    }

                    <div className="webapp-coachprofile-planinfo-buttons">
                        <button className="webapp-coachprofile-planinfo-button webapp-coachprofile-planinfo-acquired">{t('Acquired')}</button>
                        <button className="webapp-coachprofile-planinfo-button webapp-coachprofile-planinfo-cancel" onClick={openCancelPopup}>{t('Cancel')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlanInfo;