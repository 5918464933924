import { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import "./DoughnutGraph.css";

const DoghnutGraph = ({ title, titleAlign, titleFont, data, centerText, labels, darkMode }) => {
  const centerTextPlugin = {
    id: "centerTextPlugin",
    beforeDatasetDraw: (chart) => {
      const { ctx, data } = chart;
      if(!data.datasets[0].data[0]) return;
      ctx.save();
      ctx.font = "bold 12px Lato";
      ctx.fillStyle = darkMode ? "white" : "black",
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(`${data.datasets[0].data[0].toFixed(0)}${centerText}`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y);
    },
  };

  return (
    <div className="webapp-exercise-descriprions-graph">
      <Doughnut
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: true,
              text: title,
              align: titleAlign ? titleAlign : "center",
              color: darkMode ? "#FFFFFFF" : "#020314",
              padding: {
                top: 0,
                bottom: 10,
              },
              font: {
                size: titleFont ? titleFont : 12,
                family: "Lato",
              },
            },
          }
        }}
        data={{
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: ["#3C50FC", "#CED3FE"],
            },
          ],
        }}
        plugins={[centerTextPlugin]}
      />
    </div>
  );
};

export default DoghnutGraph;
