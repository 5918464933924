import "./InsideFolder.css";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import ConfirmationPopup from '../../webAppComponents/ConfirmationPopup/Popup'
import ChangeNamePopup from '../../webAppComponents/ChangeNamePopup/ChangeNamePopup.jsx'
import FolderBreadCrumb from "../../webAppComponents/FoldersBreadCrumb/FoldersBreadCrumb.jsx";
import MoveToFolderPopup from "../../webAppComponents/MoveToFolderPopup/MoveToFolderPopup.jsx";

import AuthContext from "../../context/AuthContext";

import TrainingsPageChanger from "../../webAppComponents/TrainingsPageChanger/TrainingsPageChanger";
import Sidebar from "../../webAppComponents/Sidebar/Sidebar";
import { Folder, Training } from '../../webAppComponents/TrainingExerciseFolders/TrainingExerciseFolders.jsx'
import { useTranslation } from "react-i18next";

import BckLogo from "../../webAppAssets/BackgroundLogo.svg"

const InsideFolder = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation(['inside-folder-page']);

    const [newFolderPopup, setNewFolderPopup] = useState(false);
    const [folderName, setName] = useState({ breadcrumb: [] });
    const [folderMenuPopup, setFolderMenuPopup] = useState(false)
    const [deleteFolderPopup, setDeleteFolderPopup] = useState(false)
    const [changeFolderNamePopup, setChangeFolderNamePopup] = useState(false)
    const [moveToFolderPopup, setMoveToFolderPopup] = useState(false)
    const [folders, setFolders] = useState([]);
    const { folderId } = useParams();

    const [trainings, setTrainings] = useState([])
    const [trainingMenuPopup, setTrainingMenuPopup] = useState(null)
    const [changeTrainingNamePopup, setChangeTrainingNamePopup] = useState(false)
    const [deleteTrainingPopup, setDeleteTrainingPopup] = useState(false)
    const [addTrainingsPopup, setAddTrainingsPopup] = useState(false);

    const [currentRequestedId, setCurrentRequestedId] = useState(null)


    const [isShown, setIsShown] = useState(false);


    const { authTokens } = useContext(AuthContext);

    const isMobile = useMediaQuery({ maxWidth: 900 });


    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    const changeCurrentRequestedId = (id) => {
        setCurrentRequestedId(id)
    }

    const toggleTrainingMenuPopup = (trainingId, e) => {

        if (trainingMenuPopup === trainingId) {
            setTrainingMenuPopup(null)
        }
        else {
            setTrainingMenuPopup(trainingId)
        }

    }

    //ativar popup de confirmacao
    const handleDeleteTrainingPopup = () => {
        setDeleteTrainingPopup(true);
    }

    const handleChangeTrainingNamePopup = () => {
        setChangeTrainingNamePopup(true);
    }

    const toggleFolderMenuPopup = (folderId) => {
        if (folderMenuPopup === folderId) {
            setFolderMenuPopup(null)
        }
        else {
            setFolderMenuPopup(folderId)
        }
    }

    const handleDeleteFolderPopup = () => {
        setDeleteFolderPopup(true);
    }

    const handleChangeFolderNamePopup = () => {
        setChangeFolderNamePopup(true);
    }

    const handleMoveToFolderPopup = () => {
        setMoveToFolderPopup(true);
    }

    const deleteTrainingFolderRequest = async () => {
        try {
            const response = await axios.delete(process.env.REACT_APP_BACKEND + 'training/folder/delete/' + currentRequestedId, { headers })
            const data = await response.data
            setDeleteFolderPopup(false)
            getFolders();
        } catch (error) {
            console.error(error);
        }
    }

    const getFolders = async () => {
        if (folderId) {
            try {
                const response = await axios.get(process.env.REACT_APP_BACKEND + 'training/folder/list?parent_folder=' + folderId, { headers })
                const data = await response.data
                setFolders(data)
            } catch (error) {
                console.error(error);
            }
        }
    }

    const getFolderName = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BACKEND + 'training/folder/' + folderId, { headers })
            const data = await response.data
            setName(data)
        } catch (error) {
            console.log(error);
        }
    }

    const GetTrainings = async () => {
        try {
            await axios.get(process.env.REACT_APP_BACKEND + 'training/exercise/list?folder=' + folderId + '&archive=False', { headers })
                .then((response) => {
                    // Handle the successful response here
                    setTrainings(response.data);
                })
                .catch((error) => {
                    // Handle any errors that occurred during the request
                    console.error('Error:', error);
                });
        } catch (error) {
            console.log(error);
        }
    }

    const deleteTrainingRequest = async () => {
        try {
            const response = await axios.delete(process.env.REACT_APP_BACKEND + 'training/exercise/delete/' + currentRequestedId, { headers })
            const data = await response.data
            setDeleteTrainingPopup(false)
            GetTrainings();
        } catch (error) {
            console.log(error);
        }
    }

    const changeTrainingName = async (name) => {
        setChangeTrainingNamePopup(false)
        toggleTrainingMenuPopup(currentRequestedId)
        try {
            axios.patch(process.env.REACT_APP_BACKEND + `training/exercise/update/${currentRequestedId}`, {
                name: name,
            }, { headers })
                .then((response) => {
                    // Handle the successful response here
                    GetTrainings()
                })
                .catch((error) => {
                    // Handle any errors that occurred during the request
                    console.error('Error:', error);
                });
        }
        catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        getFolders();
        getFolderName();
        GetTrainings();
    }, [folderId]);

    const createNewFolder = async (e) => {
        e.preventDefault();
        const newFolderName = document.querySelector('.webapp-create-folder-input').value;
        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND + 'training/folder/create', {
                name: newFolderName,
                parent_folder: folderId
            }, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authTokens.access}` }
            });
            getFolders();
            setNewFolderPopup(false);
        } catch (error) {
            console.error(error);
        }
    }

    const changeFolderName = async (name) => {
        setChangeFolderNamePopup(false)
        toggleFolderMenuPopup(currentRequestedId)
        try {
            axios.patch(process.env.REACT_APP_BACKEND + `training/folder/update/${currentRequestedId}`, {
                name: name,
            }, { headers })
                .then((response) => {
                    // Handle the successful response here
                    getFolders()
                })
                .catch((error) => {
                    // Handle any errors that occurred during the request
                    console.error('Error:', error);
                });
        }
        catch (error) {
            console.error(error);
        }
    }

    const addToFolder = async (folder) => {
      if(folder == undefined) folder = null;

      try {
            axios.patch(process.env.REACT_APP_BACKEND + `training/exercise/update/${currentRequestedId}`, { folder: folder }, { headers })
                .then((response) => {
                  setTrainings(trainings.filter((training) => {
                    return training.id != currentRequestedId
                  }))
                }
                )
                .catch((error) => {
                    console.error('Error:', error);
                }
                );
            setMoveToFolderPopup(false)
        }
        catch (error) {
            console.log(error);
        }

    }

    const handleBreadCrumbNavigate = (folder) => {
        if (folder)
            navigate(`/webapp/folder/${folder.id}`)
        else
            navigate(`/webapp/trainings`)
    }

    const handleNewTrainingInFolder = () => {
        navigate('/webapp/trainings/create', { state: { folderId: folderId } })
    }

    const placeBackgroundImages = () => {
        const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
        const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
        const bckLogo1Height = bckLogo1.offsetHeight;
        bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
        bckLogo2.style.top = `0%`;
        const bckLogo1Width = bckLogo1.offsetWidth;
        bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
        bckLogo2.style.left = `75%`;
    }

    useEffect(() => {
        placeBackgroundImages();
        window.addEventListener('resize', placeBackgroundImages);
        return () => {
            window.removeEventListener('resize', placeBackgroundImages);
        }
    }, [])

    const handleEditTrainingPopup = (id) => {
        for (let i = 0; i < trainings.length; i++) {
            if (trainings[i].id === id) {
                navigate('/webapp/trainings/create', { state: { training: trainings[i], editMode: true } });// Pass the found training object to the 'create' screen
                return;
            }
        }
    }


    return (
        <div className="webapp-inside-folder">
            <Sidebar selectedOption="trainings" />
            <div className="webapp-inside-folder-main">
                <div className="webapp-create-team-background-images">
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
                </div>
                <TrainingsPageChanger
                    selectedPage={0}
                    pages={[
                        { label: t('Your Exercises'), path: '/webapp/trainings' },
                        { label: t('Your Training Sessions'), path: '/webapp/sessions' },
                    ]}
                />
                <div className="webapp-inside-folder-main-content">
                    {deleteTrainingPopup ? (<ConfirmationPopup data={{ image: "close", message: [t('delete-exercise-popup.message'), ""], close: t('Cancel'), advance: t('delete-exercise-popup.delete-exercise') }} setState={setDeleteTrainingPopup} action={deleteTrainingRequest} />) : null}
                    {changeTrainingNamePopup ? (<ChangeNamePopup data={{ message: t('change-exercise-name-popup.message'), close: t('Cancel'), advance: t('change-exercise-name-popup.change'), placeholder: t('change-exercise-name-popup.exercise-name') }} setState={setChangeTrainingNamePopup} changeName={changeTrainingName} />) : null}
                    {deleteFolderPopup ? (<ConfirmationPopup data={{ image: "close", message: [t('delete-folder-popup.message'), ""], close: t('Cancel'), advance: t('delete-folder-popup.delete-folder') }} setState={setDeleteFolderPopup} action={deleteTrainingFolderRequest} />) : null}
                    {changeFolderNamePopup ? (<ChangeNamePopup data={{ message: t('change-folder-name-popup.message'), close: t('Cancel'), advance: t('change-folder-name-popup.change'), placeholder: t('change-folder-name-popup.folder-name') }} setState={setChangeFolderNamePopup} changeName={changeFolderName} />) : null}
                    {moveToFolderPopup ? (<MoveToFolderPopup setState={setMoveToFolderPopup} id={currentRequestedId} type="exercise" addToFolder={addToFolder}/>) : null}
                    <div className="webapp-inside-folder-main-header">{/* &ensp;&gt;&ensp;{folderName?.name} */}
                        <FolderBreadCrumb folder={folderName} handleBreadCrumbNavigate={handleBreadCrumbNavigate} />
                        <button className="webapp-inside-folder-main-header-button"
                            onMouseEnter={() => setIsShown(true)}
                            onMouseLeave={() => setIsShown(false)}
                        >+ {t('New')}
                            {isShown && (
                                <div className="webapp-inside-folder-main-header-button-dropdown">
                                    <div className="webapp-inside-folder-main-header-button-dropdown-option">
                                        <p onClick={() => setNewFolderPopup(true)}>{t('Folder')}</p>
                                    </div>
                                    <div className="webapp-inside-folder-main-header-button-dropdown-option">
                                        <p onClick={() => handleNewTrainingInFolder()}>{t('Exercise')}</p>
                                    </div>

                                </div>
                            )}
                        </button>
                    </div>
                    <div className="webapp-inside-folder-main-folders">
                        {folders?.map((folder) => (
                            <Folder folder={folder} editable={true} folderMenuPopup={folderMenuPopup} toggleFolderMenuPopup={toggleFolderMenuPopup} handleDeleteFolderPopup={handleDeleteFolderPopup} changeCurrentRequestedId={changeCurrentRequestedId} handleChangeFolderNamePopup={handleChangeFolderNamePopup} />
                        ))}
                    </div>
                    <div className="webapp-inside-folder-main-exercises">
                        {trainings.map((training, index) => (
                            <Training key={training.id} index={index} numberOfExercises={trainings.length} training={training} toggleTrainingMenuPopup={toggleTrainingMenuPopup} trainingMenuPopup={trainingMenuPopup} isMobile={isMobile} changeCurrentRequestedId={changeCurrentRequestedId} handleDeleteTrainingPopup={handleDeleteTrainingPopup} handleChangeTrainingNamePopup={handleChangeTrainingNamePopup} handleEditTrainingPopup={handleEditTrainingPopup} setCurrentRequestedId={setCurrentRequestedId} handleMoveToFolderPopup={handleMoveToFolderPopup} editable={true}/>
                        ))}
                    </div>
                </div>
            </div>
            {newFolderPopup ? (
                <div className="webapp-inside-folder-popup">
                    <form className="webapp-create-folder-popup-container" onSubmit={createNewFolder}>
                        <h1>{t('New Folder')}</h1>
                        <input placeholder={t('New Folder')} className="webapp-create-folder-input"></input>
                        <div>
                            <span onClick={() => setNewFolderPopup(false)} className="webapp-cancel-folder-button">{t('Cancel')}</span>
                            <button type="submit" className="webapp-create-folder-button">{t('Create')}</button>
                        </div>
                    </form>
                </div>
            ) : null}
            {addTrainingsPopup ? (
                <div className="webapp-inside-folder-popup">
                    <div className="webapp-inside-folder-add-training">
                        <h1>{('Add Exercise')}</h1>
                        <div className="webapp-inside-folder-add-training-elements">
                            {trainings.map((training) => (
                                <Training training={training}  />
                            ))}
                        </div>
                        <div className="webapp-inside-folder-add-training-buttons">
                            <span onClick={() => setAddTrainingsPopup(false)} className="webapp-cancel-folder-button">{t('Cancel')}</span>
                            <button className="webapp-create-folder-button">{t('Add')}</button>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default InsideFolder;
