import "./PlayersPage.css"

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

import Sidebar from "../../webAppComponents/Sidebar/Sidebar";
import FilterBy from "../../webAppComponents/FilterBy/FilterBy";

import AuthContext from "../../context/AuthContext";

import { useTranslation } from "react-i18next";

import BckLogo from "../../webAppAssets/BackgroundLogo.svg"

const PlayersPage = () => {
    const { t } = useTranslation(['players-page']);

    const [players, setPlayers] = useState([]);

    const { authTokens } = useContext(AuthContext);

    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };


    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND + 'users/list-players', { headers })
        .then((response) => {
            setPlayers(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);

    const navigate = useNavigate();

    const redirect = (id) => {
        navigate("/webapp/player/" + id);
    }

    const placeBackgroundImages = () => {
        const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
        const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
        const bckLogo1Height = bckLogo1.offsetHeight;
        bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
        bckLogo2.style.top = `0%`;
        const bckLogo1Width = bckLogo1.offsetWidth;
        bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
        bckLogo2.style.left = `75%`;
    }

    useEffect(() => {
        placeBackgroundImages();
        window.addEventListener('resize', placeBackgroundImages);
        return () => {
            window.removeEventListener('resize', placeBackgroundImages);
        }
    }, [])

    return (
        <div className="webapp-players">
            <Sidebar selectedOption="players"/>
            <div className="webapp-players-container">
                <div className="webapp-create-team-background-images">
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
                </div>
                <div className="webapp-players-header">
                    <h1>{t('Players')}</h1>
                    <Link to="/webapp/addplayer/" className="webapp-players-button">+ {t('Add Player')}</Link>
                </div>
                <div className="webapp-players-content">
                    {/*<FilterBy/>*/}
                    <table className="webapp-players-table">
                        <thead className="webapp-players-table-header">
                            <tr className="webapp-players-table-row">
                                <th>{t('Name')}</th>
                                <th>{t('Position')}</th>
                                <th>{t('Acronym')}</th>
                                <th>{t('Laterality')}</th>
                                <th>{t('Birthdate')}</th>
                                <th>{t('Entry Date')}</th>
                                <th>{t('Age')}</th>
                                <th>{t('Birth Quartile')}</th>
                            </tr>
                        </thead>
                        <tbody className="webapp-players-table-body">
                            {players.map((player, index) => {
                                const birthDate = new Date(player.dateofbirth);
                                const today = new Date();
                                let age = today.getFullYear() - birthDate.getFullYear();
                                const month = today.getMonth() - birthDate.getMonth();
                                if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
                                    age--;
                                }

                                //get their birth quartile from the birth month
                                let quartile = "";
                                if (birthDate.getMonth() <= 2) {
                                    quartile = "Q1";
                                } else if (birthDate.getMonth() <= 5) {
                                    quartile = "Q2";
                                } else if (birthDate.getMonth() <= 8) {
                                    quartile = "Q3";
                                } else {
                                    quartile = "Q4";
                                }
                                return (
                                    <tr key={player.user.id} className="webapp-players-table-row" onClick={()=>redirect(player.user.id)}>
                                        <th>{player.user.first_name} {player.user.last_name}</th>
                                        <td>{t(player.position)}</td>
                                        <td>{t(player.position_acronym)}</td>
                                        <td>{t(player.handedness)}</td>
                                        <td>{player.dateofbirth}</td>
                                        <td>{player.date_of_entry}</td>
                                        <td>{age}</td>
                                        <td>{quartile}</td>
                                    </tr>
                            )})}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default PlayersPage;