import { useState } from "react";
import './MapTable.css';
import scrollLeft from '../../assets/scroll-left.svg';
import scrollRight from '../../assets/scroll-right.svg';
import MapDataRow from '../MapDataRow/MapDataRow';
import yellowCard from '../../assets/yellow-card.svg';
import redCard from '../../assets/red-card.svg';

export default function MapTable({Data, columnNames, mappedColumns, detailRoute, ItemName, visibleColumns, t}) {

    const [firstVisibleColumnIndex, setFirstVisibleColumnIndex] = useState(0);
    const defaultVisibleColumns = visibleColumns ? visibleColumns : 6;

    const handleScrollLeft = () => {
        if (firstVisibleColumnIndex > 0) {
            setFirstVisibleColumnIndex(firstVisibleColumnIndex - 1);
        }
    };

    const handleScrollRight = () => {
        const maxVisibleColumnIndex = columnNames.length - 5;
        if (firstVisibleColumnIndex < maxVisibleColumnIndex) {
            setFirstVisibleColumnIndex(firstVisibleColumnIndex + 1);
        }
    };

    const renderSwitch = (columnName) => {
        switch (columnName) {
            case t('Yellow cards'):
                return <a data="Yellow Card" className="image-popup">
                    <img src={yellowCard} alt="Yellow Card" />
                </a>
            case t('Red cards'):
                return <a data="Red Card" className="image-popup">
                    <img src={redCard} alt="Red Card" />
                </a>
            default: return <>{columnName}</>
        }
    }

    return (
        <div className='webapp-maptable-page-table'>
            <div className="webapp-maptable-page-top-bar">

                <div className="webapp-maptable-page-top-bar-fixed-column">{ItemName}</div>
                <div className="webapp-evaulations-page-top-bar-scroll-items">
                    {firstVisibleColumnIndex === 0 ? <img
                            src={scrollLeft}
                            className="webapp-maptable-top-bar-scroll-left hidden-arrow"
                        /> : (
                        <img
                            src={scrollLeft}
                            alt="Scroll Left"
                            className="webapp-maptable-top-bar-scroll-left"
                            onClick={handleScrollLeft}
                        />
                    )}

                    <div className="webapp-maptable-page-top-bar-items">
                        {columnNames.slice(firstVisibleColumnIndex, firstVisibleColumnIndex + defaultVisibleColumns).map((columnName, index) => (
                            <span key={index} className="webapp-maptable-page-top-bar-column-name">
                                {renderSwitch(columnName)}
                            </span>
                        ))}
                    </div>

                    { 
                        firstVisibleColumnIndex === columnNames.length - defaultVisibleColumns ? 
                        <img
                            src={scrollRight}
                            className="webapp-maptable-top-bar-scroll-right hidden-arrow"
                        /> : 
                        (
                        <img
                            src={scrollRight}
                            alt="Scroll Right"
                            className="webapp-maptable-top-bar-scroll-right"
                            onClick={handleScrollRight}
                        />
                    )}
                </div>
            </div>


            {/* PlayerRow */}
            {Data.map((data, index) => (
                <MapDataRow
                    detailRoute={detailRoute}
                    key={index}
                    data={data}
                    columns={columnNames.slice(firstVisibleColumnIndex, firstVisibleColumnIndex + defaultVisibleColumns)}
                    mappedColumns={mappedColumns}
                    t = {t}
                />
            ))}
        </div>)
}