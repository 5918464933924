import "./AddPlayer.css";

import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import AuthContext from "../../context/AuthContext";

import arrow from "../../webAppAssets/question-arrow.svg";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";


import { useTranslation } from "react-i18next";

import BckLogo from "../../webAppAssets/BackgroundLogo.svg"


const AddPlayer = () => {
    const { t } = useTranslation(['players-page']);

    const navigate = useNavigate();
    const [player, setPlayer] = useState(
        {
            first_name: "",
            email: "",
            last_name: "",
            dateofbirth: "",
            date_of_entry: "",
            position: "Goalkeeper",
            handedness: "LEFT",
        }
    );

    const [usedEmailError, setUsedEmailError] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [team, setTeam] = useState([]);
    const [positions, setPositions] = useState([]);
    const [handedness, setHandedness] = useState([]);

    const { authTokens } = useContext(AuthContext);
    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    const [openDropdown, setOpenDropdown] = useState(null);

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND + 'users/positions', { headers })
        .then((response) => {
            setPositions(response.data);
            //set the position and handedness to the first option
            setPlayer({...player, position: response.data[0][0]});
        })
        .catch((error) => {
            console.log(error);
        });

        axios.get(process.env.REACT_APP_BACKEND + 'users/handedness', { headers })
        .then((response) => {
            setHandedness(response.data);
            //set the position and handedness to the first option
            setPlayer({...player, handedness: response.data[0][0]});
        })
        .catch((error) => {
            console.log(error);
        }
        );

        axios.get(process.env.REACT_APP_BACKEND + 'users/coach-info', { headers })
        .then((response) => {
            setTeam(response.data.team.id);
        })
        .catch((error) => {
            console.log(error);
        });
        //set the position and handedness to the first option
    
    }, []);

    const handlePlayer = (e) => {
        setUsedEmailError(false);
        setDateError(false);
        setPlayer({...player, [e.target.id]: e.target.value});
    }
    


    const addPlayer = (e) => {
        e.preventDefault();
        
        // Check if any required field is missing
        if (player.first_name === "" || player.email === "" || player.last_name === "" || player.dateofbirth === "" || player.date_of_entry === "" || player.position === "" || player.handedness === "") {
            if (player.first_name === "") incompleteInput("first_name");
            if (player.email === "") incompleteInput("email");
            if (player.last_name === "") incompleteInput("last_name");
            if (player.dateofbirth === "") incompleteInput("dateofbirth");
            if (player.date_of_entry === "") incompleteInput("date_of_entry");
            if (player.position === "") incompleteInput("position");
            if (player.handedness === "") incompleteInput("handedness");
            return;
        }
    
        // Validate date fields
        if (validateDates(player.date_of_entry, player.dateofbirth)) {
            incompleteInput("dateofbirth");
            incompleteInput("date_of_entry");
            setDateError(true);
            return; // Exit if dates are invalid
        } else {
            setDateError(false); // Clear date error if dates are valid
        }
    
        // Validate email format
        if (!validateEmail(player.email)) {
            incompleteInput("email");
            return; // Exit if email is invalid
        }
    
        // Proceed with form submission if all validations pass
        axios.post(process.env.REACT_APP_BACKEND + 'users/create-player', {
            first_name: player.first_name,
            email: player.email,
            last_name: player.last_name,
            dateofbirth: player.dateofbirth,
            date_of_entry: player.date_of_entry,
            position: player.position,
            handedness: player.handedness,
            team: team,
        }, { headers })
        .then((response) => {
            navigate('/webapp/players');
        })
        .catch((error) => {
            if (error.response.status === 409) {
                setUsedEmailError(true);
            }
            if (error.response.status === 422) {
                setDateError(true);
            }
        });
    };
    

    const incompleteInput = (input) => {
        document.getElementById(input).classList.add("webapp-addplayer-error-field");
        
        // Remove error class from both date inputs if either is focused
        document.getElementById(input).addEventListener("focus", () => {
            if (input === "dateofbirth" || input === "date_of_entry") { // if either one of both inputs are focused, both go back to black
                document.getElementById("dateofbirth").classList.remove("webapp-addplayer-error-field");
                document.getElementById("date_of_entry").classList.remove("webapp-addplayer-error-field");
            } else {
                // Remove error class for other fields
                document.getElementById(input).classList.remove("webapp-addplayer-error-field");
            }
        });
    }
    

    const validateEmail = (email) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateDates = (date_of_entry, dateofbirth) => {
        const dob = new Date(dateofbirth);
        const doe = new Date(date_of_entry);
        return doe <= dob; // Entry date should be after birthdate
    }

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    }

    const placeBackgroundImages = () => {
        const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
        const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
        const bckLogo1Height = bckLogo1.offsetHeight;
        bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
        bckLogo2.style.top = `0%`;
        const bckLogo1Width = bckLogo1.offsetWidth;
        bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
        bckLogo2.style.left = `75%`;
    }

    useEffect(() => {
        placeBackgroundImages();
        window.addEventListener('resize', placeBackgroundImages);
        return () => {
            window.removeEventListener('resize', placeBackgroundImages);
        }
    }, [])

    return (
        <div className="webapp-addplayer">
            <Link to="/webapp/players" className="webapp-addplayer-header">
                <img src={arrow} alt="Voltar"/>
                <h1>{t('New Player')}</h1>
            </Link>
            <div className="webapp-addplayer-content">

                <div className="webapp-create-team-background-images">
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
                </div>
                <h3 className="webapp-addplayer-content-title">{t('Player')}</h3>

                <form className="webapp-addplayer-content-main" onChange={handlePlayer}>
                    <div className="webapp-addplayer-content-main-inputs webapp-addplayer-content-main-left">
                        <label htmlFor="fist">{t('Name')}</label>
                        <input type="text" id="first_name" placeholder={t("example-name")}/>
                    </div>
                    <div className="webapp-addplayer-content-main-inputs webapp-addplayer-content-main-right">
                        <label htmlFor="email">{t('Email')}</label>
                        <input type="email" id="email" className={`${usedEmailError ?  "webapp-addplayer-error-field" :""}`}  placeholder={t("example-email")}/>
                        <span className={`${usedEmailError ?  "webapp-addplayer-email-already-used" :"display-none "}`}> {t("email-in-use")} </span>
                    </div>
                    <div className="webapp-addplayer-content-main-inputs webapp-addplayer-content-main-left">
                        <label htmlFor="last">{t('Surname')}</label>
                        <input type="text" id="last_name" placeholder={t("example-surname")}/>
                    </div>
                    <div className="webapp-addplayer-content-main-inputs webapp-addplayer-content-main-small">
                        <label htmlFor="dateofbirth">{t('Birthdate')}</label>
                        <input type="date" id="dateofbirth" />
                        <span className={`${dateError ?  "webapp-addplayer-dates-error" :"display-none "}`}>{t("Caution with the dates!")}</span>
                    </div>
                    <div className="webapp-addplayer-content-main-inputs webapp-addplayer-content-main-small">
                        <label htmlFor="admission_date">{t('Entry Date')}</label>
                        <input type="date" id="date_of_entry" />
                    </div>
                    <div className="webapp-addplayer-content-main-inputs webapp-addplayer-content-main-left">
                        <label htmlFor="position">{t('Position')}</label>
                        <select id="position">
                            {positions.map((position, index) => {
                                return <option key={index} value={position[0]}>{t(position[0])}</option>
                            })}
                        </select>
                    </div>
                    <div className="webapp-addplayer-content-main-inputs webapp-addplayer-content-main-right">
                        <label htmlFor="handedness">{t('Laterality')}</label>
                        <select id="handedness">
                            {handedness.map((handedness, index) => {
                                return <option key={index} value={handedness[0]}>{t(handedness[0])}</option>
                            })}
                        </select>
                    </div>
                    <button className="webapp-addplayer-content-button" onClick={addPlayer}>{t('Save Player')}</button>
                </form>
            </div>      
        </div>
    );
}

export default AddPlayer;