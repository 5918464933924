import react, { useState, useContext, useEffect, useRef} from "react";
import AuthContext from '../../context/AuthContext';
import Drawing from "./CoachPaintComponents/Drawing";
import fullField from "./CoachPaintAssets/full-field.svg";
import halfField from "./CoachPaintAssets/half-field.svg";
import onlyGrass from "./CoachPaintAssets/only-grass.svg";
import CoachPaintMenu from "./CoachPaintComponents/CoachPaintMenu";
import "./CoachPaint.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


function CoachPaint(props) {
    const { t } = useTranslation(['coach-paint']);
  	const [tool, setTool] = useState("pen");
    const backgroundOptions = [
        {
            label: t('entire-field'),
            image: fullField,
        },
        {
            label: t('half-field'),
            image: halfField,
        },
        {
            label: t('simple-field'),
            image: onlyGrass,
        },
    ];

    const stageRef = useRef(null);
    const [selectedStamp, setSelectedStamp] = useState(null);
    const [stamps, setStamps] = useState(props.drawing.stamps.length > 0 ? props.drawing.stamps : []);
    const [selectedCampo, setSelectedCampo] = useState(props.drawing.image !== null ? props.drawing.image : backgroundOptions[0]);
    const [lines, setLines] = useState(props.drawing.lines.length > 0 ? props.drawing.lines : []);
    const uniqueStageId = `stage-id-${props.drawing?.id}`;
    const [currentColor, setCurrentColor] = useState("#FFFFFF");
    const [strokeSize, setStrokeSize] = useState(2);
    const [selectedCanvasItem, setSelectedCanvasItem] = useState(["", null]);

    const downloadDrawing = () => {

        const stage = stageRef.current.getStage(); // Access the stage using the ref
        const dataURL = stage.toDataURL(); // Get the data URL of the stage content

        // Create a temporary anchor element
        const downloadLink = document.createElement('a');
        downloadLink.href = dataURL;
        downloadLink.download = 'drawing.png'; // Set the desired filename and extension
        document.body.appendChild(downloadLink);

        // Trigger a click on the anchor element to start the download
        downloadLink.click();

        // Remove the anchor element from the DOM
        document.body.removeChild(downloadLink);

        toast.success(t('download-success'))
    };

    const saveDrawing = async () => {
        props.handleDrawingDataChange('lines', lines)
        props.handleDrawingDataChange('stamps', stamps)
        props.handleDrawingDataChange('image', selectedCampo)
        toast.success(t('save-success'))
        props.handleReturn()
    }

    const deleteDrawing = () => {
        setLines([]);
        setStamps([]);
    }


	  const handleColorChange = (color) => {
		    setCurrentColor(color);
	  }

    const handleStrokeSizeChange = (size) => {
        setStrokeSize(size);
    }

    useEffect(() => {
        if (tool === "eraser") {
            document.body.classList.add('paint-cursor-eraser-style');
        }
    }), [tool]


    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        window.addEventListener('resize', handleResize);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('resize', handleResize);
        };


    }, []);

    const handleResize = () => {
        setTimeout(function() {
        const sideOptions = document.getElementById('webapp-paint-side-buttons');
        if(window.offsetWidht < 900) {
          sideOptions.style.height = `auto`;
          return;
        }
        const stageElement = document.getElementById(uniqueStageId);
        const canvasHeight = stageElement?.children[0]?.children[0]?.offsetHeight;

        sideOptions.style.height = `${canvasHeight}px`;
        }, 100);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            setSelectedCanvasItem(["", null]);
            setTool("pen");
        }
    }


	return (
		<div className={`webapp-coach-paint-page `}>
            <div className={`webapp-coach-page-container ${tool === "eraser" ? "paint-cursor-eraser-style" : ""}` }>
            <div className="webapp-coach-page-menu">
                <CoachPaintMenu
                    exerciseName={props.exerciseName}
                    exerciseDescription={props.exerciseDescription}
                    handleReturn={props.handleReturn}
                    tool={tool}
                    setTool={setTool}
                    selectedStamp={selectedStamp}
                    setSelectedStamp={setSelectedStamp}
                    selectedCampo={selectedCampo}
                    setSelectedCampo={setSelectedCampo}
                    backgroundOptions={backgroundOptions}
                    currentColor={currentColor}
                    handleColorChange={handleColorChange}
                    strokeSize={strokeSize}
                    handleStrokeSizeChange={handleStrokeSizeChange}/>
            </div>
            <div className="webapp-coach-page-paint-container">
                <div className="webapp-coach-paint-canvas" id={uniqueStageId}>
                    <Drawing
                        penSelected={tool === "pen"}
                        selectedStamp={selectedStamp}
                        stamps={stamps}
                        setStamps={setStamps}
                        setCampoState={setSelectedCampo}
                        campoState={selectedCampo.image}
                        lines={lines}
                        setLines={setLines}
                        currentColor={currentColor}
                        stageRef={stageRef}
                        justDisplay={false}
                        stageId={uniqueStageId}
                        squareSelected={tool=== "square"}
                        arrowSelected={tool === "arrow"}
                        strokeSize={strokeSize}
                        grabSelected={tool === "grab"}
                        selectedCanvasItem={selectedCanvasItem}
                        setSelectedCanvasItem={setSelectedCanvasItem}
                        />
                </div>
                <div id='webapp-paint-side-buttons' className="webapp-coach-paint-feature-buttons">
                    <button className="paint-button-delete" onClick={() => deleteDrawing()}>{t('delete-draw')}</button>
                    <div>
                        <button className="paint-button-save" onClick={saveDrawing}>{t('save-draw')}</button>
                        <button className="paint-button-download" onClick={() => downloadDrawing()}>{t('download-draw')}</button>
                    </div>
                </div>
            </div>
			</div>
		</div>
	);
}

export default CoachPaint;
