import React, { useState } from 'react';
import './GamesSubpageChanger.css';


const GamesSubpageChanger = ({ selectedSubpage, onSubpageChange, page1, page2 }) => {
    
    return (
        <div className="webapp-games-subpage-changer">
            <div className={`webapp-games-subpage-changer-container
                ${selectedSubpage === 0 ? 'game-subpage-changer-selected' : ''}`}
                onClick={() => onSubpageChange(0)}
            >
                <a className={`dark-blue-16px-600`}>{page1}</a>
                <hr />
            </div>
            <div className={`webapp-games-subpage-changer-container
                ${selectedSubpage === 1 ? 'game-subpage-changer-selected' : ''}`}
                onClick={() => onSubpageChange(1)}
            >
                <a className={`dark-blue-16px-600`}>{page2}</a>
                <hr />
            </div>
        </div>

    )

}

export default GamesSubpageChanger