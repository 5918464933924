import {useState, useEffect} from "react";
import "./Popup.css"
import Close from "../../webAppAssets/PopupClose.svg"
import Advance from "../../webAppAssets/PopupAdvance.svg"

//You need to have a function that sets the state of the popup in the parent component and parse it as setState

const Popup = ({data, setState, advance, selectedLanguage, otherOption,action}) => {
    const [src, setSrc] = useState();
    const [classe, setClasse] = useState("");

    const onCancel = () => {
        setState(false)
    }

    useEffect(() => {
        if(data.image === "Close" || data.image === "close"){
            setSrc(Close)
            setClasse("close")
        }else if(data.image === "Advance" || data.image === "advance"){
            setSrc(Advance)
            setClasse("advance")
        }
    })

    return (
        <div className="webapp-popup-background">
            <div className="webapp-popup" onClick={onCancel}>
                <div className="webapp-popup-warning">
                    <img className="webapp-popup-img" src={src}/>
                    <h3 className={`webapp-popup-title-${classe}`}>{data.title}</h3>
                </div>
                <div className="webapp-popup-message">
                    {data.message.map((item, index) => {
                        return <p key={index}>{item}</p>
                    })}
                </div>
                <hr className="webapp-popup-line"/>
                <div className="webapp-popup-buttons">
                    <button onClick={ otherOption ? otherOption : onCancel} className="webapp-createteam-button webapp-createteam-white-button">{data?.otherOption ? data.otherOption : data.close}</button>
                    <button onClick={action} className="webapp-createteam-button webapp-createteam-blue-button">{data.advance}</button>
                </div>
            </div>
        </div>
    )
}

export default Popup;
