import React, {useState} from 'react';
import './TrainingsPageChanger.css';
import { Link } from 'react-router-dom';


const TrainingsPageChanger = ({ pages, selectedPage }) => {
    return (
      <div className="webapp-trainings-page-changer">
        {pages.map((page, index) => (
          <Link
            key={index}
            to={page.path}
            className={`dark-blue-16px-600 ${selectedPage === index ? 'trainings-changer-selected' : ''}`}
            state = {page.state}
          >
            {page.label}
          </Link>
        ))}
      </div>
    );
  };

export default TrainingsPageChanger