import { useRef, useEffect } from 'react';
import './TrainingExerciseFolders.css';
import selectedCheck from "../../webAppAssets/selected-check-icon.svg";
import kebabMenu from "../../webAppAssets/3-dot.svg"
import footballField from "../../webAppAssets/football-field-training.png"
import timerClock from "../../webAppAssets/timer-clock.svg"
import Drawing from '../CoachPaint/CoachPaintComponents/Drawing';

import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Exercise = (props) => {
    const uniqueStageId = `stage-id-${props.item}`;
    return (

            <div className="webapp-list-popup-exercise " onClick={() => props.handleSelectedItemChange(props.item)} >
                { props.exercise.drawing ? (<div className="webapp-training-card-image" id={`${uniqueStageId}-display`}><Drawing justDisplay={true} lines={props.exercise.drawing.lines} campoState={props.exercise.drawing.image.image}  stamps={props.exercise.drawing.stamps} stageId={`${uniqueStageId}-display`} /> </div>) : (<img className="webapp-training-card-image" src="https://i.imgur.com/qebbL6n.png"></img>)}
                <div>
                    <span>{props.name}</span>
                </div>
                {props.selectedItem === props.item &&
                <div className="webapp-import-items-selected-check"><img  src={selectedCheck}></img></div>}
            </div>
    )
}

const Folder = (props) => {
    const { t } = useTranslation(['training-exercise-folders']);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                if (event.target.className !== 'toggle-folder-menu')
                    props.toggleFolderMenuPopup(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    if(props.InPopup){
        return (
            <div key={props.folder.id} className="webapp-trainings-folder-grid-item " onClick={() => props.handleFolderSelection(props.folder.id)} >
                <a>{props.folder.name}</a>
            </div>
        )
    } else {
    return (
        <div key={props.folder.id} className="webapp-trainings-folder-grid-item" >
            {props.InPopup ? (
            <span className={"webapp-folder-component"} onClick={() => {props.handleFolderSelection(props.folder.id)}}> {props.folder.name}  </span>
            ):
            (<Link to={`/webapp/folder/${props.folder.id}`}>{props.folder.name}</Link>)}
            {props.editable === true ? (
            <div className="webapp-trainings-folder-kebab-menu">
                <img className='toggle-folder-menu' src={kebabMenu} onClick={() => props.toggleFolderMenuPopup(props.folder.id)} ref={dropdownRef}></img>
                {props.folderMenuPopup === props.folder.id ? (
                    <div className="webapp-trainings-folder-menu-popup">
                        <span onClick={() =>  {
                            props.changeCurrentRequestedId(props.folder.id)
                            props.handleDeleteFolderPopup();}} className="black-16px-600 trainings-folder-clickable">{t('Delete Folder')}</span>
                        <span className="webapp-trainings-page-small-division"></span>
                        <span onClick={()=> {
                            if (props.changeCurrentRequestedId)
                                props.changeCurrentRequestedId(props.folder.id)
                            props.handleChangeFolderNamePopup();
                            }} className="black-16px-600 trainings-folder-clickable">{t('Change Name')}</span>
                    </div>
            ) : null }
            </div>
            ) : null}
        </div>
        )
    }
}


const Training = (props) => {
    const { t } = useTranslation(['training-exercise-folders']);
    const dropdownRef = useRef(null);
    const nameRef = useRef(null);
    const nameHolderRef = useRef(null);
    const uniqueStageId = `stage-id-${props.training.id}`;
    const drawing = props.training?.drawing;

    useEffect(() => {
      const checkExerciseNameOverflows = () => {
        const nameHolderCs = getComputedStyle(nameHolderRef.current);
        const holderWidht = nameHolderRef.current.clientWidth - 2*parseFloat(nameHolderCs.paddingLeft)
        if( holderWidht < nameRef.current.clientWidth){
          nameRef.current.classList.add("webapp-exercise-name-overflow")
        }
      }

        window.addEventListener("resize", checkExerciseNameOverflows);
        checkExerciseNameOverflows();

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                //check if the click is on clasname toggle-exercise-menu
                if (event.target.className !== 'toggle-exercise-menu')
                    props.toggleTrainingMenuPopup(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener("resize", checkExerciseNameOverflows);
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // Change time format from hh:mm:ss to minutes
    const handleTimeFormat = (time) => {
        const timeArray = time.split(":");
        const total_seconds = parseInt(timeArray[0]) * 3600 + parseInt(timeArray[1]) * 60 + parseInt(timeArray[2]);
        return (total_seconds / 60).toFixed(2);
    }

    const checkForBottomFive = (folder, index) =>{
      /*
      The last 5 exercices can have overflow of the popup if uncheck
      */
      if(props.numberOfExercises < 5) return false;

      if(folder == null){
        return index >= 5
      }else{
        if(props.numberOfExercises <= 10) return;
        let quotient = Math.floor(props.numberOfExercises / 5);
        return Math.floor((index+1)/5)== quotient;
      }
    }



    return (

        <div key={props.training.id} className="webapp-trainings-training-div" onClick={()=> {
            if (props.selectable === true && props.getCurrentRequestedId)
                props.getCurrentRequestedId(props.training.id)
        }} >
            { drawing ? (<div className="webapp-training-card-image" id={`${uniqueStageId}-display`}><Drawing justDisplay={true} lines={drawing.lines} campoState={drawing.image.image}  stamps={drawing.stamps} stageId={`${uniqueStageId}-display`} maintainAspectRatio={false} /> </div>) : (<img className="webapp-training-card-image" src="https://i.imgur.com/qebbL6n.png"></img>)}
            <div className="webapp-trainings-training-folder-info webapp-exercise-card-content" ref={nameHolderRef}>
                <p className="black-16px-600 webapp-exercise-name-display" ref={nameRef} >{props.training.name}  <span className='text-tooltip'> {props.training.name} </span> </p>
                <div className="webapp-trainings-training-time-kebab">
                    <span className="webapp-trainings-training-timer"><img src={timerClock}></img><p>{handleTimeFormat(props.training.total_duration)} min</p></span>
                    {props.editable === true ? (
                    <div className="webapp-trainings-training-menu-popup">
                        <img className='toggle-exercise-menu' src={kebabMenu} onClick={() => props.toggleTrainingMenuPopup(props.training.id)} ref={dropdownRef}></img>
                        {props.trainingMenuPopup === props.training.id ? (
                            <div  className={`webapp-trainings-folder-menu-popup
                              ${checkForBottomFive(props.training.folder, props.index) && 'webapp-trainings-training-div-bottom-overflow'}
                              ${!props.isMobile && props.index % 4 === 0 ? 'webapp-trainings-popup-overflow' : ''}
                              ${props.isMobile && props.index % 3 === 0 ? 'webapp-trainings-popup-overflow' : ''}`}>
                                <span onClick={() => {
                                props.changeCurrentRequestedId(props.training.id);
                                props.handleDeleteTrainingPopup();
                                }} className="black-16px-600 trainings-folder-clickable">{t('Delete Exercise')}</span>
                                <span className="webapp-trainings-page-small-division"></span>
                                <span onClick={() => {
                                    props.handleEditTrainingPopup(props.training.id);
                                    }} className="black-16px-600 trainings-folder-clickable">{t('Edit Exercise')}</span>
                                <span className="webapp-trainings-page-small-division"></span>
                                <span onClick={()=> {
                                        props.changeCurrentRequestedId(props.training.id);
                                        props.handleChangeTrainingNamePopup();
                                        }} className="black-16px-600 trainings-folder-clickable">{t('Change Name')}</span>
                                <span className="webapp-trainings-page-small-division"></span>
                                <span onClick={()=> {
                                        props.changeCurrentRequestedId(props.training.id);
                                        props.handleMoveToFolderPopup();
                                        }
                                    } className="black-16px-600 trainings-folder-clickable">{t('Move to Folder')}</span>
                            </div>
                        ): null}
                    </div>
                    ) : null}
                </div>
            </div>
            {props.selectable === true && props.training.id === props.currentTraining ? (
                <div className="webapp-selected-training"><img src={selectedCheck}></img></div>
            ) : null
            }

        </div>
    )

}


export { Exercise, Folder, Training }
