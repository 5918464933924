import './DashboardHorizontal.css';

import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

const Horizontal = ({ data, title }) => {
    const { t } = useTranslation(["create-training-page"]); // at the moment is only used by graphs in dashboard that needed the text from the dropdown in the ex creation

    /*useEffect(() => {
        //select all the bars
        const bars = document.querySelectorAll(`.webapp-injurieschart-bar-${title}`);
        //give each bar a width based on the percentage
        //give the width in percentage
        bars.forEach((bar, index) => {
            bar.style.width = `${data[index].percentage}%`;
        });
    }, []);*/
    
    return (
        <div className='webapp-horizontal'>
            <div className='webapp-injurieschart-header'>
                <h2 className='webapp-injurieschart-header-title'>{t(title)}</h2>
            </div>
            <table className='webapp-injurieschart-table'>
               {/*<thead className='webapp-injurieschart-table-header'>
                     <tr>
                          <th></th>
                          <th className='webapp-injurieschart-table-header-element'>{t('Percentage')}</th>
                     </tr> 
                </thead>*/}
                <tbody className='webapp-injurieschart-table-body'>
                    {Object.keys(data).map((item, index) => {
                        const itemData = data[item];
                        return(
                            <tr key={item.name} className='webapp-injurieschart-table-body-row'>
                                <th className='webapp-horizontal-table-body-row-header'>{t(itemData.name)}</th>
                                <td className='webapp-injurieschart-table-body-row-item'>
                                    {index === 0 ?
                                        <div className='webapp-injurieschart-scale'>
                                            <p>0%</p>
                                            <p>25%</p>
                                            <p>50%</p>
                                            <p>75%</p>
                                            <p>100%</p>
                                        </div> : null
                                    }
                                    <div className='webapp-injurieschart-bar-lines'>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    {/*<div className={`webapp-injurieschart-bar webapp-injurieschart-bar-${title}`}>
                                        {itemData.percentage}
                                        <span className='webapp-injurieschart-bar-tooltip'>{itemData.percentage}</span>
                                    </div>*/}
                                    <div className={`webapp-injurieschart-bar webapp-injurieschart-bar-${title}`} style={{ width: `${itemData.percentage}%`, height:'1rem'}}>
                                        <span className='webapp-injurieschart-bar-tooltip'>{itemData.percentage}</span>
                                    </div>
                                </td>
                                {/*item.id === 0 ?
                                    <td rowSpan={data.length} className='webapp-injurieschart-chart'>
                                        <Bar data={chartData} options={options}/>
                                    </td> : null
                                */}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            
        </div>
    );
}

export default Horizontal;