import './AddGameItems.css';
import GameDropdown from '../GameDropdown/GameDropdown';
import GamesForm from '../FormsGame/FormsGame';
import { useEffect, useState } from 'react';


const Adversary = ({gameData, setGameData, t, adversaryOptions}) => {
    const [validOptions, setValidOptions] = useState([...adversaryOptions])
    const [option, setOption] = useState(gameData.against ? gameData.against : undefined)
    const [clickable, setClickable] = useState(false)

    const handleEquipa = (e) => {
        setGameData({...gameData, "against": e.target.value});
        setOption(e.target.value)
    }

    useEffect(() => {
        const optionsElement = document.querySelector('.webapp-add-game-teams-options');

        const handleTransitionEnd = () => {
            const currentOpacity = parseFloat(window.getComputedStyle(optionsElement).opacity);
            if (currentOpacity === 1) {
                setClickable(true);
            } else if (currentOpacity === 0) {
                setTimeout(() => {
                    setClickable(false);
                }, 300); // Adjust the delay as needed
            }
        };

        if (optionsElement) {
            optionsElement.addEventListener('transitionend', handleTransitionEnd);
        }

        return () => {
            if (optionsElement) {
                optionsElement.removeEventListener('transitionend', handleTransitionEnd);
            }
        };
    }, []);

    const handleValidOptions = (e) => {
        // If the input is empty, show all options
        // Filter the options that contain the input value
        const filteredOptions = adversaryOptions.filter(option => option.name.toLowerCase().includes(e.target.value.toLowerCase()))
        setValidOptions(filteredOptions)
        setOption(e.target.value)
    }

    const handleOptionSelected = (e, option) => {
        if (!clickable) return;
        setGameData({...gameData, "against": option.name});
        setOption(option.name)
    }


    return (
        <div className='container-adversary'>
            <div className='adversary-info'>
                <label>{t('Opposing team')} <span className="webapp-asterisco">*</span></label>
                <input
                    id = "create-game-adversary"
                    placeholder={t('Name of the opposing team')}
                    type="text"
                    // onChange={handleEquipa}
                    value={option}
                    onChange={(e) => {handleValidOptions(e); handleEquipa(e)}}
                ></input>
                <div className='webapp-add-game-teams-options'>
                    {validOptions && validOptions.map((option, index) => {
                        return (
                            <div onClick={(e) => handleOptionSelected(e, option)} className='webapp-add-game-teams-option' key={index}>
                                <span>{option.name}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

const Liga = ({gameData, setGameData, t, leaguesOptions}) => {

    const [validOptions, setValidOptions] = useState([...leaguesOptions])
    const [option, setOption] = useState(gameData.league ? gameData.league : undefined)
    const [clickable, setClickable] = useState(false)

    const handleLiga = (e) => {
        setGameData({...gameData, "league": e.target.value});
        setOption(e.target.value)
    }

    useEffect(() => {
        const optionsElement = document.querySelector('.webapp-add-game-teams-options');

        const handleTransitionEnd = () => {
            const currentOpacity = parseFloat(window.getComputedStyle(optionsElement).opacity);
            if (currentOpacity === 1) {
                setClickable(true);
            } else if (currentOpacity === 0) {
                setTimeout(() => {
                    setClickable(false);
                }, 300); // Adjust the delay as needed
            }
        };

        if (optionsElement) {
            optionsElement.addEventListener('transitionend', handleTransitionEnd);
        }

        return () => {
            if (optionsElement) {
                optionsElement.removeEventListener('transitionend', handleTransitionEnd);
            }
        };
    }, []);

    const handleValidOptions = (e) => {
        // If the input is empty, show all options
        // Filter the options that contain the input value
        const filteredOptions = leaguesOptions.filter(option => option.name.toLowerCase().includes(e.target.value.toLowerCase()))
        setValidOptions(filteredOptions)
        setOption(e.target.value)
    }

    const handleOptionSelected = (e, option) => {
        if (!clickable) return;
        setGameData({...gameData, "league": option.name});
        setOption(option.name)
    }

    return (
        <div className='container-adversary'>
            <div className='adversary-info'>
                <label>{t('League')} <span className="webapp-asterisco">*</span></label>
                <input
                    id="create-game-league"
                    placeholder={t('League\'s name')}
                    type="text"
                    value={option}
                    onChange={(e) => {handleValidOptions(e); handleLiga(e)}}
                ></input>
                <div className='webapp-add-game-teams-options'>
                    {validOptions && validOptions.map((option, index) => {
                        return (
                            <div onClick={(e) => handleOptionSelected(e, option)} className='webapp-add-game-teams-option' key={index}>
                                <span>{option.name}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

const Data = ({gameData, setGameData, t}) => {
    const [selectedStartTime, setSelectedStartTime] = useState(gameData.start_time ? gameData.start_time : "");

    const handleDate = (e) => {
        setGameData({...gameData, "date": e.target.value});
    }

    useEffect(() => {
        setGameData({...gameData, "start_time": selectedStartTime});
    } , [selectedStartTime])

    return (
        <div className='container-adversary2'>
            <div className='adversary-info'>
                <label>{t('Date')} <span className="webapp-asterisco">*</span></label>
                <input
                    id = "create-game-date"
                    type="date"
                    value={gameData.date}
                    onChange={(e) => {handleDate(e)}}
                ></input>
            </div>
            <div className='adversary-info'>
                <label>{t('Start')} <span className="webapp-asterisco">*</span></label>
                <GameDropdown selected={selectedStartTime} setSelected={setSelectedStartTime} />
            </div>
        </div>
    )
}

const Lado = ({gameData, setGameData, t}) => {
    const labels = ["Casa", "Fora"]
    const [value, setValue] = useState("")
    const [lab, setLab] = useState(gameData.home_game ? "Casa" : "Fora");

    useEffect(() => {
        setGameData({...gameData, "home_game": value === "Casa" ? true : false});
    } , [value])

    return (
        <div className='container-adversary'>
            <div className='adversary-info'>
                <label>{t('Game Place')}<span className="webapp-asterisco">*</span></label>
                <GamesForm lab={lab} setLab={setLab} labels={labels} setValue={setValue} t={t} />
            </div>
        </div>
    )
}

export { Adversary, Liga, Data, Lado }
