import React, { useState, useEffect } from "react";
import "./LoginPage.css";
import backArrow from "../../webAppAssets/register-arrow-right.svg"
import blueLogo from "../../webAppAssets/navbar-logo.svg"
import { Link, useNavigate } from "react-router-dom";
import showHidePasswordButton from "../../webAppAssets/show-hide-password.svg"
import backgroundThreeFirst from "../../webAppAssets/blue-background-three.svg";
import AuthContext from "../../context/AuthContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';

const LoginPage = () => {
    const { t } = useTranslation(['login-page']);
    const location = useLocation();
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [loginError, setLoginError] = useState(false);


    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        if(location.state?.recovery_toast){
            toast.success(t('recovery-toast'));
        }

        if(location.state?.reset_toast){
            toast.success(t('reset-toast'));
        }else if(location.state?.reset_toast === false){
            toast.error(t('reset-error'));
        }

        location.state = null;
    }, []);

    const {loginUser} = useContext(AuthContext)

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleRecoverRedirect = () => {
        navigate("/webapp/recover-password");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const success = await loginUser(e)

        if(!success){
            setLoginError(true)
        }
    }

    return (
        <div className="webapp-login-page">
            <img className="webapp-register-left-background-three" src={backgroundThreeFirst}></img>
            <img className="webapp-register-right-background-three" src={backgroundThreeFirst}></img>
            <div className="webapp-login-container">
            <div className="webapp-login-icons">
                <Link to="/webapp"><img src={backArrow}></img></Link>
                <img src={blueLogo}></img>
            </div>
            <div className="webapp-register-form-container">
                <h1>{t('Welcome Back')}</h1>
                <form className="webapp-register-form" onSubmit = {(e) => handleSubmit(e)}>
                    <div className=" webapp-register-form-input-divs">
                        <div className="webapp-register-form-top-header-with-error">
                            <label className="dark-blue-15px-700">{t('Username')} <span className="webapp-asterisco">*</span></label>
                            <span className={`webapp-login-error-invalid ${loginError === true ? "show-error" : ""}`}>{t('login-error')}</span>
                        </div>
                        <input name="username" value={email} placeholder={t('Username')} onChange= {handleEmailChange}></input>
                    </div>

                    <div className="webapp-register-form-input-divs">
                        <label className="dark-blue-15px-700">{t('Password')} <span className="webapp-asterisco">*</span></label>
                        <input name="password" type={showPassword ? 'text': 'password'} placeholder={t('Password')} value={password} onChange={handlePasswordChange}></input>
                        <img  src = {showHidePasswordButton} onClick={togglePasswordVisibility} className= {`password-toggle ${showPassword ? 'visible' : '' }`}>
                        </img>
                    </div>
                    <div className="webapp-login-form-forgotpass">
                        <p className="black-16px-400" onClick={handleRecoverRedirect}>{t('Forgot Password')}</p>
                    </div>
                    <div className="webapp-register-submit-button">
                        <button type="submit">{t('Login')}</button>
                        <p className="black-16px-400">{t('Dont have an account')} <Link to="/webapp/register" className="webapp-register-link-login"><span className="webapp-register-login">{t('Create Account')}</span></Link></p>
                    </div>
                </form>
            </div>
        </div>
        </div>
    );
};

export default LoginPage;
