import React, {useState} from "react";
import WhiteLogo from "../../assets/3D_Branco.svg";
import "./HomePage.css";
import homeBackgroundLogoThree from "../../assets/home-three.svg";
import academyLogoBackground from "../../assets/academy-three.svg"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HomePage = () => {
    const { t } = useTranslation(['home-page']);
    
    
    return (
        <div className="webapp-home-page"> 
            <img className="webapp-home-background-three" src={homeBackgroundLogoThree}></img> 

           <img className="webapp-home-background-three-inverse" src={academyLogoBackground}></img> 
            <div className="webapp-home-page-items">
                <div className="webapp-home-logo-container">
                    <img src= {WhiteLogo} alt="3d Logo" className="webapp-logo" />
                </div>
                <div className="webapp-home-page-buttons">
                    <div>
                        <span className="white-16px-400">{t('Already have an account?')}</span>
                        <Link to="/webapp/login">
                            <button className="webapp-home-page-login-button">{t('Login')}</button>
                        </Link>
                       
                    </div>
                    <div>
                        <span className="gray-16px-400">{t('Dont have an account?')} <Link to="/webapp/register"><span className="webapp-home-page-register">{t('Create Account')}</span></Link></span>
                       
                    </div>
                </div>
            </div>     
            
             </div>
    )
    }

export default HomePage;
