import { useEffect, useState, useContext } from "react";

const PlayerCard = ({ player, edit, removePlayer, t}) => {
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(!open);
    }

    return (
        <div className={open ? "webapp-player-card  webapp-player-card-open" : "webapp-player-card"}>
            <p className="webapp-player-card-button"  alt="Toggle button" onClick={toggleOpen}> {open ? "-" : "+"} </p>
            <div className="webapp-player-card-header">
                <h2 className="webapp-player-card-header-name">{player.first_name} {player.last_name}</h2>
                <h4 className="webapp-player-card-header-email">{player.email}</h4>
            </div>
            {open &&
            <div className="webapp-player-card-dropdown">
                <div className="webapp-player-card-dropdown-text">
                    <p className="webapp-player-card-info-name">{t('Position')}:</p> <p className="webapp-player-card-info-content">{t('Position', {context:player.position})}</p>
                </div>
                <div className="webapp-player-card-dropdown-text">
                    <p className="webapp-player-card-info-name">{t('Handedness')}:</p> <p className="webapp-player-card-info-content">{t('Handedness', {context:player.handedness})}</p>
                </div>
                <div className="webapp-player-card-dropdown-text">
                    <p className="webapp-player-card-info-name">{t('Date of Birth')}:</p> <p className="webapp-player-card-info-content">{player.dateofbirth} - {player.quartile} </p>
                </div>
            </div>}
            {open && <p className="webapp-player-card-entrydate">{player.entrydate} </p>}
            {edit && <button className="webapp-createteam-blue-button webapp-player-card-remove" onClick={removePlayer} cenas={player.email}>X</button>}
        </div>
    );          
}

export default PlayerCard;