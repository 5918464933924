import React, { useState, useEffect } from "react";
import HomePage from "../HomePage/HomePage";
import AboutUs from "../AboutsUs/AboutUs";
import Questions from "../Questions/Questions";
import Academy from "../Academy/Academy";
import Packages from "../Packages/Packages";
import "./LandingPage.css";
import Navbar from "../../components/Navbar/Navbar";
import Contactos from "../Contactos/Contactos";
import Footer from "../../components/Footer/Footer";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from "react-i18next";

const LandingPage = () => {
    // pt as default language
    const { t, i18n } = useTranslation();

    //Para mostrar um item de cada vez apenas quando estamos a chegar a ele
    const { isMobile } = useMediaQuery({ maxWidth: 900 });


    const defaultThreshold = 0.2;
    const mobileThreshold = 0.0001;


    const aboutUsThreshold = isMobile ? mobileThreshold : defaultThreshold;

    const academyThreshold = isMobile ? mobileThreshold : defaultThreshold;

    const contactosThreshold = isMobile ? mobileThreshold : defaultThreshold;


    const [aboutUsRef, aboutUsInView] = useInView({ threshold: aboutUsThreshold });

    const [academyRef, academyInView] = useInView({ threshold: academyThreshold });

    const [contactosRef, contactosInView] = useInView({ threshold: contactosThreshold });



    const [homeRef, homeInView] = useInView({ threshold: 0 });
    const [aboutUsScrolled, setAboutUsScrolled] = useState(false);

    const [academyScrolled, setAcademyScrolled] = useState(false);

    const [contactosScrolled, setContactosScrolled] = useState(false);


    const [selectedLanguage, setSelectedLanguage] = useState('PT'); // Default language

    useEffect(() => {
        i18n.changeLanguage(selectedLanguage.toLowerCase());
    }
        , [selectedLanguage]);

    const changeLanguage = (language) => {
        setSelectedLanguage(language);
    };



    const handleScroll = () => {
        if (aboutUsInView && !aboutUsScrolled) {
            setAboutUsScrolled(true);
        }

        if (academyInView && !academyScrolled) {
            setAcademyScrolled(true);
        }

        if (contactosInView && !contactosScrolled) {
            setContactosScrolled(true);
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [aboutUsInView, academyInView, contactosInView]);



    return (
        <div className="landing-page">
            <section id="home" ref={homeRef} className="home-section">
                <HomePage />
            </section>
            <section id="navbar" className={`navbar-section ${homeInView ? 'fade-out' : 'fixed-nav'} slide-in-from-top`}><Navbar selectedLanguage={selectedLanguage} changeLanguage={changeLanguage} /></section>

            <section id="aboutus" ref={aboutUsRef}>
                <div className="carousel1 carousel-slider-wrapper common-carousel-style">
                    <AboutUs selectedLanguage={selectedLanguage} />
                </div>
            </section>
            <section id="questions " >
                <div className="carousel2 carousel-slider-wrapper common-carousel-style">
                    <Questions selectedLanguage={selectedLanguage} />
                </div>
            </section>
            <section id="academy" ref={academyRef} className={`academy-section ${academyInView || academyScrolled ? 'visible' : ''}`}>
                <Academy selectedLanguage={selectedLanguage} />
            </section>
            <section id="packages" className='packages-section' >
                <div className="carousel3 carousel-slider-wrapper common-carousel-style">
                    <Packages selectedLanguage={selectedLanguage} />
                </div>
            </section>
            <section id="contactos" ref={contactosRef} className={`contactos-section ${contactosInView || contactosScrolled ? 'visible' : ''}`}>
                <Contactos selectedLanguage={selectedLanguage} />
            </section>
            <Footer className="landing-footer" selectedLanguage={selectedLanguage} />
            
        </div>
    )
}

export default LandingPage;