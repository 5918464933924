import { useEffect, useState } from "react";
import {HiOutlineArrowLeft} from "react-icons/hi";
import "./CoachPaintMenu.css";
import {BiPencil} from "react-icons/bi";
import {BiEraser} from "react-icons/bi";
import { useMediaQuery } from 'react-responsive';
import { BsArrowUpRight } from "react-icons/bs";
import blueBall from "./../CoachPaintAssets/paint-ball.svg";
import baliza from "./../CoachPaintAssets/paint-baliza.svg";
import cone1 from "./../CoachPaintAssets/yellow-cone.svg";
import cone2 from "./../CoachPaintAssets/red-cone.svg";
import cone3 from "./../CoachPaintAssets/blue-cone.svg";
import boneco from "./../CoachPaintAssets/boneco.svg";
import arco1 from "./../CoachPaintAssets/red-arco.svg";
import arco2 from "./../CoachPaintAssets/blue-arco.svg";
import bigCone from "./../CoachPaintAssets/big-cone.svg";
import arrowDownDropdown from "./../CoachPaintAssets/arrow-down-dropdown.svg";
import { BiRectangle } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import player1 from "./../CoachPaintAssets/player1.svg";
import player2 from "./../CoachPaintAssets/player2.svg";
import player3 from "./../CoachPaintAssets/player3.svg";
import player4 from "./../CoachPaintAssets/player4.svg";
import player5 from "./../CoachPaintAssets/player5.svg";
import { useRef } from "react";




function CoachPaintMenu(props) {
    const { t } = useTranslation(['coach-paint']);
    const fieldDropdownRef = useRef(null);
    const [svgDataURL, setSvgDataURL] = useState(null);
    const [fieldDropdown, setFieldDropdown] = useState(false);
    const [currentMessageType, setCurrentMessageType] = useState(null);
    const isTablet = useMediaQuery({ maxWidth: 1100 });
    const [showMessage, setShowMessage] = useState(false);
    const [messageContent, setMessageContent] = useState(null);

    useEffect(() => {
      function handleClickOutside(e) {
        if(fieldDropdown && !fieldDropdownRef.current.contains(e.target))
          setFieldDropdown(false);
      }
      document.addEventListener("click", handleClickOutside);
      return () => {document.removeEventListener("click", handleClickOutside)}
    })

    const material = [{
        image: cone1,
        label: t('cone'),
        id: "cone"
    },
    {
        image: cone2,
        label: t('cone'),
        id: "cone"
    },
    {
        image: cone3,
        label: t('cone'),
        id: "cone"
    },
    {
        image: baliza,
        label: t('goal'),
        id: "baliza"
    }, {
        image: arco1,
        label: t('hoop'),
        id: "aro"
    }, {
        image: arco2,
        label: t('hoop'),
        id: "aro"

    }, {
        image: boneco,
        label: t('barrier'),
        id: "barrier"
    }, {
        image: bigCone,
        label: t('Cone'),
        id: "cone"
    }

    ]

    const playersMaterial = [{
        image: player1,
        label: t('')
    },
    {
        image: player2,
        label: t('')
    },
    {
        image: player3,
        label: t('')
    },
    {
        image: player4,
        label: t('')
    },
    {
        image: player5,
        label: t('')
    }]


    const handleFieldDropdown = () => {
        setFieldDropdown(!fieldDropdown);
    }

    const handleShowMessage = (message) => {
        if (currentMessageType === null) return;
        setMessageContent(message);
        setShowMessage(true);
    };

    useEffect (() => {
        if (currentMessageType !== null) {
            switch (currentMessageType) {
                case "ball":
                    handleShowMessage(t('add-ball'));
                    break;
                case "player":
                    handleShowMessage(t('add-player'));
                    break;
                case "cone" :
                    handleShowMessage(t('add-cone'));
                    break;
                case "aro" :
                    handleShowMessage(t('add-hoop'));
                    break;
                case "baliza":
                    handleShowMessage(t('add-goal'));
                    break;
                case "barrier":
                    handleShowMessage(t('add-barrier'));
                    break;
                case "grab":
                    handleShowMessage(t('select-grab'));
                    break;
                case "square":
                    handleShowMessage(t('add-square'));
                    break;
                case "arrow":
                    handleShowMessage(t('add-arrow'));
                    break;
                case "pen":
                    handleShowMessage(t('select-pen'));
                    break;
                case "eraser":
                    handleShowMessage(t('select-eraser'));
                    break;
                case "color":
                    handleShowMessage(t('change-color'));
                    break;
                case "size":
                    handleShowMessage(t('change-size'));
                    break;
                default:
                    break;
            }
        }
        else {
            setShowMessage(false);
        }
    }, [currentMessageType])

    const handleSvgClick = () => {
        const svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="31" height="33" viewBox="0 0 31 33">
                            <circle cx="15.4451" cy="7.47347" r="7.47347" fill="${props.currentColor}" />

                            <circle cx="15.5" cy="32.8848" r="15.5" fill="${props.currentColor}" />
                            </svg>`;
            // Convert the SVG string to a data URL
        setSvgDataURL(`data:image/svg+xml;base64,${btoa(svgString)}`)
        props.setSelectedStamp(svgDataURL);

    }

    useEffect(() => {
        if (props.selectedStamp !== null) {
            props.setTool(null)
        }
    }, [props.selectedStamp])

    const players = [player1, player2, player3, player4, player5]
    const isSelectedStampInPlayersMaterial = !players.some(item => item === props.selectedStamp);
    const isSelectedStampInMaterialsMaterial = !material.some(item => item.image === props.selectedStamp);

    return (
        <div className="webapp-coach-paint-menu">
            {isTablet ? (
                <div className="webapp-coach-paint-menu-return">
                    <button onClick={props.handleReturn}><HiOutlineArrowLeft/></button>
                    <div className="webapp-coach-paint-menu-name">
                        <div>
                            <h1>{t('drawing')}</h1>
                        </div>
                        <div>
                            <h2>{props.exerciseDescription}</h2>
                            <p>{props.exerciseDescription}</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="webapp-coach-paint-menu-return">
                    <button onClick={props.handleReturn}><HiOutlineArrowLeft/></button>
                    <div className="webapp-coach-paint-menu-name">
                        <h1>{t('drawing')}</h1>
                        <h2>{props.exerciseDescription ? props.exerciseDescription : t('new-exercise')}</h2>
                        <p>{props.exerciseDescription ? props.exerciseDescription : t('description')}</p>
                    </div>
                </div>
                ) }
            <div className="webapp-coach-paint-menu-items">
                <div className="webapp-coach-paint-menu-items-container">
                    <div className="webapp-paint-coach-menu-dropdown" ref={fieldDropdownRef}>
                        <span className="webapp-paint-menu-labels">{t('field')}</span>
                        <div className="webapp-paint-background-select">
                            <div>
                                <img  src={props.selectedCampo.image}></img>
                                <img className="webapp-paint-dropdown-arrow" onClick={() => handleFieldDropdown()} src={arrowDownDropdown}/>
                                {fieldDropdown &&
                                    <div className="webapp-paint-menu-dropdown">
                                    {props.backgroundOptions.map((option, index) => (
                                        option.image !== props.selectedCampo.image && (
                                            <div  onClick={() => props.setSelectedCampo(option) } className={` ${index === props.backgroundOptions.length - 1 ? 'webapp-paint-dropdown-last-item' : 'webapp-paint-dropdown-item'}`} >
                                                <img src={option.image} />
                                                <p className="black-16px-600">{option.label}</p>
                                            </div>
                                        )
                                    ))}
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="webapp-paint-coach-draggable-items">
                        <span className="webapp-paint-menu-labels"
                        onMouseEnter={() => {
                            setCurrentMessageType("ball");
                        }}
                        onMouseLeave={() => {
                            setCurrentMessageType(null);
                        }}>{t('ball')}</span>
                        <span ><img
                        onMouseEnter={() => {
                            setCurrentMessageType("ball");
                        }}
                        onMouseLeave={() => {
                            setCurrentMessageType(null);
                        }}
                        className={`paint-bola-icon ${props.selectedStamp === blueBall ? "canvas-drawing-type-selected" : "" }`} src={blueBall}  onClick={() => props.setSelectedStamp(blueBall)} ></img></span>
                    </div>
                    <MaterialsDropdown dropdownType="players"title={t('player')}  stamps={playersMaterial} checkStamp={isSelectedStampInPlayersMaterial} setCurrentMessageType={setCurrentMessageType} setSelectedStamp={props.setSelectedStamp} selectedStamp={props.selectedStamp}/>
                    <MaterialsDropdown title={t('materials')} stamps={material} checkStamp={isSelectedStampInMaterialsMaterial} setCurrentMessageType={setCurrentMessageType} setSelectedStamp={props.setSelectedStamp} selectedStamp={props.selectedStamp}/>
                </div>
                <div className="webapp-paint-grab-tool">
                    <div>
                        <span></span>
                        <span
                        onMouseEnter={() => {
                            setCurrentMessageType("grab");
                        }}
                        onMouseLeave={() => {
                            setCurrentMessageType(null);
                        }}

                        onClick={() => {
                        props.setTool("grab");
                        props.setSelectedStamp(null);
                        }}>
                            <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.tool==="grab" ? "canvas-drawing-type-selected" : ""} >
                                <path d="M14 31C21.0416 31 26.75 25.4036 26.75 18.5V10.1667C26.75 8.78596 25.6083 7.66667 24.2 7.66667C22.7917 7.66667 21.65 8.78596 21.65 10.1667V5.16667C21.65 3.78596 20.5083 2.66667 19.1 2.66667C17.6917 2.66667 16.55 3.78595 16.55 5.16667V3.5C16.55 2.11929 15.4083 1 14 1C12.5917 1 11.45 2.11929 11.45 3.5V5.16667C11.45 3.78595 10.3083 2.66667 8.9 2.66667C7.49167 2.66667 6.35 3.78595 6.35 5.16667V15.1667C6.35 13.786 5.20833 12.6667 3.8 12.6667C2.39167 12.6667 1.25 13.786 1.25 15.1667V18.5C1.25 25.4036 6.95837 31 14 31Z" fill="#EBEDFF"/>
                                <path d="M6.35 15.1667V19.3333M6.35 15.1667V5.16667C6.35 3.78595 7.49167 2.66667 8.9 2.66667C10.3083 2.66667 11.45 3.78595 11.45 5.16667M6.35 15.1667C6.35 13.786 5.20833 12.6667 3.8 12.6667C2.39167 12.6667 1.25 13.786 1.25 15.1667V18.5C1.25 25.4036 6.95837 31 14 31C21.0416 31 26.75 25.4036 26.75 18.5V10.1667C26.75 8.78596 25.6083 7.66667 24.2 7.66667C22.7917 7.66667 21.65 8.78596 21.65 10.1667M11.45 5.16667V14.3333M11.45 5.16667V3.5C11.45 2.11929 12.5917 1 14 1C15.4083 1 16.55 2.11929 16.55 3.5V5.16667M16.55 5.16667V14.3333M16.55 5.16667C16.55 3.78595 17.6917 2.66667 19.1 2.66667C20.5083 2.66667 21.65 3.78596 21.65 5.16667V10.1667M21.65 10.1667V14.3333" stroke="#6978F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                    </div>

                </div>
                <div className="webapp-paint-menu-square-line">
                    <div>
                        <span></span>
                        <span

                        onMouseEnter={() => {
                            setCurrentMessageType("square");
                        }}
                        onMouseLeave={() => {
                            setCurrentMessageType(null);
                        }}
                        onClick={() => {
                        props.setTool("square");
                        props.setSelectedStamp(null);

                        }}
                        ><BiRectangle  className={props.tool==="square" ? "canvas-drawing-type-selected" : ""} fill="#6978F9"/></span>

                    </div>
                    <div>
                        <span></span>
                        <span
                        onMouseEnter={() => {
                            setCurrentMessageType("arrow");
                        }}
                        onMouseLeave={() => {
                            setCurrentMessageType(null);
                        }}
                        onClick={() => {
                        props.setTool("arrow");
                        props.setSelectedStamp(null);
                        }}><BsArrowUpRight className={props.tool==="arrow" ? "canvas-drawing-type-selected" : ""} fill="#6978F9"/></span>
                    </div>
                </div>
                <div className="webapp-paint-menu-erase-pen">
                    <div>
                        <span></span>
                        <span
                        onMouseEnter={() => {
                            setCurrentMessageType("pen");
                        }}
                        onMouseLeave={() => {
                            setCurrentMessageType(null);
                        }}
                        onClick={() => {
                        props.setTool("pen");
                        props.setSelectedStamp(null);
                        }}>< BiPencil className={props.tool === "pen" ? "canvas-drawing-type-selected" : ""}/></span>
                    </div>
                    <div>
                        <span></span>
                        <span
                        onMouseEnter={() => {
                            setCurrentMessageType("eraser");
                        }}
                        onMouseLeave={() => {
                            setCurrentMessageType(null);
                        }}
                        onClick={() => {
						props.setTool("eraser");
                        props.setSelectedStamp(null);
                        }}><BiEraser className={props.tool === "eraser" ? "canvas-drawing-type-selected" : ""}/></span>
                    </div>
                    <div className="webapp-paint-menu-color-change">
                        <span
                        onMouseEnter={() => {
                            setCurrentMessageType("color");
                        }}
                        onMouseLeave={() => {
                            setCurrentMessageType(null);
                        }}
                        className="webapp-paint-menu-labels">{t('color')}</span>
                        <span>
                            <input
                            onMouseEnter={() => {
                                setCurrentMessageType("color");
                            }}
                            onMouseLeave={() => {
                                setCurrentMessageType(null);
                            }}
                            className="pen-color-change" type="color"value={props.currentColor}
                            onChange={(e) => {
                            props.handleColorChange(e.target.value);
                        }} ></input></span>
                    </div>
                    <div className="webapp-paint-menu-pen-size">
                        <span className="webapp-paint-menu-labels">{t('size')}</span>
                        <span>
                        <input
                        onMouseEnter={() => {
                            setCurrentMessageType("size");
                        }}
                        onMouseLeave={() => {
                            setCurrentMessageType(null);
                        }}
                        className="pen-size-change"
                        type="number"
                        min="1"
                        max="40"
                        value={props.strokeSize}
                        onChange={(e) => {
                            props.handleStrokeSizeChange(e.target.value);
                        }}

                    ></input></span>
                    </div>

                </div>
            </div>
            {showMessage && <MouseMessage message={messageContent}  />}
        </div>
    )

}

export default CoachPaintMenu;


const MaterialsDropdown = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(()=>{
      function handleClickOutside(e) {
       if(setDropdownOpen && !dropdownRef.current.contains(e.target))
         setDropdownOpen(false)
      }
      window.addEventListener("click", handleClickOutside);
      return() => window.removeEventListener("click", handleClickOutside);
    })

    const handleToggleDropdown= (e) => {
      setDropdownOpen(!dropdownOpen);
    }

    return (
      <div className="webapp-paint-coach-materials-dropdown-container" ref={dropdownRef}>
        <span className="webapp-paint-menu-labels"> {props.title} </span>
        <div className="webapp-paint-menu-dropdown-placeholder" onClick={(e) => handleToggleDropdown(e)}>
          <img className={`${!props.checkStamp ? 'canvas-drawing-type-selected' : '' } webapp-paint-stamp-placeholder`} src={(!props.checkStamp) ? props.selectedStamp : props?.stamps[0].image}></img>
          <img className="webapp-paint-dropdown-arrow" src={arrowDownDropdown}/>
          {dropdownOpen === true ? (
            <div className="webapp-coach-paint-materials-dropdown">
                {props.stamps.map((material, index) => (
                        <div key={index} className={`coach-paint-materials-dropdown-row`}
                        onMouseEnter={() => {
                            props.setCurrentMessageType(material?.id);
                        }}
                        onMouseLeave={() => {
                            props.setCurrentMessageType(null);
                        }}
                        onClick={() => props.setSelectedStamp(material?.image)}>
                            <div className="webapp-coach-paint-material-option">
                              <img className={props.selectedStamp===material?.image ? "canvas-drawing-type-selected" : ""} src={material?.image} alt="Image" ></img>
                            </div>
                            {material.label &&
                              <span>{material.label}</span>
                            }
                        </div>
                ))}
            </div>) : null
          }
        </div>
      </div>
    )
}



const MouseMessage = ({ message }) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const updatePosition = (e) => {
        setPosition({ x: e.clientX + 10, y: e.clientY + 10 });
    };

    useEffect(() => {
        document.addEventListener('mousemove', updatePosition);
        return () => document.removeEventListener('mousemove', updatePosition);
    }, []);

    return (
        <div style={{ position: 'absolute', top: position.y, left: position.x, zIndex: 999, backgroundColor:"white" }} className="webapp-coach-paint-explaining-message">
            {message}
        </div>
    );
};
