import "./PlayerInfo.css";

import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Sidebar from "../../webAppComponents/Sidebar/Sidebar";
import arrow from "../../webAppAssets/question-arrow.svg";
import edit from "../../webAppAssets/edit-pencil.svg";

import AuthContext from "../../context/AuthContext";

import { useTranslation } from "react-i18next";

import BckLogo from "../../webAppAssets/BackgroundLogo.svg"

const PlayerInfo = () => {
    const { t } = useTranslation(['players-page']);
    const [player, setPlayer] = useState([]);
    const [age, setAge] = useState(0);
    const [quartil, setQuartil] = useState(0);
    const { id } = useParams();
    const [editMode, setEditMode] = useState(false);

    const [positions, setPositions] = useState([]);
    const [handedness, setHandedness] = useState([]);

    const navigate = useNavigate();

    const { authTokens } = useContext(AuthContext);

    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND + 'users/player/'+id, { headers })
        .then((response) => {
            
            const birthDate = new Date(response.data.dateofbirth);
            const today = new Date();
            let age = today.getFullYear() - birthDate.getFullYear();
            const month = today.getMonth() - birthDate.getMonth();
            if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            //get their birth quartile from the birth month
            let quartile = "";
            if (birthDate.getMonth() <= 2) {
                quartile = "Q1";
            } else if (birthDate.getMonth() <= 5) {
                quartile = "Q2";
            } else if (birthDate.getMonth() <= 8) {
                quartile = "Q3";
            } else {
                quartile = "Q4";
            }
            setAge(age);
            setQuartil(quartile);
            setPlayer(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND + 'users/positions', { headers })
        .then((response) => {
            setPositions(response.data);
        })
        .catch((error) => {
            console.error(error);
        });

        axios.get(process.env.REACT_APP_BACKEND + 'users/handedness', { headers })
        .then((response) => {
            setHandedness(response.data);
        })
        .catch((error) => {
            console.error(error);
        }
        );
    }, []);

    const handleEdit = () => {
        setEditMode(!editMode);
    }

    const handleSave = () => {
        //remove the email from the player object
        //the email is in the user object
        //but we need to send the the rest of the player object to the backend
        const { user, team, ...playerData } = player;
        axios.patch(process.env.REACT_APP_BACKEND + 'users/edit-player/'+id, playerData, { headers })
        .then((response) => {
            setPlayer(response.data);
        })
        .catch((error) => {
            console.error(error);
        });
        setAge(age);
        setQuartil(quartil);
        setEditMode(false);
    }

    const handleSeeInjury = () => {
        //navigate to the injuries page
        navigate("/webapp/injuries", { state: { player: player } })
    }
    const handleSeeEvaluation = () => {
        //navigate to the evaluations page
        navigate("/webapp/evaluations", { state: { player: player } })
    }

    const placeBackgroundImages = () => {
        const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
        const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
        const bckLogo1Height = bckLogo1.offsetHeight;
        bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
        bckLogo2.style.top = `0%`;
        const bckLogo1Width = bckLogo1.offsetWidth;
        bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
        bckLogo2.style.left = `75%`;
    }

    useEffect(() => {
        placeBackgroundImages();
        window.addEventListener('resize', placeBackgroundImages);
        return () => {
            window.removeEventListener('resize', placeBackgroundImages);
        }
    }, [])

    return (
        <div className="webapp-player-info">
            <Sidebar selectedOption="players"/>
            <div className="webapp-player-info-container">
                <div className="webapp-create-team-background-images">
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
                </div>
                <Link to="/webapp/players" className="webapp-player-info-header">
                    <img src={arrow} alt="Voltar"/>
                    <h1>{t('Player')}</h1>
                </Link>
                <div className="webapp-player-info-content">
                    <div className="webapp-player-info-content-main">
                        <h1>{player.user?.first_name} {player.user?.last_name}</h1>
                        <div className="webapp-player-info-content-main-info">
                            <div className="webapp-player-info-content-main-info-column">
                                <div className="webapp-player-info-content-main-info-column-item">
                                    <h4>{t('Birthdate')}</h4>
                                    {editMode ?
                                        <input type="date" value={player.dateofbirth} onChange={(e)=>setPlayer({...player, dateofbirth: e.target.value})}/>
                                        : 
                                        <p>{player.dateofbirth}</p>
                                    }
                                </div>
                                <div className="webapp-player-info-content-main-info-column-item">
                                    <h4>{t('Age')}</h4>
                                    <p>{age}</p>
                                </div>
                                <div className="webapp-player-info-content-main-info-column-item">
                                    <h4>{t('Birth Quartile')}</h4>
                                    <p>{quartil}</p>
                                </div>
                                <div className="webapp-player-info-content-main-info-column-item">
                                    <h4>{t('Email')}</h4>
                                    <p>{player.user?.email}</p>
                                </div>
                            </div>
                            <div className="webapp-player-info-content-main-info-column">
                                <div className="webapp-player-info-content-main-info-column-item">
                                    <h4>{t('Entry Date')}</h4>
                                    {editMode ?
                                        <input type="date" value={player.date_of_entry} onChange={(e)=>setPlayer({...player, date_of_entry: e.target.value})}/>
                                        :
                                        <p>{player.date_of_entry}</p>
                                    }
                                </div>
                                <div className="webapp-player-info-content-main-info-column-item">
                                    <h4>{t('Position')}</h4>
                                    {editMode ?
                                        <select value={player.position} onChange={(e)=>setPlayer({...player, position: e.target.value})} >
                                            {positions.map((position, index) => {
                                                return (
                                                    <option key={index} value={position[0]}>{t(position[0])}</option>
                                                )
                                            }
                                            )}
                                        </select>
                                        :
                                        <p>{t(player.position)}</p>
                                    }
                                </div>
                                <div className="webapp-player-info-content-main-info-column-item">
                                    <h4>{t('Acronym')}</h4>
                                    <p>{t(player.position_acronym)}</p>
                                </div>
                                <div className="webapp-player-info-content-main-info-column-item">
                                    <h4>{t('Laterality')}</h4>
                                    {editMode ?
                                        <select value={player.handedness} onChange={(e)=>setPlayer({...player, handedness: e.target.value})}>
                                            {handedness.map((handedness, index) => {
                                                return (
                                                    <option key={index} value={handedness[0]}>{t(handedness[0])}</option>
                                                )
                                            }
                                            )}
                                        </select>
                                        :
                                        <p>{t(player.handedness)}</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`webapp-player-info-content-main-buttons ${editMode && "webapp-player-info-content-main-buttons-editmode"}`}>
                            <button onClick={handleSeeInjury} className="webapp-player-info-content-main-button">{t('Injuries')}</button>
                            <button onClick={handleSeeEvaluation} className="webapp-player-info-content-main-button">{t('Avaliations')}</button>
                        </div>
                    </div>
                    {editMode ? 
                        <button className="webapp-player-info-content-button" onClick={handleSave}>Guardar Alterações</button>
                    :
                        <button className="webapp-player-info-content-button" onClick={handleEdit}> <img src={edit}/> Editar</button>
                    }
                </div>
            </div>
        </div>
    );
}

export default PlayerInfo;