import "./MoveToFolderPopup.css";

import { useTranslation } from "react-i18next";
import FolderBreadCrumb from '../FoldersBreadCrumb/FoldersBreadCrumb';
import { Folder } from '../TrainingExerciseFolders/TrainingExerciseFolders'
import { useState, useEffect, useContext } from 'react';

import AuthContext from '../../context/AuthContext';
import axios from 'axios';

const MoveToFolderPopup = (props) => {
    const { t } = useTranslation(['training-page']);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [folders, setFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);

    const { authTokens } = useContext(AuthContext);

    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    const getPageItems = () => {
        if (selectedFolder === null) {
            setCurrentFolder(null)
            try {
                axios.get(process.env.REACT_APP_BACKEND + 'training/folder/list?parent_folder__isnull=True', { headers })
                    .then((response) => {
                        (response.data)
                        // Handle the successful response here
                        setFolders(response.data)

                    })
                    .catch((error) => {
                        // Handle any errors that occurred during the request
                        console.error('Error:', error);
                    });
            }
            catch (error) {
                console.log(error);
            }
        } else {
            // Get the current folder
            try {
                axios.get(process.env.REACT_APP_BACKEND + 'training/folder/' + selectedFolder, { headers })
                    .then((response) => {
                        // Handle the successful response here
                        setCurrentFolder(response.data)
                    })
                    .catch((error) => {
                        // Handle any errors that occurred during the request
                        console.error('Error:', error);
                    });
            }
            catch (error) {
                console.log(error);
            }
            // Get the folders and trainings inside the selected folder
            try {
                axios.get(process.env.REACT_APP_BACKEND + 'training/folder/list?parent_folder=' + selectedFolder, { headers })
                    .then((response) => {
                        // Handle the successful response here
                        setFolders(response.data)

                    })
                    .catch((error) => {
                        // Handle any errors that occurred during the request
                        console.error('Error:', error);
                    });
            }
            catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        getPageItems();

    }, [selectedFolder])

    const handleFolderSelection = (id) => {
        // set selected folder the folder with the same id as the one clicked
        setSelectedFolder(id? id : null)
    }

    const closePopup = () => {
        props.setState(false);
    }

    const handleBreadCrumbNavigate = (folder) => {
        if (folder === null) {
            handleFolderSelection(null)
        }else{
            handleFolderSelection(folder.id)
        }
    }


    return (
        <div className="webapp-move-to-folder-popup-background">
            <div className="webapp-move-to-folder-popup">
                {/*<div className="webapp-move-to-folder-popup-header">
                    <span className="black-24px-600">{t('Move to Folder')}</span>
                </div>*/}
                <div className='create-session-training-items'>
                    <FolderBreadCrumb folder={currentFolder} handleBreadCrumbNavigate={handleBreadCrumbNavigate} />
                    <div className="create-session-popup-folders webapp-move-to-folder-popup-content">
                        {folders.map((folder) => (
                            <Folder key={folder.id} folder={folder} InPopup={true} handleFolderSelection={handleFolderSelection}/> 
                        ))} 
                    </div>
                </div>
                <div className="webapp-popup-buttons">
                    <button className="webapp-createteam-button webapp-createteam-white-button" onClick={closePopup}>{t('Cancel')}</button>
                    <button className="webapp-createteam-button webapp-createteam-blue-button" onClick={()=>props.addToFolder(currentFolder?.id)}>{t('Move')}</button>
                </div>
                
            </div>
        </div>
    )
}

export default MoveToFolderPopup;
