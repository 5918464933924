import React, { useState, useEffect, useContext } from 'react';
import TreinoChartComponent from '../../webAppComponents/ChartsTreinosSessoes/index';
import TreinoChartComponent2 from '../../webAppComponents/ChartsTreinosSessoes/index2';
import Sidebar from '../../webAppComponents/Sidebar/Sidebar';
import TrainingsPageChanger from '../../webAppComponents/TrainingsPageChanger/TrainingsPageChanger';
import './Treinos-Sessoes.css';
import { FaCircleArrowLeft } from "react-icons/fa6";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import { SessionPageHeader } from '../SessionUC/SessionUC';
import { useTranslation } from 'react-i18next';

function TreinosSessoesPage() {
  const { t } = useTranslation(['session-page']);
  const { state } = useLocation()
  const [fetchedData, setFetchedData] = useState([]);
  const [sessionData, setSessionData] = useState({
    session_number: state.dataLoaded ? state.dataLoaded.session_number : "",
    date: state.dataLoaded ? state.dataLoaded.date : "",
    microcycle: {
      cycle_number: state.dataLoaded ? state.dataLoaded.microcycle.cycle_number : "",
    },
    completed: state.dataLoaded ? state.dataLoaded.completed : "",
  });

  const { authTokens } = useContext(AuthContext);

  const headers = {
    Authorization: `Bearer ${authTokens.access}`,
  };

  const extractDate = (dateTimeString) => {
    if (!dateTimeString) return '';
    return dateTimeString.split('T')[0];
  };

  useEffect(() => {
    try {
      axios.get(`${process.env.REACT_APP_BACKEND}training/session/${state.session}`, { headers });
      setFetchedData(response.data);


    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }, []);

  return (
    <div className="pretreino-page">
      <Sidebar selectedOption="trainings" />
      <div className="webapp-sessioninfo-content">
        <SessionPageHeader sessionData={sessionData} />
        <TrainingsPageChanger
          selectedPage={2}
          pages={[
            { label: t('Training details'), path: '/webapp/session/trainings', state: { "session": state.session, "dataLoaded": sessionData } },
            { label: t('Load Unit'), path: '/webapp/SessionUC', state: { "session": state.session, "dataLoaded": sessionData } },
            { label: t('Forms'), path: '/webapp/session/forms', state: { "session": state.session, "dataLoaded": sessionData } },
            { label: t('Players'), path: '/webapp/SessionEditPlayers', state: { "session": state.session, "dataLoaded": sessionData } },
          ]}
        />
        <div className="chartComponents">
          <TreinoChartComponent sessionID={state.session} />
          <TreinoChartComponent2 sessionID={state.session} />
        </div>
      </div>
    </div>
  );
}

export default TreinosSessoesPage;
