import './PlayerOptions.css';
import setinha from "../../webAppAssets/setinha.svg";
import { useState, useContext, useRef } from "react";
import AuthContext from '../../context/AuthContext'
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const UserMenu = ({ setPassword }) => {
    const { t } = useTranslation(['player-forms']);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const { logoutUser, authTokens } = useContext(AuthContext);
    const [username, setUsername] = useState("");


    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND + 'users/player-info', { headers })
            .then((response) => {
                setUsername((response.data.user.first_name + " " + response.data.user.last_name))
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [])

    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    return (
        <div>
            <div className="sep" onClick={() => setDropdownVisible(!dropdownVisible)}>
                <div className='name-text'>{username}</div>
                <img className={`${dropdownVisible && 'sep-open'}`} src={setinha} />
            </div>
            {dropdownVisible && (
                <div className="user-menu">
                    <button className="logout-button" onClick={logoutUser}>{t('Log out')}</button>
                    <div className="divider" />
                    <button className="edit-password-button" onClick={() => setPassword()}>{t('Edit Password')}</button>
                </div>
            )}
        </div>
    );
};

export default UserMenu;