import React, {useState, useEffect} from "react";
import "./Questions.css"
import joelImage from "../../assets/joel-image.png"
import { useMediaQuery } from 'react-responsive';
import { Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import academyLogoBackground from "../../assets/academy-three.svg"
import {useInView} from "react-intersection-observer";

const Questions = (props) => {

    const isMobile = useMediaQuery({ maxWidth: 900 });
    const centerMode =useMediaQuery({ minWidth: 450 });
    
    const questionsLanguageDict = {
        PT: {	
            questionsMobileText1: 'Passando do científico para o prático e dando acesso ao',
            questionsMobileText2: '3DTC',
            questionsMobileText3: 'aos treinadores através de tecnologia, conseguimos responder a perguntas como:',
            questionsText1: 'Passando do científico para o prático e dando acesso ao',
            questionsText2: '3DTC',
            questionsText3: 'aos treinadores',
            questionsText4: 'através de tecnologia, conseguimos responder a perguntas como:',
        
            question1: 'Qual é o nível de especificidade dos meus treinos?',
            question2: 'Qual é o tempo efetivo das suas sessões de treino?',
            question3: 'Qual o papel da especificidade nas lesões?',
            question4:  'Qual é o contexto que favorece o aparecimento de lesões nos treinos?',
            question5:  'Qual é o equilíbrio de carga ideal para os meus jogadores?',
            question6:  'Como pode um treinador otimizar as suas sessões de treino?',
            question7:  'Como posso tomar decisões objetivas e específicas sobre o treino?',
            question8:  'Criar treinos complexos é criar treinos específicos?',
            question9:  'Criar treinos simples é criar treinos inespecíficos?',
        
        
            questionsMobile2Text1: 'Todas estas perguntas encontrarão as suas respostas no',
            questionsMobile2Text2:  '3DTC',
            questionsMobile2Text3:  ' juntamente com outras questões de interesse para treinadores de todo o mundo.',     
        
            questions2Text1: 'Todas estas perguntas encontrarão as suas respostas no',
            questions2Text2: '3DTC',
            questions2Text3:  'juntamente,', 
            questions2Text4: 'com outras questões de interesse para treinadores de todo o mundo.',

            joelCardName: 'Joel Barrera-Diaz',
            joelCardText1: 'Doutor em Ciências do Desporto, Treinador',
            joelCardText2: 'de Futebol, empreendedor e seguidor dos seus',
            joelCardText3: 'sonhos com trabalho e dedicação.',

            joelCardQuote1: '“Whether you think you can, or you think',
            joelCardQuote2: 'you can`t – you`re right”  Henry Ford',
        },
        ES: {
            questionsMobileText1: ' Pasando de lo científico a lo práctico y dando acceso a los entrenadores al',
            questionsMobileText2: ' 3DTC',
            questionsMobileText3: ' a los entrenadores por medio de la tecnología, conseguiremos das respuestas a las siguientes preguntas.',
            questionsText1: ' Pasando de lo científico a lo práctico y dando acceso al',
            questionsText2: ' 3DTC',
            questionsText3: ' a los entrenadores',
            questionsText4: ' por medio de la tecnología, conseguiremos das respuestas a las siguientes preguntas.',
        
            question1:  '¿Cuál es el nivel de especificidad de mis entrenamientos?',
            question2:  '¿Cuál es el tiempo efectivo de sus sesiones de entrenamiento?',
            question3:  '¿Cuál es el papel de la especificidad en las lesiones?',
            question4:  '¿Cuál es el contexto que favorece la aparición de lesiones en los entrenamientos?',
            question5:  '¿Cuál es el equilibrio de carga ideal para mis jugadores?',
            question6:  '¿Cómo puede un entrenador optimizar sus sesiones de entrenamiento?',
            question7:  '¿Cómo puedo tomar decisiones objetivas y específicas sobre el entrenamiento?',
            question8:  '¿Crear entrenamientos complejos es crear entrenamientos específicos?',
            question9:  '¿Crear entrenamientos simples es crear entrenamientos inespecíficos?',
        
        
            questionsMobile2Text1: 'Todas estas preguntas encontrarán sus respuestas en',
            questionsMobile2Text2:  ' 3DTC',
            questionsMobile2Text3:  ' junto con otras preguntas de interés para entrenadores de todo el mundo.',
        
            questions2Text1:    'Todas estas preguntas encontrarán sus respuestas en',
            questions2Text2:    ' 3DTC', 
            questions2Text3:    ' junto con',
            
            questions2Text4:    ' otras preguntas de interés para entrenadores de todo el mundo.',

            joelCardName: 'Joel Barrera-Diaz',
            joelCardText1:  'Doctor en Ciencias del Deporte, Entrenador',
            joelCardText2:  'de Fútbol, emprendedor y seguidor de sus',
            joelCardText3:  'sueños con trabajo y dedicación.',

            joelCardQuote1: '“Whether you think you can, or you think',
            joelCardQuote2: 'you can`t – you`re right”  Henry Ford',
        },
        EN: {
            questionsMobileText1:   'By moving from the scientific to the practical and giving',
            questionsMobileText2:   ' coaches access to 3DTC',
            questionsMobileText3:   ` through technology, we've been able to answer questions such as`,
            questionsText1:        'By moving from the scientific to the practical and giving',
            questionsText2:     ' coaches acces to 3DTC',
            questionsText3:     '',
            questionsText4:    ' through technology, we can answer questions like:',
        
            question1:  'What is the level of specificity of my training?',
            question2:  'What is the effective time of your training sessions?',
            question3:  'What is the role of specificity in injuries?',
            question4:      'What is the context that favors the appearance of injuries in training?',       
            question5:  'What is the ideal load balance for my players?',
            question6:  'How can a coach optimize their training sessions?',
            question7:  'How can I make objective and specific decisions about training?',
            question8:  'Creating complex training is creating specific training?',
            question9:  'Creating simple training is creating non-specific training?',
        
        
            questionsMobile2Text1:  'All these questions are answered in',
            questionsMobile2Text2:  ' 3DTC',
            questionsMobile2Text3:  ' along with other questions of interest to coaches around the world.',
        
            questions2Text1:    'All these questions will find their answers in',
            questions2Text2:    ' 3DTC',
            questions2Text3:    ' along with',
            questions2Text4:    ' other questions of interest to coaches around the world.',

            joelCardName:    'Joel Barrera-Diaz',
            joelCardText1:  'Doctor in Sports Sciences, Football Coach,',
            joelCardText2:  'entrepreneur and follower of his dreams with',
            joelCardText3:  'work and dedication.',

            joelCardQuote1: '“Whether you think you can, or you think',
            joelCardQuote2: `you can't – you're right”  Henry Ford`,     
        }
    };

    const defaultThreshold = 0.1;
    const mobileThreshold = 0.0001; 

   
    const questionsThreshold = isMobile ? mobileThreshold : defaultThreshold;

    const [questionsRef, questionsInView] = useInView({ threshold: questionsThreshold });
  
 

    const [questionsScrolled, setQuestionsScrolled] = useState(false);


    const handleScroll = () => {
        if ( questionsInView && !questionsScrolled) {
            setQuestionsScrolled(true);
        }
        
    }
    
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, [questionsInView]);




    return (
        <div className="questions-background">
            
            <div className="questions-page">
            <img className="questions-first-logo-background" src= {academyLogoBackground} alt="Background" />
            <section id="questions-section-items" ref={questionsRef} className={`questions-section-items ${questionsInView || questionsScrolled ? 'visible' : ''}`}>  
            <div>
                {isMobile ? (<h4>{questionsLanguageDict[props.selectedLanguage].questionsMobileText1}<span className="strong-text">{questionsLanguageDict[props.selectedLanguage].questionsMobileText2}</span> {questionsLanguageDict[props.selectedLanguage].questionsMobileText3}</h4>)
                : (<h4 >{questionsLanguageDict[props.selectedLanguage].questionsText1} <span className="strong-text">{questionsLanguageDict[props.selectedLanguage].questionsText2}</span> {questionsLanguageDict[props.selectedLanguage].questionsText3}<p>{questionsLanguageDict[props.selectedLanguage].questionsText4}</p></h4>)}
                
                {!isMobile ? (<div className="questions-grid">
                   
                    <div className="question-card"><p>{questionsLanguageDict[props.selectedLanguage].question1}</p></div>
                    <div className="question-card"><p>{questionsLanguageDict[props.selectedLanguage].question2}</p></div>
                    <div className="question-card"><p>{questionsLanguageDict[props.selectedLanguage].question3}</p></div>
                    <div className="question-card"><p>{questionsLanguageDict[props.selectedLanguage].question4}</p></div>
                    <div className="question-card"><p>{questionsLanguageDict[props.selectedLanguage].question5}</p></div>
                    <div className="question-card"><p>{questionsLanguageDict[props.selectedLanguage].question6}</p></div>                
                    <div className="question-card"><p>{questionsLanguageDict[props.selectedLanguage].question7}</p></div>
                    <div className="question-card"><p>{questionsLanguageDict[props.selectedLanguage].question8}</p></div>
                    <div className="question-card"><p>{questionsLanguageDict[props.selectedLanguage].question9}</p></div>
                    <div className="question-card hidden"><p></p></div>
                
                </div> ) : (
                    <div className="questions-carousel">
                        <Carousel
                        infiniteLoop={true}
                        autoPlay={true}
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={true}
                        stopOnHover={true}
                        transitionTime={500}
                        interval={20000}
                        thumbWidth={90}
                        showArrows={false}
                        swipeable={true}
                        centerMode={centerMode} 
                        

                        >
                        <div className="question-card"><span className="number-circle">1</span><p>{questionsLanguageDict[props.selectedLanguage].question1}</p></div>
                        <div className="question-card"><span className="number-circle">2</span><p>{questionsLanguageDict[props.selectedLanguage].question2}</p></div>
                        <div className="question-card"><span className="number-circle">3</span><p>{questionsLanguageDict[props.selectedLanguage].question3}</p></div>
                        <div className="question-card"><span className="number-circle">4</span><p>{questionsLanguageDict[props.selectedLanguage].question4}</p></div>
                        <div className="question-card"><span className="number-circle">5</span><p>{questionsLanguageDict[props.selectedLanguage].question5}</p></div>
                        <div className="question-card"><span className="number-circle">6</span><p>{questionsLanguageDict[props.selectedLanguage].question6}</p></div>                
                        <div className="question-card"><span className="number-circle">7</span><p>{questionsLanguageDict[props.selectedLanguage].question7}</p></div>
                        <div className="question-card"><span className="number-circle">8</span><p>{questionsLanguageDict[props.selectedLanguage].question8}</p></div>
                        <div className="question-card"><span className="number-circle">9</span><p>{questionsLanguageDict[props.selectedLanguage].question9}</p></div>

                        </Carousel>
                    
                    </div>
                ) }
            </div>
            <div>
                {isMobile ? (
                    <h4>{questionsLanguageDict[props.selectedLanguage].questionsMobile2Text1} <span className="strong-text">{questionsLanguageDict[props.selectedLanguage].questionsMobile2Text2}</span>{questionsLanguageDict[props.selectedLanguage].questionsMobile2Text3}</h4>
                ): (<h4>{questionsLanguageDict[props.selectedLanguage].questions2Text1} <span className="strong-text">{questionsLanguageDict[props.selectedLanguage].questions2Text2}</span> {questionsLanguageDict[props.selectedLanguage].questions2Text3} <p>{questionsLanguageDict[props.selectedLanguage].questions2Text4} </p></h4>)}
                
                <div className="joel-card">
                    <div className="joel-card-info">
                        <img className="joel-card-image" src= {joelImage}></img>
                        <div className="joel-card-info-container">
                            <h5>{questionsLanguageDict[props.selectedLanguage].joelCardName}</h5>
                            <p className="joel-text-color">{questionsLanguageDict[props.selectedLanguage].joelCardText1} <p>{questionsLanguageDict[props.selectedLanguage].joelCardText2} </p>{questionsLanguageDict[props.selectedLanguage].joelCardText3}</p>
                        </div>
                    </div>
                    <div>
                        <p className="joel-card-quote">{questionsLanguageDict[props.selectedLanguage].joelCardQuote1}<p>{questionsLanguageDict[props.selectedLanguage].joelCardQuote2}</p></p>
                    </div>
                </div>
            </div>
            </section>  
            </div>
        
        </div>
    )
}

export default Questions;