import "./FilterInjuriesStats.css";

import { useState, useEffect } from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import arrow from "../../webAppAssets/setinha.svg";

const FilterInjuriesStats = ({options, updateSelected, none}) => {
    const { t } = useTranslation(["injuries-page"]);
    const [open, setOpen] = useState(false);

    const changeSelected = (event) => {
        const option = event.target.value;
        updateSelected(option);
    }

    useEffect(() => {
        const is = document.querySelector(".webapp-filter-is");
        const filters = document.querySelector(".webapp-filter-is-filters");
        if(!is || !filters) return;

        filters.style.top = `calc(${is.offsetHeight}px + 0.5rem)`;
    }, [open]);

    document.addEventListener("click", (event) => {
        //if the click is outside the filter, close it
        if(open && !event.target.closest(".webapp-filter-is")) {
            setOpen(false);
        }
    });

    return (
        <div className="webapp-filter-is">
            <button className="webapp-filter-is-name" onClick={()=>setOpen(!open)}><h4>{t("View Mode")}</h4> <img src={arrow} className={`webapp-filter-is-button ${open && "webapp-filter-is-button-open"}`}/></button>
            <div className={`webapp-filter-is-filters ${open && "webapp-filter-is-filters-open"}`}>
                {/*<div className="webapp-filter-is-filter">
                    <input type="checkbox" id="checkbox-1" value="all" checked={none} readOnly/>
                    <label htmlFor="checkbox-1">{t("all")}</label>
                </div>*/}
                {options.map((option) => {
                    if(option !== "Total")
                    return (
                        <label htmlFor={`checkbox-${option}`} className="webapp-filter-is-filter" key={option} onClick={changeSelected}> <input type="checkbox" id={`checkbox-${option}`} value={option}/> {t(option)}</label>
                    );
                })}
            </div>
        </div>
    );
}

export default FilterInjuriesStats;