import './FoldersBreadCrumb.css';   
import { useTranslation } from "react-i18next";

const FolderBreadCrumb = ({folder, handleBreadCrumbNavigate}) => {
    const { t } = useTranslation(['training-page']);

    if (folder === null) {
        return (
            <h2 className='webapp-folders-breadcrumb-header'> <span>{t('Folders')} </span> </h2>
        )
    } 

    return (        
        <h2 className='webapp-folders-breadcrumb-header'> <span className="webapp-folders-breadcrumb-active-path" onClick={() => handleBreadCrumbNavigate(null)} >{t('Folders')}</span> {
            folder.breadcrumb.map(folderInside => {
                // make both integers
                if (folder.id === folderInside.id) {
                    return (
                        <div className='webapp-breadcrumb-path-holder'>
                            <span className="webapp-breadcrumb-arrow">&ensp;&gt;&ensp;</span> <span>{folderInside.name}</span>
                        </div>
                    )
                } else {
                    return (
                        <div className='webapp-breadcrumb-path-holder'>
                            <span className="webapp-breadcrumb-arrow">&ensp;&gt;&ensp;</span><span className="webapp-folders-breadcrumb-active-path" onClick={() => handleBreadCrumbNavigate(folderInside)}>{folderInside.name}</span>
                        </div>
                    )
                }
            })
        } </h2>
    )
}

export default FolderBreadCrumb;

