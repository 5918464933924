import React, { useState, useEffect, useContext } from 'react';
import './EvaluationsStatistics.css';
import axios from 'axios';
import Sidebar from '../../webAppComponents/Sidebar/Sidebar';
import AuthContext from '../../context/AuthContext';
import { useTranslation } from "react-i18next";

import BckLogo from '../../webAppAssets/BackgroundLogo.svg';

import TrainingsPageChanger from '../../webAppComponents/TrainingsPageChanger/TrainingsPageChanger'
import GraphsEvaluations from '../../webAppComponents/GraphsEvaluations/GraphsEvaluations';
import { use } from 'i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EvaluationsStatistics() {
    const [graphicsData,setGraphicsData] =useState({
        labels: ["",],
        graph1: {
          bar1: [],
          bar2: [],
          bar3: [],
          bar4: []
        },
        graph2: {
          line: [],
          bar1: [],
          bar2: []
        },
        graph3: {
          line: [],
          bar1: [],
        }
      })

  const { t } = useTranslation(['evaluations-page']);
  const [ verticalFilters, setVerticalFilters] = useState([
    {"name": "Position", "content": [{"value": "Goalkeeper", "label":"GK"},{"value": "Centre-back", "label":"CB"},{"value": "Left-back", "label":"LB"},{"value": "Right-back", "label":"RB"},{"value": "Defensive Midfielder", "label":"CDM"},{"value": "Central midfielder", "label":"CM"},{"value": "Attacking midfielder", "label":"CAM"},{"value":"Left midfielder", "label":"LM"},{"value":"Right midfielder","label":"RM"},{"value": "Left winger", "label":"LW"},{"value": "Right winger", "label":"RW"},{"value":"Center forward", "label":"CF" },{"value": "Striker", "label":"ST"}]},
    {"name" : "Name", "content":[]}]);


  const [ selectedVerticalFilters, setselectedVerticalFilters] = useState({
    "Position" : [],
    "Name" : []
  })

  const [teamData, setTeamData] = useState([]);
  const [playerData, setPlayerData] = useState([]); // evaluations of all players
  const [rendering, setRendering] = useState(true);
  const [selectedPlayer, setSelectedPlayer] = useState("");
  const [selectedPosition, setSelectedPosition] = useState(null);




  const { authTokens } = useContext(AuthContext);

  // Define the headers with the Bearer token
  const headers = {
    Authorization: `Bearer ${authTokens.access}`,
  };



  const getData = () => { 
      // Make a GET request for player evaluations
      axios.get(
        process.env.REACT_APP_BACKEND + 'evaluations' + `?player=${location.state && rendering ? location.state.player.user.id : selectedPlayer}`, 
        { headers }
      )
      .then((response) => {
        const data = response.data;
        setPlayerData(data);
        //console.log("Player data:", playerData);  
  
      // After fetching evaluations, make another request to get team info
      axios.get(process.env.REACT_APP_BACKEND + 'users/list-players', { headers })
      .then((teamResponse) => {
        const teamData = teamResponse.data;
        setTeamData(teamData); // Set teamData state
        //console.log("Team data:", teamData);
  
        // Add player info to the filters using the fetched team data
        const newFilters = [...verticalFilters];
        
        // Populate the filter with the fetched team data (position acronym and player names) and with Positions
        teamData.map((player) =>{
          //console.log(player)
          if (!newFilters[1].content.some(item => item.value === player.user.id)){ // for some reason players are also duplicate this avoids that
            newFilters[1].content.push({value: player.user.id, label: `${player.user.first_name} ${player.user.last_name}`, pos: player.position });
          }
          if (!newFilters[0].content.some(item => item.value === player.position)) { // avoid position repetition
            newFilters[0].content.push({ value: player.position, label: player.position_acronym });
          }
        })
        
        //console.log(newFilters)
        
        setVerticalFilters(newFilters); // Update vertical filters
        //console.log("Updated vertical filters:", newFilters);
      })
      .catch((error) => {
        console.error("Error fetching team data:", error);
      });
    })
    .catch((error) => {
      console.error("Error fetching player evaluations:", error);
    });
  
  }


  // Uncheck all inputs for a specific filter type (e.g., Name filter)
  const uncheckInputs = (filterName) => {
    // Find all inputs with the matching name attribute
    const inputs = document.querySelectorAll(`input[name="${filterName}"]`);

    // Loop over the NodeList and set checked to false for each
    inputs.forEach(input => {
      input.checked = false;
    });
  };



  const handleVerticalFilter = (filterName, value) => {
    if (filterName === "Position") {
      //console.log("Position filter selected:", value);
  
      // Update the selected position
      setSelectedPosition(value);
  
      // Use functional updates to immediately update filters, ensuring the Name filter is cleared
      setselectedVerticalFilters(prevFilters => ({
        ...prevFilters,
        [filterName]: [value],
      }));

      renderGraphicsData(false, null);
  
      // Filter players based on the selected position
      const filteredPlayers = teamData.filter(player => player.position === value);
  
      // Find the Name filter and update it with the filtered players based on position
      const updatedVerticalFilters = verticalFilters.map(filter => {
        if (filter.name === "Name") {
          return {
            ...filter,
            content: filteredPlayers.map(player => ({
              value: player.user.id,
              label: `${player.user.first_name} ${player.user.last_name}`,
              pos: player.position,
            })),
          };
        }
        return filter;
      });

      // Clear selected player to reset the Name filter
      setSelectedPlayer("");

      // Update the vertical filters to trigger the re-render
      setVerticalFilters(updatedVerticalFilters);
      uncheckInputs("Name");
    } else if (filterName === "Name") {
      const nameFilter = verticalFilters.find(filter => filter.name === "Name");
  
      // Find the selected player
      const selectedPlayerData = nameFilter.content.find(player => player.value === Number(value));
  
      if (selectedPlayerData) {
        const playerId = selectedPlayerData.value;
        const playerLabel = selectedPlayerData.label;
  
        // Update selected filters with the player data (both label and id)
        setselectedVerticalFilters(prevFilters => ({
          ...prevFilters,
          [filterName]: [{ id: playerId, label: playerLabel }],
        }));
  
        // Find player evaluation data and render graphics
        const playerEval = playerData.find(player => player.user_id === playerId);
  
        if (playerEval) {
          renderGraphicsData(true, playerEval);
        } else {
          renderGraphicsData(false, null);
          //console.log("Player has no evaluations.");
          toast.error(t("Player has no evaluations"));
        }
        }
    } else {
      // Handle other filters (if any)
      setselectedVerticalFilters(prevFilters => ({
        ...prevFilters,
        [filterName]: [value],
      }));
    }
  };
    

  const renderGraphicsData = (flag, playerEval) => {
    //console.log("Flag:", flag, "Player eval:", playerEval);
  
    if (flag && playerEval) {
      const updatedGraphicsData = {
        labels: [t(playerEval.position || "N/A")], // Ensure the position label exists
        graph1: {
          bar1: [playerEval.ten_meters],  
          bar2: [playerEval.thirty_meters],
          bar3: [playerEval.twenty_metersL],
          bar4: [playerEval.zig_zag]
        },
        graph2: {
          line: [playerEval.imc],  
          bar1: [playerEval.heightP],
          bar2: [playerEval.weight]
        },
        graph3: {
          line: [playerEval.yo_yoP],  
          bar1: [playerEval.sj],
          bar2: [playerEval.cmj]
        }
      };
      
      setGraphicsData(updatedGraphicsData);  // Trigger re-render with updated data
  
    } else {
      // Reset if no data found or flag is false
      setGraphicsData({
        labels: [],
        graph1: { bar1: [], bar2: [], bar3: [], bar4: [] },
        graph2: { line: [], bar1: [], bar2: [] },
        graph3: { line: [], bar1: [], bar2: [] }
      });
    }
  }
  
  const placeBackgroundImages = () => {
    const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
    const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
    const bckLogo1Height = bckLogo1.offsetHeight;
    bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
    bckLogo2.style.top = `0%`;
    const bckLogo1Width = bckLogo1.offsetWidth;
    bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
    bckLogo2.style.left = `75%`;
  }

  useEffect(() => {
    getData();
  },[]);

  useEffect(() => {
    // Select all elements with class 'webapp-db-filters-clickables'
    const scrollableDivs = document.querySelectorAll('.webapp-db-filters-clickables');

    // Loop through each element and apply overflow-y: scroll
    scrollableDivs.forEach(div => {
        div.style.overflowY = 'scroll';
        div.style.height = '18rem';  //
    });
    

    placeBackgroundImages();
    window.addEventListener('resize', placeBackgroundImages);
    return () => {
        window.removeEventListener('resize', placeBackgroundImages);
    }

  }, []);

  return (
    <div className="webapp-evaluations-page-scroll">
        <Sidebar selectedOption="evaluations" />
        <div className="webapp-evaluations-page">
            <TrainingsPageChanger
                selectedPage={1}
                pages={[
                    { label: t('Total'), path: '/webapp/evaluations' },
                    { label: t('Statistics'), path: '/webapp/evaluations-statistics' },
                ]}
            />
            <div className="webapp-create-team-background-images">
                <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
                <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
            </div>
            <div className="webapp-evaluations-page-header">
                <h1>{t('Evaluations Statistics')}</h1>
            </div>
            <GraphsEvaluations data={graphicsData} filters={verticalFilters} handle={handleVerticalFilter} type={"radio"}/>
        </div>
    </div>
  );
}

export default EvaluationsStatistics;
