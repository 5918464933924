import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import react, { useState, useContext, useEffect } from 'react'
import AuthContext from '../../context/AuthContext';
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { HiOutlineArrowLeft, HiOutlineCurrencyEuro } from 'react-icons/hi';
import { useNavigate, useLocation } from 'react-router-dom';
import RedTrash from "../../webAppAssets/red-trash.svg"
import "./CreateEvaluationsPage.css"
import axios from 'axios'
import CreateEvaluationElements from '../../webAppComponents/CreateEvaluationElements/CreateEvaluationElements';

import { useTranslation } from 'react-i18next';

import BckLogo from "../../webAppAssets/BackgroundLogo.svg"



const CreateEvaluationsPage = () => {

    const { t } = useTranslation(['evaluations-page']);
    const { authTokens } = useContext(AuthContext);
    const location = useLocation();
    const [editMode, setEditMode] = useState(location.state ? location.state.editMode : false)
    const [evaluationData, setEvaluationData] = useState({
        player: (location.state && editMode) ? location.state.evaluation.player.user.id : null,
        heightP: (location.state && editMode) ? location.state.evaluation.heightP : null,
        heightS: (location.state && editMode) ? location.state.evaluation.heightS : null,
        largeP: (location.state && editMode) ? location.state.evaluation.largeP : null,
        weight: (location.state && editMode) ? location.state.evaluation.weight : null,
        cmj: (location.state && editMode) ? location.state.evaluation.cmj : null,
        sj: (location.state && editMode) ? location.state.evaluation.sj : null,
        ten_meters: (location.state && editMode) ? location.state.evaluation.ten_meters : null,
        twenty_metersL: (location.state && editMode) ? location.state.evaluation.twenty_metersL : null,
        thirty_meters: (location.state && editMode) ? location.state.evaluation.thirty_meters : null,
        zig_zag: (location.state && editMode) ? location.state.evaluation.zig_zag : null,
        yo_yoP: (location.state && editMode) ? location.state.evaluation.yo_yoP : null
    })
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [openSelectPlayer, setOpenSelectPlayer] = useState(false);
    const [players, setPlayers] = useState([]);
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [evaluationId, setEvaluationId] = useState(location.state ? location.state.evaluation.id : null);
    const [missingFields, setMissingFields] = useState([]);


    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    const handleOpenSelectPlayer = () => {
        setOpenSelectPlayer(!openSelectPlayer);
    }

    const changeSelectedPlayer = (id) => {
        setSelectedPlayer(id);
        setOpenSelectPlayer(false);
    }

    const handleDelete = async () => {
        try {
            const response = await axios.delete(process.env.REACT_APP_BACKEND + 'evaluations/delete/' + evaluationId, { headers });
            if (response.status === 204) {
                navigate('/webapp/evaluations')
            } else {
                console.log("Error")
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleChangeEvaluationData = (value, e) => {
        setEvaluationData({ ...evaluationData, [value]: e.target.value })
        const index = missingFields.indexOf(value)
        if (index != -1) {
            missingFields.splice(index, 1);
        }
    }

    const previousStep = () => {
        setStep(step - 1)
    }
        

    const handleSelectStep = (step) => {
        setStep(step)
    }

    const checkIfAllFieldsAreNull = (evaluationData) => {
        let count = 0;
        for (const key in evaluationData) {
            if (evaluationData[key] == null || evaluationData[key] === "") {
                count++;
            }
        }

        return count === (Object.keys(evaluationData).length - 1);
    }

    const nextStep = () => {
        if (step === 3) {
            if (selectedPlayer == null) {
                toast.warn(t("Select a player"));
            }
            else if (checkIfAllFieldsAreNull(evaluationData)) {
                toast.warn(t("Fill in at least one field"));
            }else{
                saveTraining();
            }
        }else{
            setStep(step + 1)
        }
    }
    
    const saveTraining = async () => {
        if (editMode) {
            //patch
            try {
                const response = await axios.patch(process.env.REACT_APP_BACKEND + 'evaluations/update/' + location.state.evaluation.id, evaluationData, { headers });
                if (response.status === 200) {
                    navigate('/webapp/evaluations')
                } else {
                    console.log("Error")
                }
            }
            catch (error) {
                console.log(error)
            }
        }
        else {
            //POST
            try {
                const response = await axios.post(process.env.REACT_APP_BACKEND + 'evaluations/post', evaluationData, { headers });
                if (response.status === 201) {
                    navigate('/webapp/evaluations')
                } else {
                    console.log("Error")
                }
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    const getPlayers = async () => {

        try {
            const response = await axios.get(process.env.REACT_APP_BACKEND + "users/list-players", { headers });
            setPlayers(response.data);
        }
        catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        getPlayers();
        if (location.state && editMode) {
            setSelectedPlayer(location.state.evaluation.player.user.id);
        }
    }, [])



    useEffect(() => {
        setEvaluationData({ ...evaluationData, player: selectedPlayer })
    }, [selectedPlayer])

    const handleReturn = () => {
        // Return to the previous page
        navigate('/webapp/evaluations')
    }

    // AQUI E PARA SABER O QUE METER NOS FORMS
    const evaluationFormData = {
        1: {
            "name": "Physical Evaluation",
            "fields": [
                {
                    "label": "Standing height",
                    "type": "number",
                    "placeholder": "Height in cm",
                    "value": "heightP",
                },
                {
                    "label": "Sitting height",
                    "type": "number",
                    "placeholder": "Height in cm",
                    "value": "heightS",
                },
                {
                    "label": "Standing width",
                    "type": "number",
                    "placeholder": "Width in cm",
                    "value": "largeP",
                },
                {
                    "label": "Weight",
                    "type": "number",
                    "placeholder": "Weight in KG",
                    "value": "weight",
                },
            ]

        },
        2: {
            "name": "Jump",
            "fields": [
                {
                    "label": "Countermovement jump",
                    "type": "number",
                    "placeholder": "In cm",
                    "value": "cmj",
                },
                {
                    "label": "Squat jump",
                    "type": "number",
                    "placeholder": "In cm",
                    "value": "sj",
                },
            ]
        },
        3: {
            "name": "Speed and Agility",
            "fields": [
                {
                    "label": "10m",
                    "type": "number",
                    "placeholder": "seconds",
                    "value": "ten_meters",

                },
                {
                    "label": "20m",
                    "type": "number",
                    "placeholder": "seconds",
                    "value": "twenty_metersL",
                },
                {
                    "label": "30m",
                    "type": "number",
                    "placeholder": "seconds",
                    "value": "thirty_meters",
                },
                {
                    "label": "agility",
                    "type": "number",
                    "placeholder": "seconds",
                    "value": "zig_zag",

                },
                {
                    "label": "YO-YO",
                    "type": "number",
                    "placeholder": "palier-o-distancia",
                    "value": "yo_yoP",
                }

            ]

        }
    }

    const placeBackgroundImages = () => {
        const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
        const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
        const bckLogo1Height = bckLogo1.offsetHeight;
        bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
        bckLogo2.style.top = `0%`;
        const bckLogo1Width = bckLogo1.offsetWidth;
        bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
        bckLogo2.style.left = `75%`;
    }

    useEffect(() => {
        placeBackgroundImages();
        window.addEventListener('resize', placeBackgroundImages);
        return () => {
            window.removeEventListener('resize', placeBackgroundImages);
        }
    }, [])

    return (

        <div className="webapp-create-evaluation-page">
            <div className="webapp-create-team-background-images">
                <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
                <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
            </div>
            <div className="webapp-coach-paint-menu-return">
                <button onClick={handleReturn}><HiOutlineArrowLeft /></button>
                <h1> {editMode ? t('Edit Evaluation') : t('New Evaluation')}</h1>
            </div>
            <div className="webapp-create-training-header">
                <div className="webapp-create-training-header-left-inputs">
                    <div>
                        <label>{t('Player Name')}<span className="webapp-asterisco">*</span></label>
                        <div id="player" className={` select-player-dropdown-container ${openSelectPlayer ? 'open-select-player-dropdown-container' : missingFields.includes("player") ? 'create-evaluation-form-input-missing' : ''}`} onClick={handleOpenSelectPlayer}>
                            <span>
                                {selectedPlayer ? players.find((player) => player.user.id === selectedPlayer)?.user?.first_name + " " + players.find((player) => player.user.id === selectedPlayer)?.user?.last_name : t('Player Name')}
                            </span>
                            {!openSelectPlayer ? <div> <BiSolidDownArrow /> </div> : <div> <BiSolidUpArrow /> </div>}
                            {openSelectPlayer ? (
                                <div className="select-player-dropdown">
                                    {players.map((player) => {
                                        return (
                                            <div key={player.user.id} className={`webapp-players-injury-options ${selectedPlayer === player.user.id ? "selected-option-player-dropdown" : ""}`} onClick={() => changeSelectedPlayer(player.user.id)}>{player.user.first_name + " " + player.user.last_name}</div>
                                        );
                                    })}
                                </div>
                            ) : null}
                        </div>

                    </div>
                    <div>
                        <label>{t('Position')}<span>*</span></label>
                        <span>
                            {selectedPlayer ?
                                t(players.find((player) => player.user.id === selectedPlayer)?.position)
                                : " "
                            }
                        </span>
                    </div>
                    {editMode &&
                        <div className='webapp-injury-delete-button' onClick={() => handleDelete()}>
                            <img src={RedTrash} /> <span>{t('delete-evaluation')}</span>
                        </div>
                    }
                </div>
            </div>

            <div className="webapp-create-injury-container">
                <div className="webapp-create-training-exercise-info">
                    <div className="webapp-create-training-exercise-info-form">
                        <div className="create-training-info-form-step-header">
                            <span className={`${step === 1 && 'create-training-info-form-selected-step'}`} onClick={() => {handleSelectStep(1)}}>1</span>
                            <h3>{t(evaluationFormData[1].name)}</h3>
                        </div>
                        <div className="create-training-info-form-step-items">
                            {step === 1 ? <CreateEvaluationElements step={1} handleChangeEvaluationData={handleChangeEvaluationData} evaluationData={evaluationData} evaluationFormData={evaluationFormData} missingFields={missingFields} t={t} /> : null}
                        </div>
                        <div className="create-training-info-form-step-header">
                            <span className={`${step === 2 && 'create-training-info-form-selected-step'}`} onClick={() => {handleSelectStep(2)}}>2</span>
                            <h3>{t(evaluationFormData[2].name)}</h3>
                        </div>
                        <div className="create-training-info-form-step-items">
                            {step === 2 ? <CreateEvaluationElements step={2} handleChangeEvaluationData={handleChangeEvaluationData} evaluationData={evaluationData} evaluationFormData={evaluationFormData} missingFields={missingFields} t={t} /> : null}
                        </div>
                        <div className="create-training-info-form-step-header">
                            <span className={`${step === 3 && 'create-training-info-form-selected-step'}`} onClick={() => {handleSelectStep(3)}}>3</span>
                            <h3>{t(evaluationFormData[3].name)}</h3>
                        </div>
                        {step === 3 ? <CreateEvaluationElements step={3} handleChangeEvaluationData={handleChangeEvaluationData} evaluationData={evaluationData} evaluationFormData={evaluationFormData} missingFields={missingFields} t={t} /> : null}
                    </div>
                    <div className="webapp-create-training-change-step-buttons">
                        <button className="create-training-previous-step-button" onClick={previousStep}>{t('Previous')}</button>
                        {step === 3 ? <button className="create-training-save-button" onClick={nextStep}>{t('Save Evaluation')}</button> :
                            <button className="create-training-next-step-button" onClick={nextStep}>{t('Next')}</button>}
                    </div>
                </div>
            </div>



        </div>

    )
}

export default CreateEvaluationsPage
