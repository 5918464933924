import './InjuriesPage.css'

import axios from 'axios'
import { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'

import Sidebar from '../../webAppComponents/Sidebar/Sidebar'
import MapTable from '../../webAppComponents/MapTable/MapTable'
import TrainingsPageChanger from '../../webAppComponents/TrainingsPageChanger/TrainingsPageChanger'
import AuthContext from '../../context/AuthContext'
import FilterBy from '../../webAppComponents/FilterBy/FilterBy'

import { useTranslation } from 'react-i18next';

import BckLogo from "../../webAppAssets/BackgroundLogo.svg"

const InjuriesPage = () => {
    const { t } = useTranslation(['injuries-page']);

    const location = useLocation()
    const [rendering, setRendering] = useState(true)

    const [injuries, setInjuries] = useState([])
    const { authTokens } = useContext(AuthContext)
    const headers = {
        Authorization: `Bearer ${authTokens.access}`
    }
    const InjuriesColumns = [t('Position'), t('Mechanism'), t('Type'), t('Anatomical location'), t('Zone'), t('Laterality'), t('Context'), t('Specific context'), t('Detailed context'), t('Injury date'), t('Discharged date'), t('Days for discharge'), t('Return date'), t('Days for return')]
    const mappedColumns = {
        "id": "id",
        "ItemName": "player",
        [InjuriesColumns[0]]: "position",
        [InjuriesColumns[1]]: "mechanism",
        [InjuriesColumns[2]]: "type",
        [InjuriesColumns[3]]: "anatomical_location",
        [InjuriesColumns[4]]: "specific_zone",
        [InjuriesColumns[5]]: "laterality",
        [InjuriesColumns[6]]: "context",
        [InjuriesColumns[7]]: "specific_context",
        [InjuriesColumns[8]]: "detailed_context",
        [InjuriesColumns[9]]: "injury_date",
        [InjuriesColumns[10]]: "discharged_date",
        [InjuriesColumns[11]]: "days_for_discharge",
        [InjuriesColumns[12]]: "return_date",
        [InjuriesColumns[13]]: "days_for_return"
    }

    const [filterOptions, setFilterOptions] = useState([{ label: t('All players'), value: "" }])
    const [selectedPlayer, setSelectedPlayer] = useState("  ")
    const handleFilterChange = (e) => {
        setSelectedPlayer(e)
    }

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_BACKEND + `injuries-list?player=${location.state && rendering ? location.state.player.user.id : selectedPlayer}`, { headers })
            .then((response) => {
                const modifiedData = response.data.map(injury => ({
                    ...injury,
                    position: injury.player.position_acronym,
                    player: `${injury.player.user.first_name} ${injury.player.user.last_name}`
                }));
                setInjuries(modifiedData);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        
        setRendering(false);
    }, [location.state, selectedPlayer]);

    useEffect(() => {
        const getPlayers = async () => {
            try {
                const { data } = await axios.get(process.env.REACT_APP_BACKEND + "users/list-players", { headers });
                // Create a new array of filter options
                let newFilterOptions = data.map(player => ({
                    label: `${player.user.first_name} ${player.user.last_name}`,
                    value: player.user.id
                }));
                // Add the default option
                newFilterOptions.unshift({ label: t('All players'), value: "" });

                setFilterOptions(newFilterOptions);

            } catch (error) {
                console.error('Error:', error);
            }
        };
        getPlayers();
    }, []);


    const createInjury = () => {
        window.location.href = "/webapp/injuries/create";
    }

    const placeBackgroundImages = () => {
        const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
        const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
        const bckLogo1Height = bckLogo1.offsetHeight;
        bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
        bckLogo2.style.top = `0%`;
        const bckLogo1Width = bckLogo1.offsetWidth;
        bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
        bckLogo2.style.left = `75%`;
    }

    useEffect(() => {
        placeBackgroundImages();
        window.addEventListener('resize', placeBackgroundImages);
        return () => {
            window.removeEventListener('resize', placeBackgroundImages);
        }
    }, [])

    return (
        <div className='webapp-injuries-page'>
            <Sidebar selectedOption="injury" />
            <div className='webapp-injuries-page-content'>
                <div className="webapp-create-team-background-images">
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
                </div>
                <TrainingsPageChanger
                    selectedPage={0}
                    pages={[
                        { label: t('Team Injuries'), path: '/webapp/injuries' },
                        { label: t('Overall Statistics'), path: '/webapp/injuries/statistics' },
                        // { label: t('Training Statistics'), path: '/webapp/stats' },
                    ]}
                />
                <div className='webapp-injuries-page-content-header'>
                    <h1>{t('Injuries')}</h1>
                    <button className='webapp-add-injury-button' onClick={createInjury}>+ {t('Add new injury')}</button>
                </div>
                <div className='webapp-injuries-page-content-body'>
                    <div className='webapp-injuries-filterby'>
                        <FilterBy options={filterOptions} onChange={handleFilterChange}/>
                    </div>
                    <div className='webapp-injuries-table'>
                        <MapTable ItemName={t("Player")} detailRoute={'/webapp/injury/'} Data={injuries} columnNames={InjuriesColumns} mappedColumns={mappedColumns} t={t}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InjuriesPage