import { useState } from "react";
import "./GameDropdown.css";
import polygon from "../../webAppAssets/polygon.svg"

const GameDropdown = ({ selected, setSelected }) => {
    const [isActive, setIsActive] = useState(false)
    var options = []

    var hora = 0
    while (hora != 24) {
        options.push(hora + "h")
        options.push(hora + "h15")
        options.push(hora + "h30")
        options.push(hora + "h45")
        hora += 1
    }

    return (
        <div className="dropdown">
            <div id="create-game-start-time" className="dropdown-btn" onClick={e => setIsActive(!isActive)}>
                {selected !== '' ? (
                    <div>{selected}</div>
                ) : "HH:MM"}
                <img src={polygon} />
            </div>
            {isActive && (
                <div className="dropdown-content">
                    {options.map(option => (
                        <div onClick={() => {
                            setSelected(option);
                            setIsActive(false);
                        }}
                            className={`dropdown-item ${selected == option? 'item-selected' : null}`}>{option}</div>
                    ))}
                </div>
            )}
        </div>
    )
}
export default GameDropdown;
