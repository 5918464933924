import React, {useState, useEffect} from "react";
import "./Navbar.css";
import navbarLogo from "../../assets/navbar_logo.svg"
import { useMediaQuery } from 'react-responsive';
import hamburger from "../../assets/hamburger.svg"
import closeMenu from "../../assets/close-menu.svg"
import whiteLogo from "../../assets/3D_Branco.svg"
import { useInView } from 'react-intersection-observer';

const Navbar = (props) => {
   
    const [isNavbarFixed, setIsNavbarFixed] = useState(false);
    const [activeLink, setActiveLink] = useState('');
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false)
    const languages = ['PT', 'ES', 'EN']
    
    const navbarLanguageDict = {
        PT: {
            aboutUs: 'Missão e Valores',
            whoWeAre: 'Quem Somos',
            academy: 'Academy',
            packages: 'Preços de Lançamento',
            contact: 'Contactos',
            // ... other translations
        },
        ES: {
            aboutUs: 'Misión y Valores',
            whoWeAre: 'Quiénes Somos',
            academy: 'Academy',
            packages: 'Precios de Lanzamiento',
            contact: 'Contactos',
            // ... other translations
        },
        EN: {
            aboutUs: 'Mission and Values',
            whoWeAre: 'Who We Are',
            academy: 'Academy',
            packages: 'Launch Price',
            contact: 'Contacts',
            // ... other translations
        }
    };


    const toggleLanguageDropdown = () => {
        
        setLanguageDropdownOpen(!languageDropdownOpen);
    }

    const handleScroll = () => {
        const sections = ['aboutus-missao', 'aboutus-about', 'academy', 'packages', 'contactos'];
        for (const sectionId of sections) {
            const section = document.getElementById(sectionId);
            if (section) {
                const rect = section.getBoundingClientRect();
                if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
                    setActiveLink(sectionId);
                    break;
                }
            }
        }
        const homeSection = document.getElementById('aboutus-missao');
        if (homeSection) {
            const rect = homeSection.getBoundingClientRect();
            setIsNavbarFixed(window.scrollY > rect.bottom);
        }
       

        
    };

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
      };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (isMenuOpen) {
            document.documentElement.classList.add('menu-open');
        } else if (!isMobile) {
            document.documentElement.classList.remove('menu-open');
        } else {
            document.documentElement.classList.remove('menu-open');
        }
    }, [isMenuOpen]);
    

    const isMobile = useMediaQuery({ maxWidth: 1100 });

    return (
            
        <div className={`navbar ${isNavbarFixed ? 'fixed-navbar' : ''}`}>
            {!isMobile ?
            <div className="navbar-container">
                <div className="navbar-logo"> <img src= {navbarLogo}/></div> 
                <div className="navbar-links">
                    
                    <a href="#aboutus-missao" className={activeLink === 'aboutus-missao' ? 'active' : ''}>{navbarLanguageDict[props.selectedLanguage].aboutUs}</a>
                    <a href="#aboutus-about" className={activeLink === 'aboutus-about' ? 'active' : ''}>{navbarLanguageDict[props.selectedLanguage].whoWeAre}</a>
                    <a href="#academy" className={activeLink === 'academy' ? 'active' : ''}>{navbarLanguageDict[props.selectedLanguage].academy}</a>
                    <a href="#packages" className={activeLink === 'packages' ? 'active' : ''}>{navbarLanguageDict[props.selectedLanguage].packages}</a>
                    <a href="#contactos" className={activeLink === 'contactos' ? 'active' : ''}>{navbarLanguageDict[props.selectedLanguage].contact}</a>
                  
                </div>
                <div className="website-languages">
                    <span onClick={() => props.changeLanguage('PT')} className={props.selectedLanguage === 'PT' ? 'selected-language' : ''}>PT</span>
                    <span onClick={() => props.changeLanguage('ES')} className={props.selectedLanguage === 'ES' ? 'selected-language' : ''}>ES</span>
                    <span onClick={() => props.changeLanguage('EN')} className={props.selectedLanguage === 'EN' ? 'selected-language' : ''}>EN</span>
                </div>
                </div>
            : <div className="mobile-navbar-container">
                <img className="navbar-hamburger" src={hamburger} onClick={toggleMenu}/>
                {isMenuOpen && 
                <div className="mobile-hamburger-menu">
                    <div className="mobile-hamburger-close" onClick= {toggleMenu}><img src= {closeMenu}/></div>
                    <div className="mobile-hamburger-menu-items">
                        <img className="white-logo"src ={whiteLogo} ></img>
                        <div className="hamburger-menu-links">
                            <a href="#aboutus-missao" onClick= {toggleMenu} className={activeLink === 'aboutus-missao' ? 'white-active' : ''}>{navbarLanguageDict[props.selectedLanguage].aboutUs}</a>
                            <a href="#aboutus-about" onClick= {toggleMenu} className={activeLink === 'aboutus-about' ? 'white-active' : ''}>{navbarLanguageDict[props.selectedLanguage].whoWeAre}</a>
                            <a href="#academy" onClick= {toggleMenu} className={activeLink === 'academy' ? 'white-active' : ''}>{navbarLanguageDict[props.selectedLanguage].academy}</a>
                            <a href="#packages" onClick= {toggleMenu} className={activeLink === 'packages' ? 'white-active' : ''}>{navbarLanguageDict[props.selectedLanguage].packages}</a>
                            <a href="#contactos" onClick= {toggleMenu} className={activeLink === 'contactos' ? 'white-active' : ''}>{navbarLanguageDict[props.selectedLanguage].contact}</a>
                        </div>
                    </div>
                </div>
                }
                <div className={`language-dropdown ${isMenuOpen ? 'ocult' : ''}`}>
                    <div className="current-language" onClick={toggleLanguageDropdown}>
                        <span>{props.selectedLanguage}</span>
                    
                        <span className="dropdown-arrow" ></span>
                    </div>
                    <div className={`language-options ${languageDropdownOpen ? 'open' : ''}`}>
                        {languages
                            .filter(language => language !== props.selectedLanguage) // Exclude selected language
                            .map((language, index) => (
                                <span key={index} onClick={() => props.changeLanguage(language)}>
                                    {language}
                                </span>
                            ))}
                    </div>
                </div>

            </div>
            
            
            
            
            }
            
        </div>
        
    )

    }
export default Navbar