import './InjuryDetail.css';
import Sidebar from '../../webAppComponents/Sidebar/Sidebar';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { Body, Xray, BandageAdhesive, Bandaged, ExerciseRunning, ExerciseWeights, Sling, Symptom, ChartLine, Calendar, ChartCuredIncreasing, HealthDataSecurity, Observation, BrokenBone, ClinicalF } from '../../webAppAssets/InjuryDetails/ExportIcons';
import EditButton from '../../webAppComponents/EditButton/EditButton'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import axios from 'axios';
import TextPlaceHolder from '../../webAppComponents/PlaceHolders/PlaceHolders';
import { useTranslation } from 'react-i18next';

import BckLogo from "../../webAppAssets/BackgroundLogo.svg"

const InjuryDetail = () => {
    const { t } = useTranslation(['injuries-page']);
    const navigate = useNavigate();
    const { injuryId } = useParams();
    const [injury, setInjury] = useState([]);
    const { authTokens } = useContext(AuthContext);
    const headers = {
        Authorization: `Bearer ${authTokens.access}`
    }


    useEffect(() => {
        axios
            .get(process.env.REACT_APP_BACKEND + `injury/${injuryId}`, { headers })
            .then((response) => {

                // Convert "DD/MM/YYYY" to "YYYY-MM-DD" format
                const parts = response.data.injury_date.split('/');
                const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
                // Get the month from the date that is set as dd/mm/yyyy
                const injuryDate = new Date(formattedDate);
                const monthName = injuryDate.toLocaleString('default', { month: 'long' });
                response.data.injury_month = monthName;

                setInjury(response.data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });


    }
        , [injuryId]);

    const handleReturn = () => {
        navigate('/webapp/injuries')
    }

    function titleCase(str) {
        return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
            return match.toUpperCase();
        });
    }

    const handleEdit = () => {
        navigate(`/webapp/injuries/create`, { state: { injury, "editMode": true } })
    }

    const placeBackgroundImages = () => {
        const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
        const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
        const bckLogo1Height = bckLogo1.offsetHeight;
        bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
        bckLogo2.style.top = `0%`;
        const bckLogo1Width = bckLogo1.offsetWidth;
        bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
        bckLogo2.style.left = `75%`;
    }

    useEffect(() => {
        placeBackgroundImages();
        window.addEventListener('resize', placeBackgroundImages);
        return () => {
            window.removeEventListener('resize', placeBackgroundImages);
        }
    }, [])

    return (
        <div className='webapp-injury-detail-page'>
            <Sidebar selectedOption="injury" />
            <div className='webapp-injury-detail-page-content'>
                <div className="webapp-create-team-background-images">
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
                </div>
                <div className="webapp-injury-detail-menu-return">
                    <button onClick={handleReturn}><HiOutlineArrowLeft /></button>
                    <h1>{t('Player Injury')}</h1>
                </div>
                <div className='webapp-injury-detail-header'>
                    {injury.player ?
                        <div className='webapp-injury-detail-player'>
                            <h2 className='webapp-injury-detail-player-name'>{titleCase(injury.player.user.first_name + ' ' + injury.player.user.last_name)} </h2>
                            <span className='webapp-injury-detail-player-position'>{t(injury.player.position)}</span>
                        </div>
                        :
                        <div className='webapp-injury-detail-player'>
                            <TextPlaceHolder fontSize={2} />
                            <TextPlaceHolder fontSize={1} />
                        </div>
                    }
                    <EditButton handleFunction={handleEdit} />
                </div>
                <div className='webapp-injury-detail-content'>
                    <div className='webapp-injury-detail-table'>
                        <h3>{t('Injury Information')}</h3>
                        <div className='webapp-injury-detail-table-row'>
                            <div className='webapp-injury-detail-table-row-cell'>
                                <div className='cell-header'>
                                    <img src={Body} alt="Body Icon" />
                                    <h4 className='cell-header'>{t('Mechanism')}</h4>
                                </div>
                                {injury ?
                                    <span className='cell-content'>{t(injury.mechanism)}</span>
                                    :
                                    <TextPlaceHolder fontSize={1} />
                                }
                            </div>
                            <div className='webapp-injury-detail-table-row-cell'>
                                <div className='cell-header'>
                                    <img src={Sling} alt="Body Icon" />
                                    <h4 className='cell-header'>{t('Type')}</h4>
                                </div>
                                {injury ?
                                    <span className='cell-content'>{t(injury.type)}</span>
                                    :
                                    <TextPlaceHolder fontSize={1} />
                                }
                            </div>
                            <div className='webapp-injury-detail-table-row-cell'>
                                <div className='cell-header'>
                                    <img src={Xray} alt="Xray Icon" />
                                    <h4 className='cell-header'>{t('Anatomical location')}</h4>
                                </div>
                                {injury ?
                                    <span className='cell-content'>{t(injury.anatomical_location)}</span>
                                    :
                                    <TextPlaceHolder fontSize={1} />
                                }
                            </div>
                        </div>
                        <div className='webapp-injury-detail-table-row'>
                            <div className='webapp-injury-detail-table-row-cell'>
                                <div className='cell-header'>
                                    <img src={Symptom} alt="Affected zone in person Icon" />
                                    <h4 className='cell-header'>{t('Zone')}</h4>
                                </div>
                                {injury ?
                                    <span className='cell-content'>{t(injury.specific_zone)}</span>
                                    :
                                    <TextPlaceHolder fontSize={1} />
                                }
                            </div>
                            <div className='webapp-injury-detail-table-row-cell'>
                                <div className='cell-header'>
                                    <img src={Bandaged} alt="Body Icon" />
                                    <h4 className='cell-header'>{t('Laterality')}</h4>
                                </div>
                                {injury ?
                                    <span className='cell-content'>{t(injury.laterality)}</span>
                                    :
                                    <TextPlaceHolder fontSize={1} />
                                }
                            </div>
                            <div className='webapp-injury-detail-table-row-cell'>
                                <div className='cell-header'>
                                    <img src={ExerciseWeights} alt="Weights exercise Icon" />
                                    <h4 className='cell-header'>{t('Context')}</h4>
                                </div>
                                {injury ?
                                    <span className='cell-content'>{t(injury.context)}</span>
                                    :
                                    <TextPlaceHolder fontSize={1} />
                                }
                            </div>
                        </div>
                        <div className='webapp-injury-detail-table-row'>
                            <div className='webapp-injury-detail-table-row-cell'>
                                <div className='cell-header'>
                                    <img src={ExerciseRunning} alt="Running Icon" />
                                    <h4 className='cell-header'>{t('Specific context')}</h4>
                                </div>
                                {injury ?
                                    <span className='cell-content'>{t(injury.specific_context)}</span>
                                    :
                                    <TextPlaceHolder fontSize={1} />
                                }
                            </div>
                            <div className='webapp-injury-detail-table-row-cell'>
                                <div className='cell-header'>
                                    <img src={BandageAdhesive} alt="Bandage Icon" />
                                    <h4 className='cell-header'>{t('Detailed context')}</h4>
                                </div>
                                {injury ?
                                    <span className='cell-content'>{t(injury.detailed_context)}</span>
                                    :
                                    <TextPlaceHolder fontSize={1} />
                                }
                            </div>
                            <div className='webapp-injury-detail-table-row-cell'>
                                <div className='cell-header'>
                                    <img src={ChartLine} alt="Chart Line going up icon" />
                                    <h4 className='cell-header'>{t('SI of ther provocative exercise')}</h4>
                                </div>
                                {injury ?
                                    <span className='cell-content'>{t(injury.exercise_specificity)}</span>
                                    :
                                    <TextPlaceHolder fontSize={1} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='webapp-injury-detail-table'>
                        <h3> {t('Injury date')} </h3>
                        <div className='webapp-injury-detail-table-row'>
                            <div className='webapp-injury-detail-table-row-cell'>
                                <div className='cell-header'>
                                    <img src={ClinicalF} alt="Clinical report icon" />
                                    <h4 className='cell-header'>{t('Injury date')}</h4>
                                </div>
                                {injury ?
                                    <span className='cell-content'>{injury.injury_date}</span>
                                    :
                                    <TextPlaceHolder fontSize={1} />
                                }
                            </div>
                            <div className='webapp-injury-detail-table-row-cell'>
                                <div className='cell-header'>
                                    <img src={BrokenBone} alt="Broken Bone icon" />
                                    <h4 className='cell-header'>{t('Injury month')}</h4>
                                </div>
                                {injury ?
                                    <span className='cell-content'>{t(injury.injury_month)}</span>
                                    :
                                    <TextPlaceHolder fontSize={1} />
                                }
                            </div>
                        </div>
                        <div className='webapp-injury-detail-table-row'>
                            <div className='webapp-injury-detail-table-row-cell'>
                                <div className='cell-header'>
                                    <img src={HealthDataSecurity} alt="Proctected health data icon" />
                                    <h4 className='cell-header'>{t('Discharged date')}</h4>
                                </div>
                                {injury ?
                                    <span className='cell-content'>{injury.discharged_date}</span>
                                    :
                                    <TextPlaceHolder fontSize={1} />
                                }
                            </div>
                            <div className='webapp-injury-detail-table-row-cell'>
                                <div className='cell-header'>
                                    <img src={ChartCuredIncreasing} alt="Cured chart line increase icon" />
                                    <h4>{t('Return date')}</h4>
                                </div>
                                {injury ?
                                    <span className='cell-content'>{injury.return_date}</span>
                                    :
                                    <TextPlaceHolder fontSize={1} />
                                }
                            </div>
                        </div>
                        <div className='webapp-injury-detail-table-row'>
                            <div className='webapp-injury-detail-table-row-cell'>
                                <div className='cell-header'>
                                    <img src={Calendar} alt="Calendar Icon" />
                                    <h4>{t('Days for discharge')}</h4>
                                </div>
                                {injury ?
                                    <span className='cell-content'>{injury.days_for_discharge}</span>
                                    :
                                    <TextPlaceHolder fontSize={1} />
                                }
                            </div>
                            <div className='webapp-injury-detail-table-row-cell'>
                                <div className='cell-header'>
                                    <img src={Observation} alt="Icon Laydown person" />
                                    <h4>{t('Days for return')}</h4>
                                </div>
                                {injury ?
                                    <span className='cell-content'>{injury.days_for_return}</span>
                                    :
                                    <TextPlaceHolder fontSize={1} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='webapp-injury-detail-table'>
                    <h3> {t('Microcycle')}</h3>
                    <div className='webapp-injury-detail-table-row'>
                        <div className='webapp-injury-detail-table-row-cell'>
                            <div className='cell-header'>
                                <h4>{t('LU Last microcycle')}</h4>
                            </div>
                            {injury ?
                                <span className='cell-content'>{injury.uc_before_injury === null ? 0 : injury.uc_before_injury}</span>
                                :
                                <TextPlaceHolder fontSize={1} />
                            }
                        </div>
                        <div className='webapp-injury-detail-table-row-cell'>
                            <div className='cell-header'>
                                <h4>{t('UC MC before return')}</h4>
                            </div>
                            {injury ?
                                <span className='cell-content'>{injury.uc_after_injury === null ? "" : injury.uc_after_injury}</span>
                                :
                                <TextPlaceHolder fontSize={1} />
                            }
                        </div>
                        <div className='webapp-injury-detail-table-row-cell'>
                            <div className='cell-header'>
                                <h4>{t('Difference MC')}</h4>
                            </div>
                            {injury ?
                                <span className='cell-content'>{injury.uc_after_injury !== null ? injury.uc_before_injury - injury.uc_after_injury : ""}</span>
                                :
                                <TextPlaceHolder fontSize={1} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InjuryDetail;